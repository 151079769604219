import { SET_OPCION_VISTA,SET_CARGANDO_FILTROS,SET_NAVIGATION,SET_FOOTER_VISIBLE } from "./configTypes";

const initialState = {
  opcionVista:"",
  cargandoFiltros:false,
  navigationRedux:'',
  footerVisible:false
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case SET_OPCION_VISTA: {
      state.opcionVista = action.payload.opcionVista
      return {...state}
    }
    case SET_CARGANDO_FILTROS: {
      state.cargandoFiltros = action.payload.opcion
      return {...state}
    }
    case SET_NAVIGATION: {
      state.navigationRedux = action.payload.navigationRedux
      return {...state}
    }
    case SET_FOOTER_VISIBLE: {
      state.footerVisible = action.payload.footerVisible
      return {...state}
    }
    default:
      return state;
  }
};

export default configReducer;