import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Divider from "../../../../../components/Divider";
import Plate from "./Plate";

const Category = ({ category, selectedPlates, addPlate, removePlate }) => {
  const { min, max, name, gid } = category;

  const sumPlates = selectedPlates.reduce((suma, item) => {
    if (item.fk_plate === gid) {
      return suma + item.cantidad;
    }
    return suma;
  }, 0);
  console.log("eloy sumPlates", sumPlates);
  const subText =
    min === max
      ? `Elige ${min} ${min === 1 ? "opción" : "opciones"}`
      : `Elige entre ${min} y ${max} opciones`;

  const selectionAllowed = sumPlates < max;
  return (
    <>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{name}</Text>
        <Text>{subText}</Text>
      </View>
      {category?.products.map((plate) => {
        const quantity =
          selectedPlates.find((item) => item.gid === plate.gid)?.cantidad || 0;
        if (plate.active === 1) {
          return (
            <Plate
              isMenu={true}
              data={{ ...plate, fk_plate: category.gid }}
              onPress={selectionAllowed ? addPlate : () => {}}
              onRemove={removePlate}
              quantity={quantity}
            />
          );
        }
      })}
      <Divider height={20} />
    </>
  );
};

export default Category;

const styles = StyleSheet.create({
  titleContainer: {
    width: "100%",
  },
  title: {
    fontSize: 28,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
});
