import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

// Components
import Divider from "../Divider";

const Footer = ({ leftTitle, rightTitle, leftAction, rightAction }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {leftTitle && (
          <>
            <TouchableOpacity style={styles.left} onPress={leftAction}>
              <Text style={styles.leftText}>{leftTitle}</Text>
            </TouchableOpacity>
            <Divider width={20} />
          </>
        )}
        <TouchableOpacity style={styles.right} onPress={rightAction}>
          <Text style={styles.rightText}>{rightTitle}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Footer;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    zIndex: 3,
    height: 90,
    position: "absolute",
    bottom: 0,
    alignItems: "center"
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
    maxWidth: 850,
  },
  left: {
    borderWidth: 1,
    borderRadius: 35,
    height: 65,
    backgroundColor: "white",
    borderColor: "#C9C9C9",
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    flex: 3,
    height: 65,
    borderRadius: 35,
    backgroundColor: "#009683",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#009683",
  },
  leftText: {
    fontWeight: "400",
    fontSize: 30,
    color: "#333333",
  },
  rightText: {
    fontWeight: "400",
    fontSize: 30,
    color: "white",
  },
});
