import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, FlatList } from "react-native";
import { vaciarCarrito, addProducto, setConexion } from "../../reducers";
import { connect } from "react-redux";
import OrdersList from "./components/OrdersList";
import FocusedOrder from "./components/FocusedOrder";
import Divider from "../FoodStallScreen/components/Divider";
import { getOrderNumber } from "./methods/getOrderNumber";
// import { getRoomNumber } from "./methods/getRoomNumber";

const PROCESSING_STATUSES = ["scheduled", "waitint", "processing"];
const READY_STATUSES = [
  "await_pickup",
  "await_shipment",
  "partially_delivered",
];

const FoodStallOrdersScreen = ({ navigation, orders }) => {
  const [processingOrders, setProcessingOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [ordersToShow, setOrdersToShow] = useState([]);
  const [shownOrders, setShownOrders] = useState([]);
  const [currentTitle, setCurrentTitle] = useState();
  const [titleEnabled, setTitleEnabled] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);

  useEffect(() => {
    let processingAux = [];
    let readyAux = [];
    orders.map((order) => {
      const roomNumber = order.order_number;
      if (roomNumber !== null) {
        const data = {
          // order: getOrderNumber(order.order_number),
          order: order.order_number,
          room: roomNumber,
        };

        if (PROCESSING_STATUSES.includes(order.state)) {
          processingAux.push(data);
        } else if (READY_STATUSES.includes(order.state)) {
          readyAux.push(data);
        }
      }
    });

    if (titleEnabled) {
      let ordersToShowAux = readyAux.filter(
        (itemA) => !shownOrders.some((itemB) => itemB.order === itemA.order)
      );
      ordersToShowAux = ordersToShowAux.map((item) => item);
      setOrdersToShow(ordersToShowAux);
    } else {
      setShownOrders([...readyAux]);
      setTitleEnabled(true);
    }

    setProcessingOrders(processingAux);
    setReadyOrders(readyAux);
  }, [orders]);

  useEffect(() => {
    const rotateTitles = () => {
      if (ordersToShow.length > 0) {
        const [nextOrder, ...remainingTitles] = ordersToShow;
        setShownOrders([...shownOrders, nextOrder]);
        setOrdersToShow(remainingTitles);
        setCurrentTitle(nextOrder.room);
      } else {
        setCurrentTitle(null);
      }
    };

    const intervalId = setInterval(rotateTitles, 3000);

    return () => clearInterval(intervalId);
  }, [ordersToShow]);

  return (
    <View style={styles.container}>
      <OrdersList list={processingOrders} title={"Preparando / Cooking"} />
      <Divider width={2} color="#E4F3F0" type="full-height" />
      <OrdersList list={readyOrders} title={"Listo / Ready"} />
      <FocusedOrder order={currentTitle} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 30,
    backgroundColor: "white",
  },
});

const mapStateToProps = (state: any) => {
  return {
    orders: state.pedidos.pedidos,
  };
};
const mapDispatchToProps = {
  vaciarCarrito,
  addProducto,
  setConexion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoodStallOrdersScreen);
