import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import LanguageSelector from "../LanguageSelector";
import Logo from "../Logo";

const Header = ({ data }) => {
  const { customDesign } = data;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Logo image={customDesign?.logo} />
        <LanguageSelector />
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 70,
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
    elevation: 1,
    backgroundColor: "white",
  },
  content: {
    maxWidth: 1200,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: "100%",
    paddingHorizontal: 18,
  },
});
