import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import gbTranslation from './languages/gb.json';
import esTranslation from './languages/es.json';
import deTranslation from './languages/de.json';
import ptTranslation from './languages/pt.json';
import frTranslation from './languages/fr.json';
import itTranslation from './languages/it.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: gbTranslation },
    es: { translation: esTranslation },
    de: { translation: deTranslation },
    fr: { translation: frTranslation },
    it: { translation: itTranslation },
    pt: { translation: ptTranslation },
  },
  language: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;