import { removeDuplicates } from "./removeDuplicates";

export const getProductSections = (products = []) => {
  const productSectionsMap = new Map();

  for (const product of products) {
    const { product_family_name, fk_product_family, gid } = product;

    const auxProduct = {
      ...product,
      gid: gid,
      family: fk_product_family,
    };
    if (!productSectionsMap.has(fk_product_family)) {
      productSectionsMap.set(fk_product_family, {
        title: product_family_name,
        gid: fk_product_family,
        data: [auxProduct],
      });
    } else {
      productSectionsMap.get(fk_product_family).data.push(auxProduct);
    }
  }

  const productSections = Array.from(productSectionsMap.values());
  return removeDuplicates(productSections);
};
