import Producto from './Producto'

export default class LineaPedido{
  id=null;
  producto = null;
  estado =null;
  cantidad = 1
  oculto = false;
  constructor(obj:any) {
    this.id=obj.id || '' ;
    this.producto = obj.producto || '';
    this.estado = obj.estado || '';
    this.cantidad = obj.cantidad || 1;
    this.oculto = obj.oculto  || false
  }
  
} 