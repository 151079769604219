import {SET_ULTIMO_PEDIDO,SET_PEDIDOS_PREPARAR,ORDENAR_FILTRADOS, SET_PEDIDOS,SET_PEDIDOS_FILTRADOS,ADD_PEDIDOS,DELETE_PEDIDO,EDIT_PEDIDOS,SET_SOCKET_CONECTADO,SET_PEDIDOS_PERDIDOS,SET_PEDIDO_SELECCIONADO,SET_PEDIDOS_ENTREGAR,SET_PEDIDOS_ENVIAR,SET_PEDIDOS_COMPLETADOS} from "./pedidosTypes";


export const setPedidosFiltrados = (pedidosFiltrados:any) => {
  
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_FILTRADOS,
      payload: {
        pedidos: pedidosFiltrados
      }
    });
     
  };
};
export const addPedido = (pedido:any) => {
  return (dispatch:any) => {
    dispatch({
      type: ADD_PEDIDOS,
      payload: {
        
        pedido: pedido
      }
    });
  };
};
export const eliminarPedido = (pedidosFiltrados:any) => {
  return (dispatch:any) => {
    dispatch({
      type: DELETE_PEDIDO,
      payload: {
        pedidos: pedidosFiltrados
      }
    });
  };
};
export const editarPedido = (pedido:any) => {
  return (dispatch:any) => {
    dispatch({
      type: EDIT_PEDIDOS,
      payload: {
        pedido: pedido
      }
    });
  };
};
export const setPedidos = (pedidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS,
      payload: {
        pedidos: pedidos
      }
    });
  };
};
export const ordenarFiltrados = (option:any) => {
  return (dispatch:any) => {
    dispatch({
      type: ORDENAR_FILTRADOS,
      payload:{
        opcion:option
      }
    });
  };
};
export const setSocketConectado = (socketConectado:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_SOCKET_CONECTADO,
      payload: {
        socketConectado: socketConectado
      }
    });
  };
};
export const setPedidosPerdidos = (pedidosPerdidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_PERDIDOS,
      payload: {
        pedidosPerdidos: pedidosPerdidos
      }
    });
  };
};
export const setPedidoSeleccionado = (pedidoSeleccionado:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDO_SELECCIONADO,
      payload: {
        pedidoSeleccionado: pedidoSeleccionado
      }
    });
  };
};
export const setPedidosParaEntregar = (pedidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_ENTREGAR,
      payload: {
        pedidos: pedidos
      }
    });
  };
};
export const setPedidosEnviados = (pedidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_ENVIAR,
      payload: {
        pedidos: pedidos
      }
    });
  };
};
export const setPedidosCompletados = (pedidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_COMPLETADOS,
      payload: {
        pedidos: pedidos
      }
    });
  };
};
export const setPedidosPreparar = (pedidos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_PREPARAR,
      payload: {
        pedidos: pedidos
      }
    });
  };
};
export const setUltimoPedido = (ultimoPedido:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_ULTIMO_PEDIDO,
      payload: {
        ultimoPedido: ultimoPedido
      }
    });
  };
};