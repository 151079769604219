import React,{Component} from 'react';

import {Dimensions,Image,StyleSheet,TouchableOpacity,Text,View} from "react-native";
const ProductoTicket = (props:any) => {
    const {producto} = props
    return(
       <View style={{paddingBottom:10}}>
          <View style={{flexDirection:'row',justifyContent:'space-between'}}>
            <View style={{flex:1,flexDirection:'row'}}>
              <Text style={{fontWeight:'bold',color:'#3AA597'}}>{producto.cantidad}x </Text>
              <Text style={{fontWeight:'bold'}}>{producto.name}</Text>
            </View>
            <Text style={{color:'#3AA597'}}>{producto.cantidad*producto.amount}€</Text>
          </View>
        </View>
    );
    
}
export default ProductoTicket;