import React from "react";
import { StyleSheet, Text, View } from "react-native";

const Description = ({ data }) => {
  const isValidData = data !== "" && data !== null && data !== undefined;
  if (isValidData) {
    return (
      <View style={styles.description}>
        <Text style={styles.text}>{data}</Text>
      </View>
    );
  } else {
    return <View />;
  }
};

export default Description;

const styles = StyleSheet.create({
  description: {
    width: "100%",
    paddingTop: 0,
    padding: 28,
  },
  text: {
    fontSize: 30,
    textAlign: "left",
    color: "black",
    fontWeight: "200",
  },
});
