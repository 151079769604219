import React from "react";
import { StyleSheet, View } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../../../components/MainButton";
import CustomInput from "../../../components/CustomInput";
import ModalBase from "../../../components/ModalBase";
import Divider from "../../../components/Divider";

const CommentModal = ({ visible, setModal, comment, setComment }) => {
  const {t} = useTranslation()
  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <CustomInput
          multiline
          value={comment}
          onChange={setComment}
          placeholder={t("comment.placeholder")}
        />
        <Divider height={10} />
        <View style={styles.buttonWrapper}>
          <MainButton
            title={t("cancel")}
            onPress={() => {
              setModal();
            }}
            color="white"
            textColor="black"
            borderColor="black"
            borderWidth={1}
          />
          <Divider width={10} />
          <MainButton
            title={t("comment.add")}
            onPress={() => {
              setModal();
            }}
            color="#3AA597"
            textColor="white"
          />
        </View>
      </View>
    </ModalBase>
  );
};

export default CommentModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    height: 300,
  },
  buttonWrapper: { height: 50, flexDirection: "row" },
});
