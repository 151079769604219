import React, { useState, useRef } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

const AccordionListItem = ({ title, children, opened, titleBackgroud }:any) => {

  const [open, setOpen] = useState(false);
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(0);

  let bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });
  let arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });
  if(opened){
    bodyHeight = animatedController.interpolate({
      inputRange: [0,1],
      outputRange: [bodySectionHeight, 0],
    })
    arrowAngle = animatedController.interpolate({
      inputRange: [0, 1],
      outputRange: [`${Math.PI}rad`, '0rad'],
    });
  }

  

  const toggleListItem = () => {
    console.log("animatedController",animatedController)
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver:false
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver:false
      }).start();
    }
    setOpen(!open);
  };

  return (
    <View  style={{width:'100%'}}>
      <TouchableWithoutFeedback onPress={() => toggleListItem()}>
        <View style={[styles.titleContainer,{backgroundColor:titleBackgroud || '#E5E5E5'}]}>
          <View style={{}}>{title}</View>
          <Animated.View style={{right:20,transform: [{ rotateZ: arrowAngle }] }}>
            <MaterialIcons name="keyboard-arrow-down" size={20} color="black" />
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground,{height: bodyHeight}]}>
        <View
          style={styles.bodyContainer}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {children}
        </View>
      </Animated.View>
    </View>
  );
};
export default AccordionListItem;

const styles = StyleSheet.create({
  bodyBackground: {
    overflow: 'hidden',
    width:'100%'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex:1,
    backgroundColor:'#E5E5E5'
  },
  bodyContainer: {
    position: 'absolute',
    bottom: 0,
    width:'100%'
  },
});
