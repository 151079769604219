import React,{useState, useEffect, useRef} from 'react';
import {Dimensions,StyleSheet,TouchableOpacity,Text,View} from "react-native";
import { connect } from "react-redux";

const PedidoCamarero = (props:any) => {

    const {pedido,seleccionarPedido,precio} = props
  
      
    return(
        <View style={{width:'100%'}}>
           
            <TouchableOpacity onPress={seleccionarPedido} style={[{flexDirection:'row',backgroundColor:'white',borderRadius: 16,marginBottom:8,borderWidth:1},props.pedidoSeleccionado.gid==pedido.gid?{borderColor:'#3AA597'}:{borderColor:'white'}]}>
                <View style={{padding:10}}>
                    <Text style={{fontWeight:'bold',fontSize: 14,color:'#3AA597',paddingTop:4,paddingBottom:4}}>#{pedido.order_number}</Text>
                    <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>{pedido.hora}</Text>
                </View>
               
                <View style={{flex:1,alignItems:'center',padding:10,justifyContent:'center'}}>
                    <Text style={{fontWeight:'bold',fontSize: 12,paddingTop:4,paddingBottom:4}}>{pedido.event.name || ''}</Text>
                    <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>{pedido.shop.name}</Text>
                </View>

                <View style={{alignItems:'center',justifyContent:'center',padding:10}}>
                    <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4,textAlignVertical:'center'}}>
                       {pedido.calcularPrecioPedido().toFixed(2)}€
                    </Text>
                </View>

            </TouchableOpacity>
        </View>
    );
    
}

const mapStateToProps = (state:any) => {
  return {
   
   pedidoSeleccionado:state.pedidos.pedidoSeleccionado
  };
};
export default connect(mapStateToProps)(PedidoCamarero);