import React from "react";
import { TextInput, StyleSheet } from "react-native";

const CustomInput = ({
  onChange,
  placeholder,
  value = "",
  secure = false,
  multiline = false,
  viewOnly=false,
  isDiscount = false,
  ...props
}) => (
  <TextInput
    style={[
      styles.input,
      multiline === true && { flex: 1, paddingTop: 10 },
      // TODO: captura different TextFields so to know which style to use, isDiscount created for discountFildOnly
      isDiscount === true && { flex: 1, width: undefined },
      props,
    ]}
    onChangeText={onChange}
    placeholderTextColor={"grey"}
    value={value}
    editable={viewOnly ? false : true}
    placeholder={placeholder}
    secureTextEntry={secure}
    autoCapitalize="none"
    multiline={multiline}
    {...props}
  />
);

const styles = StyleSheet.create({
  input: {
    width: "100%",
    paddingHorizontal: 8,
    paddingVertical: 5.5,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "grey",
    fontSize: 22,
    color: "black",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
});

export default CustomInput;
