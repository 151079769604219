import React, { useContext, useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  StyleSheet,
  Text,
  ScrollView,
  View,
  useWindowDimensions,
} from "react-native";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Components
import Divider from "../../components/Divider";
import Footer from "../../components/Footer";
import OrderList from "./components/OrderList";
import Header from "../../components/Header";
import RoomNumberModal from "./modals/RoomNumberModal";
import CommentModal from "./modals/CommentModal";
import FinishModal from "./modals/FinishModal";
import BackButton from "./components/BackButton";
import ErrorModal from "./modals/ErrorModal";

// Context
import { FoodStallContext } from "../../context/FoodStallContext";

// Methods
import { getOrder } from "./methods/getOrder";

// Reducers
import usuarioCalls from "../../../../funcionalidades/usuarioCalls";
import { vaciarCarrito, setUltimoPedido } from "../../../../reducers";

// Objects
import Pedido from "../../../../objetos/Pedido";

const OrderResume = ({ tiendas, ...props }) => {
  const [modal, setModal] = useState("");
  const [comment, setComment] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [error, setError] = useState();
  const navigation = useNavigation();
  const height = useWindowDimensions().height;
  const { shop } = useContext(FoodStallContext);

  const { t } = useTranslation();

  function finalizarPedido(carritoDatafonoAux: any, option: any) {
    let carritoAux = carritoDatafonoAux.carrito;

    for (let i = 0; i < carritoAux.orders?.length; i++) {
      let order = carritoAux.orders[i];
      let pedido = new Pedido(order);
      pedido.payment_method = carritoAux.payment_method || "cash";
      pedido.comment = carritoAux.comment || "";
      pedido.cashPaid = carritoAux.cashPaid
        ? Math.floor(parseFloat(carritoAux.cashPaid) * 100)
        : carritoAux.amount;
      props.setUltimoPedido(pedido);
    }
    if (option != "on") {
      global.usuario.comprobarCarritosDatafono();
    }
  }

  const resetNavigation = () => {
    props.vaciarCarrito();
    if (props.route != undefined) {
      props.navigation.goBack();
    }
  };

  const finishHandler = () => {
    try {
      setComment("");
      const order = getOrder({
        tiendas: tiendas,
        comment: comment,
        roomNumber: roomNumber,
        cashPaid: 0,
      });

      const carritoAux = {
        carrito: { orders: [order] },
        payment_method: "cash",
      };

      usuarioCalls
        .crearPedidoDatafono(carritoAux)
        .then(async function (res: any) {
          if (res.status === "success") {
            finalizarPedido(carritoAux, "off");
            setModal("Finish");
          } else {
            setError(res.message);
            setModal("Error");
          }
        });
    } catch (error) {
      setError(error.message);
      setModal("Error");
    }
  };

  useEffect(() => {
    navigation.setOptions({
      header: () => <Header data={shop} />,
    });
  }, []);

  const backHandler = () => {
    navigation.goBack();
  };

  return (
    <ScrollView
      style={[{ backgroundColor: "white", height: height - 120 }]}
      contentContainerStyle={{ flex: 1 }}
    >
      <View style={styles.screen}>
        {tiendas.length > 0 ? (
          <View style={styles.content}>
            <BackButton action={backHandler} />
            <Text style={styles.title}>{t("orderDetails") + ": "}</Text>
            <Divider height={20} />
            <OrderList />
          </View>
        ) : (
          <View style={styles.emptyWrapper}>
            <Text style={styles.title}>{t("emptyCart")}</Text>
          </View>
        )}
      </View>
      {tiendas.length > 0 ? (
        <Footer
          leftTitle={t("comment.title")}
          rightTitle={t("finish")}
          leftAction={() => setModal("Comment")}
          rightAction={() => setModal("RoomNumber")}
        />
      ) : (
        <Footer
          leftTitle={null}
          rightTitle={t("back")}
          leftAction={null}
          rightAction={() => navigation.goBack()}
        />
      )}
      <CommentModal
        setModal={setModal}
        visible={modal === "Comment"}
        comment={comment}
        setComment={setComment}
      />
      <RoomNumberModal
        setModal={setModal}
        visible={modal === "RoomNumber"}
        roomNumber={roomNumber}
        setRoomNumber={setRoomNumber}
        onFinish={finishHandler}
      />
      <FinishModal
        visible={modal === "Finish"}
        setModal={setModal}
        onPress={resetNavigation}
      />
      <ErrorModal
        visible={modal === "Error"}
        setModal={setModal}
        error={error}
      />
    </ScrollView>
  );
};

const mapStateToProps = (state) => {
  return { tiendas: state.carrito.tiendas };
};

const mapDispatchToProps = {
  vaciarCarrito,
  setUltimoPedido,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderResume);

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 850,
    padding: 32,
  },
  emptyWrapper: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 70,
    maxWidth: 850,
  },
  title: {
    fontSize: 25,
  },
});
