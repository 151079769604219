import React, { useState, useEffect, useRef } from 'react';
import { FlatList,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';
import { StatusBar } from 'expo-status-bar';
import {Octicons,MaterialIcons,FontAwesome  } from '@expo/vector-icons';

import MasonryList from '@react-native-seoul/masonry-list';

import ProductoTicket from '../components/ProductoTicket';
import { connect } from "react-redux";
import { LinearGradient } from 'expo-linear-gradient';

import ProductoFoto from '../components/ProductoFoto';
const VistaFamilias = (props:any) => {
  let tienda
  const [precioTotal, setPrecioTotal] = useState(0);
  const [totalProductos, setTotalProductos] = useState(0);
  if(props.route!=undefined){
    tienda=props.route.params.tienda
  }else{
    tienda=props.tienda
  }

    useEffect(() => {
        props.navigation.setOptions({
            headerTitle:tienda.name
        });
    }, []);

  useEffect(() => {
    let precioTotalAux = 0
    let totalProductosAux = 0
    props.tiendas.map(function(tienda:any) {
      let precioTienda = 0
      tienda.productos.map(function(item:any) {
        precioTienda = precioTienda + item.cantidad*item.amount
        totalProductosAux=totalProductosAux+item.cantidad
      })
      
      precioTotalAux=precioTotalAux+precioTienda
    })
    setTotalProductos(totalProductosAux)
    setPrecioTotal(precioTotalAux)
  }, [props.tiendas]);
  const [familiaSel, setFamiliaSel] = useState(tienda.products[0]);
 
  function abrirVistaTicket(familia:any){
    let aux={
      tienda:tienda
    }
    props.navigation.navigate('VistaResumenPedido',aux)
  }

  function onClickFamilia(familia:any){
    let aux={
      familia:familia,
      tienda:tienda
    }
    props.navigation.navigate('listadoProductos',aux)
    setFamiliaSel(familia)
  }
  /*
  */
  return (

        <View style={{backgroundColor:'white',width:'100%',height:'100%'}}>
          <View style={{height:50}}>
            <View style={{flex:1,justifyContent:'center'}}>
              <LinearGradient
                colors={[ '#3AA597','white']}
                start={{x: 0, y: 0}}
                end={{x: 1, y: 0}}
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  height: 50
                }}
              />
              <Text style={{paddingLeft:20,zIndex:2,fontSize:18,color:'white'}}>Familias</Text>
            </View>
          </View>
          <View style={{marginTop:4,marginBottom:4,height:'100%'}}>

            <MasonryList
              data={tienda.products}
              numColumns={3}
              showsVerticalScrollIndicator={false}
              renderItem={({item}:any) => {
                return(
                  <View style={[{height:120,width:'100%' ,marginTop:8}]}>
                    <View style={[{height:120,width:'100%'}]}>
                      <TouchableOpacity onPress={onClickFamilia.bind(this,item)}>
                        <View style={{width:'100%',justifyContent:'center',alignItems:'center',borderRadius:20,overflow:'hidden'}}>
                            {item.image!='' && item.image!=undefined && item.image!=null ?
                               (<Image
                                style={{width: 50,height: 50,resizeMode:'cover'}}
                                source={
                                  {uri:item.image}
                                }
                            />
                                   ):(<Image
                                style={{width: 50,height: 50,resizeMode:'cover'}}
                                source={
                                  require('../../assets/icons/productoSinImagen.jpg')
                                }
                            />
                                   )}
                            
                        </View>
                        <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                            <Text style={{fontSize: 16,padding:8,textAlign:'center'}}>{item.name}</Text>
                        </View>
                        
                    </TouchableOpacity>
                  </View>
                </View>
                )
                
              }}
            />
          </View>
          {
            global.pantallaDividida ?
              (null)
              :
              (
                <View style={{position:'absolute',bottom:0,width:'100%'}}>
                  {totalProductos>0?(
                    <View style={{backgroundColor:'white',height:50,width:'100%'}}>
                      <View style={{height:'100%',flexDirection:'row',justifyContent:'space-between'}}>
                        <View style={{height:'100%',justifyContent:'center',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                          {totalProductos==1?(
                            <Text style={{fontSize:16}}>{totalProductos}x Producto</Text>
                          ):(
                            <Text style={{fontSize:16}}>{totalProductos}x Productos</Text>
                          )}
                        </View>
                        <View style={{height:'100%',justifyContent:'center',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                          <Text style={{color:'#BABABA',fontSize:16}}>Total</Text>
                          <Text style={{fontSize:16}}>{precioTotal.toFixed(2)}</Text>
                        </View>
                        
                      </View>
                    </View>
                  ):(null)}
                  
                  <TouchableOpacity  onPress={abrirVistaTicket} style={{backgroundColor:'#3AA597',width:'100%',alignItems:'center',justifyContent:'center'}}>

                      <Text style={{padding:13,fontSize:18,fontWeight:'bold',color:'white'}}>Aceptar</Text>

                  </TouchableOpacity>
                </View>
                
              )
          }
        </View> 
        
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height
  };
};
export default connect(mapStateToProps) (VistaFamilias);
