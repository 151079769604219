import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import ShortDescription from "./ShortDescription";
import ProductName from "./ProductName";
import Allergens from "./Allergens";
import AddProduct from "./AddProduct";

const InfoContainer = ({ data, onAdd, isMenu, showAddButton }) => {
  const {
    name,
    short_description,
    allergens,
    amount,
    increment,
  } = data;

  const price = (isMenu ? increment : amount) ?? null;

  return (
    <View style={styles.infoContainer}>
      <View style={styles.infoColumn}>
        <ProductName name={name} />
        <ShortDescription description={short_description} />
        <Allergens allergens={allergens} />
      </View>
      <AddProduct amount={price} showButton={showAddButton} onAdd={onAdd} />
    </View>
  );
};

export default InfoContainer;

const styles = StyleSheet.create({
  infoContainer: {
    flex: 1,
    height: 90,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  infoColumn: {
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "center",
  },
});
