import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../../../components/MainButton";
import ModalBase from "../../../components/ModalBase";
import Divider from "../../../components/Divider";

const NotCompletedModal = ({ visible, setModal }) => {
  const { t } = useTranslation();

  const modalHandler = () => {
    setModal();
  };
  return (
    <ModalBase modalVisible={visible} handleModal={modalHandler}>
      <View style={styles.inputWrapper}>
        <Image
          style={styles.image}
          source={require("../../../../../assets/images/roomImage.png")}
        />
        <Divider height={20} />
        <Text style={styles.text}>{t("productNotCompleted")}</Text>
        <Divider height={20} />
        <View style={styles.buttonWrapper}>
          <MainButton title={t("accept")} onPress={modalHandler} />
        </View>
      </View>
    </ModalBase>
  );
};

export default NotCompletedModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    maxWidth: 200,
    width: "100%",
  },
  image: {
    width: 200,
    resizeMode: "contain",
    height: 100
  },
  inputContainer: {
    width: 190,
    height: 45,
  },
  text: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
  },
  error: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
  },
});
