import React from "react";
import {
  Modal,
  View,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import Divider from "./Divider";

const ModalBase = ({
  children,
  handleModal,
  modalVisible,
  size = "default",
  color = null,
  height = null,
  canClose = true,
  width = null,
  closeColor = "black",
  closeSize = null,
  paddingBottom = 0,
}) => {
  const windowWidth = useWindowDimensions().width;
  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        style={styles.modal}
        statusBarTranslucent
      >
        <View style={styles.centeredView}>
          <TouchableOpacity
            style={styles.closeContainer}
            onPress={canClose ? handleModal : () => {}}
          />
          <View
            style={[
              styles.modalView,
              { maxWidth: windowWidth > 400 ? 364 : null },
              size === "window"
                ? { padding: 0 }
                : windowWidth < 370 && { padding: 12 },
              color && { backgroundColor: color },
              width && { width: width, maxWidth: "100%" },
              height && { height: height },
            ]}
          >
            {children}
          </View>
          <Divider height={paddingBottom}/>
        </View>
      </Modal>
    </View>
  );
};

export default ModalBase;

const styles = StyleSheet.create({
  modal: { flex: 1, height: "100%" },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  closeContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    height: "100%",
  },
  modalView: {
    width: "90%",
    maxHeight: "90%",
    backgroundColor: "white",
    borderRadius: 12,
    padding: 18,
    alignItems: "center",
    justifyContent: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
