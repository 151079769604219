import React, { useState, useEffect, useRef } from "react";
import {
  ScrollView,
  View,
} from "react-native";

import MasonryList from "@react-native-seoul/masonry-list";
import PedidoCocina from "../components/PedidoCocina";
import { connect } from "react-redux";
import CabeceraVertical from "../components/CabeceraVertical";
import MenuFiltros from "../components/MenuFiltros";
import MenuFiltrosDatafono from "../components/MenuFiltrosDatafono";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ordenarFiltrados,
  setPedidosParaEntregar,
  setPedidosEnviados,
  setPedidosPreparar,
  setPedidosCompletados,
  setPedidos,
  addPedido,
  eliminarPedido,
  editarPedido,
  setTiendas,
  setPedidosFiltrados,
  setOpcionVista,
  setCargandoFiltros,
} from "../../application/reducers";
import { Audio } from "expo-av";

import globales from "../funcionalidades/globales";
import ProductosAgrupados from "./productosAgrupados";

let localizacionesMarcadasFiltros = [];

const gestionPedidos = (props: any) => {
  const child = useRef();

  let initArray: Array<any> = [];
  let puntosDeVentaPruebas = [
    {
      id: "1",
      name: "Nombre del punto de venta",
      active: 1,
      products: [
        {
          id: "1",
          name: "nombre del producto 1",
          active: 1,
        },
        {
          id: "2",
          name: "nombre del producto 2",
          active: 1,
        },
      ],
    },
    {
      id: "2",
      name: "Nombre del punto de venta 2",
      active: 1,
      products: [
        {
          id: "1",
          name: "nombre del producto 1",
          active: 1,
        },
        {
          id: "2",
          name: "nombre del producto 2",
          active: 1,
        },
      ],
    },
  ];

  let filtrosPrueba = {
    orden: [
      { id: 1, nombre: "Entrega Ascendente", valor: false },
      { id: 2, nombre: "Entrega Descendente", valor: false },
      { id: 3, nombre: "Estado", valor: true },
      { id: 4, nombre: "Id", valor: false },
    ],
    locations: [
      {
        id: 1,
        name: "Nombre de la tienda",
        active: 1,
        points_sale: [
          {
            id: 1,
            name: "Nombre del punto de venta",
            active: 1,
            locations: [
              {
                id: 1,
                name: "Nombre localizacion1",
                active: 1,
                childs: [
                  { id: 1, name: "Nombre localizacion hija 1", active: 1 },
                  {
                    id: 2,
                    name: "Nombre localizacion hija 2",
                    active: 1,
                    childs: [
                      {
                        id: 1,
                        name: "Nombre localizacion hija hija 1",
                        active: 1,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    status: {
      scheduled: { name: "Programado", active: 0 },
      waiting: { name: "Espera", active: 0 },
      processing: { name: "En proceso", active: 0 },
      await_pickup: { name: "Listo para recoger", active: 0 },
      await_shipment: { name: "Listo para enviar", active: 0 },
      partially_delivered: { name: "Entregado parcialmente", active: 0 },
      shipped: { name: "Enviado", active: 0 },
      completed: { name: "Completado", active: 0 },
      cancelled: { name: "Cancelado", active: 0 },
    },
    products: [],
    tiendas: [],
  };
  const [paginaCargada, setPaginaCargada] = useState(false);
  const [vistaListado, setVistaListado] = useState(false);
  const [filtrosSel, setFiltrosSel] = useState(filtrosPrueba);
  const [listadoPedidos, setListadoPedidos] = useState(initArray);
  const [pedidosBuscador, setPedidosBuscador] = useState(initArray);
  const [puntosDeVenta, setPuntosDeVenta] = useState(puntosDeVentaPruebas);

  const [numeroEspera, setNumeroEspera] = useState(0);
  const [numeroProceso, setNumeroProceso] = useState(0);
  const [numeroRecoger, setNumeroRecoger] = useState(0);
  const [numeroCompletado, setNumeroCompletado] = useState(0);
  const [numeroEnviar, setNumeroEnviar] = useState(0);

  const [cargandoPedidos, setCargandoPedidos] = useState(false);
  const [limitePedidos, setLimitePedidos] = useState(10);

  const inputRef = useRef([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [estadoFiltrado, setEstadoFiltrado] = useState("para preparar");

  const [search, setSearch] = useState("");
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(function () {
      setRefreshing(false);
    }, 2000);
  }, []);
  async function obtenerFiltrosAnteriores(pedidos: any) {
    let filtrosAnteriores = global.usuario.filtros;

    if (
      filtrosAnteriores != "" &&
      filtrosAnteriores != undefined &&
      filtrosAnteriores != null
    ) {
      let filtrosProductos = [];
      props.stock.map(function (punto: any, index: any) {
        let productosAux = [];
        let puntoActive = 1;
        punto.products.map(function (producto: any, i: any) {
          let productoActive = 1;
          filtrosAnteriores?.products?.map(function (
            puntoAnt: any,
            index: any
          ) {
            if (puntoAnt.gid == punto.gid) {
              puntoActive = puntoAnt.active;
            }
            puntoAnt.products.map(function (productoAnt: any, index: any) {
              if (productoAnt.gid == producto.gid) {
                productoActive = productoAnt.active;
              }
            });
          });
          producto.active = productoActive;
          productosAux.push(producto);
        });
        punto.active = puntoActive;
        punto.products = productosAux;
        filtrosProductos.push(punto);
      });
      filtrosPrueba.products = filtrosProductos;
      aplicarFiltros(pedidos, filtrosAnteriores);
    } else {
      let filtrosProductos = [];
      props.stock.map(function (punto: any, index: any) {
        let productosAux = [];
        punto.products.map(function (producto: any, i: any) {
          producto.active = 1;
          productosAux.push(producto);
        });
        punto.active = 1;
        punto.products = productosAux;
        filtrosProductos.push(punto);
      });
      filtrosPrueba.products = filtrosProductos;
      aplicarFiltros(pedidos, filtrosPrueba);
    }
  }
  function ordenarPedidos(pedidos: any, orden: any) {
    let ordenSel = {};
    orden?.map(function (orden: any, index: number) {
      if (orden.valor) {
        ordenSel = orden;
      }
    });
    switch (ordenSel.id) {
      case 1:
        pedidos.sort(function (a: any, b: any) {
          if (a.order_date > b.order_date) {
            return -1;
          }
          if (a.order_date < b.order_date) {
            return 1;
          }
          return 0;
        });
        break;
      case 2:
        pedidos.sort(function (a: any, b: any) {
          if (a.order_date < b.order_date) {
            return -1;
          }
          if (a.order_date > b.order_date) {
            return 1;
          }
          return 0;
        });
        break;
      case 3:
        pedidos.sort(function (a: any, b: any) {
          if (a.prioridadesPedido[a.estado] < a.prioridadesPedido[b.estado]) {
            return -1;
          }
          if (a.prioridadesPedido[a.estado] > a.prioridadesPedido[b.estado]) {
            return 1;
          }
          return 0;
        });
        break;
      case 4:
        pedidos.sort(function (a: any, b: any) {
          if (parseFloat(a.gid) > parseFloat(b.gid)) {
            return -1;
          }
          if (parseFloat(a.gid) < parseFloat(b.gid)) {
            return 1;
          }
          return 0;
        });
        break;
    }

    return pedidos;
  }
  /*useEffect(() => {
    console.log("pedidosFiltrados",pedidosFiltrados)
    let pedidosBuscadorAux = pedidosFiltrados.slice()
    setPedidosBuscador(pedidosBuscadorAux)

  }, [pedidosFiltrados]);*/
  async function playSound(status: any) {
    let sound;

    switch (status) {
      case "waiting":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/enespera.mp3")
        );
        break;
      case "await_pickup":
        sound = await Audio.Sound.createAsync(
          require("../../assets/sounds/enproceso.mp3")
        );
        break;
    }
    console.log("sound", sound);
    //if(!sound.status.isPlaying){
    await sound.sound.playAsync();
    //}
  }
  let sound;
  let colaSonidos = [];

  function addColaSonidos(sonido: number) {
    colaSonidos.push(sonido);
    if (sound == undefined) {
      recorrerCola();
    } else {
      if (!sound.status.isPlaying) recorrerCola();
    }

    return;
  }
  async function recorrerCola() {
    while (colaSonidos.length > 0) {
      let elementoCola = colaSonidos.shift();
      switch (elementoCola) {
        case "waiting":
          sound = await Audio.Sound.createAsync(
            require("../../assets/sounds/enespera.mp3")
          );
          break;
        case "await_pickup":
          sound = await Audio.Sound.createAsync(
            require("../../assets/sounds/enproceso.mp3")
          );
          break;
      }
      await sound.sound.playAsync();
    }
  }
  async function comprobarAlarmas(pedidos: any) {
    if (global.usuario.setting.alarm != undefined) {
      for (let i = 0; i < global.usuario.setting.alarm.length; i++) {
        let alarm = global.usuario.setting.alarm[i];
        let existe = -1;
        pedidos.map(function (pedido: any, index: number) {
          if (pedido.state == alarm.status) {
            existe = index;
          }
        });
        if (existe != -1) {
          /*switch(alarm.status){
          case "waiting":
          sound = await Audio.Sound.createAsync(require('../../assets/sounds/alarma.mp3'))
          break;
          case "await_pickup":
          sound = await Audio.Sound.createAsync(require('../../assets/sounds/alarma2.mp3'))
          break;
        }*/
          console.log("sound", sound);
          //if(!sound.status.isPlaying){
          //await sound.sound.playAsync()

          //}
          addColaSonidos(alarm.status);
        }
      }
    }
  }
  useEffect(() => {
    let pedidosAux: Array<any> = [];
    /*props.tiendas.map(function(tienda:any,index:number) {
      tienda.barras.map(function(barra:any,index:number) {
        barra.pedidos.map(function(pedido:any,index:number) {
          pedidosAux.push(pedido)     
           
        })
      })
    })*/
    pedidosAux = props.pedidos;

    setListadoPedidos(pedidosAux);
    props.setPedidosFiltrados(pedidosAux);
    obtenerFiltrosAnteriores(pedidosAux);
    global.gestorAbierto = true;
    props.navigation.addListener("beforeRemove", () => {
      global.gestorAbierto = false;
      /*if(intervaloAlarma!=''){
        clearInterval(intervaloAlarma)

      }*/
      /*console.log("beforeRemove")
      if(intervaloAlarma!=''){
        clearInterval(intervaloAlarma)

      }
      pedidosBuscador.map(function(pedido:any,index:any){
          if(pedido.intervaloAlarma!=''){
            clearInterval(pedido.intervaloAlarma)
          }
      })*/
    });

    if (props.route?.params?.opcion == "recoger") {
      setTimeout(function () {
        aplicarFiltrosEstado("recoger", pedidosAux);
      }, 1000);
    }
  }, []);
  function comprobarAgotados(pedidos: any) {
    props.stock.map(function (puntoDeVenta: any, index: number) {
      puntoDeVenta.products.map(function (productoSel: any, index: number) {
        console.log("productoSel", productoSel);
        if (productoSel.stock == 0) {
          pedidos.map(function (pedido: any, index: number) {
            pedido.productos.map(function (producto: any, index: number) {
              if (
                pedido.state == "scheduled" ||
                pedido.state == "waiting" ||
                pedido.state == "processing"
              ) {
                producto.producto.menu_lines.map(function (
                  productoMenu: any,
                  indice: number
                ) {
                  if (productoSel.gid == productoMenu.product.gid) {
                    productoMenu.agotado = true;
                  }
                });

                if (productoSel.gid == producto.producto.fk_product) {
                  producto.producto.agotado = true;
                }
              }
            });
          });
        }
      });
    });
    return pedidos;
  }
  function obtenerNumeros(pedidos: any) {
    console.log("obtenerNumeros", pedidos);
    let pedidosAux: Array<any> = [];
    //console.log("se han chambiado los pedidos",props.pedidos)
    pedidosAux = pedidos;
    //comprobarAgotados(pedidosAux)

    let numEspera = 0;
    let numProceso = 0;
    let numRecoger = 0;
    let numCompletado = 0;
    let numEnviar = 0;

    pedidosAux.map(function (pedido: any, index: any) {
      if (pedido.state == "waiting") {
        numEspera = numEspera + 1;
      } else if (pedido.state == "processing") {
        numProceso = numProceso + 1;
      } else if (
        pedido.state == "await_pickup" ||
        pedido.state == "await_shipment"
      ) {
        numRecoger = numRecoger + 1;
      } else if (pedido.state == "completed") {
        numCompletado = numCompletado + 1;
      } else if (pedido.state == "shipped") {
        numEnviar = numEnviar + 1;
      }
    });

    setNumeroEspera(numEspera);
    setNumeroProceso(numProceso);
    setNumeroRecoger(numRecoger);
    setNumeroCompletado(numCompletado);
    setNumeroEnviar(numEnviar);
  }
  useEffect(() => {
    let pedidosAux: Array<any> = [];
    //console.log("se han chambiado los pedidos",props.pedidos)
    pedidosAux = props.pedidos;
    //obtenerNumeros(pedidosAux)

    obtenerFiltrosAnteriores(pedidosAux);

    /*if(intervaloAlarma!=''){
      clearInterval(intervaloAlarma)
    }
    intervaloAlarma = setInterval(async function(){
      await comprobarAlarmas(pedidosAux)
    },5000)*/
    /*props.navigation.addListener('beforeRemove', () => {
      if(intervaloAlarma!=''){
        clearInterval(intervaloAlarma)

      }
      pedidosBuscador.map(function(pedido:any,index:any){
          if(pedido.intervaloAlarma!=''){
            clearInterval(pedido.intervaloAlarma)
          }
      })
    });*/
  }, [props.pedidos]);
  function añadirPedido() {
    let pedido = {
      id: "3",
      hora: "33:33",
      localizacion: "Localizacion",
      tienda: "Tienda3",
      estado: "Espera",
      productos: [{ nombre: "producto3" }, { nombre: "producto3" }],
    };
    let posicion = 4;
    /*let filteredItemsAux = filteredItems.slice();
    filteredItemsAux.splice( posicion, 0, pedido );
    setFilteredItems(filteredItemsAux)*/
    props.addPedido(posicion, pedido);
  }
  function eliminarPedido() {
    props.eliminarPedido("2");
  }
  function cambiarEstado(pedido: any) {
    let listadoPedidosAux = props.pedidos.slice();
    props.setPedidos(listadoPedidosAux);
  }
  function cambiarVistaListado() {
    setVistaListado(!vistaListado);
  }

  function abrirFiltros() {
    props.setCargandoFiltros(true);
    console.log("global.usaurio.filtros11111", global.usuario.filtros);
    setTimeout(function () {
      props.navigation.navigate("filtros", {
        onGoBack: (filtros: any) => filtrarPedidosVista(filtros),
        filtrosSel: filtrosSel,
      });
    }, 0);
  }
  function abrirStock() {
    props.navigation.navigate("vistaStock", {
      onGoBack: (stock: any) => cambiarStock(stock),
      puntosDeVenta: puntosDeVenta,
      filtrosSel: filtrosSel,
    });
  }
  function cambiarStock(stock: any) {
    setPuntosDeVenta(stock);
  }
  useEffect(() => {
    props.setPedidos(comprobarAgotados(props.pedidos));
    setPuntosDeVenta(props.stock);
  }, [props.stock]);

  function aplicarFiltros(pedidos: any, filtros: any) {
    setFiltrosSel(JSON.parse(JSON.stringify(filtros)));

    console.log("filtros", filtros);
    setPaginaCargada(false);
    let pedidosFiltradosAux: Array<any> = [];
    let estadoSel: Array<any> = [];
    if (filtros?.status != undefined) {
      Object.keys(filtros?.status).map(function (key: any) {
        let estado = filtros?.status[key];

        if (estado?.active == 1 || estado?.active == true) {
          estadoSel.push(estado?.name);
        }
      });
    }
    let productosSel: Array<any> = [];

    props?.pedidos?.map(function (pedido: any, index: number) {
      pedido.productos.map(function (producto: any, index: number) {
        if (filtros.products != undefined) {
          producto.oculto = true;
          filtros?.products?.map(function (punto: any) {
            if (punto.active == 1 || punto.active == true) {
              punto?.products?.map(function (productoFiltro: any) {
                producto.producto.menu_lines.map(function (
                  productoMenu: any,
                  indice: number
                ) {
                  if (productoFiltro.gid == productoMenu.product.gid) {
                    if (
                      productoFiltro.active == 1 ||
                      productoFiltro.active == true
                    ) {
                      productoMenu.oculto = false;
                    } else {
                      productoMenu.oculto = true;
                    }
                  }
                });
                if (productoFiltro.gid == producto.producto.fk_product) {
                  console.log("productoFiltro", productoFiltro);
                  console.log("producto.producto", producto.producto);
                  console.log(
                    "active",
                    productoFiltro.active == 1 || productoFiltro.active == true
                  );
                  if (
                    productoFiltro.active == 1 ||
                    productoFiltro.active == true
                  ) {
                    producto.oculto = false;
                  } else {
                    producto.oculto = true;
                  }
                }
              });
            }
          });
        }
        console.log("producto", producto);
      });
    });

    console.log("pedidos", pedidos);
    pedidos.map(function (pedido: any) {
      let cumpleFiltros = false;

      let tieneEstado = false;
      if (estadoSel.length > 0) {
        //console.log("estadoSel",estadoSel)
        //console.log("pedido.estado",pedido.estado)
        if (estadoSel.indexOf(pedido.estado) != -1) {
          tieneEstado = true;
        }
      } else {
        tieneEstado = true;
      }

      let tieneProducto = true;
      if (tieneProducto && tieneEstado) {
        cumpleFiltros = true;
      }
      if (cumpleFiltros) {
        pedidosFiltradosAux.push(pedido);
      }
    });
    console.log("pedidosFiltradosAux", pedidosFiltradosAux);

    let pedidosAux = [];

    pedidosFiltradosAux.map(function (pedido: any, index: any) {
      console.log("pedido", pedido);
      let visible = false;
      pedido.productos.map(function (producto: any, index: any) {
        if (!producto.oculto) {
          visible = true;
        }
      });
      console.log("visible", visible);
      if (visible) {
        pedidosAux.push(pedido);
      }
    });
    console.log("pedidosAux", pedidosAux.length);
    console.log("pedidosAux", pedidosAux);
    pedidosAux = filtrarPedidosLocalizaciones(pedidosAux, filtros.tiendas);

    ordenarPedidos(pedidosAux, filtros.orden);
    console.log("pedidosAux2", pedidosAux);
    props.setPedidosFiltrados(pedidosAux);
    setPedidosBuscador(pedidosAux);

    let pedidosAgrupados = globales.agruparPedidosEstado(pedidosAux);
    props.setPedidosParaEntregar(pedidosAgrupados.pedidosParaEntregar);
    props.setPedidosEnviados(pedidosAgrupados.pedidosEnviados);
    props.setPedidosPreparar(pedidosAgrupados.pedidosPreparar);
    props.setPedidosCompletados(pedidosAgrupados.pedidosCompletados);

    aplicarFiltrosEstado(estadoFiltrado, pedidosAux, filtros);
    setPaginaCargada(true);
  }
  function filtrarPedidosLocalizaciones(pedidos: any, tiendas: any) {
    let filtrosTiendas = {};
    if (tiendas != undefined && tiendas != null) {
      if (tiendas.length > 0) {
        tiendas.map(function (tienda: any, index: any) {
          if (tienda.marcado) {
            let localizacionesMarcadas = [];
            filtrosTiendas[tienda.gid] = [];
            tienda.localizaciones.map(function (localizacion: any, index: any) {
              comprobarMarcadas(localizacion.arrayOpciones, "");
              if (localizacionesMarcadasFiltros.length > 0) {
                filtrosTiendas[tienda.gid].push(localizacionesMarcadasFiltros);
                localizacionesMarcadasFiltros = [];
              }
            });
          }
        });

        let pedidosAux = [];
        pedidos.map(function (pedido: any, index: any) {
          let cumpleFiltro = false;
          let indice = Object.keys(filtrosTiendas).indexOf(
            pedido.id_shop.toString()
          );

          if (indice != -1) {
            if (
              filtrosTiendas[Object.keys(filtrosTiendas)[indice]].length > 0
            ) {
              filtrosTiendas[Object.keys(filtrosTiendas)[indice]].map(function (
                localizacionesPermitidas: any,
                index: any
              ) {
                localizacionesPermitidas.map(function (
                  localizacionStr: any,
                  index: any
                ) {
                  if (pedido.location.includes(localizacionStr.trim())) {
                    cumpleFiltro = true;
                  }
                });
              });
            } else {
              cumpleFiltro = true;
            }
          }
          if (cumpleFiltro) {
            pedidosAux.push(pedido);
          }
        });
        return pedidosAux;
      } else {
        return pedidos;
      }
    } else {
      return pedidos;
    }
  }

  function comprobarMarcadas(opciones: any, localizacionesMarcadas: any) {
    let hayMarcadas = false;
    let marcada;
    //console.log("comprobarMarcadas",opciones,localizacionesMarcadas)
    opciones.map(function (opcion: any, index: any) {
      if (opcion.marcado) {
        hayMarcadas = true;
        marcada = opcion;
        comprobarMarcadas(
          opcion.arrayOpciones,
          localizacionesMarcadas + " " + opcion.value
        );
      }
    });
    if (!hayMarcadas) {
      if (localizacionesMarcadas.length > 0) {
        localizacionesMarcadasFiltros.push(localizacionesMarcadas);
      }
    }
  }
  function guardarConfiguracionBBDD(filtros: any) {
    console.log("--guardarConfiguracionBBDD--");
    global.db.transaction(
      (tx: any) => {
        tx.executeSql(
          "CREATE TABLE IF NOT EXISTS userFiltros (" +
            "userGid TEXT" +
            "," +
            "filtros TEXT" +
            ");"
        );
      },
      function (err: any) {
        console.log("err creating", err);
      }
    );

    global.db.transaction(
      (tx: any) => {
        tx.executeSql(
          "DELETE from userFiltros WHERE userGid = " + global.usuario.gid + ";"
        );
      },
      function (err: any) {
        console.log("err deleting", err);
      }
    );

    global.db.transaction(
      (tx: any) => {
        tx.executeSql(
          "INSERT OR REPLACE INTO userFiltros(" +
            "userGid" +
            "," +
            "filtros" +
            ") VALUES ('" +
            global.usuario.gid +
            "','" +
            JSON.stringify(filtros) +
            "');"
        );
      },
      function (err: any) {
        console.log("err inserting", err);
      }
    );
  }
  function filtrarPedidosVista(filtros: any) {
    AsyncStorage.setItem("@filtrosAnteriores", JSON.stringify(filtros));
    guardarConfiguracionBBDD(JSON.stringify(filtros));
    global.usuario.filtros = JSON.parse(JSON.stringify(filtros));
    global.filtrosAnteriores = filtros;
    console.log("global.usaurio.filtro333333", global.usuario.filtros);

    aplicarFiltros(props.pedidos, filtros);
    /*if(filtros.filtrosLocalizacion!=undefined){
      filtros.filtrosLocalizacion.map(function(tienda:any,index:number) {
        
      })
    }*/
  }
  function reachedMasonry() {
    if (!cargandoPedidos) {
      setCargandoPedidos(true);
      setTimeout(function () {
        setCargandoPedidos(false);
      }, 3000);
    }

    //,maxHeight:(props.height-80)
  }
  function marcarcompletados(productosAgrupados: any) {
    let pedidosAux: Array<any> = [];
    pedidosAux = props.pedidos;
    pedidosAux.map(function (pedido: any, index: any) {
      pedido.order_lines.map(function (order_line: any, indice: number) {
        productosAgrupados.map(function (item: any, index: number) {
          if (item.marcado) {
            item.productosReales.map(function (producto: any, index: number) {
              producto.marcado = true;
              if (order_line.gid == producto.gid) {
                order_line.marcado = true;
                console.log("marcado");
              }
            });
          }
        });
      });
      //let pedidoAux = new Pedido(JSON.parse(JSON.stringify(pedido)))
      //pedidosAux.push(pedidoAux)
    });
    console.log("pedidosAux", pedidosAux);
    props.setPedidos(pedidosAux);
  }

  function abrirProductosAgrupados() {
    console.log("abrirProductosAgrupadoss");
    if (props.opcionVista != "productosAgrupados") {
      props.setOpcionVista("productosAgrupados");
    } else {
      props.setOpcionVista("");
    }

    /* props.navigation.navigate('productosAgrupados',
      { 
        onGoBack: (agrupados:any) => marcarcompletados(agrupados),
      }
    );*/
  }

  function aplicarFiltrosEstado(
    estado: any,
    pedidosParam: any,
    filtrosParam: any
  ) {
    props.setOpcionVista("");
    let pedidos = pedidosParam;
    if (pedidos == undefined) {
      pedidos = props.pedidos;
    }
    let filtros = pedidosParam;
    if (filtros == undefined) {
      filtros = filtrosSel;
    }
    pedidos = filtrarPedidosLocalizaciones(pedidos, filtros.tiendas);
    obtenerNumeros(pedidos);

    let pedidosAux = [];

    setEstadoFiltrado(estado);
    if (estado == "recoger") {
      pedidos.map(function (pedido: any) {
        if (
          pedido.state == "await_pickup" ||
          pedido.state == "await_shipment"
        ) {
          let visible = false;
          pedido.productos.map(function (producto: any, index: any) {
            if (!producto.oculto) {
              visible = true;
            }
          });
          if (visible) {
            pedidosAux.push(pedido);
          }
        }
      });
    } else if (estado == "para preparar") {
      pedidos.map(function (pedido: any) {
        if (pedido.state == "waiting" || pedido.state == "processing") {
          let visible = false;
          pedido.productos.map(function (producto: any, index: any) {
            if (!producto.oculto) {
              visible = true;
            }
          });
          if (visible) {
            pedidosAux.push(pedido);
          }
        }
      });
    } else if (estado == "completed") {
      pedidos.map(function (pedido: any) {
        if (pedido.state == "completed") {
          let visible = false;
          pedido.productos.map(function (producto: any, index: any) {
            if (!producto.oculto) {
              visible = true;
            }
          });
          if (visible) {
            pedidosAux.push(pedido);
          }
        }
      });
    } else if (estado == "enviar") {
      pedidos.map(function (pedido: any) {
        if (pedido.state == "shipped") {
          let visible = false;
          pedido.productos.map(function (producto: any, index: any) {
            if (!producto.oculto) {
              visible = true;
            }
          });
          if (visible) {
            pedidosAux.push(pedido);
          }
        }
      });
    }

    ordenarPedidos(pedidosAux, filtros.orden);
    props.setPedidosFiltrados(pedidosAux);
    console.log("setPedidosFiltrados", pedidosAux);
    setPedidosBuscador(pedidosAux);
  }

  return (
    <View style={{ width: props.width, height: props.height }}>
      {global.pantallaDividida ? (
        <View style={{ width: props.width, height: props.height }}>
          <CabeceraVertical
            search={search}
            setSearch={setSearch}
            navigation={props.navigation}
            abrirStock={abrirStock}
            abrirFiltros={abrirFiltros}
            setPedidosBuscador={setPedidosBuscador}
            pedidosFiltrados={props.pedidosFiltrados}
          ></CabeceraVertical>
          <MenuFiltros
            setSearch={setSearch}
            navigation={props.navigation}
            abrirProductosAgrupados={abrirProductosAgrupados}
            numeroEspera={numeroEspera}
            numeroProceso={numeroProceso}
            numeroRecoger={numeroRecoger}
            numeroCompletado={numeroCompletado}
            numeroEnviar={numeroEnviar}
            aplicarFiltrosEstado={aplicarFiltrosEstado}
          ></MenuFiltros>
          <ScrollView
            style={{
              width: "100%",
              height: props.height,
              marginTop: 60,
              paddingBottom: 15,
              paddingLeft: 75,
              paddingRight: 15,
            }}
          >
            {props.opcionVista == undefined ||
            props.opcionVista == false ||
            props.opcionVista == "" ? (
              <MasonryList
                data={pedidosBuscador}
                numColumns={
                  parseInt((props.width - 60) / 266) == 0
                    ? 1
                    : parseInt((props.width - 60) / 266)
                }
                style={{ width: "100%" }}
                renderItem={({ item, i }: any) => {
                  return (
                    <PedidoCocina
                      key={i.toString()}
                      cambiarEstado={cambiarEstado.bind(this, item)}
                      navigation={props.navigation}
                      pedido={item}
                    ></PedidoCocina>
                  );
                }}
              />
            ) : (
              <View style={{ width: "100%" }}>
                <ProductosAgrupados></ProductosAgrupados>
              </View>
            )}
          </ScrollView>
        </View>
      ) : (
        <View style={{ width: props.width, height: props.height }}>
          <MenuFiltrosDatafono
            navigation={props.navigation}
            numeroEspera={numeroEspera}
            numeroProceso={numeroProceso}
            numeroRecoger={numeroRecoger}
            numeroCompletado={numeroCompletado}
            numeroEnviar={numeroEnviar}
          />
        </View>
      )}
    </View>
  );
};

const mapDispatchToProps = {
  ordenarFiltrados,
  setPedidos,
  addPedido,
  eliminarPedido,
  editarPedido,
  setTiendas,
  setPedidosFiltrados,
  setOpcionVista,
  setCargandoFiltros,
  setPedidosParaEntregar,
  setPedidosEnviados,
  setPedidosPreparar,
  setPedidosCompletados,
};
const mapStateToProps = (state: any) => {
  return {
    pedidosFiltrados: state.pedidos.pedidosFiltrados,
    pedidos: state.pedidos.pedidos,
    width: state.dimensions.width,
    height: state.dimensions.height,
    stock: state.stock.stock,
    opcionVista: state.config.opcionVista,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(gestionPedidos);
