import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";

// Components
import Divider from "../Divider";
import LanguageModal from "./modals/LanguageModal";
import Flag from "./Flag";

// i18n
import { languageNames } from "../../../../i18n/languageNames";

// Context
import { useLanguage } from "../../../../contexts/LanguageContext";

const LanguageSelector = () => {
  const [modal, setModal] = useState<String>();

  const { language } = useLanguage();

  return (
    <View>
      <TouchableOpacity
        style={styles.content}
        onPress={() => setModal("Language")}
      >
        <Flag code={language} />
        <Divider width={8} />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {languageNames[language].substring(0, 3)}
          </Text>
        </View>
      </TouchableOpacity>
      <LanguageModal visible={modal === "Language"} setModal={setModal} />
    </View>
  );
};

export default LanguageSelector;

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    color: "black",
    lineHeight: 21,
  },
  content: {
    flexDirection: "row",
    borderRadius: 40,
    paddingRight: 10,
    height: 40,
    width: "100%",
    borderColor: "grey",
    alignItems: "center",
    paddingHorizontal: 5,
    borderWidth: 1,
  },
  titleContainer: {
    width: 35,
  },
});
