import React, { useState, useEffect, useRef } from 'react';
import {Dimensions,StyleSheet,TouchableOpacity,Text,View,TextInput} from "react-native";
import { Switch } from 'react-native-switch';

import { StatusBar } from 'expo-status-bar'
import { SearchBar } from 'react-native-elements';
import { connect } from "react-redux";
import {ordenarFiltrados, setPedidos,addPedido,eliminarPedido,editarPedido,setSocketConectado,setPedidosPerdidos } from "../../application/reducers";
import {Octicons,Entypo,MaterialIcons,AntDesign,EvilIcons,Ionicons ,FontAwesome,FontAwesome5,Feather } from '@expo/vector-icons';
import { createStyles, maxWidth} from 'react-native-media-queries';
const MenuFiltros = (props:any) => {
   
    const [btnParaPreparar, setBtnParaPreparar] = useState(
      [
        {id:'1',text:'Nuevos'},
        {id:'2',text:'En proceso'}
      ]
    );
    const [btnParaEntregar, setBtnParaEntregar] = useState(
      [
        {id:'3',text:'Para entregar'},
        {id:'5',text:'Enviados'},
        {id:'4',text:'Completados'}
      ]
    );
    const [btnFiltroSelParaPreparar, setBtnFiltroSelParaPreparar] = useState('1');
    const [btnFiltroSelParaEntregar, setBtnFiltroSelParaEntregar] = useState('3');
    const [btnSel, setBtnSel] = useState('Para preparar');
    function onPressBtn(btn:any){
      props.setSearch('')
      setBtnSel(btn)
      if(btn=='Para preparar'){
        props.aplicarFiltrosEstado('para preparar')  
      }else{
        if(btnFiltroSelParaEntregar=='3'){
          props.aplicarFiltrosEstado('recoger')
        }else if(btnFiltroSelParaEntregar=='5'){
          props.aplicarFiltrosEstado('enviar')
        }else{
          props.aplicarFiltrosEstado('completed')
        }  
      }
    }
    /*function onPressFiltroParaPreparar(idBtn:any){
      setBtnFiltroSelParaPreparar(idBtn)
      if(idBtn=='1'){
        props.aplicarFiltrosEstado('waiting')
      }else{
        props.aplicarFiltrosEstado('processing')
      }   

    }*/
    function onPressFiltroParaEntregar(idBtn:any){
      props.setSearch('')
      setBtnFiltroSelParaEntregar(idBtn)
      if(idBtn=='3'){
        props.aplicarFiltrosEstado('recoger')
      }else if(idBtn=='5'){
        props.aplicarFiltrosEstado('enviar')
      }else{
        props.aplicarFiltrosEstado('completed')
      }  
    }
    return(
        <View style={{zIndex:2,top:0,height:60,position:'absolute',flex:1,justifyContent:'space-between',alignItems:'center',flexDirection:'row',width:(props.width-100),left:100}}>
          <View style={{width:(props.width-100-370), justifyContent:'center', alignItems:'center'}}>
            { btnSel=='Para preparar' ? (
              <View style={{flexDirection:'row',justifyContent:'space-around',width:'100%'}}>
                <TouchableOpacity style={[styles.btnAgrupados,props.opcionVista=='productosAgrupados'?{backgroundColor:'#3AA597',borderWidth:0}:{}]} onPress={props.abrirProductosAgrupados}>
                  
                  {props.opcionVista=='productosAgrupados'?(
                    <Ionicons name="pricetag-outline" size={20} color="white" />
                    ):(
                    <Ionicons name="pricetag-outline" size={20} color="black" />
                    )}
                  <Text style={[{paddingLeft:10},props.opcionVista=='productosAgrupados'?{color:'white'}:{color:'black'}]}>{props.opcionVista=='productosAgrupados'?'Desagrupar pedidos':'Agrupar pedidos'}</Text>
                </TouchableOpacity>
                <View style={{flexDirection:'row'}}>
                {  
                  btnParaPreparar.map(function(btn:any,index:number) {
                    return(
                      <View style={[styles.btnFiltro,{borderBottomWidth:0}]}>
                        <Text style={[styles.textBtnFiltro]}>{btn.text}</Text>
                          {btn.id=='1'?(
                            <View>

                                <View style={styles.numContainer}>
                                  <Text  style={styles.numText}>{props.numeroEspera}</Text>
                                </View>

                            </View>
                            )
                          :( <View>
                                <View style={styles.numContainer}>
                                  <Text  style={styles.numText}>{props.numeroProceso}</Text>
                                </View>
                            </View>)}
                      </View>
                      )
                  })
                }
                </View>
              </View>
            ) : (
              <View style={{flexDirection:'row'}}>
                {  
                  btnParaEntregar.map(function(btn:any,index:number) {
                    return(
                      <TouchableOpacity onPress={onPressFiltroParaEntregar.bind(this,btn.id)} style={[styles.btnFiltro,btnFiltroSelParaEntregar==btn.id?{borderBottomColor:'#3AA597'}:{borderBottomColor:'black'}]}>
                        <Text style={[styles.textBtnFiltro,btnFiltroSelParaEntregar==btn.id?{color:'#3AA597'}:{color:'black'}]}>{btn.text}</Text>
                        {btn.id=='3'?(
                            <View>
                                <View style={styles.numContainer}>
                                  <Text  style={styles.numText}>{props.numeroRecoger}</Text>
                                </View>
                            </View>
                            )
                          :(null)}
                         {btn.id=='4'?(
                             <View>
                             
                                <View style={styles.numContainer}>
                                  <Text  style={styles.numText}>{props.numeroCompletado}</Text>
                                </View>
                            </View>
                            )
                          :(null)}

                         {btn.id=='5'?(
                            <View>
                                <View style={styles.numContainer}>
                                  <Text  style={styles.numText}>{props.numeroEnviar}</Text>
                                </View>
                            </View>
                            )
                          :(null)}
                      </TouchableOpacity>
                      )
                  })
                }
              </View>
            )
            }
          </View>
          <View style={{flexDirection:'row',width:370}}>
            <TouchableOpacity onPress={onPressBtn.bind(this,'Para preparar')} style={[styles.btn,btnSel=='Para preparar'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
              <Text style={[styles.textBtn,btnSel=='Para preparar'?{color:'white'}:{color:'black'}]}>Para preparar</Text>
             
                <View style={styles.numContainer}>
                  <Text  style={styles.numText}>{(props.numeroEspera+props.numeroProceso)}</Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={onPressBtn.bind(this,'Para entregar')} style={[styles.btn,btnSel=='Para entregar'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
              <Text style={[styles.textBtn,btnSel=='Para entregar'?{color:'white'}:{color:'black'}]}>Para entregar</Text>
                <View style={styles.numContainer}>
                  <Text  style={styles.numText}>{(props.numeroRecoger)}</Text>
                </View>
            </TouchableOpacity>
          </View>
        </View>
    );
    
}
const mapDispatchToProps = {
  ordenarFiltrados,
  setPedidos,
  addPedido,
  eliminarPedido,
  editarPedido,
  setSocketConectado,
  setPedidosPerdidos
};
const mapStateToProps = (state:any) => {
  return {
   socketConectado: state.pedidos.socketConectado,
   pedidosPerdidos:state.pedidos.pedidosPerdidos,
   width: state.dimensions.width,
    opcionVista:state.config.opcionVista
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(MenuFiltros);
const styles = createStyles({
  btn:{
    borderWidth:1,
    borderColor:'black',
    borderRadius:4,
    height:40,
    justifyContent:'center',
    paddingHorizontal:10,
    marginHorizontal:10,
    flexDirection:'row',
    alignItems:'center'
  },
  textBtn:{
    fontWeight:'bold'
  },
  btnFiltro:{
    height:40,
    justifyContent:'center',
    paddingHorizontal:10,
    borderBottomWidth:1,
    borderBottomColor:'black',
    marginHorizontal:10,
    flexDirection:'row',
    alignItems:'center'
  },
  textBtnFiltro:{
    fontWeight:'bold'
  },
  numContainer:{
    borderRadius:4,
    backgroundColor:'#3AA597',
    justifyContent:'center',
    alignItems:'center',
    width:30,
    height:30,
    marginLeft:10
  },
  numText:{
    fontWeight:'bold',
    color:'white'
  },
  btnAgrupados:{
    borderWidth:1,
    borderColor:'black',
    borderRadius:45,
    height:45,
    flexDirection:'row',
    alignItems:'center',
    padding:10,
    paddingLeft:20,
    width:220,
    justifyContent:'flex-start'
  }
});