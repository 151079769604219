import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import { connect } from "react-redux";
import ProductoPedido from "../components/ProductoPedido";
import { setPedidos, editarPedido } from "../../application/reducers";
import MasonryList from "@react-native-seoul/masonry-list";

const productosAgrupados = (props: any) => {
  const [productosAgrupados, setProductosAgrupados] = useState([]);
  const [familiasAgrupadas, setFamiliasAgrupadas] = useState([]);

  function compararVariantes(grupoVariantes1: any, grupoVariantes2: any) {
    let sonIguales = false;
    let grupoVariantes1Aux = [];
    let grupoVariantes2Aux = [];
    if (grupoVariantes1 != undefined) {
      grupoVariantes1.map(function (variante1: any, index: any) {
        if (variante1.variants != undefined) {
          variante1.variants.map(function (variante: any, index: any) {
            let aux = {
              fk_product_variant: variante.fk_product_variant,
              quantity: variante.quantity,
              fk_product_group_variant: variante1.fk_product_group_variant,
            };
            grupoVariantes1Aux.push(aux);
          });
        }
      });
    }
    if (grupoVariantes2 != undefined) {
      grupoVariantes2.map(function (variante2: any, index: any) {
        if (variante2.variants != undefined) {
          variante2.variants.map(function (variante: any, index: any) {
            let aux = {
              fk_product_variant: variante.fk_product_variant,
              quantity: variante.quantity,
              fk_product_group_variant: variante2.fk_product_group_variant,
            };
            grupoVariantes2Aux.push(aux);
          });
        }
      });
    }
    if (
      JSON.stringify(grupoVariantes1Aux) == JSON.stringify(grupoVariantes2Aux)
    ) {
      sonIguales = true;
    }
    return sonIguales;
  }

  function compararProductos(producto1: any, producto2: any) {
    let sonIguales = false;
    if (producto1.fk_product == producto2.fk_product) {
      if (compararVariantes(producto1.groups, producto2.groups)) {
        if (
          JSON.stringify(producto1.ingredientesSinSel) ==
          JSON.stringify(producto2.ingredientesSinSel)
        ) {
          sonIguales = true;
        }
      }
    }
    return sonIguales;
  }
  useEffect(() => {
    let productosAgrupadosAux = [];
    console.log("props.pedidos", props.pedidos);
    let filtros = global.usuario.filtros;
    let estadoSel: Array<any> = ["Espera", "En proceso"];

    props.pedidos.map(function (pedido: any, index: any) {
      let tieneEstado = false;
      if (estadoSel.length > 0) {
        if (estadoSel.indexOf(pedido.estado) != -1) {
          tieneEstado = true;
        }
      } else {
        tieneEstado = true;
      }
      if (tieneEstado) {
        pedido.productos.map(function (linea: any, index: any) {
          let producto = linea.producto;
          if (!producto.oculto && !producto.marcado) {
            if (producto.menu_lines.length > 0) {
              let existe = -1;
              producto.menu_lines.map(function (
                productoMenu: any,
                index: number
              ) {
                if (!productoMenu.oculto && !productoMenu.marcado) {
                  productoMenu.fk_product = productoMenu.product.gid;
                  productoMenu.groups = productoMenu.variants || [];
                  productoMenu.ingredientesSinSel =
                    productoMenu.ingredients_removed || [];
                  productosAgrupadosAux.map(function (
                    item: any,
                    index: number
                  ) {
                    let productoAux = item.producto;
                    if (compararProductos(productoMenu, productoAux)) {
                      existe = index;
                      item.cantidad++;
                      item.productosReales.push(productoMenu);
                    }
                  });
                  if (existe == -1) {
                    productosAgrupadosAux.push({
                      producto: productoMenu,
                      cantidad: 1,
                      marcado: false,
                      productosReales: [productoMenu],
                      cantidadSel: 1,
                    });
                  }
                }
              });
            } else {
              let existe = -1;
              productosAgrupadosAux.map(function (item: any, index: number) {
                let productoAux = item.producto;
                if (compararProductos(producto, productoAux)) {
                  existe = index;
                  item.cantidad += producto.cantidad;
                  item.productosReales.push(producto);
                }
              });
              if (existe == -1) {
                let aux = {
                  producto: producto,
                  cantidad: 1,
                  marcado: false,
                  productosReales: [producto],
                  cantidadSel: 1,
                };
                productosAgrupadosAux.push(aux);
              }
            }
          }
        });
      }
    });
    setProductosAgrupados(productosAgrupadosAux);
    let auxFamilias = [];
    productosAgrupadosAux.map(function (item: any, index: number) {
      let productoAux = item.producto;
      props.stock.map(function (tienda: any, index: any) {
        tienda.products.map(function (productoStock: any, index: any) {
          if (productoStock.gid == productoAux.fk_product) {
            let existe = -1;
            auxFamilias.map(function (familia: any, index: number) {
              if (
                familia.fk_product_family == productoStock.fk_product_family
              ) {
                existe = index;
              }
            });
            if (existe == -1) {
              let familiaAux = {
                fk_product_family: productoStock.fk_product_family,
                product_family_name: productoStock.product_family_name,
                productos: [item],
              };
              auxFamilias.push(familiaAux);
            } else {
              auxFamilias[existe].productos.push(item);
            }
          }
        });
      });
    });
    setFamiliasAgrupadas(auxFamilias);
  }, [props.pedidos]);

  function onPressCheck(indice: any) {
    let productosAgrupadosAux = productosAgrupados.slice();
    productosAgrupadosAux[indice].marcado =
      !productosAgrupadosAux[indice].marcado;
    setProductosAgrupados(productosAgrupadosAux);
  }

  return (
    <View>
      <Text
        style={{
          padding: 10,
          fontWeight: "bold",
          color: "#3AA597",
          fontSize: 18,
          marginTop: 10,
          textAlign: "center",
        }}
      >
        Productos Agrupados
      </Text>
      <MasonryList
        data={familiasAgrupadas}
        numColumns={2}
        style={{ width: "100%" }}
        renderItem={({ item, i }: any) => {
          let familia = item;
          return (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                paddingHorizontal: 20,
              }}
            >
              <Text
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  color: "#3AA597",
                  borderBottomWidth: 2,
                  borderBottomColor: "#3AA597",
                }}
              >
                {familia.product_family_name}
              </Text>
              <View style={{ width: "100%" }}>
                {familia.productos.map(function (
                  lineaAgrupada: any,
                  indice: any
                ) {
                  console.log("lineaAgrupada", lineaAgrupada);
                  return (
                    <ProductoPedido
                      producto={lineaAgrupada}
                      productoAgrupado={true}
                      marcado={lineaAgrupada.marcado || false}
                      onPressCheck={onPressCheck.bind(this, indice)}
                    ></ProductoPedido>
                  );
                })}
              </View>
            </View>
          );
        }}
      />
    </View>
  );
};

const mapDispatchToProps = {
  setPedidos,
  editarPedido,
};

const mapStateToProps = (state: any) => {
  return {
    pedidos: state.pedidos.pedidos,
    height: state.dimensions.height,
    stock: state.stock.stock,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(productosAgrupados);
