import React, { useState, useEffect, useRef } from 'react';
import {ScrollView,FlatList,Text,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import { FontAwesome,AntDesign,Ionicons } from '@expo/vector-icons';
import {CheckBox} from "react-native-elements";
import { StatusBar } from 'expo-status-bar';
import { connect } from "react-redux";
import MasonryList from '@react-native-seoul/masonry-list';
import {setCargandoFiltros,setTiendas,setStock} from "../../application/reducers";
import Tienda from "../objetos/Tienda"
import AccordionListItem from '../components/AccordionListItem'

//import {loadAsync} from 'expo-font';

const filtros = (props:any) => {
  let initLoc:Array<any>=[]
  
  let contadorOpciones=0
  //const [paginaCargada, setPaginaCargada] = useState(false);
  const [categorias, setCategorias] = useState({tiendas:[],products:[],orden:[]});
  const [actualizando,setActualizando] = useState(false);
  const [checksLocalizaciones, setChecksLocalizaciones] = useState(initLoc);
  const [checksOrden,setChecksOrden] = useState();
  function changeCheckOrden(item:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))

    categoriasAux.orden.map(function(orden:any,indice:number) {
      orden.valor=false
      if(orden.id==item.id){
         orden.valor=true
      }
    })
    setCategorias(categoriasAux)
  }
  function changeCheckEstado(key:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    let checksEstadosAux =categoriasAux.status
    checksEstadosAux[key].active=!checksEstadosAux[key].active
    categoriasAux.status = checksEstadosAux
    setCategorias(categoriasAux)
  }
  function actualizarFiltros(){
    setActualizando(true)
    cargarStock()
    cargarTiendasAll()
  }
  function cargarStock(){
    global.usuario.cargarStock().then(function(res:any){
      if(res!=undefined && res!=null){
        props.setStock(res)
      }
    })
  }
  function cargarTiendasAll(){
    global.usuario.cargarTiendas().then(async function(res:any){
      let tiendasAux:Array<any>=[]
      for(let i = 0 ; i<res.length;i++){
        let item = res[i]
        let tienda = new Tienda(item)
        await tienda.getDatosTienda(tienda.gid,'pick_up').then(function(){
          tiendasAux.push(tienda)
        })
      }
      props.setTiendas(tiendasAux)
      setActualizando(false)
      let categoriasAux = JSON.parse(JSON.stringify(categorias))
      categoriasAux.products=agruparProductosEnFamilias(obtenerProductos())
      categoriasAux.tiendas=obtenerTiendas()
      setCategorias(categoriasAux)
      /*res.map(function(item:any,indice:number) {
        let tienda = new Tienda(item)
        tienda.getDatosTienda(tienda.gid,'pick_up').then(function(res:any){
          tiendasAux.push(tienda)
        })
      });*/
      
    })
 
    
    
    
  }
  
  function changeCheckPunto(indicePunto:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    let checksProductosAux =categorias.products
    
    let marcado=checksProductosAux[indicePunto].active
   
    checksProductosAux[indicePunto].active=!marcado
    
    categoriasAux.products = checksProductosAux
    setCategorias(categoriasAux)
  }

  
 
  function goBack(){
    props.navigation.goBack()
  }
  function aplicarFiltros(){
    console.log('categorias',categorias)
    props.route.params.onGoBack(categorias);
    goBack()
  }
  function comprobarOpciones(localizacion,opcion){
    let marcado = false
    if(localizacion.arrayOpciones!=undefined && localizacion.arrayOpciones!=null && localizacion.arrayOpciones!=''){
      localizacion.arrayOpciones.map(function(item:any,indice:any){
        if(item.value==opcion){
          marcado=item.marcado
        }

      })
      if(!marcado){
        if(localizacion.childs!=undefined){
          localizacion.childs.map(function(hijo:any,indice:number) {
            marcado = comprobarOpciones(hijo,opcion)    
          })
        }
      }
    }
    return marcado
  }
  function comprobarLocalizacionMarcada(localizacionStr,tienda){
    let existe = -1
    let marcado = false
    
    return marcado
  }
  function agruparProductosEnFamilias(tiendas:any){

        tiendas.map(function(tienda:any,index:any){
            tienda.products.sort(function (a:any, b:any) {
              if (a.name.toUpperCase() > b.name.toUpperCase()) {
                return 1;
              }
              if (a.name.toUpperCase() < b.name.toUpperCase()) {
                return -1;
              }
              return 0;
          })
          let productosAux = []
          let familiasPunto = [{
            gid:0,
            name:'Todos',
            products:tienda.products
          }]
          tienda.products.map(function(producto:any,index:any){
            let existe = -1
            
            familiasPunto.map(function(familia:any,index:any){
              if(familia.gid==producto.fk_product_family){
                existe=index
              }
            })
            if(existe!=-1){
              familiasPunto[existe].products.push(producto)
            }else{
              let familiaAux={
                gid:producto.fk_product_family,
                name:producto.product_family_name,
                products:[producto]
              }
              familiasPunto.push(familiaAux)
            }
          })

          tienda.familiaSel=familiasPunto[0]
          tienda.familias=familiasPunto

        })
        console.log("tiendas",tiendas)
        return tiendas
        
  }
  function cargarOpciones(localizacion:any,tienda){
    let arrayOpciones=[]
    
    switch (localizacion.structure.type) {
      case "numeric":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
           let name = localizacion.name+' '+i
           let marcado = comprobarLocalizacionMarcada(name,tienda)
           let arrayOpcionesAux = []

            if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {

                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
           
           
           arrayOpciones.push(
           {
             label:name,
             value:name,
             marcado:marcado,
             arrayOpciones:arrayOpcionesAux
           }
          )
          contadorOpciones++
        }
      break;
       case "numeric_even_odd":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          if(localizacion.structure.even_odd=='even'){
            if(i%2==0){
              let name = localizacion.name+' '+i
              let marcado = comprobarLocalizacionMarcada(name,tienda)
              let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {

                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
              arrayOpciones.push(
               {
                 label:name,
                 value:name,
                 marcado:marcado,
                 arrayOpciones:arrayOpcionesAux
               }
              )
              contadorOpciones++
            }
          }else{
            if(i%2!=0){
              let name = localizacion.name+' '+i
              let marcado = comprobarLocalizacionMarcada(name,tienda)
              let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
              
              arrayOpciones.push(
               {
                 label:name,
                 value:name,
                 marcado:marcado,
                 arrayOpciones:arrayOpcionesAux
               }
              )
              contadorOpciones++
            }
          }
           
        }
      break;
       case "alphanumeric":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
            let name = localizacion.name+' '+i+' '+String.fromCharCode(j)
            let marcado = comprobarLocalizacionMarcada(name,tienda)

            let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
            arrayOpciones.push(
             {
               label:name,
               value:name,
               marcado:marcado,
               arrayOpciones:arrayOpcionesAux
             }
            )
            contadorOpciones++
          }
        }
      break;
      case "alphanumeric_even_odd":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          if(localizacion.structure.even_odd=='even'){
            if(i%2==0){
              for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
                let name = localizacion.name+' '+i+' '+String.fromCharCode(j)
                let marcado = comprobarLocalizacionMarcada(name,tienda)

                let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
                arrayOpciones.push(
                 {
                   label:name,
                   value:name,
                   marcado:marcado,
                   arrayOpciones:arrayOpcionesAux
                 }
                )
                contadorOpciones++
              }
            }
          }else{
            if(i%2!=0){
              for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
                let name = localizacion.name+' '+i+' '+String.fromCharCode(j)
                let marcado = comprobarLocalizacionMarcada(name,tienda)

               let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
                arrayOpciones.push(
                 {
                   label:name,
                   value:name,
                   marcado:marcado,
                   arrayOpciones:arrayOpcionesAux
                 }
                )
                contadorOpciones++   
              }
            }
          }
        }
      break;
      case "alphabetical":
        for (var i = localizacion.structure.letter_from.charCodeAt(0); i <= localizacion.structure.letter_to.charCodeAt(0); i++) {
          let name = localizacion.name+' '+String.fromCharCode(i)
          let marcado = comprobarLocalizacionMarcada(name,tienda)

          let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
          arrayOpciones.push(
           {
             label:name,
             value:name,
             marcado:marcado,
             arrayOpciones:arrayOpcionesAux
           }
          )
          contadorOpciones++    
        }
      break;
      case "writing":
        localizacion.structure.writing.map(function(opcion:any,indice:number) {
          let name = localizacion.name+' '+opcion
          let marcado = comprobarLocalizacionMarcada(name,tienda)

          let arrayOpcionesAux = []
              if(localizacion.childs!=undefined){
              localizacion.childs.map(function(hijo:any,indice:number) {
                arrayOpcionesAux=cargarOpciones(hijo,tienda) 
              })
             }
          arrayOpciones.push(
            {
              label:name,
              value:name,
              marcado:marcado,
              arrayOpciones:arrayOpcionesAux
            }
           )
           contadorOpciones++
        })
      break;
    }
    return arrayOpciones
  }
  function obtenerProductos(){
    let filtrosAnteriores = global.usuario.filtros
    let filtrosProductos = []
    props.stock.map(function(punto:any,index:any){
        let productosAux = []
        let puntoActive=1
        punto.products.map(function(producto:any,i:any){
          let productoActive=1
          filtrosAnteriores?.products?.map(function(puntoAnt:any,index:any){
            if(puntoAnt.gid==punto.gid){
              puntoActive=puntoAnt.active
            }
            puntoAnt.products.map(function(productoAnt:any,index:any){
              if(productoAnt.gid==producto.gid){
                productoActive=productoAnt.active
              }
            })
          })
          producto.active=productoActive
          productosAux.push(producto)
        })
        punto.active=puntoActive
        punto.products=productosAux
        filtrosProductos.push(punto)
    })
    console.log("filtrosProductos",filtrosProductos)
    return filtrosProductos
  }
  function obtenerOrden(){
    let filtrosAnteriores = global.usuario.filtros
    let ordenAux = [{"id":1,"nombre":"Entrega Ascendente","valor":false},{"id":2,"nombre":"Entrega Descendente","valor":false},{"id":3,"nombre":"Estado","valor":true},{"id":4,"nombre":"Id","valor":false}]
    if(filtrosAnteriores.orden?.length>=0){
      ordenAux = filtrosAnteriores.orden
    }
    return ordenAux
  }
  function obtenerTiendas(){
    props.tiendas.map(function(tienda:any,indice:number) {
      tienda.localizaciones.map(function(localizacion:any,indice:number) {
        let arrayHijos:Array<any>=[]
        localizacion.arrayOpciones=cargarOpciones(localizacion,tienda)
      })
    })
    let tiendasParam=[]
    if(global.usuario.filtros.tiendas!=undefined){
      tiendasParam=global.usuario.filtros.tiendas.slice()
    }
      
      props.tiendas.map(function(tienda:any,indice:number) {
        tienda.marcado=true
        tiendasParam.map(function(tiendaParam:any,indiceParam:number) {
        if(tiendaParam.gid==tienda.gid){
          tienda.marcado=tiendaParam.marcado
          tiendaParam.localizaciones.map(function(localizacionParam:any,indice:number) {
            tienda.localizaciones.map(function(localizacion:any,indice:number) {
              if(localizacionParam.gid==localizacion.gid){
                comprobarMarcados(localizacionParam.arrayOpciones,localizacion.arrayOpciones)
                
              }
            })
          })
        }
        })
      })
    
    console.log("obtenerTiendas",props.tiendas)
    return props.tiendas
  }
  useEffect(() => {
    console.log("categorias",categorias)
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    categoriasAux.products=agruparProductosEnFamilias(obtenerProductos())
    categoriasAux.tiendas=obtenerTiendas()
    categoriasAux.orden = obtenerOrden()
    setCategorias(categoriasAux)
    setTimeout(function(){
      props.setCargandoFiltros(false)
    },1500)
    

  }, []);
  function comprobarMarcados(opcionesParam:any,opciones){
    if(opcionesParam!=undefined && opcionesParam!=null && opciones!=undefined && opciones!=null){
      opcionesParam.map(function(opcionParam:any,indice:number) {
        opciones.map(function(opcion:any,indice:number) {
          let marcado=false
          if(opcionParam.value==opcion.value){
            marcado=opcionParam.marcado
            opcion.marcado=marcado
          }
          if(marcado){
            comprobarMarcados(opcionParam.arrayOpciones,opcion.arrayOpciones)
          }
        })
      })
    }
  }
  function recorrerOpciones(opciones:any,arrayIndices:any){

    let indice = arrayIndices.shift()
    if(arrayIndices.length>0){
      let marcado = false
      let opcionSel
      opciones.map(function(item:any,index:any){

        if(item.value==indice){
          opcionSel=item
        }
      })

      marcado = recorrerOpciones(opcionSel.arrayOpciones,arrayIndices)
      if(marcado){
        opcionSel.marcado=true
      }
      return marcado
    }else{
      let marcado = false
      let itemSel
      opciones.map(function(item:any,index:any){
        if(item.value==indice){
          itemSel=item
          item.marcado=!item.marcado
          marcado=item.marcado
        }
      })
      if(!marcado){
        desmarcarOpciones(itemSel.arrayOpciones)
      }
      return marcado
      //opciones[indice].marcado=!opciones[indice].marcado
    }

  }
  function desmarcarOpciones(opciones){
    opciones.map(function(item:any,index:any){
      item.marcado=false
      if(item.arrayOpciones.length>0){
        desmarcarOpciones(item.arrayOpciones)
      }
    })
  }
  function onPressLocacion(localizacionStr:any,indiceTienda:any,indiceLoc:any,arrayIndices:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    let checksTiendasAux =categorias.tiendas
    if(arrayIndices.length>0){
      let marcado = false
      marcado = recorrerOpciones(checksTiendasAux[indiceTienda].localizaciones[indiceLoc].arrayOpciones,arrayIndices)
      if(marcado){
        checksTiendasAux[indiceTienda].marcado=true
      }
    }
    categoriasAux.tiendas = checksTiendasAux
    setCategorias(categoriasAux)
  }
  function onPressTienda(indiceTienda:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    let checksTiendasAux =categorias.tiendas
    let marcado = checksTiendasAux[indiceTienda].marcado || false
    checksTiendasAux[indiceTienda].marcado=!marcado

    if(marcado){
      checksTiendasAux[indiceTienda].localizaciones.map(function(localizacion:any,index:number) {
        desmarcarOpciones(localizacion.arrayOpciones)
      })  
    }
    categoriasAux.tiendas = checksTiendasAux

    setCategorias(categoriasAux)
  }
  
  function pintarOpciones(opciones:any,indiceTienda:any,indiceLoc:any,arrayIndices:any){
    return(
      <>
      {
          opciones!=undefined?(
            <View>
            {
              opciones.map(function(opcion:any,indiceOpcion:number) {
                 
                  return(
                    <View style={{marginLeft:20}}>
                    {
                      opcion.arrayOpciones!=undefined && opcion.arrayOpciones.length>0?(
                          <AccordionListItem title={
                               <CheckBox
                                  title={opcion.label}
                                  checked={opcion.marcado}
                                  textStyle={{fontWeight:'normal',color:'black'}}
                                  containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                  checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                  uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                  onPress={(data) => {onPressLocacion(opcion.label,indiceTienda,indiceLoc,arrayIndices.concat([opcion.label]))}} 
                                />
                            }
                            titleBackgroud='#F5F5F5'
                            opened={false}
                          >

                          
                            {
                              opcion.arrayOpciones!=undefined?(
                                <View>
                                  <View style={{marginTop:15,marginBottom:10,justifyContent:'center',alignItems:'center'}}>
                                    <Text>Si no seleccionas ningún subnivel, recibirás todos los pedidos de este nivel</Text>
                                  </View>
                                {
                                  pintarOpciones(opcion.arrayOpciones,indiceTienda,indiceLoc,arrayIndices.concat([opcion.label]))
                                }
                                </View>
                              ):(null)
                            }
                          </AccordionListItem>
                        ):(

                          <CheckBox
                              title={opcion.label}
                              checked={opcion.marcado}
                              textStyle={{fontWeight:'normal',color:'black'}}
                              containerStyle={{backgroundColor:'traparent',borderWidth:0}}
                              checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                              uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                              onPress={(data) => {onPressLocacion(opcion.label,indiceTienda,indiceLoc,arrayIndices.concat([opcion.label]))}} 
                            />
                        )
                    }
                    </View>
                  )
              })
            }
            </View>
          ):(null)
      }
      </>
    )

  }
  function pintarLocalizaciones(localizacion:any,indiceTienda:any,indiceLoc:any){

      return(
        <View style={{marginLeft:20,flex:1,flexDirection:'row'}}>

        {
          localizacion.arrayOpciones!=undefined?(
            <View style={{width:'100%'}}>
            {
              pintarOpciones(localizacion.arrayOpciones,indiceTienda,indiceLoc,[])
            }
            </View>
          ):(null)
        }
        </View>
      )
  }
  
  function changeCheckProducto(indicePunto:any,producto:any){
    let categoriasAux = JSON.parse(JSON.stringify(categorias))
   
    let checksProductosAux =categorias.products
    let marcado=producto.active
    checksProductosAux.map(function(item:any,index:any){
      item.products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.active=!marcado
        }
      })
      item.familiaSel.products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.active=!marcado
        }
      })
    })
    categoriasAux.products = checksProductosAux
    setCategorias(categoriasAux)
  }
  function onClickFamilia(indicePunto:any,familia:any){

    let categoriasAux = JSON.parse(JSON.stringify(categorias))
    let checksProductosAux =categorias.products
    checksProductosAux[indicePunto].familiaSel=familia
    categoriasAux.products = checksProductosAux
    setCategorias(categoriasAux)
  }
  /*
  
            <TouchableOpacity activeOpacity={1}>
              <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>ESTADOS</Text>
              <FlatList
                  data={Object.keys(categorias.status)}
                 
                  numColumns={Math.min(parseInt(props.width/250),2)}
                  showsVerticalScrollIndicator={false}
                  renderItem={({item,i}:any) => {
                    let estado = categorias.status[item] 
                    return( 
                      <View style={{flex:1}} key={item.name}>
                          <CheckBox
                            title={estado.name}
                            checked={estado.active}
                            textStyle={{fontWeight:'normal',color:'black',fontSize:16}}
                            containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                            checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                            uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                            onPress={(data) => {changeCheckEstado(item)}} 
                          />
                      </View>
                    )
                  }}
              />
            </TouchableOpacity>
  */
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack} style={[{height:'100%'},props.cargandoFiltros?{display:'none'}:{display:'flex'}]}>
    <View style={{backgroundColor:'rgba(255,255,255,0.3)',height:'100%',alignItems:'center',justifyContent:'center'}}>
      
      <TouchableOpacity activeOpacity={1} style={{width:'60%',height:'80%',borderRadius:8,backgroundColor:'white',minWidth:350}}>
        <TouchableOpacity  onPress={goBack}  style={{position:'absolute',top:20,right:20,zIndex:2}}>
          <AntDesign name="close" size={32} color="#3AA597" />
        </TouchableOpacity>
       
        <ScrollView 
         
          contentContainerStyle={{paddingBottom:90, justifyContent: 'center'}} 
          style={{marginLeft:20,paddingRight:20,maxHeight:props.height-20}}>
          <TouchableOpacity
            style={{marginVertical:10,flexDirection:'row'}}
            onPress={actualizarFiltros.bind(this)}>
            <Text style={{color:'#3AA597',marginRight:10}}>Actualizar Filtros</Text>
            {actualizando?(
              <ActivityIndicator color='#3AA597'/>
            ):(null)}
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={1}>
            <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>LOCALIZACIONES</Text>
            {
              categorias.tiendas.map(function(tienda:any,indiceTienda:number) {
                    
                return(
                  <View style={{}}>
                          <AccordionListItem title={
                          
                              <CheckBox
                                title={tienda.name}
                                checked={tienda.marcado}
                                textStyle={{fontWeight:'normal',color:'black'}}
                                containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                onPress={(data) => {onPressTienda(indiceTienda)}}
                              />
                           
                          }
                          opened={false}
                          >
                          <View>
                          <View style={{marginTop:15,marginBottom:10,justifyContent:'center',alignItems:'center'}}>
                            <Text>Si no seleccionas ningún subnivel, recibirás pedidos de toda la tienda</Text>
                          </View>
                          {
                            tienda.localizaciones.map(function(localizacion:any,indiceLoc:number) {
                              return(pintarLocalizaciones(localizacion,indiceTienda,indiceLoc))
                            })  
                          }
                          </View>
                          </AccordionListItem>
                          
                      </View>
                  )
                    
                    
              })}
            </TouchableOpacity>
          
          
            <TouchableOpacity activeOpacity={1}>
          <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>PUNTOS DE VENTA Y PRODUCTOS</Text>
          {
            categorias.products.map(function(punto:any,indicePunto:number) {
                  
              return(
                <View style={{}}>
                <AccordionListItem title={
                  <Text style={{paddingLeft:20,paddingBottom:20,fontSize:18,marginTop:20}}>
                  {punto.name}
                  </Text>
                 }
                 opened={true}
                 style={{backgroundColor:'#E5E5E5'}}
              >
                <View  style={{marginTop:10,minHeight:70}}>
                <FlatList
                    data={punto.familias}
                    keyExtractor={(item:object, index:number): string => index.toString()}
                    showsVerticalScrollIndicator={false}
                    horizontal
                    renderItem={function(item:any){
                      let familia=item.item
                      if(familia.products.length==0){
                        return(<View style={{height:70}}></View>)
                      }else{
                        return(
                          <TouchableOpacity 
                            style={[
                              {justifyContent:'center',marginRight:4,marginLeft:4,borderRadius:40,height:40},
                              familia.gid==punto.familiaSel.gid ? {backgroundColor:'#3AA597'} : {borderWidth:1,borderColor:'#3AA597'}
                            ]}
                            onPress={onClickFamilia.bind(this,indicePunto,familia)}
                          >
                            <Text style={[{fontWeight:'500',paddingLeft:20,paddingRight:20,paddingTop:5,paddingBottom:5,fontSize:16,color:'white',textAlign:'center'},familia.gid==punto.familiaSel.gid ? {} : {color:'#3AA597'}]}>{familia.name}</Text>
                          </TouchableOpacity>
                        )
                      }
                    }}
                />
                {punto.familiaSel!=null && punto.familiaSel.products.length>0?(
                   <MasonryList
                        data={punto.familiaSel.products}
                        //keyExtractor={(item:object, index:number) => {return(index.toString())}}
                        numColumns={2}
                        showsVerticalScrollIndicator={false}
                        style={{paddingLeft:20}}
                        renderItem={({item,i}:any) => {
                          let producto = item
                          return( 
                            <View style={{}}>
                                <CheckBox
                                  title={<TouchableOpacity style={{paddingLeft:5,alignItems:'center',justifyContent:'center'}} activeOpacity={1} onPress={changeCheckProducto.bind(this,indicePunto,producto)}><Text>{producto.name}</Text></TouchableOpacity>}
                                  checked={producto.active }

                                  textStyle={{fontWeight:'normal',color:'black',fontSize:16}}
                                  containerStyle={{borderWidth:0,backgroundColor:'transparent'}}
                                  checkedIcon={<TouchableOpacity activeOpacity={1} onPress={changeCheckProducto.bind(this,indicePunto,producto)}><FontAwesome size={20} name="check-square" color="black" /></TouchableOpacity>}
                                  uncheckedIcon={<TouchableOpacity activeOpacity={1} onPress={changeCheckProducto.bind(this,indicePunto,producto)}><FontAwesome size={20} name="square-o" color="black" /></TouchableOpacity>}
                                  activeOpacity={1}
         
                                  //onPress={(data) => {changeCheckProducto(indicePunto,i)}} 
                                />
                            </View>
                          )
                        }}
                    />
                  ):(null)}
                   
                </View>
              </AccordionListItem>
                    
                    </View>
                )
                  
                  
            })}
            </TouchableOpacity>

            <TouchableOpacity activeOpacity={1}>
            <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>ORDEN</Text>



            <FlatList
                  data={categorias.orden}
                 
                  numColumns={Math.min(parseInt(props.width/250),2)}
                  showsVerticalScrollIndicator={false}
                  renderItem={({item,i}:any) => {
                   
                    return( 
                      <View style={{flex:1}} key={item.nombre}>
                          <CheckBox
                            title={item.nombre}
                            checked={item.valor}
                            textStyle={{fontWeight:'normal',color:'black',fontSize:16}}
                            containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                            checkedIcon={<Ionicons name="radio-button-on" size={20} color="black" />}
                            uncheckedIcon={<Ionicons name="radio-button-off" size={20} color="black" />}
                            onPress={(data) => {changeCheckOrden(item)}} 
                          />
                      </View>
                    )
                  }}
              />
          </TouchableOpacity>
          
        </ScrollView>

        <TouchableOpacity  onPress={aplicarFiltros} style={{position:'absolute',bottom:0,paddingBottom:20,width:'100%',alignItems:'center',justifyContent:'center',backgroundColor:'white',borderRadius:8}}>
          <View style={{justifyContent:'center',alignItems:'center',minWidth:250,backgroundColor:'#FEB241',borderRadius:50,height:50}}>
            <Text style={{fontWeight:'bold',color:'white',fontSize:16}}>Aplicar filtros</Text>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
      
    </View>
    </TouchableOpacity>
  );
};
const mapDispatchToProps = {
  setCargandoFiltros,
  setTiendas,
  setStock
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width:state.dimensions.width,
    height:state.dimensions.height,
    stock:state.stock.stock,
    cargandoFiltros:state.config.cargandoFiltros
  };
};
export default connect(mapStateToProps,mapDispatchToProps )(filtros);



/*
<TouchableOpacity activeOpacity={1}>
            <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>LOCALIZACIONES</Text>
            {
              categorias.locations.map(function(tienda:any,indiceTienda:number) {
                    
                return(
                  <View style={{}}>
                          <AccordionListItem title={
                          
                              <CheckBox
                                title={tienda.name}
                                checked={tienda.active}
                                textStyle={{fontWeight:'normal',color:'black'}}
                                containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                onPress={(data) => {changeCheckTienda(indiceTienda)}} 
                              />
                           
                          }
                          opened={false}
                          >
                          <View>
                          {
                            tienda.points_sale.map(function(barra:any,indiceBarra:number) {
                             
                              return(
                                <View style={{marginLeft:20}}>
                                  <CheckBox
                                    title={barra.name}
                                    checked={barra.active}
                                    textStyle={{fontWeight:'normal',color:'black'}}
                                    containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                    checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                    uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                    onPress={(data) => {changeCheckBarra(indiceTienda,indiceBarra)}} 
                                  />
                                  <Text style={{marginLeft:20,flex:1,flexDirection:'row'}}>
                                  {
                                    barra.locations.map(function(loc:any,indiceLoc:number) {
                                        return(
                                          <View style={{marginLeft:20}}>
                                           <CheckBox
                                            title={loc.name}
                                            checked={loc.active}
                                            textStyle={{fontWeight:'normal',color:'black'}}
                                            containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                            checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                            uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                            onPress={(data) => {changeCheckLoc(indiceTienda,indiceBarra,indiceLoc)}} 
                                            />
                                            {
                                              pintarHijos([indiceTienda,indiceBarra,indiceLoc],loc)
                                            }
                                          </View>
                                        )
                                    })
                                  }
                                  </Text>
                                    
                                  
                                </View>
                                )
                            })  
                          }
                          </View>
                          </AccordionListItem>
                          
                      </View>
                  )
                    
                    
              })}
            </TouchableOpacity>



<TouchableOpacity activeOpacity={1}>
          <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>PRODUCTOS</Text>
          {
            categorias.products.map(function(punto:any,indicePunto:number) {
                  
              return(
                <View style={{}}>
                     <AccordionListItem title={
                        <CheckBox
                          title={punto.name}
                          checked={punto.active}
                          textStyle={{fontWeight:'normal',color:'black'}}
                          containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                          checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                          uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                          onPress={(data) => {changeCheckPunto(indicePunto)}} 
                        />
                       }
                       opened={false}>
                       <View>
                        {
                          punto.products.map(function(producto:any,indiceProducto:number) {
                           
                            return(
                              <View style={{marginLeft:20}}>
                                <CheckBox
                                  title={producto.name}
                                  checked={producto.active}
                                  textStyle={{fontWeight:'normal',color:'black'}}
                                  containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                                  checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                                  uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                                  onPress={(data) => {changeCheckProducto(indicePunto,indiceProducto)}} 
                                />
                              </View>
                              )
                          })  
                        }
                        </View>
                        </AccordionListItem>
                    </View>
                )
                  
                  
            })}
            </TouchableOpacity>
*/