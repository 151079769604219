import React, { useState, useEffect, useRef } from 'react';
import {TextInput,ScrollView,FlatList ,Text,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import { FontAwesome,AntDesign,Ionicons } from '@expo/vector-icons';
import {CheckBox} from "react-native-elements";
import { StatusBar } from 'expo-status-bar';
import { connect } from "react-redux";
import MasonryList from '@react-native-seoul/masonry-list';
import AccordionListItem from '../components/AccordionListItem'
import { setTiendas,setPedidos,setStock } from "../../application/reducers";

const vistaStock = (props:any) => {
  let initArray:Array<any>=[]
  
  const [puntosDeVenta, setPuntosDeVenta] = useState(initArray);
  const [familias, setFamilias] = useState(initArray);
  const [filtrosSel, setFiltrosSel] = useState(initArray);
  const [familiaSel, setFamiliaSel] = useState({});
  const [checkCambiados,setCheckCambiados] = useState([]);

  function goBack(){
    
    props.navigation.goBack()
  }
  useEffect(() => {

    global.usuario.cargarPuntosDeVentaTienda(props.route.params.tienda).then(function(res:any){
       console.log('res',res)
       let familias = []
       if(res){
         res.map(function(puntoDeVenta:any,index:any){
           global.usuario.cargarProductos(puntoDeVenta.gid).then(function(productos){
              console.log('productos',productos)
              productos.sort(function (a:any, b:any) {
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                  return 1;
                }
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                  return -1;
                }
                return 0;
              })
              let productosAgotados = []
              let familiasPunto = []
              productos.map(function(producto:any,index:any){
                let existe = -1
                producto.action='Añadir'
                producto.cantidadCambiada=0
               
                if(producto.stock==0){
                  productosAgotados.push(producto)
                }
                familiasPunto.map(function(familia:any,index:any){
                  if(familia.gid==producto.fk_product_family){
                    existe=index
                  }
                })
                if(existe!=-1){
                  familiasPunto[existe].products.push(producto)
                }else{
                  let familiaAux={
                    gid:producto.fk_product_family,
                    name:producto.product_family_name,
                    products:[producto]
                  }
                  familiasPunto.push(familiaAux)
                }
              })
              familiasPunto.unshift({
                gid:999,
                name:'Agotados',
                products:productosAgotados
              })
              familiasPunto.unshift({
                gid:0,
                name:'Todos',
                products:productos,

              })
              puntoDeVenta.familiaSel=familiasPunto[0]
              puntoDeVenta.familias=familiasPunto

              setPuntosDeVenta(res)
              
              
           })
         })

       }
    })
    
    let filtrosSel=JSON.parse(JSON.stringify(global.usuario.filtros))
    setFiltrosSel(filtrosSel)
   
  }, []);
  function changeCantidad(indicePunto:any,indiceProducto:any,cantidad:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    puntosDeVentaAux[indicePunto].familiaSel.products[indiceProducto].cantidadCambiada=cantidad

    setPuntosDeVenta(puntosDeVentaAux)
  }
  function changeAction(indicePunto:any,indiceProducto:any,action:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    puntosDeVentaAux[indicePunto].familiaSel.products[indiceProducto].action=action
    setPuntosDeVenta(puntosDeVentaAux)
  }
  function changeActive(indicePunto:any,indiceProducto:any,active:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    let punto = puntosDeVentaAux[indicePunto]
    let familia = punto.familiaSel
    let producto = familia.products[indiceProducto]
    global.usuario.cambiarActiveProducto(producto.gid,active).then(function(data){
        puntosDeVentaAux[indicePunto].familiaSel.products[indiceProducto].active=active
        setPuntosDeVenta(puntosDeVentaAux)
    })
  }
  function changeAgotado(indicePunto:any,indiceProducto:any,cantidad:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    console.log("puntosDeVentaAux",puntosDeVentaAux)
    let punto = puntosDeVentaAux[indicePunto]
    let familia = punto.familiaSel
    let producto = familia.products[indiceProducto]
    global.usuario.cambiarStock(punto.gid,producto.gid,cantidad,'Establecer').then(function(data){
        producto.stock=data.stock
        setPuntosDeVenta(puntosDeVentaAux)
    })
  }
  function onPressAction(indicePunto:any,indiceProducto:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    let punto = puntosDeVentaAux[indicePunto]
    let familia = punto.familiaSel
    let producto = familia.products[indiceProducto]
    global.usuario.cambiarStock(punto.gid,producto.gid,producto.cantidadCambiada,producto.action).then(function(data){
        producto.cantidadCambiada=0
        producto.stock=data.stock
        setPuntosDeVenta(puntosDeVentaAux)
    })
    
  }
  function changeCheckPunto(indicePunto:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
   
    let marcado=puntosDeVentaAux[indicePunto].active
    if(marcado){
      puntosDeVentaAux[indicePunto].products.map(function(producto:any,indiceProducto:number) {
        producto.active = false
      })
    }else{
      puntosDeVentaAux[indicePunto].products.map(function(producto:any,indiceProducto:number) {
        producto.active = true
      })
    }
    puntosDeVentaAux[indicePunto].active=!marcado
    setPuntosDeVenta(puntosDeVentaAux)
  }
 
  function changeCheckProducto(indicePunto:any,producto:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    let marcado=producto.stock
    if(marcado!=0){
      puntosDeVentaAux[indicePunto].products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.stock=0
        }
      })
      puntosDeVentaAux[indicePunto].familiaSel.products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.stock=0
        }
      })
      setPuntosDeVenta(puntosDeVentaAux)

      checkCambiados.map(function(item:any,index:any){
        if(item.puntoGid==puntosDeVentaAux[indicePunto].gid && item.productoGid==producto.gid ){
          checkCambiados.splice(index,1)
        }
      })

      let checkCambiadoAux = {
        puntoGid:puntosDeVentaAux[indicePunto].gid,
        productoGid:producto.gid,
        stock:0
      }
      checkCambiados.push(checkCambiadoAux)
      setCheckCambiados(checkCambiados)
    }else{
      puntosDeVentaAux[indicePunto].products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.stock=null
        }
      })
      puntosDeVentaAux[indicePunto].familiaSel.products.map(function(item:any,index:any){
        if(item.gid==producto.gid){
          item.stock=null
        }
      })
      setPuntosDeVenta(puntosDeVentaAux)

      checkCambiados.map(function(item:any,index:any){
        if(item.puntoGid==puntosDeVentaAux[indicePunto].gid && item.productoGid==producto.gid ){
          checkCambiados.splice(index,1)
        }
      })

      let checkCambiadoAux = {
        puntoGid:puntosDeVentaAux[indicePunto].gid,
        productoGid:producto.gid,
        stock:null
      }
      checkCambiados.push(checkCambiadoAux)
      setCheckCambiados(checkCambiados)
    }
  }
  
  function onClickFamilia(indicePunto:any,familia:any){
    let puntosDeVentaAux = puntosDeVenta.slice();
    puntosDeVentaAux[indicePunto].familiaSel=familia
     setPuntosDeVenta(puntosDeVentaAux)
  }
  
  return (
    <View style={{flex:1,backgroundColor:'white',paddingLeft:10}}>
      <ScrollView 
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{paddingBottom:90}} 
        style={{height:(props.height-80)}}
      >
      {
        puntosDeVenta.map(function(punto:any,indicePunto:number) {

          let filtrado = false
          if(filtrosSel.products!=undefined){
            filtrosSel.products.map(function(puntoAux:any,i:number) {
              if(punto.gid==puntoAux.gid){
                if(puntoAux.active==1 || puntoAux.active==true){
                    filtrado=true
                }
                
              }
            })
          }
          if(true){

          return(

                <View  style={{marginTop:10}}>
                <View style={{marginVertical:20}}>
                  <Text style={{fontSize:18}}>{punto.name}</Text>
                </View>
                <FlatList
                    data={punto.familias}
                    keyExtractor={(item:object, index:number): string => index.toString()}
                    showsVerticalScrollIndicator={false}
                    horizontal

                    style={{maxHeight:50}}
                    renderItem={function(item:any){
                      let familia=item.item
                      if(familia.products.length==0){
                        return(null)
                      }else{
                        return(
                          <TouchableOpacity 
                            style={[
                              {justifyContent:'center',marginRight:4,marginLeft:4,borderRadius:40,height:40},
                              familia.gid==punto.familiaSel.gid ? {backgroundColor:'#3AA597'} : {borderWidth:1,borderColor:'#3AA597'}
                            ]}
                            onPress={onClickFamilia.bind(this,indicePunto,familia)}
                          >
                            <Text style={[{fontWeight:'500',paddingLeft:20,paddingRight:20,paddingTop:5,paddingBottom:5,fontSize:16,color:'white',textAlign:'center'},familia.gid==punto.familiaSel.gid ? {} : {color:'#3AA597'}]}>{familia.name}</Text>
                          </TouchableOpacity>
                        )
                      }
                    }}
                />
                <View style={{flexDirection:'row'}}>
                  <View style={{width:100}}>
                    <View style={{flexDirection:'row',marginVertical:10,height:50}}>
                      <View style={{justifyContent:'center',alignItems:'flex-start'}}>
                        <Text style={[{fontSize:16,color:'black',textAlign:'left'}]}>Producto</Text>
                      </View>
                    </View>
                  {
                    punto.familiaSel?.products.map(function(producto:any,indiceProducto:number) {
                      return(
                        <View style={{flexDirection:'row',marginVertical:10,height:50}}>
                          <View style={{justifyContent:'center',alignItems:'flex-start',flex:1}}>
                            <Text style={[{fontSize:16,color:'black',textAlign:'left',flex:1}]}>{producto.name}</Text>
                          </View>
                        </View>
                      )
                    })
                  }
                  </View>

                  <ScrollView 
                    showsHorizontalScrollIndicator={false}
                    horizontal
                  >
                  <View>
                    <View style={{flexDirection:'row',marginVertical:20}}>
                          <View style={{height:50,width:100,justifyContent:'center',alignItems:'center'}}>
                            <Text style={[{fontSize:16,color:'black',textAlign:'center'}]}>Stock</Text>
                          </View>
                          <View style={{height:50,width:250,flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <Text style={[{fontSize:16,color:'black',textAlign:'center'}]}>Cantidad</Text>
                          </View>

                          
                          <View style={{height:50,width:150,justifyContent:'center',alignItems:'center'}}>
                            <Text style={[{fontSize:16,color:'black',textAlign:'center'}]}>Agotado</Text>
                          </View>
                          <View style={{height:50,width:100,justifyContent:'center',alignItems:'center'}}>
                             <Text style={[{fontSize:16,color:'black',textAlign:'center'}]}>Visible</Text>
                          </View>
                        </View>
                  {
                    punto.familiaSel?.products.map(function(producto:any,indiceProducto:number) {
                      console.log('productofasdfasdf',producto)
                      return(
                        <View style={{flexDirection:'row',marginVertical:10}}>
                          <View style={{width:100,justifyContent:'center',alignItems:'center'}}>
                            <Text style={[{fontSize:16,color:'black',textAlign:'center'}]}>{producto.stock}</Text>
                          </View>
                          <View style={{width:250,flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <View style={{maxWidth:50,width:50,flexDirection:'row',marginRight:10}}>
                              <TextInput
                                value={producto.cantidadCambiada}
                                style={{borderWidth:1,maxWidth:50,textAlign:'center',height:50,borderRadius:8}}
                                numberOfLines = {1}
                                keyboardType="numeric"
                                onChangeText={ text => {
                                    changeCantidad(indicePunto,indiceProducto,text);
                                }}
                              />
                            </View>
                            <View style={{flex:1,flexDirection:'row'}}>
                              <View style={{flex:1,flexDirection:'row',overflow:'hidden',borderRadius:8,backgroundColor:'#3AA597'}}>
                                <TouchableOpacity style={{justifyContent:'center',alignItems:'center',flex:1}} onPress={onPressAction.bind(this,indicePunto,indiceProducto)}>
                                  <Text style={{fontSize:16,color:'white',textAlign:'center'}}>{producto.action}</Text>
                                </TouchableOpacity>
                                <View style={{backgroundColor:'white',width:1,height:'100%'}}>
                                </View>
                                <Picker
                                  style={{height:50,backgroundColor:'#3AA597',color:'white',width:40,fontSize:0}}
                                  mode={"dropdown"}
                                  onValueChange={
                                    function(itemValue, itemIndex) {
                                      changeAction(indicePunto,indiceProducto,itemValue)
                                    }
                                  }
                                >
                                  <Picker.Item color={'#3AA597'} label={'Añadir'} value={'Añadir'} />
                                  <Picker.Item color={'#3AA597'} label={'Quitar'} value={'Quitar'} />
                                  <Picker.Item color={'#3AA597'} label={'Establecer'} value={'Establecer'} />
                                </Picker>
                              </View>
                            </View>

                          </View>
                          <View style={{width:150,justifyContent:'center',alignItems:'center'}}>
                            <TouchableOpacity 
                              style={[
                                {justifyContent:'center',marginRight:4,marginLeft:4,borderRadius:40,height:40},
                                producto.stock==0 ? {backgroundColor:'#3AA597'} : {borderWidth:1,borderColor:'#3AA597'}
                              ]}
                              onPress={changeAgotado.bind(this,indicePunto,indiceProducto,producto.stock==0?9999:0)}
                            >
                              <Text style={[{paddingLeft:20,paddingRight:20,paddingTop:5,paddingBottom:5,fontSize:16,color:'white',textAlign:'center'},producto.stock==0 ? {} : {color:'#3AA597'}]}>Agotado</Text>
                            </TouchableOpacity>
                          </View>
                          <View style={{width:100,justifyContent:'center',alignItems:'center'}}>
                             {producto.active?(
                               <TouchableOpacity style={{paddingRight:10}} onPress={changeActive.bind(this,indicePunto,indiceProducto,false)}>
                                  <AntDesign name="eye" size={24} color="#3AA597" />
                                </TouchableOpacity>
                                )
                              :
                              (
                                
                                <TouchableOpacity style={{paddingRight:10}} onPress={changeActive.bind(this,indicePunto,indiceProducto,true)}>
                                  <AntDesign name="eyeo" size={24} color="#3AA597" />
                                </TouchableOpacity>
                              )
                             }
                          </View>
                        </View>
                      )
                    })
                  }
                   </View>
                   </ScrollView>
                  </View>
                </View>

          )
          }
        })
      }
    </ScrollView>
    </View>
  );
};
const mapDispatchToProps = {
  setTiendas,
  setPedidos,
  setStock
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    pedidos: state.pedidos.pedidos,
    height:state.dimensions.height,
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(vistaStock);