import hasIngredients from "../../ProductDetail/methods/hasIngredients";
import hasVariants from "../../ProductDetail/methods/hasVariants";

const isComplex = (product) => {
  const { long_description } = product;
  const validDescription =
    long_description !== "" &&
    long_description !== null &&
    long_description !== undefined;

  const isMenu = product?.product_type === "menu";
  if (
    isMenu ||
    hasVariants(product) ||
    hasIngredients(product) ||
    validDescription
  ) {
    return true;
  }
};

export default isComplex;
