import React, { useState, useEffect } from "react";
import { View } from "react-native";
import MasonryList from "@react-native-seoul/masonry-list";
import FamiliaListado from "../components/FamiliaListado";
import { connect } from "react-redux";
import { createStyles, maxWidth } from "react-native-media-queries";
import { setConexion } from "../../application/reducers";

const listadoFamilias = (props: any) => {
  let tienda = props.route.params.tienda;
  const [listadoFamilias, setlistadoFamilias] = useState([]);

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: tienda.name,
    });
    setlistadoFamilias(tienda.products);
  }, []);

  function onPressFamilia(familia: any) {
    let aux = {
      tienda: tienda,
      familia: familia,
    };
    props.navigation.navigate("detallesTienda", aux);
  }

  return (
    <View style={{ flex: 1 }}>
      <View
        style={[
          { paddingTop: 20, marginRight: 20, marginLeft: 20 },
          global.pantallaDividida
            ? { height: props.height - 60 }
            : { height: props.height - 120 },
        ]}
      >
        <MasonryList
          data={listadoFamilias}
          numColumns={
            parseInt(props.width / 350) == 0 ? 1 : parseInt(props.width / 350)
          }
          showsVerticalScrollIndicator={false}
          renderItem={({ item }: any) => (
            <FamiliaListado
              onPress={onPressFamilia}
              familia={item}
            ></FamiliaListado>
          )}
        />
      </View>
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    conexion: state.dimensions.conexion,
  };
};
const mapDispatchToProps = {
  setConexion,
};
export default connect(mapStateToProps, mapDispatchToProps)(listadoFamilias);
const base = {
  btnMenu: {
    padding: 10,
    paddingLeft: 20,
    borderBottomWidth: 1,
  },
  txtMenu: {
    fontSize: 24,
  },
  menuContainer: {
    minWidth: 200,
  },
};
const stylesBase = createStyles(
  base,
  // override styles only if screen height is less than 500
  maxWidth(400, {
    txtMenu: {
      fontSize: 16,
    },
  })
);
