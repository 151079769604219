import React,{useEffect,useState} from 'react';

import {Dimensions,Image,StyleSheet,TouchableOpacity,Text,View} from "react-native";
import {Entypo} from '@expo/vector-icons'; 

import Swipeable from 'react-native-gesture-handler/Swipeable';
const ProductoResumenDatafono = (props:any) => {
  const {lineaPedido} = props
  let producto = lineaPedido.product
  const [strVariantes, setStrVariantes] = useState('');
  const [strIngredientes, setStrIngredientes] = useState('');
  let arrayInit:Array<any>=[]
  const [menu_lines, setMenu_lines] = useState(arrayInit);
  
  
    function pintarVariantes(variantes:any,quantityProduct:any){
      console.log("variantes",variantes)
     if(variantes!=undefined && variantes!=null){
       return(
         
          variantes.map(function(grupoVariante:any,indice:number) {
            if(grupoVariante!=undefined && grupoVariante!=null){
              let opciones = grupoVariante.orderLineGroupVariants
              if(grupoVariante.orderLineMenuGroupVariants){
                opciones=grupoVariante.orderLineMenuGroupVariants
              }
              return(
                opciones.map(function(opcion:any,index:number) {

                    const strCantidad = `+ ${opcion.quantity > 1 ?  `${opcion.quantity}x ` : ''}`;
                    return(
                      <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
                        <View style={{flex:1,flexDirection:'row'}}>
                          <Text style={{fontWeight:'bold'}}>{strCantidad}</Text>
                          <Text style={{}}>{opcion.name}</Text>
                        </View>
                        
                          <Text style={{color:'black',fontSize:12}}>{(quantityProduct*opcion.quantity*(opcion.amount/100||0)).toFixed(2)}€</Text>
                        
                        
                      </View>
                    )
                  
                })
              )
             }
          })
        )
      }

    }
    function pintarIngredientes(ingredientes:any){
      /*let strIngredientes = ''
      let primerIng = true
      producto.ingredients_removed.map(function(ingrediente:any,indice:number) {
        var strComa=','
        if(primerIng){
          strComa=''
          primerIng=false
        }
        strIngredientes+=strComa+' '+ingrediente.name    
      })
      if(strIngredientes!=''){
        return(
          <View style={{marginLeft:20}}>
            <Text>SIN: {strIngredientes}</Text>          
          </View>
        )
      }*/
      console.log("ingredientes",ingredientes)
      if(ingredientes!=undefined){
      return(
        ingredientes.map(function(ingrediente:any,indice:number) {
         
          return(
            <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
              <View style={{flex:1,flexDirection:'row'}}>
                <Text style={{fontWeight:'bold'}}>SIN </Text>
                 <Text>{ingrediente}</Text> 
              </View>
             
            </View>
          )
              
        })
      )
    }
    }
    
  
  
  useEffect(() => {
     console.log("useEffect",producto)
    let menu = lineaPedido.orderLineMenus || []
    let menuAux = []
    menu.map(function(item:any,index:any){
      if(item!=null){
        menuAux.push(item)
      }
    })
    setMenu_lines(menuAux)
     console.log("menuAux",menuAux)
  }, [producto]);
  /*
  { strIngredientes!='' ? (
              <View style={{paddingLeft:20}}>
                <Text style={{paddingRight:4,fontSize: 14,color:'black'}}>SIN: {strIngredientes}</Text>
              </View>
            ) : null}
            { strVariantes!='' ? (
            <View style={{paddingLeft:20}}>
              <Text style={{paddingRight:4,fontSize: 14}}>{strVariantes}</Text>
            </View>
            ) : null}
  */
    return(
      
        <View 
        
          >
          <View style={{marginBottom:1,backgroundColor:'white',paddingTop:5,paddingBottom:5}}>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
              {menu_lines.length>0 ? (
                <View style={{flexDirection:'row'}}>
                  <Text style={[{lineHeight:21,fontSize: 18,fontWeight:'bold',color:'black'}]}>{lineaPedido.quantity || 1}x </Text>
                  <Text style={[{fontSize: 15},props.datafono?{fontWeight:'bold',fontSize: 17,color:'black'}:{fontSize: 15}]}>{producto.name}</Text>
                </View>
              ) : (
                <View style={{flexDirection:'row'}}>
                  <Text style={[{lineHeight:21,fontSize: 18,fontWeight:'bold',color:'#3AA597'},props.datafono?{color:'black'}:{color:'#3AA597'}]}>{lineaPedido.quantity || 1}x </Text>
                  <Text style={[{fontSize: 15},props.datafono?{fontWeight:'bold',fontSize: 17,color:'black'}:{fontSize: 15}]}>{producto.name}</Text>
                </View>
              )}
                <Text style={props.datafono?{fontWeight:'bold',fontSize: 17}:{fontSize: 15}}>{(lineaPedido.amount/100)}€</Text>
            </View>
            {pintarVariantes(lineaPedido.orderLineGroups,lineaPedido.quantity)}
            {pintarIngredientes(lineaPedido.ingredientsRemoved)}
            {menu_lines.length>0 ? (  
                menu_lines.map(function(productoPlato:any,index:number) {
                  if(productoPlato!=null){
                    console.log("productoPlato",productoPlato)
                     console.log("productoPlato",productoPlato.oculto)
                    if(!productoPlato.oculto){
                      return(
                        <View style={{marginLeft:20}}>
                        <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                         

                          <Text style={[{fontSize: 16,color:'black'}]}>{productoPlato.name}</Text>
                           {productoPlato.increment?.increment>0 && (<Text style={{fontSize: 16,color:'black'}}>{productoPlato.increment.increment/100}€</Text>)}
                        </View>
                        <View style={{}}>
                          {pintarVariantes(productoPlato.orderLineMenuGroups,1)}
                          {pintarIngredientes(productoPlato.ingredientsRemoved)}
                        </View>
                      </View>
                      )
                    }
                  }                  
                  
                })

              ) : null}
          </View>
        </View>
    );
    
}
export default (ProductoResumenDatafono);