export function getVariantsText(variantes: any) {
  let srtVariantesAux = "";
  let primero = true;
  if (variantes != undefined) {
    variantes.map(function (grupoVariante: any, indice: number) {
      if (grupoVariante != null && grupoVariante != undefined) {
        let strOpciones = "";
        if (grupoVariante.opciones != undefined) {
          grupoVariante.opciones.map(function (opcion: any, index: number) {
            if (opcion.valor) {
              var strComa = ", ";
              if (primero) {
                strComa = "";
                primero = false;
              }
              let strCantidad = "";
              if (opcion.cantidad > 1) {
                strCantidad = opcion.cantidad + "x ";
              }
              strOpciones += strComa + strCantidad + opcion.name;
            }
          });
        }
        srtVariantesAux += strOpciones;
      }
    });
  }
  console.log("srtVariantesAux", srtVariantesAux);
  return srtVariantesAux;
}
