import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Components
import NotCompletedModal from "./components/NotCompletedModal";
import Description from "./components/Description";
import Ingredients from "./components/Ingredients";
import Variants from "./components/variants/Variants";
import Plates from "./components/plates/Plates";
import Divider from "../../components/Divider";
import Header from "./components/Header";
import Footer from "../../components/Footer";

// Context
import { FoodStallContext } from "../../context/FoodStallContext";

// Objects
import Producto from "../../../../objetos/Producto";

// Redux
import { addProducto } from "../../../../reducers";

const ProductDetail = ({ addProducto, route }) => {
  const height = useWindowDimensions().height;
  const [modal, setModal] = useState("");
  const { product, parent, setPlate } = route.params;

  const { t } = useTranslation();
  const navigation = useNavigation();
  const { shop } = useContext(FoodStallContext);

  const [selectedPlates, setSelectedPlates] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const { long_description } = product;

  const isMenuCompleted = () => {
    return product?.platos_elegir?.every(({ min, max, gid }) => {
      const sumPlates = selectedPlates
        .filter((plate) => plate.fk_plate === gid)
        .reduce((sum, plate) => sum + plate.cantidad, 0);

      return sumPlates >= min && sumPlates <= max;
    });
  };

  useEffect(() => {
    navigation.setOptions({
      header: () => <View />,
    });
  }, []);

  const addProduct = () => {
    if (parent) {
      let productoAux = new Producto(product);
      productoAux.amount = productoAux.amount * 100;
      productoAux.ingredientesSinSel = selectedIngredients;
      productoAux.variantesSel = selectedVariants;

      const plateIndex = selectedPlates.findIndex(
        (item) => item.gid === productoAux.gid
      );

      if (plateIndex === -1) {
        setPlate((prevState) => [
          ...prevState,
          { ...productoAux, cantidad: 1 },
        ]);
      } else {
        setPlate((prevSelectedPlates) =>
          prevSelectedPlates.map((item, index) =>
            index === plateIndex
              ? { ...item, cantidad: item.cantidad + 1 }
              : item
          )
        );
      }
      if (isMenuCompleted()) {
        navigation.goBack();
      } else {
        setModal("NotCompleted");
      }
    } else {
      if (isMenuCompleted()) {
        let productoAux = new Producto(product);
        productoAux.amount = productoAux.amount * 100;
        productoAux.ingredientesSinSel = selectedIngredients;
        productoAux.variantesSel = selectedVariants;
        productoAux.platosSel = selectedPlates.map(
          (plate) => new Producto(plate)
        );
        addProducto(shop, productoAux);
        navigation.goBack();
      } else {
        setModal("NotCompleted");
      }
    }
  };

  return (
    <ScrollView
      style={[{ backgroundColor: "white", height: height - 120 }]}
      contentContainerStyle={{ flex: 1 }}
    >
      <View style={styles.screen}>
        <View style={styles.content}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Header data={product} />
            <Divider height={28} />
            <Description data={long_description} />
            <Variants
              data={product}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
            />
            <Plates
              data={product}
              selectedPlates={selectedPlates}
              setSelectedPlates={setSelectedPlates}
            />
            <Ingredients
              data={product}
              selectedIngredients={selectedIngredients}
              setSelectedIngredients={setSelectedIngredients}
            />
            <Divider height={90} />
          </ScrollView>
        </View>
      </View>
      <Footer
        leftTitle={t("cancel")}
        rightTitle={t("add")}
        leftAction={() => navigation.goBack()}
        rightAction={addProduct}
      />
      <NotCompletedModal
        visible={modal === "NotCompleted"}
        setModal={setModal}
      />
    </ScrollView>
  );
};

const mapDispatchToProps = {
  addProducto,
};

export default connect(null, mapDispatchToProps)(ProductDetail);

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 850,
  },
});
