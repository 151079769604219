import React, { useState, useEffect, useRef } from "react";
import {
  ToastAndroid,
  Platform,
  Alert,
  FlatList,
  ScrollView,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Dimensions,
  View,
  StyleSheet,
  Image,
  ImageBackground,
  Text,
} from "react-native";
import { styles } from "../../assets/styles/styles";
import { StatusBar } from "expo-status-bar";
import {
  Octicons,
  MaterialIcons,
  FontAwesome,
  AntDesign,
} from "@expo/vector-icons";
import {
  setUltimoPedido,
  addProducto,
  eliminarProducto,
  vaciarCarrito,
  setTienda,
  setPedidoImprimir,
  setComentario,
  setEmail,
  setConexion,
  setFacturaProductos,
  setFacturaComision,
} from "../../application/reducers";
import ProductoResumen from "../components/ProductoResumen";
import { connect } from "react-redux";
import { CheckBox } from "react-native-elements";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { Picker } from "@react-native-picker/picker";
import Pedido from "../objetos/Pedido";
import CarritoDatafono from "../objetos/CarritoDatafono";
import NetInfo from "@react-native-community/netinfo";

//import RNFetchBlob from 'react-native-fetch-blob';

import myCrypto from "../funcionalidades/myCrypto";

const TopBar = createMaterialTopTabNavigator();

const VistaResumenPedido = (props: any) => {
  let intervaloConexion = "";
  let tienda;

  if (props.route != undefined) {
    tienda = props.route.params.tienda;
    props.navigation.setOptions({
      headerTitle: tienda.nombre,
    });
  } else {
    tienda = props.tienda;
  }
  props.navigation.setOptions({
    headerRight: () => {
      return (
        <View>
          <TouchableOpacity
            style={{ padding: 10 }}
            onPress={(data) => {
              props.vaciarCarrito();
              props.navigation.goBack();
            }}
          >
            <AntDesign name="delete" size={32} color="black" />
          </TouchableOpacity>
        </View>
      );
    },
  });
  let variableComentario = "";
  let variableEmailCliente = "";
  let arrayDescuentos = [
    0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95, 100,
  ];
  let initArray: Array<any> = [];
  const [descuentoSel, setDescuentoSel] = useState(0);
  const [descuentoAmount, setDescuentoAmount] = useState(0);
  const [precioTotalProductos, setPrecioTotalProductos] = useState(0);
  const [precioTotal, setPrecioTotal] = useState(0);
  const [precioComisionGopick, setPrecioComisionGopick] = useState(0);
  const [precioComisionGestion, setPrecioComisionGestion] = useState(0);
  const [precioComisionServicio, setPrecioComisionServicio] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [activityVisible, setActivityVisible] = useState(false);
  const [swipeEnabled, setSwipeEnabled] = useState(false);
  const [deliveryEnabled, setDeliveryEnabled] = useState(false);
  const [programacionEnabled, setProgramacionEnabled] = useState(false);
  const [bonos, setBonos] = useState(initArray);
  const [creditos, setCreditos] = useState(initArray);
  const [widthTab, setWidthTab] = useState(
    props.route != undefined ? props.width - 28 : props.width * 0.4 - 28
  );
  const [comentario, setComentario] = useState("");
  const [email, setEmail] = useState("");
  const [formattedAdress, setFormattedAdress] = useState("");
  //const [ref_comentario, setRef_comentario] = useState('');
  const ref_comentario = useRef(null);
  const ref_email = useRef(null);
  const [localizacionUsuario, setLocalizacionUsuario] = useState("");
  useEffect(() => {
    let precioTotalAux = 0;
    let precioTotalProductosAux = {};
    let precioComisionGopickAux = 0;
    let precioComisionGestionAux = 0;
    let precioComisionServicioAux = 0;
    let activarDelivery = false;
    let activarProgramacion = false;
    props.tiendas.map(function (tienda: any) {
      let productoConComision = false;
      let precioTienda = 0;
      tienda.productos.map(function (item: any) {
        precioTienda = precioTienda + item.cantidad * item.precioTotalProducto;
        if (item.not_pay_service == 0) {
          productoConComision = true;
          if (
            tienda.waiter_commission != null &&
            tienda.type_waiter_commission != null
          ) {
            if (tienda.type_waiter_commission == "percentage") {
              item.comisionGestionProducto =
                (tienda.waiter_commission / 100) * item.precioTotalProducto ||
                0;
              precioComisionGestionAux =
                precioComisionGestionAux +
                (tienda.waiter_commission / 100) *
                  item.cantidad *
                  item.precioTotalProducto;
            }
          }
          if (
            tienda.gopick_commission != null &&
            tienda.type_gopick_commission != null
          ) {
            if (tienda.type_gopick_commission == "percentage") {
              item.comisionGopickProducto =
                (tienda.gopick_commission / 100) * item.precioTotalProducto ||
                0;
              precioComisionGopickAux =
                precioComisionGopickAux +
                (tienda.gopick_commission / 100) *
                  item.cantidad *
                  item.precioTotalProducto;
            }
          }
        }
      });
      if (productoConComision) {
        if (
          tienda.gopick_commission != null &&
          tienda.type_gopick_commission != null
        ) {
          if (tienda.type_gopick_commission != "percentage") {
            precioComisionGopickAux =
              precioComisionGopickAux + tienda.gopick_commission;
          }
        }
        if (
          tienda.waiter_commission != null &&
          tienda.type_waiter_commission != null
        ) {
          if (tienda.type_waiter_commission != "percentage") {
            precioComisionGestionAux =
              precioComisionGestionAux + tienda.waiter_comission;
          }
        }
      }
      precioTotalAux = precioTotalAux + precioTienda;
      precioTotalProductosAux[tienda.gid] = precioTienda;
      if (tienda.servicioSel != null && tienda.servicioSel != undefined) {
        if (
          tienda.servicioSel.amount != null &&
          tienda.servicioSel.amount != 0 &&
          tienda.servicioSel.amount != undefined
        ) {
          if (tienda.servicioSel.type_amount == "percentage") {
            precioComisionServicioAux =
              precioComisionServicioAux +
              (tienda.servicioSel.amount / 10000) * precioTienda;
          } else {
            precioComisionServicioAux =
              precioComisionServicioAux + tienda.servicioSel.amount / 100;
          }
        }
      }

      if (tienda.servicioSel != null && tienda.servicioSel != undefined) {
        if (tienda.servicioSel.key == "delivery") {
          activarDelivery = true;
        }
      }
      if (tienda.pick_up_time == 1) {
        activarProgramacion = true;
      }
    });
    setProgramacionEnabled(activarProgramacion);
    setDeliveryEnabled(activarDelivery);
    console.log("pprops.tiendas", props.tiendas);
    setPrecioTotalProductos(precioTotalProductosAux);
    setPrecioComisionGopick(precioComisionGopickAux);
    setPrecioComisionGestion(precioComisionGestionAux);
    setPrecioComisionServicio(precioComisionServicioAux);

    let cantidadDescuento = 0;

    if (descuentoSel == 100) {
      precioTotalAux =
        precioTotalAux +
        precioComisionGopickAux +
        precioComisionGestionAux +
        precioComisionServicioAux;
      cantidadDescuento = (precioTotalAux * descuentoSel) / 100;
      setDescuentoAmount(cantidadDescuento);
      precioTotalAux = precioTotalAux - cantidadDescuento;
    } else {
      cantidadDescuento = (precioTotalAux * descuentoSel) / 100;
      setDescuentoAmount(cantidadDescuento);
      precioTotalAux =
        precioTotalAux +
        precioComisionGopickAux +
        precioComisionGestionAux +
        precioComisionServicioAux -
        cantidadDescuento;
    }

    setPrecioTotal(precioTotalAux);
    if (bonos.length + creditos.length != 0) {
      actualizarCreditosYBonos();
    }
  }, [props.tiendas, descuentoSel, localizacionUsuario]);

  useEffect(() => {
    let bonosAux: Array<any> = [];
    let creditosAux: Array<any> = [];
    console.log("usuario", global.usuario);
    if (global.usuario != null && global.usuario != undefined) {
      global.usuario.bonos.map(function (bono: any) {
        bono.active = false;
        bono.descuento = calcularDescuentoBono(bono);
        bonosAux.push(bono);
      });
      global.usuario.creditos.map(function (credito: any) {
        credito.active = false;
        credito.descuento = calcularDescuentoCredito(credito);
        creditosAux.push(credito);
      });
    }
    creditosAux.sort(function (a, b) {
      if (a.shops.length > b.shops.length) {
        return -1;
      } else {
        return 1;
      }
    });
    bonosAux.sort(function (a, b) {
      if (a.shops.length > b.shops.length) {
        return -1;
      } else {
        return 1;
      }
    });
    console.log("bonos", bonosAux);
    setBonos(bonosAux);
    setCreditos(creditosAux);
  }, [usuario]);
  useEffect(() => {
    if (props.cashLess.credits != undefined) {
      let creditosAux: Array<any> = [];
      let credito = props.cashLess.credits[0];
      credito.cashLess = true;
      credito.active = true;

      credito.amount_disponible = credito.amount_available / 100;
      credito.descuento = calcularDescuentoCredito(credito);
      creditosAux.push(credito);
      console.log("credito", credito);
      setCreditos(creditosAux);
      if (creditosAux.length != 0) {
        actualizarCreditosYBonos(creditosAux);
      }
    } else {
      setCreditos([]);
    }
  }, [props.cashLess]);
  useEffect(() => {
    setWidthTab(
      props.route != undefined ? props.width - 28 : props.width * 0.4 - 28
    );
  }, [props.width]);
  () => {
    setWidthTab(
      props.route != undefined ? props.width - 28 : props.width * 0.4 - 28
    );
  },
    [props.width];
  function addProducto(tienda: any, producto: any) {
    props.addProducto(tienda, producto);
  }
  function eliminarProductoCompleto(tienda: any, producto: any) {
    props.eliminarProducto(tienda, producto, true);
  }
  function eliminarProducto(tienda: any, producto: any) {
    props.eliminarProducto(tienda, producto, false);
  }

  function abrirEscanerCashLess() {
    props.navigation.navigate("vistaEscanerCashLess");
  }
  function abrirEscanerBonos() {
    //props.navigation.navigate('vistaEscanerBonos')

    iniciarItos().then(function () {
      pagarItos();
    });
    setTimeout(function () {
      pagarItos();
    }, 3000);
  }
  function abrirCalculadora(refComentario: any, refEmail: any) {
    console.log("calculadora", refComentario, refEmail);
    let aux = {
      precioTotal: precioTotal,
      prepararPedido: prepararPedido.bind(
        this,
        "cash",
        refComentario,
        refEmail
      ),
    };
    props.navigation.navigate("vistaCalculadora", aux);
  }
  function deshabilitarSwipe() {
    setSwipeEnabled(false);
  }
  function habilitarSwipe() {
    setSwipeEnabled(true);
  }
  function abrirVistaProgramacionTiendas() {
    if (props.tiendas.length > 0) {
      props.navigation.navigate("vistaProgramacionTiendas");
    }
  }
  function seleccionarDireccion(address: any, tienda: any) {
    console.log("tienda", tienda, "address", address);
    tienda.deliveryAddress = address;
    props.setTienda(tienda);
  }
  function abrirVistaDelivery(tienda: any) {
    props.navigation.navigate("vistaDelivery", {
      onGoBack: (address: any) => seleccionarDireccion(address, tienda),
      tienda: tienda,
    });
  }

  async function imprimirAdyen(carritoDatafono: any) {
    console.log("imprimirAdyen", carritoDatafono);
    let carritoAux = carritoDatafono.carrito;
    for (let i = 0; i < carritoAux.orders.length; i++) {
      let order = carritoAux.orders[i];
      let pedido = new Pedido(order);
      console.log("pedido imprimir", pedido);
      pedido.payment_method = "dataphone";
      pedido.comment = carritoAux.comment || "";
      props.setPedidoImprimir(pedido);
      console.log("setPedidoImprimir");
      myCrypto.imprimirAdyen();
    }
  }
  async function realizarPagoAdyen(carritoDatafonoAux: any, option: any) {
    let serviceID = new Date().getTime().toString();
    let terminalAPIPaymentRequest = {
      SaleToPOIRequest: {
        MessageHeader: {
          ProtocolVersion: "3.0",
          MessageClass: "Service",
          MessageCategory: "Payment",
          MessageType: "Request",
          SaleID: "POSSystemID12345",
          ServiceID: serviceID.substr(serviceID.length - 8),
          POIID: global.deviceName,
        },
        PaymentRequest: {
          SaleData: {
            SaleToAcquirerData: "tenderOption=ReceiptHandler",
            SaleTransactionID: {
              TransactionID: carritoDatafonoAux.transactionID,
              TimeStamp: new Date(),
            },
          },
          PaymentTransaction: {
            AmountsReq: {
              Currency: "EUR",
              RequestedAmount: parseFloat(precioTotal.toFixed(2)),
            },
          },
        },
      },
    };

    console.log(
      "terminalAPIPaymentRequest",
      JSON.stringify(terminalAPIPaymentRequest)
    );
    myCrypto.getBlob(JSON.stringify(terminalAPIPaymentRequest)).then(
      async function (res: any) {
        console.log("respuesta", res);
        let result = {
          SaleToPOIRequest: {
            MessageHeader: {
              ProtocolVersion: "3.0",
              MessageClass: "Service",
              MessageCategory: "Payment",
              MessageType: "Request",
              SaleID: "POSSystemID12345",
              ServiceID: serviceID.substr(serviceID.length - 8),
              POIID: global.deviceName,
            },
            NexoBlob: res.mensaje,
            SecurityTrailer: {
              KeyVersion: 1,
              KeyIdentifier: "comandero",
              Hmac: res.hmac,
              Nonce: res.iv,
              AdyenCryptoVersion: 1,
            },
          },
        };
        console.log("result", JSON.stringify(result));
        await RNFetchBlob.config({
          trusty: true,
        })
          .fetch(
            "POST",
            "https://localhost:8443/nexo",
            {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            JSON.stringify(result)
          )
          .then(async (res) => {
            let resultado = await myCrypto.desencriptar(await res.json());
            console.log("resultado", resultado);

            if (resultado.includes("Success")) {
              await carritoDatafonoAux.actualizarEstado("PAID");
              props.vaciarCarrito();
              setLocalizacionUsuario("");
              if (props.route != undefined) {
                props.navigation.goBack();
              }
              if (Platform.OS != "web") {
                ToastAndroid.show(
                  "Pedido guardado correctamente",
                  ToastAndroid.SHORT
                );
              } else {
                alert("Pedido guardado correctamente");
              }

              if (option == "on") {
                let carritoAux = carritoDatafonoAux.carrito;
                for (let i = 0; i < carritoAux.orders?.length; i++) {
                  let order = carritoAux.orders[i];
                  let pedido = new Pedido(order);

                  pedido.payment_method = carritoAux.payment_method || "cash";
                  pedido.comment = carritoAux.comment || "";
                  props.setUltimoPedido(pedido);
                }
              } else {
                imprimirAdyen(carritoDatafonoAux);
                global.usuario.comprobarCarritosDatafono();
              }
            } else {
              await carritoDatafonoAux.actualizarEstado("CANCELLED");
              ToastAndroid.show(
                "Ha habido un problema con pago",
                ToastAndroid.SHORT
              );
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      },
      function (err) {
        console.log("err", err);
      }
    );
  }

  function prepararPedido(payment_method: any, refComentario: any, refEmail) {
    return new Promise(function (resolve, reject) {
      let ordersAux: Array<any> = [];
      let locations: Array<any> = [];
      let locationsTiendas = "";
      let contadorLocalizaciones = 0;
      props.tiendas.map(function (item: any, i: number) {
        let orderLinesBono: Array<any> = [];
        let orderLinesCreditos: Array<any> = [];
        let order_lines: Array<any> = [];
        let order_lines_credit_consumed = [];
        let order_lines_bono_consumed = [];
        if (item.locationsSel != undefined) {
          item.locationsSel.map(function (loc: any, index: any) {
            if (contadorLocalizaciones == index) {
              contadorLocalizaciones++;
              locationsTiendas = locationsTiendas + " " + loc.select;
            }
          });
        }

        creditos.map(function (credito: any, index: any) {
          if (credito.active) {
            if (credito.descuentoPorTienda[item.gid] > 0) {
              order_lines_credit_consumed.push({
                name: credito.name,
                amount: Math.round(
                  credito.descuentoPorTienda[item.gid].toFixed(2) * -100
                ),
                id_order_line_credit: credito.gid_order_line_credit,
                fk_user: props.cashLess != "" ? props.cashLess.user.gid : null,
              });
            }
          }
        });
        bonos.map(function (bono: any, index: any) {
          if (bono.active) {
            if (bono.descuentoPorTienda[item.gid] > 0) {
              order_lines_bono_consumed.push({
                name: bono.name,
                quantity: bono.quantity_avalilable - bono.cantidadMaxima,
                amount: Math.round(
                  bono.descuentoPorTienda[item.gid].toFixed(2) * -100
                ),
                id_order_line_credit: bono.gid,
              });
            }
          }
        });
        let precioTotalProductosRestantes = 0;
        item.productos.map(function (producto: any, i: number) {
          console.log("producto", producto);
          precioTotalProductosRestantes =
            precioTotalProductosRestantes +
            (producto.precioTotalProducto || producto.unit_price / 100) *
              producto.cantidad;
          if (producto.product_type == "credit") {
            orderLinesCreditos.push({
              id_credit: producto.gid,
              quantity: producto.cantidad,
              amount: producto.amount * 100,
              name: producto.name,
              exclusive_order: producto.exclusive_order,
            });
          } else if (producto.product_type == "bono") {
            orderLinesBono.push({
              id_bono: producto.gid,
              quantity: producto.cantidad,
              amount: producto.amount * 100,
              name: producto.name,
              exclusive_order: producto.exclusive_order,
            });
          } else {
            let delete_ingredients = producto.getArrayIngredientesSin();
            let variants = producto.getArrayVariantes();
            let menu_selection = producto.getMenuSelection();
            let orderLine = {
              id_product: producto.gid,
              quantity: producto.cantidad,
              id_point_sale: producto.fk_point_sale,
              fk_point_sale: producto.fk_point_sale,
              fk_product: producto.gid,
              variants: variants,
              groups: variants,
              ingredients_removed: delete_ingredients,
              delete_ingredients: delete_ingredients,
              menu_selection: menu_selection,
              amount: Math.round(producto.amount.toFixed(2) * 100),
              name: producto.name,
              unit_price: producto.amount * 100,
              exclusive_order: producto.exclusive_order,
              comisionGestionProducto: producto.comisionGestionProducto,
              comisionGopickProducto: producto.comisionGopickProducto,
            };

            order_lines.push(orderLine);
          }
        });

        let serviceSel = "pick_up";
        if (!global.tpv) {
          console.log("item", item);
          serviceSel = global.usuario.setting.services[0];
          if (item.servicioSel != null && item.servicioSel != undefined) {
            serviceSel = item.servicioSel.key;
          }
        }

        let date = Math.floor(new Date().getTime() / 1000);
        if (
          tienda.horaProgramada != undefined &&
          tienda.horaProgramada != "" &&
          tienda.horaProgramada != "Lo antes posible"
        ) {
          let dateHoy = new Date();
          let anio = dateHoy.getFullYear();
          let mes = "0" + (dateHoy.getMonth() + 1);
          let dia = "0" + dateHoy.getDate();
          console.log(dia, mes, anio);
          let fechaProgramada =
            anio +
            "-" +
            mes.substr(-2) +
            "-" +
            dia.substr(-2) +
            " " +
            tienda.horaProgramada;
          date = Math.floor(new Date(fechaProgramada).getTime() / 1000);
          console.log("debe estar programado");
        }
        console.log("delivery_address", item.deliveryAddress);
        let locationTienda = "";
        let gidLocation = null;
        if (item.locationsSel != undefined && item.locationsSel.length > 0) {
          item.locationsSel.map(function (loc: any, index: any) {
            locationTienda = locationTienda + " " + loc.select;
          });
          gidLocation =
            item.locationsSel[item.locationsSel.length - 1].gid_location ||
            null;
        }
        if (item.deliveryAddress != undefined && item.deliveryAddress != "") {
          locationTienda = item.deliveryAddress;
        }
        if (localizacionUsuario != "") {
          locationTienda = localizacionUsuario;
        }

        let orderLineServicio = [];
        if (precioComisionServicio > 0 && descuentoSel != 100) {
          orderLineServicio = [
            {
              name: "Servicio",
              amount: Math.round(precioComisionServicio.toFixed(2) * 100),
            },
          ];
        }
        let orderLineComision = [];
        if (precioComisionGestion > 0 && descuentoSel != 100) {
          orderLineComision.push({
            name: "Gastos de servicio",
            amount: Math.round(precioComisionGestion.toFixed(2) * 100),
          });
        }
        if (precioComisionGopick > 0 && descuentoSel != 100) {
          orderLineComision.push({
            name: "Gastos de gestión",
            amount: Math.round(precioComisionGopick.toFixed(2) * 100),
          });
        }
        let descuentoServidor =
          descuentoAmount -
          precioComisionServicio -
          precioComisionGestion -
          precioComisionGopick;
        let orderLineDescuento = [];
        if (descuentoServidor > 0) {
          orderLineDescuento = [
            {
              id_discount: 1,
              name: "Descuento",
              amount: Math.round((-1 * descuentoServidor).toFixed(2) * 100),
            },
          ];
        }

        let order_lines_exclusive = [];
        let order_lines_restantes = [];
        let precioComisionGestionRestante = precioComisionGestion;
        let precioComisionGopickRestante = precioComisionGopick;
        let precioDescuentoRestante = descuentoAmount * 100;
        order_lines.map(function (item: any, index: any) {
          if (item.exclusive_order == 1) {
            let cantidad = item.quantity;
            item.quantity = 1;
            for (let i = 0; i < cantidad; i++) {
              order_lines_exclusive.push(item);
            }
          } else {
            order_lines_restantes.push(item);
          }
        });
        if (order_lines_exclusive.length > 0) {
          console.log("order_lines_exclusive", order_lines_exclusive);
          order_lines_exclusive.map(function (order_line: any, index: any) {
            let orderLineComisionProducto = [];
            if (order_line.comisionGestionProducto > 0 && descuentoSel != 100) {
              precioComisionGestionRestante =
                precioComisionGestionRestante -
                order_line.comisionGestionProducto;
              orderLineComisionProducto.push({
                name: "Gastos de servicio",
                amount: Math.round(
                  order_line.comisionGestionProducto.toFixed(2) * 100
                ),
              });
            }
            if (order_line.comisionGopickProducto > 0 && descuentoSel != 100) {
              precioComisionGopickRestante =
                precioComisionGopickRestante -
                order_line.comisionGopickProducto;
              orderLineComisionProducto.push({
                name: "Gastos de gestión",
                amount: Math.round(
                  order_line.comisionGopickProducto.toFixed(2) * 100
                ),
              });
            }

            let descuentoProducto =
              ((order_line.precioTotalProducto || order_line.unit_price / 100) *
                descuentoSel) /
              100;

            console.log("descuentoProducto1", descuentoProducto);

            /*if(descuentoSel!=100&&descuentoSel!=0){
            descuentoProducto= descuentoProducto-((order_line.comisionGestionProducto|| 0)*100)-((order_line.comisionGopickProducto || 0)*100)
          }*/
            console.log("descuentoProducto", descuentoProducto);
            console.log("precioDescuentoRestante", precioDescuentoRestante);
            precioDescuentoRestante =
              precioDescuentoRestante - descuentoProducto * 100;

            let orderLineDescuentoProducto = [];
            if (descuentoProducto > 0) {
              orderLineDescuentoProducto = [
                {
                  id_discount: 1,
                  name: "Descuento",
                  amount: Math.round((-1 * descuentoProducto).toFixed(2)),
                },
              ];
            }

            console.log(
              "precioTotalProductosRestantes1",
              precioTotalProductosRestantes
            );

            let precioTotalDelProducto =
              (order_line.precioTotalProducto || order_line.unit_price) / 100 +
              (order_line.comisionGestionProducto || 0) +
              (order_line.comisionGopickProducto || 0) -
              descuentoProducto;
            console.log("precioTotalDelProducto", precioTotalDelProducto);
            precioTotalProductosRestantes =
              precioTotalProductosRestantes -
              (order_line.precioTotalProducto || order_line.unit_price) / 100;

            let order_lines_credit_consumedProducto = [];
            if (props.cashLess != "") {
              order_lines_credit_consumedProducto.push({
                name: props.cashLess.credits[0].name,
                amount: Math.round(precioTotalDelProducto.toFixed(2) * -100),
                id_order_line_credit:
                  props.cashLess.credits[0].gid_order_line_credit,
                fk_user: props.cashLess != "" ? props.cashLess.user.gid : null,
              });
            }

            let timeNow = new Date().getTime().toString();
            let gid = timeNow.substr(-9);
            let localOrderId = gid;
            let orderAux = {
              localOrderId: localOrderId,
              gid: gid,
              id_shop: item.gid,
              id_event: item.fk_event,
              shop_name: item.name,
              service: serviceSel || "pick_up",
              date: date,
              order_lines: [order_line],
              order_date: new Date(),
              amountLocal: precioTotalDelProducto,
              order_lines_commission: orderLineComisionProducto,
              order_lines_discount: orderLineDescuentoProducto,

              order_lines_credit_consumed: order_lines_credit_consumedProducto,

              location: locationTienda,
              gid_location: gidLocation,
            };
            if (
              index == order_lines_exclusive.length - 1 &&
              order_lines_exclusive.length == 0 &&
              orderLinesBono.length == 0 &&
              orderLinesCreditos.length == 0
            ) {
              orderAux.order_lines_service = orderLineServicio;
            }
            ordersAux.push(orderAux);
          });
        }
        if (
          order_lines_restantes.length > 0 ||
          orderLinesBono.length > 0 ||
          orderLinesCreditos.length > 0
        ) {
          let orderLineComisionRestante = [];
          if (precioComisionGestionRestante > 0 && descuentoSel != 100) {
            orderLineComisionRestante.push({
              name: "Gastos de servicio",
              amount: Math.round(
                precioComisionGestionRestante.toFixed(2) * 100
              ),
            });
          }
          if (precioComisionGopickRestante > 0 && descuentoSel != 100) {
            orderLineComisionRestante.push({
              name: "Gastos de gestión",
              amount: Math.round(precioComisionGopickRestante.toFixed(2) * 100),
            });
          }
          let orderLineDescuentoRestante = [];
          if (precioDescuentoRestante > 0) {
            orderLineDescuentoRestante = [
              {
                id_discount: 1,
                name: "Descuento",
                amount: Math.round((-1 * precioDescuentoRestante).toFixed(2)),
              },
            ];
          }

          let order_lines_credit_consumedRestante = [];
          console.log(
            "precioComisionGestionRestante",
            precioComisionGestionRestante
          );
          console.log(
            "precioComisionGopickRestante",
            precioComisionGopickRestante
          );
          console.log("precioComisionServicio", precioComisionServicio);
          let precio_credito =
            precioTotalProductosRestantes +
            precioComisionGestionRestante +
            precioComisionGopickRestante +
            precioComisionServicio -
            precioDescuentoRestante / 100;
          if (props.cashLess != "" && props.cashLess.credits[0].active) {
            order_lines_credit_consumedRestante.push({
              name: props.cashLess.credits[0].name,
              amount: Math.round(precio_credito.toFixed(2) * -100),
              id_order_line_credit:
                props.cashLess.credits[0].gid_order_line_credit,
              fk_user: props.cashLess != "" ? props.cashLess.user.gid : null,
            });
          }
          console.log("precio_credito", precio_credito);
          let timeNow = new Date().getTime().toString();
          let gid = timeNow.substr(-9);
          let localOrderId = gid;
          let orderAux = {
            localOrderId: localOrderId,
            gid: gid,
            id_shop: item.gid,
            id_event: item.fk_event,
            shop_name: item.name,
            service: serviceSel || "pick_up",
            date: date,
            order_date: new Date(),
            amountLocal: precio_credito,
            order_lines: order_lines_restantes,
            order_lines_bono: orderLinesBono,
            order_lines_bono_consumed: order_lines_bono_consumed,
            order_lines_credit: orderLinesCreditos,
            order_lines_credit_consumed: order_lines_credit_consumedRestante,
            order_lines_service: orderLineServicio,
            order_lines_commission: orderLineComisionRestante,
            order_lines_discount: orderLineDescuentoRestante,
            location: locationTienda,
            gid_location: gidLocation,
          };
          ordersAux.push(orderAux);
        }
      });

      let comentario = "";
      if (
        refComentario != undefined &&
        refComentario != null &&
        refComentario.current != undefined &&
        refComentario.current != null
      ) {
        comentario = refComentario.current.value;
      }
      let email = "";
      if (
        refEmail != undefined &&
        refEmail != null &&
        refEmail.current != undefined &&
        refEmail.current != null
      ) {
        email = refEmail.current.value;
      }
      if (!global.isDatafono && payment_method != "cash") {
        payment_method = "others";
      }
      let carritoAux = {
        id_user: global.usuario.gid,
        payment_method:
          props.cashLess != "" && props.cashLess.credits[0].active
            ? "cashless"
            : payment_method || "cash", //controlar esto cash o dataphonedataphone, cashless,
        //"id_payment_card":1, // Si paga con una tarjeta guardada,
        //"jetToken":1, // si paga con una tarjeta nueva,
        comment: variableComentario,
        customer_email: variableEmailCliente,
        location: locationsTiendas,
        id_currency: 1, //moneda con la que se paga 1=€
        delivery_address: "",
        orders: ordersAux,
      };
      console.log("carritoAux", carritoAux);
      console.log("carritoAux", JSON.stringify(carritoAux));

      if (
        props.cashLess != "" &&
        props.cashLess.credits[0].active &&
        Math.round(precioTotal.toFixed(2)) > 0
      ) {
        alert("No hay suficiente credito para hacer el pedido");
      } else {
        if (global.isDatafono && payment_method == "dataphone") {
          crearPedidoDatafono(carritoAux);
          resolve(true);
        } else {
          if (Platform.OS === "web" || payment_method == "cash") {
            crearPedidoDatafono(carritoAux);

            resolve(true);
          } else {
            Alert.alert(
              "",
              "¿Desea realizar el pedido?",
              [
                {
                  text: "Aceptar",
                  onPress: () => {
                    crearPedidoDatafono(carritoAux);
                    resolve(true);
                  },
                },
                {
                  text: "Cancelar",
                  onPress: () => {
                    console.log("Cancel Pressed");
                  },
                  style: "cancel",
                },
              ],
              { cancelable: true }
            );
          }
        }
      }
    });
  }
  function finalizarPedido(carritoDatafonoAux: any, option: any) {
    props.vaciarCarrito();
    setLocalizacionUsuario("");
    if (props.route != undefined) {
      props.navigation.goBack();
    }
    if (Platform.OS != "web") {
      ToastAndroid.show("Pedido guardado correctamente", ToastAndroid.SHORT);
    } else {
      alert("Pedido guardado correctamente");
    }

    let carritoAux = carritoDatafonoAux.carrito;
    for (let i = 0; i < carritoAux.orders?.length; i++) {
      let order = carritoAux.orders[i];
      let pedido = new Pedido(order);
      pedido.payment_method = carritoAux.payment_method || "cash";
      pedido.comment = carritoAux.comment || "";
      props.setUltimoPedido(pedido);
    }
    if (option != "on") {
      global.usuario.comprobarCarritosDatafono();
    }
  }
  async function crearPedidoDatafono(carritoAux: any) {
    console.log("crearPedidoDatafono");
    if (carritoAux.orders.length > 0) {
      let transactionID = new Date().getTime().toString();
      let carritoDatafonoAux = new CarritoDatafono({
        transactionID: transactionID,
        carrito: carritoAux,
      });

      await carritoDatafonoAux.guardarPedidoBBDDDatafono();
      if (props.conexion) {
        NetInfo.fetch().then(async (state) => {
          if (state.isConnected) {
            console.log("global.usuario", global.usuario);
            global.usuario
              .crearPedidoDatafono(carritoDatafonoAux)
              .then(async function () {
                if (carritoAux.payment_method != "cash" && global.isDatafono) {
                  await realizarPagoAdyen(carritoDatafonoAux, "on");
                } else {
                  finalizarPedido(carritoDatafonoAux, "on");
                }
              });
          } else {
            props.setConexion(false);
            if (carritoAux.payment_method != "cash" && global.isDatafono) {
              await realizarPagoAdyen(carritoDatafonoAux, "off");
            } else {
              finalizarPedido(carritoDatafonoAux, "off");
            }
          }
        });
      } else {
        if (carritoAux.payment_method != "cash" && global.isDatafono) {
          await realizarPagoAdyen(carritoDatafonoAux, "off");
        } else {
          finalizarPedido(carritoDatafonoAux, "off");
        }
      }
    }
  }

  function abrirServicios(tienda: any) {
    let aux = {
      tienda: tienda,
    };
    props.navigation.navigate("seleccionServicios", aux);
  }

  function guardarPedidoBBDD(carritoAux: any) {
    return new Promise(async function (resolve, reject) {
      await global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "CREATE TABLE IF NOT EXISTS carritos(" + "carritos TEXT" + ");"
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );

      await global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "INSERT OR REPLACE INTO carritos(" +
              "carritos" +
              ") VALUES ('" +
              JSON.stringify(carritoAux) +
              "');"
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );

      await global.db.transaction(
        (tx: any) => {
          tx.executeSql(
            "SELECT MAX(rowid) FROM carritos;",
            [],
            function (trans: any, result: any) {
              if (result != undefined) {
                if (result.rows.length > 0) {
                  resolve(result.rows.item(0)["MAX(rowid)"]);
                }
              }
            }
          );
        },
        function (err: any) {
          console.log("err", err);
        }
      );
    });
  }
  function cambiarLocalizacionUsuario(localizacion: any) {
    setLocalizacionUsuario(localizacion);
  }
  function abrirSeleccionarLocalizacion() {
    let servicioSite = -1;
    if (tienda.services != undefined && tienda.services != null) {
      tienda.services.map(function (servicio: any, index: any) {
        if (servicio.key == "my_site") {
          if (servicio.structure.length > 0) {
            servicioSite = servicio;
          }
        }
      });
    }
    if (servicioSite != -1) {
      let aux = {
        servicioEnMiSitio: servicioSite,
        onGoBack: (localizacionUsuario: any) =>
          cambiarLocalizacionUsuario(localizacionUsuario),
      };
      props.navigation.navigate("seleccionarLocalizacion", aux);
    } else {
      alert('No existen localizaciones en el servico "En mi sitio"');
    }
  }
  /* */
  function createTabResumen() {
    console.log("programacionEnabled", programacionEnabled);

    return (
      <ScrollView
        style={{ height: props.height - 252 }}
        contentContainerStyle={{ paddingBottom: 100, width: "100%" }}
        showsVerticalScrollIndicator={false}
      >
        <View
          style={{
            width: "100%",
            justifyContent: "flex-start",
            flex: 1,
            height: "100%",
          }}
        >
          {bonos.length + creditos.length == 0 ? null : (
            <View style={{ width: "100%" }}>
              {bonos.map(function (item: any, indiceBono: number) {
                let nombreTiendas = "";
                if (
                  item.descuentoPorTienda != undefined &&
                  item.descuentoPorTienda != null
                ) {
                  if (Object.keys(item.descuentoPorTienda).length > 1) {
                    Object.keys(item.descuentoPorTienda).map(function (
                      key: any,
                      i: any
                    ) {
                      props.tiendas.map(function (tienda: any, i: any) {
                        if (key == tienda.gid) {
                          if (nombreTiendas == "") {
                            nombreTiendas = tienda.name;
                          } else {
                            nombreTiendas = nombreTiendas + ", " + tienda.name;
                          }
                        }
                      });
                    });
                  }
                }
                return (
                  <View style={{ width: "100%" }}>
                    <CheckBox
                      title={item.name + " (" + nombreTiendas + ")"}
                      checked={item.active}
                      textStyle={{
                        fontWeight: "normal",
                        color: "black",
                        fontSize: 16,
                      }}
                      containerStyle={{
                        backgroundColor: "transparent",
                        borderWidth: 0,
                      }}
                      checkedIcon={
                        <FontAwesome
                          size={20}
                          name="check-square"
                          color="black"
                        />
                      }
                      uncheckedIcon={
                        <FontAwesome size={20} name="square-o" color="black" />
                      }
                      onPress={(data) => {
                        changeCheckBono(indiceBono);
                      }}
                    />
                  </View>
                );
              })}
              {creditos.map(function (item: any, indiceCredito: number) {
                let nombreTiendas = "";
                if (
                  item.descuentoPorTienda != undefined &&
                  item.descuentoPorTienda != null
                ) {
                  if (Object.keys(item.descuentoPorTienda).length > 1) {
                    Object.keys(item.descuentoPorTienda).map(function (
                      key: any,
                      i: any
                    ) {
                      props.tiendas.map(function (tienda: any, i: any) {
                        if (key == tienda.gid) {
                          if (nombreTiendas == "") {
                            nombreTiendas = tienda.name;
                          } else {
                            nombreTiendas = nombreTiendas + ", " + tienda.name;
                          }
                        }
                      });
                    });
                  }
                }
                return (
                  <View style={{ width: "100%" }}>
                    <CheckBox
                      title={item.name}
                      checked={item.active}
                      textStyle={{
                        fontWeight: "normal",
                        color: "black",
                        fontSize: 16,
                      }}
                      containerStyle={{
                        backgroundColor: "transparent",
                        borderWidth: 0,
                      }}
                      checkedIcon={
                        <FontAwesome
                          size={20}
                          name="check-square"
                          color="black"
                        />
                      }
                      uncheckedIcon={
                        <FontAwesome size={20} name="square-o" color="black" />
                      }
                      onPress={(data) => {
                        if (props.cashLess == "") {
                          changeCheckCredito(indiceCredito);
                        }
                      }}
                    />
                  </View>
                );
              })}
            </View>
          )}
          {props.tiendas.length > 0 ? (
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  fontSize: 16,
                  padding: 10,
                  paddingBottom: 0,
                  fontWeight: "bold",
                }}
              >
                Detalles del Pedido
              </Text>

              {props.tiendas.map(function (tienda: any) {
                if (tienda.productos.length > 0) {
                  return (
                    <View style={{ marginLeft: 10, marginRight: 10 }}>
                      <Text style={{ fontSize: 16, padding: 10 }}>
                        {tienda.name}
                      </Text>
                      {tienda.productos.map(function (item: any) {
                        return (
                          <ProductoResumen
                            habilitarSwipe={habilitarSwipe}
                            deshabilitarSwipe={deshabilitarSwipe}
                            eliminarProducto={eliminarProducto.bind(
                              this,
                              tienda,
                              item
                            )}
                            eliminarProductoCompleto={eliminarProductoCompleto.bind(
                              this,
                              tienda,
                              item
                            )}
                            producto={item}
                            addProducto={addProducto.bind(this, tienda, item)}
                          ></ProductoResumen>
                        );
                      })}

                      {precioComisionGestion > 0 ? (
                        <View
                          style={{
                            marginBottom: 1,
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text style={{ fontSize: 16 }}>
                                Gastos de gestión
                              </Text>
                            </View>
                            <Text style={{ fontSize: 16 }}>
                              {precioComisionGestion.toFixed(2)}€
                            </Text>
                          </View>
                        </View>
                      ) : null}
                      {precioComisionGopick > 0 ? (
                        <View
                          style={{
                            marginBottom: 1,
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text style={{ fontSize: 16 }}>
                                Gastos de gestión
                              </Text>
                            </View>
                            <Text style={{ fontSize: 16 }}>
                              {precioComisionGopick.toFixed(2)}€
                            </Text>
                          </View>
                        </View>
                      ) : null}
                      {precioComisionServicio > 0 ? (
                        <View
                          style={{
                            marginBottom: 1,
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text style={{ fontSize: 16 }}>Servicio</Text>
                            </View>
                            <Text style={{ fontSize: 16 }}>
                              {precioComisionServicio.toFixed(2)}€
                            </Text>
                          </View>
                        </View>
                      ) : null}
                      {descuentoAmount > 0 ? (
                        <View
                          style={{
                            marginBottom: 1,
                            backgroundColor: "white",
                            padding: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1, flexDirection: "row" }}>
                              <Text style={{ fontSize: 16 }}>Descuento</Text>
                            </View>
                            <Text style={{ fontSize: 16 }}>
                              {(-1 * descuentoAmount).toFixed(2)}€
                            </Text>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  );
                }
              })}
            </View>
          ) : (
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16, paddingTop: 20, paddingBottom: 20 }}>
                Selecciona un producto para añadir al pedido
              </Text>
            </View>
          )}
        </View>
      </ScrollView>
    );
  }
  function recalcularPrecio(creditosParam: any, bonosParam: any) {
    let precioARepartir = 0;

    let precioComisionGopickAux = 0;
    let precioComisionGestionAux = 0;
    let precioComisionServicioAux = 0;
    let precioParaDescuento = 0;
    let totalDescuento = 0;
    let tiendasPrecio = {};
    let productosPrecio = {};
    props.tiendas.map(function (tienda: any) {
      let precioTienda = 0;
      let productosPrecio = {};
      let productoConComision = false;
      tienda.productos.map(function (item: any) {
        console.log("producto1", item);
        precioTienda =
          precioTienda + item.cantidad * item.precioTotalProducto ||
          item.unit_price / 100;

        let comision = 0;
        if (item.not_pay_service == 0) {
          productoConComision = true;
          if (
            tienda.waiter_commission != null &&
            tienda.type_waiter_commission != null
          ) {
            if (tienda.type_waiter_commission == "percentage") {
              precioComisionGestionAux =
                precioComisionGestionAux +
                  (tienda.waiter_commission / 100) *
                    item.cantidad *
                    item.precioTotalProducto || item.unit_price;
              comision =
                comision +
                  (tienda.waiter_commission / 100) * item.precioTotalProducto ||
                item.unit_price;
            }
          }
          if (
            tienda.gopick_commission != null &&
            tienda.type_gopick_commission != null
          ) {
            if (tienda.type_gopick_commission == "percentage") {
              precioComisionGopickAux =
                precioComisionGopickAux +
                  (tienda.gopick_commission / 100) *
                    item.cantidad *
                    item.precioTotalProducto || item.unit_price;
              comision =
                comision +
                  (tienda.gopick_commission / 100) * item.precioTotalProducto ||
                item.unit_price;
            }
          }
        }
        productosPrecio[item.gid] = {
          cantidad: item.cantidad,
          precioProducto: item.amount,
          comision: comision,
        };
      });

      if (productoConComision) {
        if (
          tienda.gopick_commission != null &&
          tienda.type_gopick_commission != null
        ) {
          if (tienda.type_gopick_commission != "percentage") {
            precioComisionGopickAux =
              precioComisionGopickAux + tienda.gopick_commission;
          }
        }
        if (
          tienda.waiter_commission != null &&
          tienda.type_waiter_commission != null
        ) {
          if (tienda.type_waiter_commission != "percentage") {
            precioComisionGestionAux =
              precioComisionGestionAux + tienda.waiter_comission;
          }
        }
      }
      if (tienda.servicioSel != null && tienda.servicioSel != undefined) {
        if (
          tienda.servicioSel.amount != null &&
          tienda.servicioSel.amount != 0 &&
          tienda.servicioSel.amount != undefined
        ) {
          if (tienda.servicioSel.type_amount == "percentage") {
            precioComisionServicioAux =
              precioComisionServicioAux +
              (tienda.servicioSel.amount / 10000) * precioTienda;
          } else {
            precioComisionServicioAux =
              precioComisionServicioAux + tienda.servicioSel.amount / 100;
          }
        }
      }
      let cantidadDescuento = 0;
      if (descuentoSel == 100) {
        precioTienda =
          precioTienda +
          precioComisionServicioAux +
          precioComisionGestionAux +
          precioComisionGopickAux;
        cantidadDescuento = (precioTienda * descuentoSel) / 100;
        totalDescuento = totalDescuento + cantidadDescuento;
      } else {
        cantidadDescuento = (precioTienda * descuentoSel) / 100;
        totalDescuento = totalDescuento + cantidadDescuento;
        precioTienda =
          precioTienda +
          precioComisionServicioAux +
          precioComisionGestionAux +
          precioComisionGopickAux -
          cantidadDescuento;
      }

      precioARepartir = precioARepartir + precioTienda;
      tiendasPrecio[tienda.gid] = {
        precioARepartir: precioARepartir,
        precio: precioTienda,
        productosPrecio: productosPrecio,
      };
    });

    let bonosAux = bonos.slice();
    if (bonosParam != undefined) {
      bonosAux = bonosParam.slice();
    }
    bonosAux.map(function (item: any, indiceCredito: number) {
      item.descuento = 0;
      item.descuentoPorTienda = {};
      item.cantidadMaxima = item.quantity_avalilable;
      if (item.active) {
        item.shops.map(function (idTiendaBono: any) {
          let indiceTienda = Object.keys(tiendasPrecio).indexOf(idTiendaBono);
          if (indiceTienda != -1) {
            let descuentoTienda = 0;

            item.entity_refs.map(function (idProductoBono: any) {
              let indiceProducto = Object.keys(
                tiendasPrecio[idTiendaBono].productosPrecio
              ).indexOf(idProductoBono);
              if (indiceProducto != -1) {
                if (
                  item.cantidadMaxima <=
                  tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                    .cantidad
                ) {
                  descuentoTienda =
                    item.cantidadMaxima *
                    (tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                      .precioProducto +
                      tiendasPrecio[idTiendaBono].productosPrecio[
                        idProductoBono
                      ].comision);
                  tiendasPrecio[idTiendaBono].precioARepartir =
                    tiendasPrecio[idTiendaBono].precioARepartir -
                    descuentoTienda;
                  item.cantidadMaxima = 0;
                } else {
                  descuentoTienda =
                    tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                      .cantidad *
                    (tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                      .precioProducto +
                      tiendasPrecio[idTiendaBono].productosPrecio[
                        idProductoBono
                      ].comision);
                  tiendasPrecio[idTiendaBono].precioARepartir =
                    tiendasPrecio[idTiendaBono].precioARepartir -
                    descuentoTienda;
                  item.cantidadMaxima =
                    item.cantidadMaxima -
                    tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                      .cantidad;
                }
              }
            });
            item.descuento = item.descuento + descuentoTienda;
            item.descuentoPorTienda[idTiendaBono] = descuentoTienda;
          }
        });
        if (item.descuento == 0) {
          item.active = false;
        }
      }
    });
    let creditosAux = creditos.slice();
    if (creditosParam != undefined) {
      creditosAux = creditosParam.slice();
    }
    creditosAux.map(function (item: any, indiceCredito: number) {
      item.descuento = 0;
      item.descuentoPorTienda = {};
      item.cantidadMaxima = item.amount_disponible;
      if (item.active) {
        item.shops.map(function (idTiendaCredito: any) {
          let indiceTienda = Object.keys(tiendasPrecio).indexOf(
            idTiendaCredito.toString()
          );
          if (indiceTienda != -1) {
            let descuentoTienda = 0;
            if (
              item.cantidadMaxima >=
              tiendasPrecio[idTiendaCredito].precioARepartir
            ) {
              descuentoTienda = tiendasPrecio[idTiendaCredito].precioARepartir;
              tiendasPrecio[idTiendaCredito].precioARepartir =
                tiendasPrecio[idTiendaCredito].precioARepartir -
                descuentoTienda;
              item.cantidadMaxima = item.cantidadMaxima - descuentoTienda;
            } else {
              descuentoTienda = item.cantidadMaxima;
              tiendasPrecio[idTiendaCredito].precioARepartir =
                tiendasPrecio[idTiendaCredito].precioARepartir -
                descuentoTienda;
              item.cantidadMaxima = 0;
            }
            item.descuento = item.descuento + descuentoTienda;
            item.descuentoPorTienda[idTiendaCredito] = descuentoTienda;
          }
        });
      }

      if (item.descuento == 0) {
        item.active = false;
      }
    });
    let precioTotalTiendas = 0;

    Object.keys(tiendasPrecio).map(function (key: any, i: any) {
      let item = tiendasPrecio[key];
      precioTotalTiendas = precioTotalTiendas + item.precioARepartir;
    });
    console.log("creditosAux", creditosAux);
    setBonos(bonosAux);
    setCreditos(creditosAux);

    setDescuentoAmount(totalDescuento);

    setPrecioTotal(precioTotalTiendas);
  }
  function changeCheckBono(indiceBono: any, bonosParam: any) {
    let bonosAux = bonos.slice();
    if (bonosParam != undefined) {
      if (bonosParam.length > 0) {
        bonosAux = bonosParam.slice();
      }
    }
    bonosAux[indiceBono].active = !bonosAux[indiceBono].active;
    setBonos(bonosAux);
    recalcularPrecio(creditos, bonosAux);
  }
  function changeCheckCredito(indiceCredito: any, creditosParam: any) {
    let creditosAux = creditos.slice();
    if (creditosParam != undefined) {
      if (creditosParam.length > 0) {
        creditosAux = creditosParam.slice();
      }
    }
    creditosAux[indiceCredito].active = !creditosAux[indiceCredito].active;
    setCreditos(creditosAux);
    recalcularPrecio(creditosAux, bonos);
  }
  function calcularDescuentoBono(bono: any) {
    let tiendasPrecio = {};
    let disponible = false;

    let descuento = 0;
    props.tiendas.map(function (tienda: any) {
      let precioTienda = 0;
      let productosPrecio = {};
      tienda.productos.map(function (item: any) {
        precioTienda = precioTienda + item.cantidad * item.precio;
        productosPrecio[item.gid] = {
          cantidad: item.cantidad,
          precioProducto: item.precio,
        };
      });
      if (
        tienda.waiter_commission != null &&
        tienda.type_waiter_commission != null
      ) {
        if (tienda.type_waiter_commission == "percentage") {
          precioTienda =
            precioTienda + (tienda.waiter_commission / 100) * precioTienda;
        } else {
          precioTienda = precioTienda + tienda.waiter_comission;
        }
      }
      if (
        tienda.gopick_commission != null &&
        tienda.type_gopick_commission != null
      ) {
        if (tienda.type_gopick_commission == "percentage") {
          precioTienda =
            precioTienda + (tienda.gopick_commission / 100) * precioTienda;
        } else {
          precioTienda = precioTienda + tienda.gopick_commission;
        }
      }
      tiendasPrecio[tienda.gid] = {
        precio: precioTienda,
        productosPrecio: productosPrecio,
      };
    });

    bono.shops.map(function (idTiendaBono: any) {
      let indiceTienda = Object.keys(tiendasPrecio).indexOf(idTiendaBono);
      if (indiceTienda != -1) {
        bono.entity_refs.map(function (idProductoBono: any) {
          let indiceProducto = Object.keys(
            tiendasPrecio[idTiendaBono].productosPrecio
          ).indexOf(idProductoBono);
          if (indiceProducto != -1) {
            if (
              bono.quantity_avalilable <=
              tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                .cantidad
            ) {
              descuento =
                bono.quantity_avalilable *
                tiendasPrecio[idTiendaBono].productosPrecio[idProductoBono]
                  .precioProducto;
              disponible = true;
            }
          }
        });
      }
    });

    return descuento;
  }
  function calcularDescuentoCredito(credito: any) {
    let tiendasPrecio = {};
    let disponible = false;
    let descuentoDisponibleTienda = 0;
    let descuento = 0;
    props.tiendas.map(function (tienda: any) {
      let precioTienda = 0;
      let productosPrecio = {};
      tienda.productos.map(function (item: any) {
        precioTienda = precioTienda + item.cantidad * item.amount;
      });
      if (
        tienda.waiter_commission != null &&
        tienda.type_waiter_commission != null
      ) {
        if (tienda.type_waiter_commission == "percentage") {
          precioTienda =
            precioTienda + (tienda.waiter_commission / 100) * precioTienda;
        } else {
          precioTienda = precioTienda + tienda.waiter_comission;
        }
      }
      if (
        tienda.gopick_commission != null &&
        tienda.type_gopick_commission != null
      ) {
        if (tienda.type_gopick_commission == "percentage") {
          precioTienda =
            precioTienda + (tienda.gopick_commission / 100) * precioTienda;
        } else {
          precioTienda = precioTienda + tienda.gopick_commission;
        }
      }
      let descuentoAplicadoEnTienda = 0;
      creditos.map(function (item: any, indiceCredito: number) {
        if (item.gid != credito.gid) {
          if (item.idTiendaDelDescuento == tienda.gid) {
            descuentoAplicadoEnTienda =
              descuentoAplicadoEnTienda + item.descuento;
          }
        }
      });
      tiendasPrecio[tienda.gid] = {
        precio: precioTienda - descuentoAplicadoEnTienda,
      };
    });

    credito.shops.map(function (idTiendaBono: any) {
      let indiceTienda = Object.keys(tiendasPrecio).indexOf(
        idTiendaBono.toString()
      );

      if (indiceTienda != -1) {
        credito.idTiendaDelDescuento = idTiendaBono;
        descuentoDisponibleTienda =
          descuentoDisponibleTienda + tiendasPrecio[idTiendaBono].precio;
      }
    });
    if (descuentoDisponibleTienda >= credito.amount_disponible) {
      descuento = credito.amount_disponible;
    } else {
      descuento = descuentoDisponibleTienda;
    }
    return descuento;
  }
  function actualizarCreditosYBonos(creditosParam: any) {
    bonos.map(function (item: any, indiceBono: number) {
      item.active = false;
      changeCheckBono(indiceBono);
    });
    let creditosAux = creditos;
    if (creditosParam != undefined) {
      creditosAux = creditosParam;
    }
    console.log("creditos.length", creditosAux.length);
    creditosAux.map(function (item: any, indiceCredito: number) {
      item.active = false;
      changeCheckCredito(indiceCredito, creditosAux);
    });
  }

  function createTabOtros() {
    return (
      <View
        style={{
          marginTop: 10,
          justifyContent: "flex-start",
          flex: 1,
          alignItems: "center",
        }}
      >
        <View style={{ width: "100%" }}>
          <View style={{ marginLeft: 10, marginRight: 10 }}>
            {programacionEnabled ? (
              <TouchableOpacity
                onPress={abrirVistaProgramacionTiendas}
                style={{
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 16, padding: 10, fontWeight: "bold" }}>
                  Programar pedido
                </Text>
                <AntDesign name="right" size={14} color="black" />
              </TouchableOpacity>
            ) : null}

            {props.tiendas.map(function (tienda: any) {
              console.log("tienda", tienda);
              if (tienda.servicioSel != undefined) {
                if (tienda.servicioSel.key == "delivery") {
                  return (
                    <TouchableOpacity
                      onPress={abrirVistaDelivery.bind(this, tienda)}
                      style={{
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ padding: 10 }}>
                        <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                          {tienda.name} Delivery
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                          {tienda.deliveryAddress || ""}
                        </Text>
                      </View>
                      <AntDesign name="right" size={14} color="black" />
                    </TouchableOpacity>
                  );
                }
              }
            })}
            <TouchableOpacity
              onPress={abrirSeleccionarLocalizacion}
              style={{
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontSize: 16, padding: 10, fontWeight: "bold" }}>
                {localizacionUsuario != ""
                  ? localizacionUsuario
                  : "Seleccionar localización"}
              </Text>
              <AntDesign name="right" size={14} color="black" />
            </TouchableOpacity>
          </View>
        </View>
        <TextInput
          placeholder="Comentario"
          ref={ref_comentario}
          style={[styles.inputForm, { marginTop: 10 }]}
          blurOnSubmit={true}
          onChangeText={(text) => {
            variableComentario = text;
          }}
          //value={variableComentario}
        />

        <TextInput
          placeholder="Email"
          ref={ref_email}
          style={[styles.inputForm]}
          blurOnSubmit={true}
          keyboardType="email-address"
          onChangeText={(text) => {
            variableEmailCliente = text;
          }}
        />
        <View
          style={[
            styles.inputForm,
            { marginBottom: 20 },
            Platform.OS != "web"
              ? {
                  paddingLeft: 20,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: "gray",
                }
              : { backgroundColor: "#E1E1E1" },
          ]}
        >
          <Picker
            style={{ height: 50, width: "100%" }}
            mode={"dropdown"}
            selectedValue={descuentoSel}
            onValueChange={function (itemValue, itemIndex) {
              setDescuentoSel(itemValue);
            }}
          >
            {arrayDescuentos.map(function (item: any, index: any) {
              if (item == 0) {
                return <Picker.Item label={"Aplicar descuento"} value={item} />;
              } else {
                return (
                  <Picker.Item label={item.toString() + "%"} value={item} />
                );
              }
            })}
          </Picker>
        </View>
      </View>
    );
  }
  return (
    <View style={{ height: "100%" }}>
      <View
        style={{
          borderColor: "#BABABA",
          borderWidth: 1,
          backgroundColor: "#E1E1E1",
          width: "100%",
          height: "100%",
        }}
      >
        <TopBar.Navigator
          sceneContainerStyle={{ backgroundColor: "transparent" }}
          style={{ backgroundColor: "transparent", width: "100%" }}
          screenOptions={{
            tabBarActiveTintColor: "#3AA597",
            tabBarInactiveTintColor: "#929292",
            tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
            tabBarItemStyle: [
              { width: (props.width * 0.4) / 2 },
              global.pantallaDividida ? {} : { width: props.width / 2 },
            ],
            tabBarStyle: [
              { backgroundColor: "white", width: props.width * 0.4 },
              global.pantallaDividida ? {} : { width: props.width },
            ],
            tabBarIndicatorStyle: { backgroundColor: "#3AA597", height: 3 },
            tabBarPressColor: "white",
          }}
        >
          <TopBar.Screen
            name="Resumen"
            component={createTabResumen}
            options={{ title: "Resumen" }}
          />
          <TopBar.Screen
            name="Otros"
            component={createTabOtros}
            options={{ title: "Otros datos" }}
          />
        </TopBar.Navigator>
      </View>
      <View
        style={{
          backgroundColor: "white",
          position: "absolute",
          height: 50,
          width: "100%",
          bottom: 50,
        }}
      >
        <View
          style={{
            height: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Text style={{ color: "#BABABA", fontSize: 16 }}>
              {props.cashLess != "" &&
              props.cashLess.credits[0].active &&
              precioTotal.toFixed(2) > 0
                ? "Falta"
                : "Total"}
            </Text>
            <Text
              style={[
                { fontSize: 16 },
                props.cashLess != "" &&
                props.cashLess.credits[0].active &&
                precioTotal.toFixed(2) > 0
                  ? { color: "#3AA597" }
                  : {},
              ]}
            >
              {precioTotal.toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          backgroundColor: "white",
          position: "absolute",
          height: 50,
          width: "100%",
          bottom: 0,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#3AA597",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
            onPress={prepararPedido.bind(
              this,
              "dataphone",
              ref_comentario,
              ref_email
            )}
          >
            <FontAwesome name="credit-card" size={28} color="white" />
          </TouchableOpacity>
          <View
            style={{ width: 1, height: "100%", backgroundColor: "white" }}
          ></View>
          <TouchableOpacity
            style={{
              backgroundColor: "#3AA597",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
            onPress={abrirCalculadora.bind(this, ref_comentario, ref_email)}
          >
            <FontAwesome name="dollar" size={28} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = (state: any) => {
  return {
    comentario: state.carrito.comentario,
    email: state.carrito.email,
    tiendas: state.carrito.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    conexion: state.dimensions.conexion,
    cashLess: state.carrito.cashLess,
  };
};
const mapDispatchToProps = {
  setPedidoImprimir,
  vaciarCarrito,
  addProducto,
  eliminarProducto,
  setTienda,
  setComentario,
  setEmail,
  setConexion,
  setFacturaProductos,
  setFacturaComision,
  setUltimoPedido,
};
export default connect(mapStateToProps, mapDispatchToProps)(VistaResumenPedido);
