import { SET_WIDTH,SET_HEIGHT,SET_CONEXION } from "./dimensionsTypes";


export const setWidth = (width:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_WIDTH,
      payload: {
        width: width
      }
    });
  };
};
export const setHeight = (height:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_HEIGHT,
      payload: {
        height: height
      }
    });
  };
};
export const setConexion = (conexion:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_CONEXION,
      payload: {
        conexion: conexion
      }
    });
  };
};
