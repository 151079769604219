import React, { useState, useEffect, useRef } from 'react';
import { ScrollView,FlatList,TouchableOpacity, View,Text,DeviceEventEmitter } from 'react-native';
import { connect } from "react-redux";
import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  BluetoothManager,
  BluetoothEscposPrinter,
  BluetoothTscPrinter,
} from "react-native-bluetooth-escpos-printer";
import { requestPermissionsAsync } from "expo-location";
const listadoBluetooth = (props:any) => {

  const [dispositivos, setDispositivos] = useState([]);
  
  function goBack(){
    props.navigation.goBack()
  }
  
  const pedirPermisos = async () => {

    await requestPermissionsAsync();
    
   };
  useEffect(() => {
    pedirPermisos()
    pruebasBT()
  }, []);
  async function seleccionarImpresora(impresora:any){
    AsyncStorage.setItem('@address_impresora',impresora.address);
    //await global.imprimirPedido(impresora.address,tipoManager)
    props.route.params.impresoraSeleccionada(impresora.address)
    goBack()
  }
  
  function pruebasBT(){
    DeviceEventEmitter.addListener(
      BluetoothManager.EVENT_DEVICE_ALREADY_PAIRED,
      (rsp) => {
        //alert("EVENT_DEVICE_ALREADY_PAIRED"+JSON.stringify(rsp)) // rsp.devices would returns the paired devices array in JSON string.
        
        if(rsp.devices!=undefined && rsp.devices!=null){
          setDispositivos(JSON.parse(rsp.devices))
        }
      }
    );
    /*DeviceEventEmitter.addListener(BluetoothManager.EVENT_DEVICE_FOUND, (rsp) => {
      alert("EVENT_DEVICE_FOUND"+JSON.stringify(rsp)) // rsp.devices would returns the found device object in JSON string
    });*/
    
    BluetoothManager.isBluetoothEnabled().then((enabled)=> {
        if(enabled){
            // se ejecuta solo para se llame a los eventos de EVENT_DEVICE_ALREADY_PAIRED y EVENT_DEVICE_FOUND
            BluetoothManager.scanDevices().then(
              (scannedDevices) => {
                console.log("succc")
              },
              (er) => {
                console.log("err",er)
                //alert("error" + JSON.stringify(er));
              }
            );
        }else{
          BluetoothManager.enableBluetooth().then(
            (r) => {
               BluetoothManager.scanDevices().then(
                (scannedDevices) => {
                  console.log("succc")
                },
                (er) => {
                  console.log("err",er)
                  //alert("error" + JSON.stringify(er));
                }
              );
            },
            (err) => {
              alert('No se ha podido activar el bluetooth');
            }
          );
        }
    }, (err)=> {
        alert('Comprueba que el bluetooth está activado')
    });
  }
  return (
     <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.8)',alignItems:'center',justifyContent:'center',height:(props.height),width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{maxWidth:400,width:'80%',height:'90%',borderRadius:8,backgroundColor:'white'}}>
        <View style={{padding:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
              <Text style={{textAlign:'center',fontWeight:'bold'}}>Selecciona el dispositivo donde quieres imprimir</Text> 
        </View>
        <ScrollView 
          contentContainerStyle={{paddingBottom:90}}
          showsVerticalScrollIndicator={false}
        >
        { dispositivos.length>0 ? (
            <View style={{padding:20,width:'100%'}}>
              {
               dispositivos.map(function(dispositivo:any) {
                 
                  return(
                    <TouchableOpacity onPress={seleccionarImpresora.bind(this,dispositivo)} style={{padding:8,alignItems:'center',borderRadius:8,backgroundColor:'#E1E1E1',flexDirection:'row',justifyContent:'space-between',marginBottom:20}}>
                      <View style={{flexDirection:'row',alignItems:'center'}}>
                        <Text style={{paddingLeft:8}}>{dispositivo.name}</Text>
                      </View>
                      <Text>{dispositivo.address || ''}</Text>
                    </TouchableOpacity>
                  )
                })
              }
            </View>
          ) : (null)
        }
       
       
          </ScrollView>
          <TouchableOpacity onPress={goBack} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
            <View style={{backgroundColor:'#3AA597',borderRadius:50}}>
              <Text style={{padding:10,fontWeight:'bold',color:'white',paddingLeft:20,paddingRight:20}}>Cerrar</Text>
            </View>
          </TouchableOpacity>
      </TouchableOpacity>
    </View>
    </TouchableOpacity>
        
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height
  };
};
export default connect(mapStateToProps)(listadoBluetooth);
