import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

const MainButton = ({
  title,
  onPress,
  color = "#3AA597",
  textColor = "white",
  borderColor = "#3AA597",
  borderWidth = 0,
}) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        { borderWidth, borderColor, backgroundColor: color },
      ]}
      onPress={onPress}
    >
      <Text style={[styles.text, { color: textColor }]}>{title}</Text>
    </TouchableOpacity>
  );
};

export default MainButton;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 50,
    paddingHorizontal: 3,
    borderRadius: 30,
    backgroundColor: "#3AA597",
  },
  text: {
    fontSize: 18,
    textAlign: "center",
    color: "white",
  },
});
