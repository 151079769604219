import React from "react";
import { Text, StyleSheet } from "react-native";

const ProductName = ({ name, amount = null }) => (
  <Text numberOfLines={2} style={styles.name}>
    {name} {amount && "(" + amount + ")"}
  </Text>
);

export default ProductName;

const styles = StyleSheet.create({
  name: {
    fontSize: 22,
    fontWeight: "500",
  },
});
