import React, { useState, useEffect, useRef } from 'react';
import {Text,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';

import {addProducto} from "../../application/reducers";
import { connect } from "react-redux";

const windowHeight = Dimensions.get('window').height;

const descripcionLarga = (props:any) => {
  var producto=props.route.params.producto
  var tienda=props.route.params.tienda
  
  function goBack(){
    props.navigation.goBack()
  }
 
  function addProductoCarrito(){
    props.addProducto(tienda,producto)
    goBack()
  }
  
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(windowHeight-80),marginTop:80},pantallaDividida?{width:'60%',marginLeft:'40%'}:{width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{width:'80%',height:'90%',borderRadius:8,backgroundColor:'#E1E1E1'}}>
        <TouchableOpacity  onPress={goBack}  style={{position:'absolute',top:20,right:20,zIndex:2}}>
          <AntDesign name="close" size={32} color="#3AA597" />
        </TouchableOpacity>
        <View style={{width:'100%',height:300,justifyContent:'center',alignItems:'center'}}>
            <ImageBackground 
                style={{width:'100%',height:300}}
                source={
                  {uri:'https://picsum.photos/seed/picsum/200/300'}
                }
                resizeMode="cover"
            >
            </ImageBackground>
        </View>
        <View style={{padding:20,width:'100%'}}>
            <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:18}}>{producto.name}</Text>
            <View style={{}}>
              <Text style={{padding:10}}>{producto.long_description}</Text>
            </View>
        </View>
        <TouchableOpacity  onPress={addProductoCarrito} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
          <View style={{backgroundColor:'#3AA597',borderRadius:50}}>
            <Text style={{padding:10,fontWeight:'bold',color:'white'}}>Añadir</Text>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </View>
    </TouchableOpacity>
  );
};
const mapDispatchToProps = {
  addProducto
};
export default connect(null,mapDispatchToProps)(descripcionLarga);
