import { SET_STOCK } from "./stockTypes";


const initialState = {
  stock: []
};
const dimensionsReducers = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_STOCK: {
      state.stock =  action.payload.stock
      return {...state}
    }
    
    default:
      return state;
  }
};

export default dimensionsReducers;