import {
  GET_PRODUCTO,
  ADD_PRODUCTO,
  DELETE_PRODUCTO,
  VACIAR_CARRITO,
  SET_TIENDAS_CARRITO,
  SET_TIENDA,
  SET_COMENTARIO,
  SET_EMAIL,
  SET_CASHLESS,
} from "./carritoTypes";
import globales from "../../funcionalidades/globales";
const initialState = {
  tiendas: [],
  comentario: "",
  email: "",
  cashLess: "",
};
function calcularPrecio(producto: any) {
  console.log("calcularPrecio", producto);
  let precioTotal = producto.amount;

  if (producto.variantesSel != undefined) {
    producto.variantesSel.map(function (grupo: any, index: number) {
      grupo.opciones.map(function (opcion: any, index: number) {
        if (opcion.valor) {
          precioTotal = precioTotal + opcion.incremento * opcion.cantidad;
        }
      });
    });
  }
  if (producto.platosSel != undefined) {
    producto.platosSel.map(function (producto: any, index: number) {
      let precioProducto = producto.precio;
      producto.variantesSel.map(function (grupo: any, index: number) {
        grupo.opciones.map(function (opcion: any, index: number) {
          if (opcion.valor) {
            precioProducto =
              precioProducto + opcion.incremento * opcion.cantidad;
          }
        });
      });
      precioTotal = precioTotal + precioProducto;
    });
  }
  return precioTotal;
}
const carritoReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_PRODUCTO: {
      let tiendasAux = state.tiendas.slice();
      let tiendaSel = -1;
      tiendasAux.map(function (tienda: any, index: number) {
        if (tienda.gid == action.payload.tienda.gid) {
          tiendaSel = tienda;
        }
      });

      if (tiendaSel != -1) {
        let existe = -1;
        tiendaSel.productos.map(function (item: any, index: number) {
          if (item.gid == action.payload.producto.gid) {
            if (
              JSON.stringify(item.variantesSel) ==
              JSON.stringify(action.payload.producto.variantesSel)
            ) {
              if (
                JSON.stringify(item.platosSel) ==
                JSON.stringify(action.payload.producto.platosSel)
              ) {
                if (
                  JSON.stringify(item.ingredientesSinSel) ==
                  JSON.stringify(action.payload.producto.ingredientesSinSel)
                ) {
                  if (
                    item.product_type == action.payload.producto.product_type
                  ) {
                    existe = index;
                  }
                }
              }
            }
          }
        });
        if (existe != -1) {
          let productoPrincipio = tiendaSel.productos[existe];
          productoPrincipio.cantidad++;
          tiendaSel.productos.splice(existe, 1);
          tiendaSel.productos.unshift(productoPrincipio);
        } else {
          action.payload.producto.precioTotalProducto = calcularPrecio(
            action.payload.producto
          );
          action.payload.producto.cantidad = 1;
          tiendaSel.productos.unshift(action.payload.producto);
        }
      } else {
        let productosTienda = [];

        action.payload.producto.precioTotalProducto = calcularPrecio(
          action.payload.producto
        );
        action.payload.producto.cantidad = 1;
        productosTienda.push(action.payload.producto);
        action.payload.tienda.productos = productosTienda;
        tiendasAux.push(action.payload.tienda);
      }
      state.tiendas = tiendasAux;
      return {
        ...state,
      };
    }
    case DELETE_PRODUCTO: {
      let tiendasAux = state.tiendas.slice();
      let tiendaSel = -1;
      let indiceTienda = -1;
      tiendasAux.map(function (tienda: any, index: number) {
        if (tienda.gid == action.payload.tienda.gid) {
          tiendaSel = tienda;
          indiceTienda = index;
        }
      });

      if (tiendaSel != -1) {
        let existe = -1;
        tiendaSel.productos.map(function (item: any, index: number) {
          if (item.gid == action.payload.producto.gid) {
            if (
              JSON.stringify(item.variantesSel) ==
              JSON.stringify(action.payload.producto.variantesSel)
            ) {
              if (
                JSON.stringify(item.platosSel) ==
                JSON.stringify(action.payload.producto.platosSel)
              ) {
                existe = index;
              }
            }
          }
        });
        if (existe != -1) {
          if (action.payload.eliminarTodo) {
            tiendaSel.productos.splice(existe, 1);
          } else {
            var cantidad = tiendaSel.productos[existe].cantidad - 1;
            if (cantidad == 0) {
              tiendaSel.productos.splice(existe, 1);
            } else {
              tiendaSel.productos[existe].cantidad = cantidad;
            }
          }
          if (tiendaSel.productos.length == 0) {
            tiendasAux.splice(indiceTienda, 1);
          }
          state.tiendas = tiendasAux;
        }
      }
      return { ...state };
    }

    case GET_PRODUCTO: {
      let tiendaSel = -1;
      state.tiendas.map(function (tienda: any, index: number) {
        if (tienda.gid == action.payload.tienda.gid) {
          tiendaSel = tienda;
        }
      });
      let productosAux = [];
      if (tiendaSel != -1) {
        tiendaSel.productos.map(function (item: any) {
          if (item.gid == action.payload.gid) {
            productosAux.push(item);
          }
        });
      }
      action.payload.productosAux = productosAux;
      return { ...state };
    }
    case VACIAR_CARRITO: {
      state.cashLess = "";
      state.tiendas = [];
      return { ...state };
    }
    case SET_TIENDAS_CARRITO: {
      let tiendasAux = action.payload.tiendas.slice();
      state.tiendas = tiendasAux;
      return { ...state };
    }
    case SET_TIENDA: {
      let tiendasAux = state.tiendas.slice();
      tiendasAux.map(function (tienda: any, index: number) {
        if (tienda.gid == action.payload.tienda.gid) {
          tienda = action.payload.tienda;
        }
      });
      state.tiendas = tiendasAux;
      return { ...state };
    }
    case SET_COMENTARIO: {
      state.comentario = action.payload.comentario;
      return { ...state };
    }
    case SET_EMAIL: {
      state.email = action.payload.email;
      return { ...state };
    }
    case SET_CASHLESS: {
      state.cashLess = action.payload.cashLess;
      return { ...state };
    }
    default:
      return state;
  }
};

export default carritoReducers;
