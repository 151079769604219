import { SET_STOCK } from "./stockTypes";


export const setStock = (stock:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_STOCK,
      payload: {
        stock: stock
      }
    });
  };
};

