import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

const BackButton = ({ action }) => {
  return (
    <TouchableOpacity style={styles.backContainer} onPress={action}>
      <View style={{ marginRight: 8, paddingTop: 4 }}>
        <FontAwesome name="chevron-left" size={20} color={"#009683"} />
      </View>
      <Text style={styles.backText}>Volver</Text>
    </TouchableOpacity>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  backContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  backText: {
    fontSize: 25,
    color: "#009683",
  },
});
