import React from "react";
import { Text, View, StyleSheet } from "react-native";

const ShortDescription = ({ description }) => {
  return description !== null ? (
    <Text style={styles.text}>{description}</Text>
  ) : (
    <View></View>
  );
};

export default ShortDescription;

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    marginTop: 5,
    fontWeight: "200",
  },
});
