import React from "react";
import { StyleSheet, ScrollView } from "react-native";

// Components
import Divider from "../../../../components/Divider";
import ProductItem from "../ProductItem";

const List = ({ products }) => {
  return (
    <ScrollView
      style={styles.content}
      contentContainerStyle={styles.scrollContent}
      showsVerticalScrollIndicator={false}
    >
      <Divider height={30} />
      {products.map((product) => (
        <ProductItem key={product.gid} data={product} />
      ))}
      <Divider height={90} />
    </ScrollView>
  );
};

export default List;

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 850,
  },
  scrollContent: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
  },
});
