import React from "react";
import { Text, View, StyleSheet } from "react-native";

// Methods
import getFormattedPrice from "../../../../../methods/getFormattedPrice";

const Price = ({ amount }) => {
  const showAmount = amount > 0 && amount !== null && amount !== undefined;
  return showAmount ? (
    <View>
      <Text style={styles.text}>{getFormattedPrice(amount)}</Text>
    </View>
  ) : (
    <View></View>
  );
};

export default Price;

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    marginLeft: "auto",
  },
});
