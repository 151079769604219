import React, { useState, useRef, useEffect } from "react";
import { Text, Animated, Easing, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";

// Components
import ActionButton from "./ActionButton";

const RemoveProduct = ({
  quantity,
  onPress,
  buttonSize = 35,
  iconSize = 30,
}) => {
  const animatedController = useRef(new Animated.Value(0)).current;
  const [visible, setVisible] = useState(true);

  let animatedWidth = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 40],
  });

  const closeComponent = () => {
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 0,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false,
    }).start();
    setVisible(false);
  };

  const openComponent = () => {
    setVisible(true);
    Animated.timing(animatedController, {
      duration: 300,
      toValue: 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
      useNativeDriver: false,
    }).start();
  };

  const toggleListItem = () => {
    if (visible && quantity === 0) {
      closeComponent();
    } else {
      openComponent();
    }
  };

  useEffect(() => {
    toggleListItem();
  }, [quantity]);

  return (
    <Animated.View style={[styles.actions, { width: animatedWidth }]}>
      <Text style={styles.qtyText}>{quantity}</Text>
      <ActionButton color={"#FFC4C4"} onPress={onPress} size={buttonSize}>
        <Feather
          name={quantity > 1 ? "minus" : "trash-2"}
          size={quantity > 1 ? iconSize : iconSize - 4}
          color={"#C61414"}
        />
      </ActionButton>
    </Animated.View>
  );
};

export default RemoveProduct;

const styles = StyleSheet.create({
  actions: {
    height: "100%",
    minHeight: 80,
    maxWidth: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    marginRight: 20,
  },
  qtyText: {
    fontSize: 22,
    fontWeight: "500",
    color: "#009683",
  },
});
