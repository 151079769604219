import React, { useState, useEffect, useRef } from "react";
import { Animated, TouchableOpacity, View, Text } from "react-native";
import { connect } from "react-redux";
import VistaFamiliaProductos from "./vistaFamiliaProductos";
import VistaResumenPedido from "./vistaResumenPedido";
import {
  vaciarCarrito,
  addProducto,
  setConexion,
} from "../../application/reducers";
import { createStyles, maxWidth } from "react-native-media-queries";

const detallesTienda = (props: any) => {
  let tienda = props.route.params.tienda;
  let familiaSel = props.route.params.familia || "";
  const [data, setData] = useState(["hola", "adios", "hasta luego"]);
  const [dataFiltrado, setDataFiltrado] = useState([]);
  const [query, setQuery] = useState("");
  const menuAnimatedController = useRef(new Animated.Value(0)).current;
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [dataDropdown, setDataDropdown] = useState([]);

  let menuWidth = menuAnimatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, props.width * 0.4],
  });

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: tienda.name,
    });

    let dataAux: Array<any> = [];
    let dataDropdownAux: Array<any> = [];
    tienda.products.map(function (familia: any, index: number) {
      familia.products.map(function (producto: any, index: number) {
        dataAux.push(producto);
        dataDropdownAux.push({
          id: producto.gid,
          title: producto.name,
        });
      });
    });
    setData(dataAux);
    setDataDropdown(dataDropdownAux);
  }, []);

  useEffect(() => {}, [
    dataFiltrado,
    data,
    query,
    open,
    openMenu,
    props.conexion,
  ]);
  function abrirListadoPedidosCamarero() {
    props.navigation.navigate("listadoPedidosDatafono");
  }

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      <Animated.View
        style={[
          {
            zIndex: 2,
            backgroundColor: "white",
            overflow: "hidden",
            position: "absolute",
            height: "100%",
          },
          { width: menuWidth },
          {},
        ]}
      >
        <View style={stylesBase.menuContainer}>
          <TouchableOpacity
            style={stylesBase.btnMenu}
            onPress={abrirListadoPedidosCamarero.bind(this)}
          >
            <Text style={stylesBase.txtMenu}>Pedidos</Text>
          </TouchableOpacity>
          <TouchableOpacity style={stylesBase.btnMenu}>
            <Text style={stylesBase.txtMenu}>Configuración</Text>
          </TouchableOpacity>
          <TouchableOpacity style={stylesBase.btnMenu}>
            <Text style={stylesBase.txtMenu}>Arqueo de caja</Text>
          </TouchableOpacity>
          <TouchableOpacity style={stylesBase.btnMenu}>
            <Text style={stylesBase.txtMenu}>Sacar dinero</Text>
          </TouchableOpacity>
        </View>
      </Animated.View>
      {global.pantallaDividida ? (
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ width: "40%" }}>
            <VistaResumenPedido
              navigation={props.navigation}
              tienda={tienda}
            ></VistaResumenPedido>
          </View>
          <View style={{ width: "60%" }}>
            <VistaFamiliaProductos
              navigation={props.navigation}
              tienda={tienda}
              familia={familiaSel}
            ></VistaFamiliaProductos>
          </View>
        </View>
      ) : (
        <View style={{ width: "100%" }}>
          <VistaFamiliaProductos
            navigation={props.navigation}
            tienda={tienda}
            familia={familiaSel}
          ></VistaFamiliaProductos>
        </View>
      )}
    </View>
  );
};
const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    conexion: state.dimensions.conexion,
  };
};
const mapDispatchToProps = {
  vaciarCarrito,
  addProducto,
  setConexion,
};

export default connect(mapStateToProps, mapDispatchToProps)(detallesTienda);
const base = {
  btnMenu: {
    padding: 10,
    paddingLeft: 20,
    borderBottomWidth: 1,
  },
  txtMenu: {
    fontSize: 24,
  },
  menuContainer: {
    minWidth: 200,
  },
  styleBtnScaner: {},
  containerBtns: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
};
const stylesBase = createStyles(
  base,
  // override styles only if screen height is less than 500
  maxWidth(600, {
    txtMenu: {
      fontSize: 16,
    },
    styleBtnScaner: {
      display: "none",
    },
  })
);
