import getQuantityByGroup from "./getQuantityByGroup.ts";

const checkType = ({ group, option, currentGroup, setSelectedVariants }) => {
  const { gid, name, max_select } = group;

  const auxOption = {
    valor: true,
    gid: option.gid,
    name: option.name,
    cantidad: 1,
    incremento: option?.increment,
  };

  if (currentGroup) {
    const containsOption = currentGroup?.opciones?.findIndex(
      (opt) => opt.gid === option.gid
    );

    let auxGroup = currentGroup;

    const quantity = getQuantityByGroup(currentGroup);
    if (containsOption === -1) {
      if (
        (max_select !== null && quantity < max_select) ||
        max_select === null
      ) {
        auxGroup.opciones.push(auxOption);
      }
    } else {
      auxGroup.opciones = auxGroup.opciones.filter(
        (opt) => opt.gid !== option.gid
      );
    }

    setSelectedVariants((prevVariants) => {
      const updatedVariants = prevVariants.filter((group) => group.gid !== gid);
      return [...updatedVariants, auxGroup];
    });
  } else {
    const newGroup = {
      gid,
      name,
      opciones: [auxOption],
    };
    setSelectedVariants((prevVariants) => {
      return [...prevVariants, newGroup];
    });
  }
};

export default checkType;
