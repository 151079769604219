import { combineReducers } from "redux";

import pedidosReducer from "./pedidos/pedidosReducers";
import tiendasReducer from "./tiendas/tiendasReducers";
import carritoReducer from "./carrito/carritoReducers";
import dimensionsReducers from "./screenDimensions/dimensionsReducers"
import stockReducer from "./stock/stockReducers"
import pedidoImprimir from "./pedidoImprimir/pedidoImprimirReducers"
import config from "./config/configReducer"
const rootReducer = combineReducers({
  pedidos: pedidosReducer,
  tiendas: tiendasReducer,
  carrito: carritoReducer,
  dimensions: dimensionsReducers,
  stock: stockReducer,
  pedidoImprimir:pedidoImprimir,
  config:config
});

export default rootReducer;