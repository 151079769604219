import React, { useState, useEffect, useRef } from 'react';
import {Dimensions,StyleSheet,TouchableOpacity,Text,View,TextInput,Animated,Easing,Platform,ScrollView,StatusBar,Alert,ActivityIndicator} from "react-native";
import { Switch } from 'react-native-switch';

import { SearchBar } from 'react-native-elements';
import { connect } from "react-redux";
import {setPedidoImprimir,setTiendas,setConexion,setPedidos,setTiendasCarrito,setPedidosPerdidos,setSocketConectado } from "../../application/reducers";
import {Octicons,Entypo,MaterialIcons,AntDesign,EvilIcons,Ionicons ,FontAwesome,FontAwesome5,Feather } from '@expo/vector-icons';
import { createStyles, maxWidth} from 'react-native-media-queries';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Tienda from "../objetos/Tienda"
import NetInfo from '@react-native-community/netinfo';
import VistaMenuInterior from '../screens/vistaMenuInterior';
const CabeceraVertical = (props:any) => {

    const animatedController = useRef(new Animated.Value(0)).current;
    const animatedControllerInterior = useRef(new Animated.Value(0)).current;
    const [open, setOpen] = useState(false);
    const [openInterior, setOpenInterior] = useState(false);
    const [switchValue, setSwitchValue] = useState(true);
    
    let bodyWidth = animatedController.interpolate({
      inputRange: [0, 1],
      outputRange: [60, 200],
    });
    let bodyWidthInterior = animatedControllerInterior.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 300],
    });
   function abrirEscaner(){
      props.navigation.navigate('vistaEscaner');
    }
     function abrirMenu(){
        props.navigation.reset({
          index:0,
          routes:[{name:'menu'}]
        })
    }
    const toggleMenu = () => {
      if (open) {
        Animated.timing(animatedController, {
          duration: 300,
          toValue: 0,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
      } else {
        Animated.timing(animatedController, {
          duration: 300,
          toValue: 1,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
      }
      if(open){
        setOpen(!open);
      }else{
        setTimeout(function(){
          setOpen(!open);
        },200)
      }
    };
    const toggleMenuInterior = () => {

      if (openInterior) {
        Animated.timing(animatedControllerInterior, {
          duration: 300,
          toValue: 0,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
      } else {
        Animated.timing(animatedControllerInterior, {
          duration: 300,
          toValue: 1,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
      }
      if(openInterior){
        setOpenInterior(!openInterior);
      }else{
        setTimeout(function(){
          setOpenInterior(!openInterior);
        },200)
      }
      
      
    };
    function abrirMenuInterior(){

        Animated.timing(animatedController, {
          duration: 300,
          toValue: 1,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
       setTimeout(function(){
          setOpen(true);
          Animated.timing(animatedControllerInterior, {
            duration: 300,
            toValue: 1,
            easing: Easing.bezier(0.4, 0.0, 0.2, 1),
            useNativeDriver:false
          }).start();
          setOpenInterior(true);
        },200)

      
    }
    function cerrarMenus(){
      console.log("cerrar menu")
        Animated.timing(animatedControllerInterior, {
          duration: 300,
          toValue: 0,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver:false
        }).start();
        setOpenInterior(false);
       setTimeout(function(){
           Animated.timing(animatedController, {
            duration: 300,
            toValue: 0,
            easing: Easing.bezier(0.4, 0.0, 0.2, 1),
            useNativeDriver:false
          }).start();
          setOpen(false);
          
        },300)
    }
    function abrirTiendas(){
      global.tpv=true
      if(props.conexion){
        global.usuario.cargarTiendas().then(function(res:any){
          let tiendasAux:Array<any>=[]
          res.map(function(tienda:any,indice:number) {
            tiendasAux.push(new Tienda(tienda))
          });
          props.setTiendas(tiendasAux)
          comprobarTiendas(tiendasAux)
        })
      }else{
        comprobarTiendas(props.tiendas)
      }
    }
    function comprobarTiendas(tiendasAux:any){
      if(tiendasAux.length==1){
        abrirTienda(tiendasAux[0])
      }else{
        let aux={
          "tpv":true,
          "tiendas":tiendasAux
        }
        props.navigation.navigate('listadoTiendas',aux)
      }
    }
    function abrirTienda(tienda:any){
      let aux={
        tienda:tienda
      }
      if(props.conexion){
        tienda.getDatosTienda(tienda.gid,'pick_up').then(function(res:any){
          if(tienda.products.length>0){
            props.navigation.navigate('detallesTienda',aux)
          }else{
            alert("La tienda seleccionada no tiene productos")
          }
        })
      }else{
        if(tienda.products.length>0){
            props.navigation.navigate('detallesTienda',aux)
          }else{
            alert("La tienda seleccionada no tiene productos")
          }
      }
    }
    function onPressBarra(){
      if(open){
        cerrarMenus()
      }else{
        toggleMenu()
      }
    }
    function abrirVistaCerrarTiendas(){
      let auxTiendas=[]
      global.usuario.cargarTiendas().then(function(res:any){
        auxTiendas=res
        if(auxTiendas.length>0){
           let aux={
            tiendas:auxTiendas
          }
          props.navigation.navigate('vistaCerrarTiendas',aux)
        }
      })
      
    }
    function abrirConfiguracionImpresoras(){
      if(Platform.OS!='web'){
        let aux={
          impresoraSeleccionada: (address:any) => {},
        }
        props.navigation.navigate('listadoBluetooth',aux);
      }
    }
    function borrarRedux(){
      props.setPedidoImprimir(null)
      props.setTiendas([])
      props.setConexion(true)
      props.setPedidos([])
      props.setTiendasCarrito([])
    }
    function reiniciarApp(){
      props.navigation.reset({
          index:0,
          routes:[{name:'menu'}]
      })
      recargarPedidos()
    }
    function cerrarSesion(){
      AsyncStorage.setItem('@email_usuario','');
      AsyncStorage.setItem('@pass_usuario', '');
       props.navigation.reset({
          index:0,
          routes:[{name:'login'}]
      })

      global.socket.socket.off("orders:recent")
      global.socket.socket.off("orders:createPaid:result")
      global.socket.socket.offAny();
      global.socket.socket.disconnect()
      global.socket.socketProductos.disconnect()
      global.socket=undefined
      borrarRedux()
     
      ///DevSettings.reload()
    }
    function completarPedidos(){
      let ordersIds=[]
      props.pedidosFiltrados.map(function(pedido:any,index:number){
        ordersIds.push(pedido.gid)

      })
      global.usuario.completarPedidos(ordersIds,9)
    }
    function updateSearch(search_aux:any){

      if(search_aux.length > 0){
        var search = search_aux.toUpperCase();
        const filtro = props.pedidosFiltrados.filter(item => {
          var cadena = item.order_number
          return cadena.toUpperCase().indexOf(search) > -1;
        });
        props.setPedidosBuscador(filtro)
        props.setSearch(search_aux);
      }else{
        props.setPedidosBuscador(props.pedidosFiltrados)
        props.setSearch('');
      }
    }
    function cambiarConexion(){
      if(props.conexion){
        props.setConexion(false)
      }else{
        NetInfo.fetch().then(state => {
          if(state.isConnected){
             props.setConexion(true)
          }else{
            alert("No hay acceso a internet")
          }
        });
      }
    }
    function recargarPedidos(){
      NetInfo.fetch().then(state => {
        if(state.isConnected){
           props.setSocketConectado(true)
            props.setPedidosPerdidos(false)
            global.socket.socket.emit("orders:read",{})
        }else{
          alert("No hay acceso a internet")
        }
      });
      
       
      
    }
    function finalizarTurno(){
      AsyncStorage.setItem('@ultimoCierre', (new Date().getTime()/1000).toString());
      completarPedidos()
      reiniciarApp()
    }
    function cerrarTurno(){
      if(Platform.OS === 'web'){
         finalizarTurno()
      }else{
        Alert.alert(
          '',
          '¿Desea finalizar el turno?',
          [
            {
              text: 'Finalizar turno',
              onPress: () => {
                finalizarTurno()
                
              },
            },
            {
              text: 'Cancelar',
              onPress: () => {console.log('Cancel Pressed')},
              style: 'cancel'
            },
          ],
          { cancelable: true }
        );
      }
     
    }
    return(
        <View style={[{zIndex:5,position:'absolute',left:0,top:0},open?{width:'100%'}:{width:60}]}>
        <Animated.View style={{width:bodyWidth,height:(props.height),backgroundColor:'#3AA597'}}>
          <TouchableOpacity activeOpacity={1} onPress={onPressBarra} style={{height:(props.height)}}>
          {open?(
            <View style={{flexDirection:'row',width:'100%',height:'100%'}}>
              <View style={{width:'100%',alignItems:'center', justifyContent:'space-between',height:'100%'}}>
                <View style={{alignItems:'flex-start',width:'100%',paddingHorizontal:7.5}}>
                  {props.socketConectado?(
                    <View style={{flexDirection:'row',marginTop:30,justifyContent:'center',alignItems:'center',marginHorizontal:7.5}}>
                      <View style={{width:30,height:30,borderRadius:30,backgroundColor:'white',justifyContent:'center',alignItems:'center'}}>
                        <View style={[styles.btnSocket,props.pedidosPerdidos?{backgroundColor:'#F0F345'}:{}]}>
                        </View>
                      </View>
                      <Text style={{color:'white',paddingLeft:5}}>
                        {props.pedidosPerdidos?'Reconectado':'Conectado'}
                      </Text>
                    </View>)
                  :(
                    <View style={{flexDirection:'row',marginTop:30,justifyContent:'center',alignItems:'center',marginHorizontal:5}}>
                      <View style={{width:30,height:30,borderRadius:30,backgroundColor:'white',justifyContent:'center',alignItems:'center'}}>
                        <View style={[styles.btnSocket,{backgroundColor:'red'}]}>
                        </View>
                      </View>
                      <Text style={{color:'white',paddingLeft:5}}>
                        Desconectado
                      </Text>
                    </View>
                  )}
                  {(props.socketConectado && props.pedidosPerdidos) || !props.socketConectado ?(
                    <TouchableOpacity style={{backgroundColor:'#F0F345',borderRadius:45,height:45,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%'}} onPress={recargarPedidos}>
                      <Ionicons name="reload" size={25} color="black" />
                      <Text style={{paddingLeft:5}}>
                        Recargar pedidos
                      </Text>
                    </TouchableOpacity>)
                  :(<TouchableOpacity style={{backgroundColor:'white',borderRadius:45,height:45,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%'}} onPress={recargarPedidos}>
                      <Ionicons name="reload" size={25} color="black" />
                      <Text style={{paddingLeft:5}}>
                        Recargar pedidos
                      </Text>
                    </TouchableOpacity>)}
                  
                  <TouchableOpacity style={{borderWidth:1,borderColor:'white',borderRadius:47,height:47,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%'}}  onPress={abrirEscaner}>
                    <AntDesign name="scan1" size={25} color="white"/>
                    <Text style={{color:'white',paddingLeft:5}}>
                      Escanear QR
                    </Text>
                  </TouchableOpacity>
                 
                  <TouchableOpacity activeOpacity={1} style={{backgroundColor:'white',borderRadius:45,height:45,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%',overflow:'hidden'}}>
                    <AntDesign name="search1" size={25} color="black" />
                    <TextInput
                      placeholder="Buscar pedido"
                      onChangeText={text => updateSearch(text)}
                      style={[styles.inputBuscador]}
                      value={props.search}
                    />
                  </TouchableOpacity>
                </View>

                <View style={{alignItems:'flex-start',width:'100%',paddingLeft:10}}>
                  <TouchableOpacity style={{flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:10}} onPress={toggleMenuInterior}>
                    <Feather name="menu" size={40} color="white" />
                    <Text style={{color:'white',paddingLeft:5}}>
                      Menú
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              
            </View>
            ):
            (<View style={{width:'100%',alignItems:'center', justifyContent:'space-between',height:'100%'}}>
              <View style={{width:'100%',paddingHorizontal:7.5}}>
                
                {props.socketConectado?(
                  <View style={styles.containerBtnSocket}>
                    <View style={[styles.btnSocket,props.pedidosPerdidos?{backgroundColor:'#F0F345'}:{}]}>
                    </View>
                  </View>)
                :(
                  <View style={styles.containerBtnSocket}>
                    <View style={[styles.btnSocket,{backgroundColor:'red'}]}>
                    </View>
                  </View>
                )}
                {(props.socketConectado && props.pedidosPerdidos) || !props.socketConectado ?(
                  <TouchableOpacity style={{backgroundColor:'#F0F345',borderRadius:45,height:45,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%'}} onPress={recargarPedidos}>
                    <Ionicons name="reload" size={25} color="black" />
                  </TouchableOpacity>)
                :(<TouchableOpacity style={{backgroundColor:'white',borderRadius:45,height:45,flexDirection:'row',marginTop:30,justifyContent:'flex-start',alignItems:'center',padding:10,width:'100%'}} onPress={recargarPedidos}>
                    <Ionicons name="reload" size={25} color="black" />
                  </TouchableOpacity>)}
                <TouchableOpacity style={styles.btnScanner} onPress={abrirEscaner}>
                  <AntDesign name="scan1" size={25} color="white"/>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnBuscador} onPress={toggleMenu}>
                  <AntDesign name="search1" size={25} color="black" />
                </TouchableOpacity>
              </View>
              <View style={{width:'100%',paddingHorizontal:10}}>
                <TouchableOpacity style={{marginBottom:10}} onPress={abrirMenuInterior}>
                  <Feather name="menu" size={40} color="white" />
                </TouchableOpacity>
              </View>
            </View>
            )
          }
          </TouchableOpacity>
          
              
        </Animated.View>
        {bodyWidthInterior?(
            <Animated.View style={{zIndex:5,width:bodyWidthInterior,top:0,height:(props.height),backgroundColor:'white',position:'absolute',left:200, overflow:'hidden'}}>
                <View style={{flex:1,minWidth:300}}>
                  <VistaMenuInterior navigation={props.navigation}></VistaMenuInterior>
                </View>
              </Animated.View>
            ):(
            null)}
            {open?(
              <TouchableOpacity onPress={cerrarMenus.bind(this)} activeOpacity={1} style={[{zIndex:5,height:props.height,position:'absolute'},openInterior?{width:(props.width-200-300),left:480}:{width:(props.width-200),left:200}]}>
              </TouchableOpacity>
              ):(
              null
            )}
      </View>
    );
    
}
const mapDispatchToProps = {

  setPedidoImprimir,
  setTiendas,
  setConexion,
  setPedidos,
  setTiendasCarrito,
  setPedidosPerdidos,
  setSocketConectado
};
const mapStateToProps = (state:any) => {
  return {
   conexion: state.dimensions.conexion,
   socketConectado: state.pedidos.socketConectado,
   pedidosPerdidos:state.pedidos.pedidosPerdidos,
   height:state.dimensions.height,
   width:state.dimensions.width,
    tiendas: state.tiendas.tiendas,
    cargandoFiltros:state.config.cargandoFiltros
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(CabeceraVertical);

const styles = createStyles({
  containerBtnSocket: {
    width:30,
    height:30,
    borderRadius:30,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center',
    marginTop:30,
    marginHorizontal:7.5

  },
  btnSocket:{
    width:22,
    height:22,
    borderRadius:22,
    backgroundColor:'#73E13F'
  },
  btnBuscador:{
    width:45,
    height:45,
    borderRadius:45,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center',
    marginTop:30
  },
  btnScanner:{
    width:47,
    height:47,
    borderRadius:45,
    borderColor:'white',
    borderWidth:1,
    justifyContent:'center',
    alignItems:'center',
    marginTop:30,
  },
  paddingBotones:{
    padding:5
  },
  styleBtnScaner:{
    display:'none'
  },
  inputBuscador: {
    borderRadius: 32,
    backgroundColor: "white",
    textAlign:'left',
    height:32,
    fontSize:16,
    outline:'none',
    paddingLeft:5
  },
});