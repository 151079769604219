import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";

const Tag = ({ name, onPress, selected }) => {
  const tagStyle = [
    styles.tagContainer,
    { backgroundColor: selected ? "#3AA597" : "white" },
  ];
  const textSTyle = [styles.text, { color: selected ? "white" : "#3AA597" }];
  return (
    <TouchableOpacity style={tagStyle} onPress={onPress}>
      <Text style={textSTyle}>{name}</Text>
    </TouchableOpacity>
  );
};

export default Tag;

const styles = StyleSheet.create({
  tagContainer: {
    height: 42,
    borderRadius: 24,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 8,
    borderWidth: 2,
    borderColor: "#3AA597",
  },
  text: {
    fontSize: 20,
    paddingHorizontal: 18,
  },
});
