import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

const Ingredient = ({ selected, name, onPress }) => {
  const buttonStyle = [
    styles.button,
    selected ? styles.selected : styles.unselected,
  ];

  const clickHandler = () => {
    onPress(name);
  };

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      <Text style={styles.text}>{name}</Text>
      <TouchableOpacity style={buttonStyle} onPress={clickHandler}>
        <MaterialCommunityIcons
          name={selected ? "check" : "plus"}
          size={selected ? 22 : 28}
          color={selected ? "white" : "#4AA81E"}
        />
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

export default Ingredient;

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: 60,
  },
  text: {
    fontSize: 24,
  },
  button: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
  },
  selected: {
    backgroundColor: "#009683",
  },
  unselected: {
    backgroundColor: "#D2F3C2",
  },
});
