import React, { useState, useEffect, useRef } from 'react';
import {Text,ScrollView,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';

import {CheckBox} from "react-native-elements";
import { StatusBar } from 'expo-status-bar';
import {addProducto} from "../../application/reducers";
import { connect } from "react-redux";
import Producto from "../../application/objetos/Producto";
import { Entypo } from '@expo/vector-icons'; 


const vistaProgramacionTiendas = (props:any) => {
 
  function goBack(){
    props.navigation.goBack()
  }
  function abrirVistaSeleccionProgramacion(tienda:any){
    let aux={
      tienda:tienda
    }
    props.navigation.navigate('vistaSeleccionProgramacion',aux)
  }
 
  useEffect(() => {
    
  }, []);
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(props.height-80),marginTop:80,width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{maxWidth:400,width:'80%',height:'90%',borderRadius:8,backgroundColor:'white'}}>
        <ScrollView 
          contentContainerStyle={{paddingBottom:90}}
          showsVerticalScrollIndicator={false}
        >
        { props.tiendas.length>0 ? (
            <View style={{padding:20,width:'100%'}}>
              {
                props.tiendas.map(function(tienda:any) {
                  console.log("tineda",tienda)
                  if(tienda.productos.length>0){
                    return(
                      <TouchableOpacity onPress={abrirVistaSeleccionProgramacion.bind(this,tienda)} style={{padding:8,alignItems:'center',borderRadius:8,backgroundColor:'#E1E1E1',flexDirection:'row',justifyContent:'space-between',marginBottom:20}}>
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                          <Entypo name="shop" size={24} color="black" />
                          <Text style={{paddingLeft:8}}>{tienda.name}</Text>
                        </View>
                        <Text>{tienda.horaProgramada || 'Lo antes posible'}</Text>
                      </TouchableOpacity>
                    )
                   }
                })
              }
              
            </View>
          ) : (null)
        }
       
       
          </ScrollView>
          <TouchableOpacity onPress={goBack} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
            <View style={{backgroundColor:'#3AA597',borderRadius:50}}>
              <Text style={{padding:10,fontWeight:'bold',color:'white',paddingLeft:20,paddingRight:20}}>Aceptar</Text>
            </View>
          </TouchableOpacity>
      </TouchableOpacity>
    </View>
    </TouchableOpacity>
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height
  };
};
export default connect(mapStateToProps)(vistaProgramacionTiendas);
