const removeOption = ({ option, currentGroup, setSelectedVariants }) => {
  const auxGroup = currentGroup;
  let optIndex = -1;
  auxGroup.opciones.map((opt, index) => {
    if (opt.gid === option.gid) {
      if (opt.cantidad === 1) {
        optIndex = index;
      }
      if (opt.cantidad > 1) {
        opt.cantidad--;
      }
    }
  });
  if (optIndex !== -1) {
    auxGroup.opciones.splice(optIndex, 1);
  }

  setSelectedVariants((prevVariants) => {
    const updatedVariants = prevVariants.filter(
      (group) => group.gid !== currentGroup.gid
    );
    return [...updatedVariants, auxGroup];
  });
};
export default removeOption;
