import getQuantityByGroup from "./getQuantityByGroup.ts";

const addOption = ({
  max_select,
  option,
  currentGroup,
  setSelectedVariants,
}) => {
  const quantity = getQuantityByGroup(currentGroup);

  if (quantity < max_select) {
    const auxGroup = currentGroup;
    auxGroup.opciones.map((opt) => {
      if (opt.gid === option.gid) {
        opt.cantidad++;
      }
    });
    setSelectedVariants((prevVariants) => {
      const updatedVariants = prevVariants.filter(
        (group) => group.gid !== currentGroup.gid
      );
      return [...updatedVariants, auxGroup];
    });
  }
};
export default addOption;
