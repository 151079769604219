import React, { useState, useEffect, useRef } from 'react';
import {Text,TextInput,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import { FontAwesome,AntDesign } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
//import {loadAsync} from 'expo-font';

const enviarMensaje = (props:any) => {
  var pedido=props.route.params.pedido
  const [text, setText] = useState('');
  function goBack(){
    props.navigation.goBack()
  }
  function updateText(text:any){
    setText(text)
  }
  return (
    <View style={{backgroundColor:'transparent',flex:1,alignItems:'center',justifyContent:'center'}}>
      <View style={{width:'60%',height:'80%',borderRadius:8,backgroundColor:'white',marginLeft:20,marginRight:20}}>
        <View style={{position:'absolute',top:20,width:'100%',flex:1,flexDirection:'row',justifyContent:'space-between',zIndex:2}}>
          <Text style={{color:'#3AA597',fontSize:32,marginLeft:20}}>{pedido.order_id}</Text>
         
          <TouchableOpacity style={{marginRight:20}} onPress={goBack}>
            <AntDesign name="close" size={32} color="#3AA597" />
          </TouchableOpacity>

        </View>
        <View style={{paddingTop:60,height:'100%',width:'100%',justifyContent:'center',alignItems:'center'}}>
          <TextInput
            placeholder="Escribe tu mensaje..."
            onChangeText={text => updateText(text)}
            style={[styles.inputForm,{backgroundColor:"#DCDCDC"}]}
          />

          <TouchableOpacity activeOpacity={1} style={styles.btnLogin}>
              <Text style={styles.textoBtn}>Enviar Mensaje</Text>       
          </TouchableOpacity>
        </View>
        
      </View>
    </View>
  );
};

export default (enviarMensaje);
