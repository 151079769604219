import { SET_WIDTH,SET_HEIGHT,SET_CONEXION } from "./dimensionsTypes";
import {Dimensions} from 'react-native';

const initialState = {
  width: Dimensions.get('screen').width,
  height: Dimensions.get('screen').height,
  conexion: true
};
const dimensionsReducers = (state = initialState, action:any) => {
  switch (action.type) {
    case SET_WIDTH: {
      state.width =  action.payload.width
      return {...state}
    }
    case SET_HEIGHT: {
      state.height =  action.payload.height
      return {...state }
    }
    case SET_CONEXION: {
      state.conexion =  action.payload.conexion
      return {...state }
    }
    default:
      return state;
  }
};

export default dimensionsReducers;