import React, { memo } from "react";
import { StyleSheet, FlatList, View } from "react-native";

// Components
import Divider from "../../../../components/Divider";

const CustomList = ({
  data,
  renderItem,
  onScroll,
  listRef,
  footerComp,
  hasPadding = false,
  ...props
}) => {
  return (
    <FlatList
      data={data}
      keyExtractor={(item) => item.gid}
      renderItem={renderItem}
      style={styles.listStyle}
      ref={listRef}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      {...props}
      ListFooterComponent={
        <View style={{ flexDirection: "column" }}>
          {footerComp}
          {hasPadding && <Divider height={100} />}
        </View>
      }
    />
  );
};

export default memo(CustomList);

const styles = StyleSheet.create({
  listStyle: {
    width: "100%",
    height: "100%",
  },
});
