import React from "react";
import { StyleSheet, Text, View } from "react-native";

const FocusedOrder = ({ order }) => {
  if(!order){
    return <View></View>
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{order}</Text>
    </View>
  );
};

export default FocusedOrder;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: 800,
    height: 450,
    borderRadius: 40,
    backgroundColor: "#3AA597",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: [{ translateX: -400 }, { translateY: -225 }],
  },
  text: {
    fontSize: 180,
    fontWeight: "bold",
    color: "white",
  },
});
