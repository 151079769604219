import React from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { connect } from "react-redux";

// Components
import Divider from "../../../components/Divider";
import ProductItem from "./ProductItem";

// Reducers
import { addProducto, eliminarProducto } from "../../../../../reducers";

const OrderList = ({ tiendas, addProducto, eliminarProducto }) => {
  function addProduct(tienda: any, producto: any) {
    addProducto(tienda, producto);
  }

  function removeProduct(tienda: any, producto: any) {
    eliminarProducto(tienda, producto, false);
  }

  return (
    <ScrollView
      contentContainerStyle={styles.scroll}
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.shop}>
        {tiendas.map(function (tienda: any) {
          if (tienda.productos.length > 0) {
            return (
              <View style={{ marginLeft: 10, marginRight: 10 }}>
                {tienda.productos.map((item: any) => (
                  <>
                    <ProductItem
                      product={item}
                      onDecrease={() => removeProduct(tienda, item)}
                      onAdd={() => addProduct(tienda, item)}
                    />
                    <Divider height={20} />
                  </>
                ))}
              </View>
            );
          }
        })}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scroll: {
    width: "100%",
    flex:1,
  },
  shop: {
    width: "100%",
    justifyContent: "flex-start",
    height: "100%",
  },
});

const mapStateToProps = (state) => {
  return { tiendas: state.carrito.tiendas };
};

const mapDispatchToProps = {
  eliminarProducto,
  addProducto,
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
