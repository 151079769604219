
export default class CarritoDatafono{
  transactionID=''
  carrito={}
  sinc=false
  estado='PENDING'
  constructor(obj:any) {
    let t =this
    t.transactionID=obj.transactionID || ''
    t.carrito=obj.carrito || {}
    t.sinc=obj.sinc || false
    t.estado=obj.estado || 'PENDING'
  }
 
  async guardarPedidoBBDDDatafono(){
      let t = this
      await global.db.transaction((tx:any) => {
         let queryCreate = "CREATE TABLE IF NOT EXISTS local_orders("+
             "transactionID TEXT,"+
             "carrito TEXT,"+
             "sinc TEXT,"+
             "estado TEXT"+
           ");";
         tx.executeSql(
           queryCreate
         );
      },function(err:any){console.log("err",err)});

      await global.db.transaction((tx:any) => {
        let queryInsert ="INSERT OR REPLACE INTO local_orders("+
             "transactionID,"+
             "carrito,"+
             "sinc,"+
             "estado"+
           ") VALUES ('"+
             t.transactionID+"','"+
             JSON.stringify(t.carrito)+"','"+
             "false','"+
             "PENDING"+
           "');"

         tx.executeSql(
          queryInsert
         );
      },function(err:any){console.log("err",err)});

   }
   async actualizarEstado(estado:any){
      let t = this
      t.estado=estado
      await global.db.transaction((tx:any) => {
        let queryUpdate= "UPDATE local_orders SET estado='"+estado+"' WHERE transactionID='"+t.transactionID+"';"

        tx.executeSql(
          queryUpdate
        );
      },function(err:any){console.log("err",err)});


  }
  async actualizarSync(sinc:any){
      let t = this
      t.sinc=sinc
      await global.db.transaction((tx:any) => {
        let queryUpdate= "UPDATE local_orders SET sinc='"+sinc+"' WHERE transactionID='"+t.transactionID+"';"

        tx.executeSql(
          queryUpdate
        );
      },function(err:any){console.log("err",err)});


  }
  async actualizarCarrito(){
      let t = this
      console.log("actualizarCarrito!!!!!!!!!!!!!!!!!!!",t.carrito)
      await global.db.transaction((tx:any) => {
        let queryUpdate= "UPDATE local_orders SET sinc='"+t.sinc+"', carrito='"+JSON.stringify(t.carrito)+"', estado='"+t.estado+"' WHERE transactionID='"+t.transactionID+"';"

        tx.executeSql(
          queryUpdate
        );
      },function(err:any){console.log("err",err)});


  }
 
  

} 