import React, { useState } from "react";
import { Text, View } from "react-native";

import * as Animatable from "react-native-animatable";

import { styles } from "../../assets/styles/styles";
import Logo from "../../assets/icons/logo-gopick";
import { StatusBar } from "expo-status-bar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Usuario from "../objetos/Usuario";
import { connect, useDispatch } from "react-redux";
import {
  addPedido,
  setPedidos,
  editarPedido,
  setNavigation,
} from "../../application/reducers";

const splashScreen = (props: any) => {
  let [animating, setAnimating] = useState(true);
  const dispatch = useDispatch();
  let estiloE = "fadeIn";
  let estiloS = "";
  let duracionE = 1000;
  let duracionS = 0;
  let delayI = 100;
  let delayT = 0;
  let [finishFirst, setFirst] = useState(false);

  async function cargarSiguienteScreen() {
    props.setNavigation(props.navigation);
    let emailUsuario = await AsyncStorage.getItem("@email_usuario");
    let passUsuario = await AsyncStorage.getItem("@pass_usuario");

    if (emailUsuario != "" && passUsuario != "") {
      global.usuario = new Usuario({});
      await global.usuario
        .login(emailUsuario, passUsuario)
        .then((res: any) => {
          props.navigation.reset({
            index: 0,
            routes: [{ name: "menu" }],
          });
          global.usuario.comprobarCarritosDatafono();
        })
        .catch((err: any) => {
          props.navigation.reset({
            index: 0,
            routes: [{ name: "login" }],
          });
        });
    } else {
      props.navigation.reset({
        index: 0,
        routes: [{ name: "login" }],
      });
    }
  }
  return (
    <View style={{ flex: 1 }}>
      <View
        style={[
          styles.image,
          { alignItems: "center", justifyContent: "center" },
        ]}
      >
        <Animatable.View
          animation={finishFirst ? estiloS : estiloE}
          duration={finishFirst ? duracionS : duracionE}
          delay={delayI}
          style={[
            finishFirst ? styles.imagenAnimada : {},
            { width: 300, height: 300 },
          ]}
          onAnimationEnd={() => {
            setTimeout(() => {
              cargarSiguienteScreen();
            }, 500);
          }}
        >
          <Logo width="300" height="300" />
        </Animatable.View>
        <Text style={{ fontSize: 12, color: "black" }}>
          {global.versionNumber}
        </Text>
      </View>

      <StatusBar style="light" animated={true} />
    </View>
  );
};
const mapDispatchToProps = {
  addPedido,
  setPedidos,
  editarPedido,
  setNavigation,
};
const mapStateToProps = (state: any) => {
  return {
    pedidos: state.pedidos.pedidos,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(splashScreen);

/*<ImageBackground imageStyle={{resizeMode:'contain'}} source={require('../../assets/splash.png')}  style={[styles.image,{alignItems:'center',justifyContent: 'center'}]}>*/
