async function getDatosTienda(
  idTienda: any,
  servicio: any,
  localizaciones: any
) {
  return new Promise(function (resolve, reject) {
    var url =
      global.endpoint +
      "management/shop/get/" +
      idTienda +
      "?service=" +
      (servicio || "pick_up");
    if (localizaciones != undefined) {
      if (localizaciones.length > 0) {
        let paramLocalizacion = "";
        paramLocalizacion =
          "&location=" + localizaciones[localizaciones.length - 1].select;
      }
    }

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.usuario.jwt,
      },
    })
      .then(async (response) => {
        const resData = await response.json();

        if (response.status === 200) {
          resolve(resData);
        } else {
          reject({
            status: response.status,
            data: resData,
            error: resData.message,
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default { getDatosTienda };
