const validate = (email: any) => {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email).toLowerCase());
};
const igualarArrays = (a: any, b: any) => {
  if (a != undefined && b != undefined) {
    return a.length === b.length && a.every((v: any, i: any) => v === b[i]);
  } else {
    return false;
  }
};
const agruparPedidosEstado = (pedidos: any) => {
  let pedidosAgrupados = {
    pedidosParaEntregar: [],
    pedidosEnviados: [],
    pedidosPreparar: [],
    pedidosCompletados: [],
  };
  pedidos.map(function (pedido: any, index: any) {
    switch (pedido.state) {
      case "await_pickup":
      case "await_shipment":
        pedidosAgrupados.pedidosParaEntregar.push(pedido);
        break;
      case "shipped":
        pedidosAgrupados.pedidosEnviados.push(pedido);
        break;
      case "waiting":
      case "processing":
        pedidosAgrupados.pedidosPreparar.push(pedido);
        break;
      case "completed":
        pedidosAgrupados.pedidosCompletados.push(pedido);
        break;
    }
  });
  return pedidosAgrupados;
};
const actualizarGroupVariants = (groups: any) => {
  let groupsVariants = [];
  groups.map(function (item: any) {
    let productGroupVariants = [];
    item.variants.map(function (variant: any) {
      productGroupVariants.push({
        productGroupVariantId: variant.gid,
        quantity: variant.quantity,
      });
    });
    groupsVariants.push({
      groupVariantId: item.id_group_variants,
      productGroupVariants: productGroupVariants,
    });
  });
  if (groupsVariants.length > 0) {
    return groupsVariants;
  } else {
    return undefined;
  }
};
const actualizarMenuLines = (menu_selection: any) => {
  let menuLines = [];
  menu_selection.map(function (item: any) {
    let ingredientsRemovedAux;
    if (item.ingredients_removed.length > 0) {
      ingredientsRemovedAux = item.ingredients_removed;
    }
    menuLines.push({
      productId: item.id_product,
      ingredientsRemoved: ingredientsRemovedAux,
      menuTypePlateId: item.id_menu_type_plate,
      groupVariants: actualizarGroupVariants(item.variants),
    });
  });

  if (menuLines.length > 0) {
    return menuLines;
  } else {
    return undefined;
  }
};
const actualizarOrders = (carritoAux: any) => {
  let ordersAux = [];

  carritoAux.orders.map(function (order: any, index: any) {
    let orderLineCommissionAux = { name: "Gastos de servicio", amount: 0 };
    if (order.order_lines_commission?.length > 0) {
      orderLineCommissionAux = order.order_lines_commission[0];
    }
    let orderLineServiceAux;
    if (order.order_lines_service?.length > 0) {
      orderLineServiceAux = order.order_lines_service[0];
    }
    let orderLineDiscountAux;
    if (order.order_lines_discount?.length > 0) {
      orderLineDiscountAux = order.order_lines_discount[0];
    }
    let statusId = 1;
    if (carritoAux.estado == "CANCELLED") {
      statusId = 15;
    }
    let comment;
    if (carritoAux.comment != "") {
      comment = carritoAux.comment;
    }
    let customerEmail;
    if (carritoAux.customer_email != "") {
      customerEmail = carritoAux.customer_email;
    }
    let amountLocal = "0";
    if (order.amountLocal !== undefined && order.amountLocal !== null) {
      amountLocal = Math.round(order.amountLocal.toFixed(2) * 100);
    }
    let paymentMethodId = 6;
    if (carritoAux.payment_method == "cash") {
      paymentMethodId = 5;
      statusId = undefined;
    }
    if (carritoAux.payment_method == "others") {
      paymentMethodId = 8;
      statusId = undefined;
    }
    ordersAux.push({
      createdAt: order.orderDate,
      comment: comment,
      customerEmail: customerEmail,
      statusId: statusId,
      localOrderId: order.localOrderId,
      shopId: order.id_shop,
      eventId: order.id_event,
      serviceId: 1,
      paymentMethodId: paymentMethodId,
      orderType: "ASAP",
      cashPaid: carritoAux.cashPaid
        ? Math.floor(parseFloat(carritoAux.cashPaid) * 100)
        : carritoAux.amountLocal,
      amount: amountLocal,
      orderLines: actualizarOrderLines(order.order_lines || []),

      scheduledAt: order.orderDate,
      orderLineCommission: orderLineCommissionAux,
      orderLineService: orderLineServiceAux,
      orderLineDiscount: orderLineDiscountAux,
      location: order.location,
    });
  });
  return ordersAux;
};
const actualizarOrderLines = (orderLines: any) => {
  let orderLinesAux = [];
  orderLines.map(function (item: any, index: any) {
    let ingredientsRemovedAux;
    if (item.ingredients_removed.length > 0) {
      ingredientsRemovedAux = item.ingredients_removed;
    }
    orderLinesAux.push({
      pointSaleId: item.id_point_sale,
      productId: item.id_product,
      quantity: item.quantity,
      amount: item.amount,
      ingredientsRemoved: ingredientsRemovedAux,
      groupVariants: actualizarGroupVariants(item.groups),
      orderLinesMenu: actualizarMenuLines(item.menu_selection),
    });
  });
  return orderLinesAux;
};
function getFecha() {
  let fecha = new Date();
  let horas = "0" + fecha.getHours();
  let minutos = "0" + fecha.getMinutes();

  let dia = "0" + fecha.getDate();
  let mes = "0" + (fecha.getMonth() + 1);
  let year = fecha.getFullYear();
  return (
    horas.substr(-2) +
    ":" +
    minutos.substr(-2) +
    " " +
    dia.substr(-2) +
    "/" +
    mes.substr(-2) +
    "/" +
    year
  );
}
export default {
  validate,
  igualarArrays,
  agruparPedidosEstado,
  actualizarOrderLines,
  actualizarOrders,
  getFecha,
};
