import tiendaCalls from "../../../../../funcionalidades/tiendaCalls";
import * as Localization from "expo-localization";
import { fetchUrl } from "./fetchUrl";

const timezone = Localization.timezone;

const fetchPointSales = async (shopId) => {
  const url = `${global.endpoint}v2/point-sale?timezone=${timezone}&shopId=${shopId}&sortBy=asc&active=true&opened=true`;
  const response = await fetchUrl(url);

  return response;
};

export const fetchProducts = async ({ shopId, pointSales, language }) => {
  let productsArray = [];
  const response = { status: "fail", data: [], message: "" };

  let status = "success";
  for (const gid of pointSales) {
    const fetchResponse = await fetchUrl(
      `${global.endpoint}v2/product?onlyMenu=false&shopId=${shopId}&pointSaleId=${gid}&active=true&language=${language}`
    );
    if (fetchResponse.status === "success") {
      productsArray = [...productsArray, ...fetchResponse.data];
    } else {
      status = "fail";
      response.message = fetchResponse.error;
      break;
    }
  }
  if (status === "fail") {
    response.status = "success";
    response.data = [];
  } else if (productsArray.length === 0) {
    response.status = "success";
    response.data = [];
  } else {
    response.status = "success";
    response.data = productsArray;
  }

  return response;
};

const fetchShopData = async (gid, lang) => {
  const response = await fetchUrl(
    `${global.endpoint}v2/shop/${gid}?language=${lang}`
  );
  return response;
};

const getShopData = async (gid, lang = "ES") => {
  let finalResponse = {
    status: "error",
    error: "Error al cargar la tienda",
    data: null,
  };
  const language = lang.toUpperCase();
  const shopResponse = await fetchShopData(gid, language);

  if (shopResponse.status === "success") {
    const pointSalesResponse = await fetchPointSales(gid);
    if (pointSalesResponse.status === "success") {
      const pointSales = pointSalesResponse.data.map((ps) => ps.gid);
      if (pointSales.length > 0) {
        const productsResponse = await fetchProducts({
          shopId: gid,
          pointSales,
          language,
        });
        const { data, status, message } = productsResponse;
        if (status === "success") {
          if (data.length === 0) {
            finalResponse.error = `No hay productos disponibles.`;
          } else {
            const shop = {
              ...shopResponse.data,
              products: data,
              fk_event: shopResponse.data.eventId,
            };
            finalResponse.data = shop;
            finalResponse.status = "success";
            finalResponse.error = "";
          }
        } else {
          finalResponse.error = message;
        }
      } else {
        finalResponse.error = `La tienda ${shopResponse.data.name} no cuenta con productos disponibles en este momento`;
      }
    }
  } else {
    finalResponse.error = shopResponse.error;
  }

  return finalResponse;
};

export default getShopData;
