import { SET_TIENDAS,GET_TIENDA,ADD_TIENDA,DELETE_TIENDA,EDIT_TIENDA } from "./tiendasTypes";


export const getTiendas = () => {
  return (dispatch:any) => {
    dispatch({type: GET_TIENDA});
     
  };
};
export const addTienda = (posicion:any,tienda:any) => {
  return (dispatch:any) => {
    dispatch({
      type: ADD_TIENDA,
      payload: {
        posicion: posicion,
        tienda: tienda
      }
    });
  };
};
export const eliminarTienda = (id_tienda:any) => {
  return (dispatch:any) => {
    dispatch({
      type: DELETE_TIENDA,
      payload: {
        id: id_tienda
      }
    });
  };
};
export const editarTienda = (tienda:any) => {
  return (dispatch:any) => {
    dispatch({
      type: EDIT_TIENDA,
      payload: {
        tienda: tienda
      }
    });
  };
};
export const setTiendas = (tiendas:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_TIENDAS,
      payload: {
        tiendas: tiendas
      }
    });
  };
};