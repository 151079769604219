import React, { useState, useEffect, useRef } from 'react';
import {Text,ScrollView,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';

import {CheckBox} from "react-native-elements";
import { StatusBar } from 'expo-status-bar';
import {addProducto} from "../../application/reducers";
import { connect } from "react-redux";
import Producto from "../../application/objetos/Producto";
import { AntDesign } from '@expo/vector-icons'; 
import { setTiendasCarrito } from "../../application/reducers";
//import {loadAsync} from 'expo-font';

const vistaSeleccionProgramacion = (props:any) => {
  let tienda=props.route.params.tienda
  const [horaSel, setHoraSel] = useState(tienda.horaProgramada || 'Lo antes posible');
  function goBack(){
    props.navigation.goBack()
  }
  useEffect(() => {
    
  }, []);
  function cambiarHoraSel(hora:any){
    if(hora.active!=0){
      setHoraSel(hora.time)
    }
    
  }
  function guardarProgramacion(){
    tienda.horaProgramada=horaSel
    /*props.tiendas.map(function(tiendaAux:any) {
      if(tiendaAux.id==tienda.id){
        tiendaAux=tienda
      }
    })*/
    console.log("he guardado la hora",horaSel)
    props.setTiendasCarrito(props.tiendas)
    goBack()
  }
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(props.height-80),marginTop:80,width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{maxWidth:400,width:'80%',height:'90%',borderRadius:8,backgroundColor:'#E1E1E1'}}>
        <TouchableOpacity  onPress={goBack} style={{position:'absolute',top:20,right:20,zIndex:2}}>
          <AntDesign name="close" size={32} color="#3AA597" />
        </TouchableOpacity>
        <ScrollView 
          contentContainerStyle={{paddingBottom:90,paddingTop:52,justifyContent:'center',alignItems:'center'}}
          showsVerticalScrollIndicator={false}

        >
        { tienda.rangosHorarios!=undefined ? (
            <View
             style={{padding:20,width:'100%',justifyContent:'center',alignItems:'center'}}
            >
              {
                tienda.rangosHorarios.map(function(rangoHorario:any) {
                    return(
                      <ScrollView 
                        contentContainerStyle={{flexDirection:'row',alignItems:'center',padding:5,paddingBottom:20,maxWidth:400,width:'80%'}}
                        horizontal={true}
                      >
                          {
                            rangoHorario.map(function(hora:any) {
                                return(
                                  <TouchableOpacity style={hora.active!=0?{}:{opacity:0.5}}>
                                    <TouchableOpacity onPress={cambiarHoraSel.bind(this,hora)} style={[{margin:5,borderRadius:8,padding:8},hora.time==horaSel?{backgroundColor:'#3AA597'}:{backgroundColor:'white'}]}>
                                        <Text style={[hora.time==horaSel?{color:'white'}:{color:'#3AA597'}]}>{hora.time}</Text>
                                    </TouchableOpacity>
                                  </TouchableOpacity>
                                )
                               
                            })
                          }
                      </ScrollView>
                    )
                   
                })
              }
              
            </View>
          ) : (null)
        }
       
       
          </ScrollView>
          <TouchableOpacity onPress={guardarProgramacion} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
            <View style={{backgroundColor:'#3AA597',borderRadius:50}}>
              <Text style={{padding:10,fontWeight:'bold',color:'white',paddingLeft:20,paddingRight:20}}>Aceptar</Text>
            </View>
          </TouchableOpacity>

      </TouchableOpacity>
    </View>
    </TouchableOpacity>
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height
  };
};
const mapDispatchToProps = {
  setTiendasCarrito
}
export default connect(mapStateToProps,mapDispatchToProps)(vistaSeleccionProgramacion);
