import React, { useState, useEffect, useRef } from 'react';
import { FlatList,ScrollView,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';
import {Octicons,MaterialIcons,FontAwesome  } from '@expo/vector-icons';

import { connect } from "react-redux";

import ProductoDetalles from '../components/ProductoDetalles';
import ProductoResumenCamarero from '../components/ProductoResumenCamarero';
const VistaDetallesPedido = (props:any) => {
  let pedido
  if(props.route!=undefined){
    pedido=props.route.params.pedido
  }else{
    pedido=props.pedido
  }
  useEffect(() => {
   
  }, []);
   function getHoraToTimestamp(timestamp:any){
    let fecha = new Date(timestamp*1000)
    let horas  = "0" + fecha.getHours();
    let minutos = "0" + fecha.getMinutes();
    return horas.substr(-2)+':'+minutos.substr(-2)
  }
  return (
    
        <View style={[{height:props.height-140,borderColor:'#BABABA',borderWidth:1,backgroundColor:'white',width:'100%',alignItems:'center',maxWidth:400,justifyContent:'center'},global.isDatafono?{height:props.height-210}:{}]}>
            
            <View style={{height:'100%',width:'100%',maxWidth:400}}>
              <View style={{height:'100%',flex:1,width:'100%'}}>
                    <ScrollView style={{flex:1,width:'100%',backgroundColor:'white',padding:20}}>
                      <View style={{flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                        <View style={{flexDirection:'row',marginBottom:20}}>
                          <Text style={{marginRight:10,fontWeight:'bold',color:'#3AA597'}}>
                            #{pedido.order_number}
                          </Text>
                          <Text>
                            {getHoraToTimestamp(pedido.order_date)}
                          </Text>
                        </View>
                        <Text>
                          {pedido.calcularPrecioPedido()}€
                        </Text>
                      </View>
                      <View style={{flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                        <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>{pedido.shop.name || ''}</Text>
                        {pedido.payment_method=='dataphone'?
                          (
                          <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>
                            Tarjeta
                          </Text>)
                          :
                          (<Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>
                            Efectivo
                          </Text>)
                        }
                      </View>
                    
                      {pedido.location!='' ? (

                            <View style={{alignItems:'flex-start',justifyContent:'flex-start'}}>
                                <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>Localización: {pedido.location}</Text>
                            </View>
                        ) : null}
                      {
                        pedido.comment!=undefined && pedido.comment!=''?(
                            <View style={{justifyContent:'flex-start',alignItems:'flex-start',}}>
                             <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>Comentario: {pedido.comment}</Text>
                            </View>
                        ):(null)
                    }
                    <View style={{marginTop:20,marginBottom:20,height:1,backgroundColor:'black',width:'100%'}}>
                    </View>
                    <View>
                      {
                        pedido.productos.map(function(item:any) {
                          return (
                             <ProductoResumenCamarero disabled={true} lineaPedido={item}></ProductoResumenCamarero>
                          )
                        })
                      }
                      {
                        pedido.order_lines_ticket.map(function(item:any,index:any) {

                          if(item.amount>0){
                            console.log("item",item)
                            return(
                              <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                  <View style={{flex:1,flexDirection:'row'}}>
                                    <Text style={{fontSize:16}}>{item.name}</Text>
                                  </View>
                                    <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                </View>
                              </View>
                            )
                          }
                        })
                      }
                      {
                        pedido.order_lines_commission.map(function(item:any,index:any) {

                          if(item.amount>0){
                            console.log("item",item)
                            return(
                              <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                  <View style={{flex:1,flexDirection:'row'}}>
                                    <Text style={{fontSize:16}}>{item.name}</Text>
                                  </View>
                                    <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                </View>
                              </View>
                            )
                          }
                        })
                      }
                      {
                        pedido.order_lines_service.map(function(item:any,index:any) {
                          
                          if(item.amount>0){
                            console.log("item",item)
                            return(
                              <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                  <View style={{flex:1,flexDirection:'row'}}>
                                    <Text style={{fontSize:16}}>{item.name}</Text>
                                  </View>
                                    <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                </View>
                              </View>
                            )
                          }
                        })
                      }
                      {
                        pedido.order_lines_discount.map(function(item:any,index:any) {
                          
                           if(item.amount!=undefined && item.amount!=null){
                            if(item.amount<0){
                              return(
                                <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                  <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <View style={{flex:1,flexDirection:'row'}}>
                                      <Text style={{fontSize:16}}>{item.name}</Text>
                                    </View>
                                      <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                  </View>
                                </View>
                              )
                            }
                          }
                        })
                      }

                    </View>
                  </ScrollView>
                </View>
              
              <View style={{alignItems:'center'}}>
                <TouchableOpacity onPress={props.validarPedido} style={{backgroundColor:'#3AA597',width:'80%',marginTop:20,marginBottom:20,borderRadius:50}}>
                   <Text style={{fontWeight:'bold',color:'white',textAlign:'center',padding:10}}>Validar</Text>
                </TouchableOpacity>
              </View>
            </View>
            
        </View>
  );
};
const mapStateToProps = (state:any) => {
  return {
    height: state.dimensions.height
  };
};

export default connect(mapStateToProps)(VistaDetallesPedido);
/*
<View style={{justifyContent:'space-between',flexDirection:'row',marginBottom:20,borderBottomWidth:1,borderBottomColor:'#bdbdbd'}}>
                <View style={{flex:1,borderRightWidth:1,borderRightColor:'#bdbdbd',paddingRight:10,alignItems:'flex-start',justifyContent:'center'}}>
                  <Text style={[{fontWeight:'bold',fontSize: 18,color:'#3AA597',paddingTop:4,paddingBottom:4}]}>#{pedido.order_number}</Text>
                  <Text style={[{fontSize: 15,paddingTop:4,paddingBottom:4}]}>{pedido.hora}</Text>     
                </View>
                <View style={{flex:1,paddingLeft:10,paddingRight:10,alignItems:'flex-end',justifyContent:'center'}}>
                  <Text style={[{fontWeight:'bold',fontSize: 15,paddingTop:4,paddingBottom:4}]}>{pedido.event.name}</Text>
                  <Text style={[{fontSize: 15,paddingTop:4,paddingBottom:4}]}>{pedido.shop.name}</Text>
                </View>
              </View>
              {
                pedido.productos.map(function(item:any) {
                  return (
                    <ProductoDetalles producto={item}></ProductoDetalles>
                  )
                })
              }
              {
                pedido.comment!=undefined && pedido.comment!=''?(
                  <View style={{paddingLeft:10,paddingRight:10,justifyContent:'center',flexDirection:'row'}}>
                    <Text style={{fontSize: 15}}>{pedido.comment}</Text>
                  </View>
                ):(null)
            }
*/