const radioType = ({ group, option, currentGroup, setSelectedVariants }) => {
  const { gid, name } = group;
  const auxOption = {
    valor: true,
    gid: option.gid,
    name: option.name,
    cantidad: 1,
    incremento: option?.increment,
  };

  if (currentGroup) {
    const auxGroup = {
      ...currentGroup,
      opciones: [auxOption],
    };

    setSelectedVariants((prevVariants) => {
      const updatedVariants = prevVariants.filter((group) => group.gid !== gid);
      return [...updatedVariants, auxGroup];
    });
  } else {
    const newGroup = {
      gid,
      name,
      opciones: [auxOption],
    };

    setSelectedVariants((prevVariants) => {
      return [...prevVariants, newGroup];
    });
  }
};

export default radioType;
