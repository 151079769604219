import React from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import Divider from "../../../../components/Divider";
import Variant from "./Variant";

// Methods
import removeOption from "./methods/removeOption";
import addOption from "./methods/addOption";
import checkType from "./methods/checkType";
import radioType from "./methods/radioType";
import getQuantityByGroup from "./methods/getQuantityByGroup.ts";

const GroupVariants = ({ data, selectedVariants, setSelectedVariants }) => {
  const { max_select, options, variants, name, allow_quantity, type, gid } =
    data;
  const maxText = max_select > 0 && "(Máx. " + parseInt(max_select) + ")";

  const currentGroup = selectedVariants.find((group) => group.gid === gid);

  const addHandler = (option) => {
    addOption({
      max_select,
      option,
      currentGroup,
      setSelectedVariants,
    });
  };

  const removeHandler = (option) => {
    removeOption({
      option,
      currentGroup,
      setSelectedVariants,
    });
  };

  const variantHandler = (option) => {
    if (type === "radio") {
      radioType({
        group: data,
        option,
        currentGroup,
        setSelectedVariants,
      });
    } else {
      checkType({
        group: data,
        option,
        currentGroup,
        setSelectedVariants,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{name}</Text>
      <Text style={styles.subtitle}>{maxText}</Text>
      <Divider height={10} />
      <View style={styles.variantsWrapper}>
        {(variants || options)?.map((option) => {
          const selectedVariant = currentGroup?.opciones.find(
            (opt) => opt.gid === option.gid
          );
          const quantity = getQuantityByGroup(currentGroup);
          const moreAvailable =
            max_select === null || quantity < max_select ? true : false;
          return (
            <Variant
              type={data.type}
              data={option}
              quantity={selectedVariant?.cantidad}
              moreAvailable={moreAvailable}
              onPress={variantHandler}
              selected={selectedVariant ? true : false}
              allowQuantity={allow_quantity}
              addHandler={addHandler}
              removeHandler={removeHandler}
            />
          );
        })}
      </View>
    </View>
  );
};

export default GroupVariants;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  title: {
    fontSize: 30,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 18,
  },
  variantsWrapper: {
    width: "100%",
  },
});
