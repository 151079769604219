import React, {Component, useState, useEffect, useRef } from 'react'
import {View,Platform,Image,InputAccessoryView,ScrollView,Button, ImageBackground, Dimensions,Alert, Text, StyleSheet,TouchableOpacity,KeyboardAvoidingView,TextInput,TouchableWithoutFeedback,Keyboard} from "react-native";
//import {View,Image,InputAccessoryView,Button, ImageBackground, Dimensions, Alert, Text, StyleSheet,TouchableOpacity,KeyboardAvoidingView,TextInput,TouchableWithoutFeedback,Keyboard} from "react-native";
import globales from '../funcionalidades/globales';
//import SvgUri from "expo-svg-uri";
import Usuario from '../objetos/Usuario'
import { FontAwesome, AntDesign } from '../../node_modules/@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import Logo from "../../assets/icons/logo-gopick"
import Socket from '../objetos/Socket'
import { styles } from '../../assets/styles/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {request, PERMISSIONS, RESULTS} from 'react-native-permissions';
import { connect } from "react-redux";
import {addPedido,setPedidos,editarPedido } from "../../application/reducers";
import Pedido from '../objetos/Pedido'
const login = (props:any) => {
    
    const inputAccessoryViewID = 'uniqueID';

    const [loginDisabled, setLoginDisabled] = useState(false);

    const [ref_input1, setRef_input1] = useState('');
    const [ref_input2, setRef_input2] = useState('');

    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');


    const [inputStyle1, setInputStyle1] = useState(false);
    const [inputStyle2, setInputStyle2] = useState(false);

    const [labelStyle1, setLabelStyle1] = useState();
    const [labelStyle2, setLabelStyle2] = useState();



    const [ref_label1, setRef_label1] = useState(false);
    const [ref_label2, setRef_label2] = useState(false);


    const [inpSel, setInputSel] = useState('');

    const [flechaDesactivada, setFlechaDesactivada] = useState(false);
    const [flechaDesactivadaR, setFlechaDesactivadaR] = useState(false);
    
    var duracion = 300
    function nextInput(){
        switch(inpSel){
          case 'ref_input1':
            ref_input2._root.focus();
          break;
        }
    }

    function prevInput(){
        switch(inpSel){
          case 'ref_input2':
            ref_input1._root.focus();
          break;
         
        }
    }

    async function checkInput1(){
        
        if(!globales.validate(input1)){
            setInputStyle1(true);  
        }
    }
    async function checkInput2(){
        if(input2 == ''){
            setInputStyle2(true);  
        }
    }
    

    function subirLabel(ref:any){
    ref.animate({ 
      0: {  fontSize: 16,
            top:20
      }, 
      1: {  fontSize: 12,
            top:4
      }
    });
  }
  function bajarLabel(ref:any){
    ref.animate({ 
      0: {  fontSize: 12,
            top:4
      }, 
      1: {  fontSize: 16,
            top:15
      }
    });
  }
  async function requestBluetoothPermission(){
    console.log("requestBluetoothPermission")
     try {
       console.log("RESULTS.GRANTED",RESULTS.GRANTED)
        let resPermiso1 = await request(PERMISSIONS.ANDROID.BLUETOOTH_CONNECT)
        console.log("resPermiso1",resPermiso1)
        let resPermiso2 = await request(PERMISSIONS.ANDROID.BLUETOOTH_SCAN)
        console.log("resPermiso2",resPermiso2)
        if(resPermiso1==RESULTS.GRANTED && resPermiso2==RESULTS.GRANTED){
          return true
        }else{
          return false
        }
     } catch (err) {
       console.warn(err);
       return false
     }
  }
 
  async function onLogin(){
    global.usuario = new Usuario({})
    global.usuario.login(input1,input2).then(async (res:any) => {
        let respuestaPermisos = true
        if(!global.isDatafono && Platform.OS!='web'){
          respuestaPermisos = await requestBluetoothPermission()
        }
        if(respuestaPermisos){
          AsyncStorage.setItem('@email_usuario',input1);
          AsyncStorage.setItem('@pass_usuario', input2);
          props.navigation.reset({
            index:0,
            routes:[{name:'menu'}]
          })
        }else{
          alert('Para acceder necesita aceptar los permisos');
        }
    }).catch((err:any) => {
        console.log("err",err)
        alert(err.message || 'El email o la contraseña son incorrectos');
    })
  }
  
    let estiloE = 'fadeIn';
    let estiloS = '';
    let duracionE = 1000;
    let duracionS = 0;
    let delayI = 100;
    let delayT = 0;
    let [finishFirst, setFirst] = useState(true);

    useEffect(() => {
      setTimeout(function(){
          setFirst(false)
          props.navigation.setOptions({
            headerShown:false,
        });
      }, 1000)
    }, []);
      useEffect(() => {
    if(global.socket!=undefined){
      if(global.socket.socket.conectado){
        global.socket.socket.off("orders:update:result")
        global.socket.socket.on("orders:update:result",(res:any)=>{
              console.log("result CambiarEstado pedido",res)
              let pedido = -1

              props.pedidos.map(function(item:any,index:number) {

                if(item.gid ==  res.data.gid){
                  pedido=item
                  pedido.state=res.data.status
                }
              })
              if(pedido!=-1){
                props.editarPedido(pedido)
                pedido.cargarEstado()
                console.log("pedido",pedido)
              }
              
        })
      }
    }
  }, [props.pedidos]);
    return(

      
               <View style={[styles.mainBody]}>
                <TouchableWithoutFeedback>
                

                      <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>

                       <Animatable.View 
                        animation={finishFirst ? estiloS : estiloE}
                        duration={duracion}
                        style={[styles.containerHead,finishFirst ? styles.imagenAnimada : {}]}> 
                          
                          <Logo width="150" height="150"/>
                       </Animatable.View>
                       <Animatable.View
                        animation={finishFirst ? estiloS : estiloE}
                        duration={duracion}
                       
                        style={[{width: '100%',justifyContent:'center',alignItems:'center'},finishFirst ? styles.imagenAnimada : {}]}>
                              <View style={{width:400}}>
                                  <View style={{marginLeft: 0,borderBottomWidth: 0,alignItems:'center'}}>
                                   
                                      <TextInput
                                        style={[
                                            styles.inputFormLogin,
                                            inputStyle1 ? styles.inputFormBad : {} 
                                        ]}
                                        blurOnSubmit={true}
                                        ref={(c:any) => setRef_input1(c)}
                                        returnKeyType="next"
                                        //placeholderTextColor="#858585"
                                        contextMenuHidden={true}
                                        enablesReturnKeyAutomatically={true}
                                        spellCheck={true}
                                        numberOfLines = {1}
                                        keyboardType="email-address"
                                        autoCorrect={true}
                                        onChangeText={ text => {
                                            setInput1(text.trim());
                                            setInputStyle1(false);
                                        }}
                                        inputAccessoryViewID={inputAccessoryViewID}
                                        placeholder="Email"
                                       
                                      />
                                    
                                  
                                      
                                  </View>
                                  <View style={{marginLeft: 0,borderBottomWidth: 0,alignItems:'center'}}>
                                      <TextInput
                                        style={[
                                            styles.inputFormLogin,
                                            inputStyle2 ? styles.inputFormBad : {} 
                                        ]}
                                        ref={(c:any) => setRef_input2(c)}
                                        blurOnSubmit={true}
                                        //multiline={(Platform.OS === 'ios' ? false : true)}
                                        contextMenuHidden={true}
                                        spellCheck={false}
                                        numberOfLines = {1}
                                        keyboardType="default"
                                        secureTextEntry={true}
                                        textContentType="password"
                                        autoCorrect={false}

                                        onChangeText={ text => {
                                            setInput2(text.trim());
                                            setInputStyle2(false);
                                        }}
                                        inputAccessoryViewID={inputAccessoryViewID}
                                        placeholder="Contraseña"
                                      />
                          
                                      
                                  </View>
                              </View>
                              <View style={{width: 400,alignItems: 'center'}}>
                                  <TouchableOpacity activeOpacity={1} style={styles.btnLogin} onPress={onLogin} disabled={loginDisabled}>
                                      <Text style={styles.textoBtn}>Acceder</Text>       
                                  </TouchableOpacity>
                              </View>
                             
                              


                           </Animatable.View>
                         
                         
         
                        

                
                </View>

              </TouchableWithoutFeedback>




            { Platform.OS === 'ios' ? (
                <InputAccessoryView style={styles.barraAccesorios} backgroundColor='#fffffff7' nativeID={inputAccessoryViewID}>
                  <View style={styles.barraAccesoriosFlechas}>
                    <TouchableOpacity  onPress={prevInput}>
                        <FontAwesome style={[styles.arrowLeft,flechaDesactivada ? {opacity:0.6} : {}]} size={40} name="angle-up" color="#007aff" />
                    </TouchableOpacity>
                    <TouchableOpacity  onPress={nextInput}>
                        <FontAwesome style={[styles.arrowRight,flechaDesactivadaR ? {opacity:0.6} : {}]} size={40} name="angle-down" color="#007aff" />
                    </TouchableOpacity>
                  </View>
                  <View style={styles.barraAccesoriosBoton}>
                    <Button
                      onPress={Keyboard.dismiss}
                      title="Hecho"
                    />
                  </View>
                  
                </InputAccessoryView>
              ) : null}
           
            

        </View>

        
        
  );
  
}
const mapDispatchToProps = {
  addPedido,
  setPedidos,
  editarPedido
};
const mapStateToProps = (state:any) => {
  return {
  
    pedidos: state.pedidos.pedidos
  };
};
export default connect(mapStateToProps,mapDispatchToProps )(login);


/*
<View style={{marginLeft: 0,borderBottomWidth: 0,alignItems:'center'}}>
                                   
                                      <TextInput
                                        style={[
                                            styles.inputForm,
                                            inputStyle1 ? styles.inputFormBad : {} 
                                        ]}
                                        blurOnSubmit={true}
                                        ref={(c:any) => setRef_input1(c)}
                                        returnKeyType="next"
                                        //placeholderTextColor="#858585"
                                        contextMenuHidden={true}
                                        enablesReturnKeyAutomatically={true}
                                        spellCheck={true}
                                        numberOfLines = {1}
                                        keyboardType="email-address"
                                        autoCorrect={true}
                                        onChangeText={ text => {
                                            setInput1(text);
                                            setInputStyle1(false);
                                        }}
                                        inputAccessoryViewID={inputAccessoryViewID}
                                        
                                        onFocus={ () => {
                                          setFlechaDesactivada(true)
                                          setInputSel('ref_input1')
                                          if(input1==''){
                                            subirLabel(ref_label1)
                                          }
                                        }}
                                        onBlur={ () => {
                                          setFlechaDesactivada(false)
                                          //setInputSel(null)
                                          //checkInput1();
                                          if(input1==''){
                                            bajarLabel(ref_label1)
                                          }
                                        }}
                                      />
                                    
                                  
                                      <View pointerEvents='none' style={styles.labelStyle}>
                                          <Animatable.Text
                                              duration={400}
                                              ref={ (c:any) => setRef_label1(c)}
                                              style={styles.labelStyle}
                                              >Email
                                          </Animatable.Text>
                                      </View>
                                  </View>
*/