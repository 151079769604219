import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../../../components/MainButton";
import CustomInput from "../../../components/CustomInput";
import ModalBase from "../../../components/ModalBase";
import Divider from "../../../components/Divider";
import ConfirmModal from "./ConfirmModal";
import CloseButton from "../components/CloseButton";

const RoomNumberModal = ({
  visible,
  setModal,
  onFinish,
  roomNumber,
  setRoomNumber,
}) => {
  const [error, setError] = useState(null);
  const [modal2, setModal2] = useState<String>();
  const { t } = useTranslation();

  const buttonHandler = () => {
    if (roomNumber) {
      setModal2("Confirm");
    } else {
      setError(true);
    }
  };

  const finishHandler = () => {
    setRoomNumber();
    setModal2(null);
    onFinish();
  };

  useEffect(() => {
    setError(null);
  }, [roomNumber]);

  const roomNumberSetter = (text) => {
    setRoomNumber(text.replace(/[^0-9]/g, ""));
  };
  return (
    <ModalBase
      canClose={false}
      modalVisible={visible}
      handleModal={() => setModal()}
      paddingBottom={200}
      width={500}
    >
      <View style={styles.inputWrapper}>
        <CloseButton onPress={() => setModal()} />
        <Image
          style={styles.image}
          source={require("../../../../../assets/images/door.png")}
        />
        <Divider height={20} />
        <Text style={styles.text}>{t("roomNumber.text")}</Text>
        <Divider height={20} />
        <View style={styles.inputContainer}>
          <CustomInput
            multiline
            value={roomNumber}
            onChange={roomNumberSetter}
            placeholder={t("roomNumber.placeholder")}
          />
        </View>
        <Divider height={20} />
        {error && (
          <>
            <Text style={styles.error}>{t("roomNumber.error")}</Text>
            <Divider height={20} />
          </>
        )}
        <View style={styles.buttonWrapper}>
          <MainButton title={t("finish")} onPress={buttonHandler} />
        </View>
      </View>
      <ConfirmModal
        visible={modal2 === "Confirm"}
        setModal={setModal2}
        onFinish={finishHandler}
        roomNumber={roomNumber}
      />
    </ModalBase>
  );
};

export default RoomNumberModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: 460,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    maxWidth: 200,
    width: "100%",
  },
  image: {
    width: "100%",
    resizeMode: "contain",
    height: 70,
  },
  inputContainer: {
    width: 190,
    height: 45,
  },
  text: {
    fontSize: 25,
    color: "black",
    textAlign: "center",
  },
  error: {
    fontSize: 24,
    color: "red",
    textAlign: "center",
  },
});
