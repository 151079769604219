import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, TouchableOpacity } from "react-native";

const ActionButton = ({
  selected,
  type,
  allowQuantity,
  moreAvailable,
  onAdd,
}) => {
  if (selected && !allowQuantity) {
    return (
      <TouchableOpacity
        style={[styles.button, styles.selected]}
        onPress={onAdd}
      >
        <MaterialCommunityIcons name="check" size={22} color="white" />
      </TouchableOpacity>
    );
  }
  if (
    (!selected && allowQuantity && !moreAvailable) ||
    (!selected && !allowQuantity ) && type !== "check"
  ) {
    return (
      <TouchableOpacity
        style={[styles.button, styles.unselected]}
        onPress={onAdd}
      >
        <MaterialCommunityIcons name="plus" size={28} color="#757575" />
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity style={styles.button} onPress={onAdd}>
      <MaterialCommunityIcons name="plus" size={28} color="#4AA81E" />
    </TouchableOpacity>
  );
};

export default ActionButton;

const styles = StyleSheet.create({
  button: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    backgroundColor: "#D2F3C2",
  },
  selected: {
    backgroundColor: "#009683",
  },
  unselected: {
    backgroundColor: "white",
  },
});
