import React, { useState, useEffect, useRef } from 'react';
import {DeviceEventEmitter,Platform, FlatList,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';
import { StatusBar } from 'expo-status-bar';
import {Octicons,MaterialIcons,FontAwesome  } from '@expo/vector-icons';
import {setFacturaProductos,setFacturaComision,setPedidoImprimir,setReferenciaTicket} from "../../application/reducers";
import ProductoResumenCamarero from '../components/ProductoResumenCamarero';
import PedidoCamarero from '../components/PedidoCamarero';
import Logo from "../../assets/icons/logo-gopick"
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  BluetoothManager,
  BluetoothEscposPrinter,
  BluetoothTscPrinter,
} from "react-native-bluetooth-escpos-printer";
//import * as MediaLibrary from "expo-media-library";

import { connect } from "react-redux";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { captureRef } from 'react-native-view-shot';
import myCrypto from '../funcionalidades/myCrypto';


const DetallesPedidoCamarero = (props:any) => {
  var ticketRef = useRef(null)
  let eliminarPedidosBBDD
  
 function abrirDevolucion(){
    if(props.pedidoSeleccionado!=false){
      let aux = {
        pedido:props.pedidoSeleccionado
      }
      console.log("navigation",props.navigation)
      props.navigation.navigate('devolucion',aux);
      
      
    }
    
  }
  function devolverPedido(){

    if(props.route!=undefined){
      props.route.params.eliminarPedidosBBDD([props.pedidoSeleccionado.note_id]);
      props.navigation.goBack()
    }else{
      props.eliminarPedidosBBDD([props.pedidoSeleccionado.note_id])
    }
  }
 useEffect(() => {
   
      if(props.route!=undefined){
        props.navigation.setOptions({
            headerTitle:props.pedidoSeleccionado.order_id
        });
      }
      //pruebasBT()
      //cogerTicket()
    }, []);
 function toDataURL(url:any, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


 
 function imprimirTicketCliente(){
    if(props.pedidoSeleccionado!=false){
      /*toDataURL('https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl='+props.pedidoSeleccionado.id+'&chld=H|0', function(dataUrl:any) {

        let html=[
          '<div style="width:300px">',
          '<p style="font-size:8">'+props.pedidoSeleccionado.order_id+'</p>',
          '<p style="font-size:8">'+props.pedidoSeleccionado.date_delivery+'</p>',
          '<p style="font-size:8">'+props.pedidoSeleccionado.shop.name+'</p>',
          '<p style="font-size:8">'+props.pedidoSeleccionado.localizacion+'</p>',
          '<div style="border-top: black;border-top-style: dashed;border-top-width: 1px;"></div>',
          '<div style="display:flex;justify-content:space-between">',
          '<p style="font-size:8">Uds Producto</p>',
          '<p style="font-size:8">Importe</p>',
          '</div>',
          '<div style="border-top: black;border-top-style: dashed;border-top-width: 1px;"></div>',
          props.pedidoSeleccionado.getHtmlProductos(true),
          '<div style="border-top: black;border-top-style: dashed;border-top-width: 1px;"></div>',
          '<div style="display:flex;justify-content:space-between">',
          '<p style="font-size:8">TOTAL (Impuestos Inc.)</p>',
          '<p style="font-size:8">'+props.pedidoSeleccionado.calcularPrecioPedido()+'€</p>',
          '</div>',
          
          '<p style="font-size:8">Forma de pago: Efectivo</p>',
          '<div style="border-top: black;border-top-style: dashed;border-top-width: 1px;"></div>',
          '<div style="margin-top:10px;margin-bottom:10px;alignItems:center;display:flex;justify-content:center">',
          '<img crossorigin="*" src="'+dataUrl+'" width="100" height="100" />',
          '</div>',
          '<div style="border-top: black;border-top-style: dashed;border-top-width: 1px;"></div>',
          '<p style="font-size:8;text-align:center">GRACIAS POR SU COMPRA</p>',
          '</div>',
        ]
        //var div = document.createElement('div');
        //div.innerHTML = html.join('');

        
         var opt = {
            margin:       [10, 10, 10, 10],
            filename:     'document.pdf',
            image:        { type: 'png'},
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'mm', format: 'letter', orientation: 'portrait' }
        };
        
        //printToFile(html.join(''))
        
       
        //var worker = html2pdf().from(html.join('')).set(opt).save()
        //console.log("window",global)
      })*/
      //cogerTicket()
      // pruebasBT()
    }
  }
  function reImprimirFacturas(){
    global.usuario.obtenerFacturas(props.pedidoSeleccionado.gid).then(async function(res:any){
      console.log("resultado obtener facutras", res)
      if(res?.data?.data?.length>0){
        if(res?.data?.data?.length>0){
          for (var i=res?.data?.data?.length-1; i>=0; i--) {

              let item=res.data.data[i]
              let factura=item.data
              factura.ticket_bai_url=item.ticket_bai_url || ''
              if(i==0){
                props.setFacturaProductos(factura)
                setTimeout(async function(){
                  await myCrypto.imprimirAdyen(global.ticketRefFacturaProductos)
                },1000)
                
                
              }
              if(i==1){
                props.setFacturaComision(factura)
                setTimeout(async function(){
                  await myCrypto.imprimirAdyen(global.ticketRefFacturaComision)
                },1000)
              }


          }
        }
      }
      console.log("resobtenerFacturas",res)
    })
  }
  async function comprobarImpresora(){
    /*let address = await AsyncStorage.getItem('@address_impresora');
    if(address!=undefined && address!='' &&  address!=null){
      conectarBluetooth(address)
    }else{
      abrirListadoBluetooth()
    }*/
    //setTimeout(function(){
      props.setPedidoImprimir(props.pedidoSeleccionado)
      setTimeout(function(){
        

        if(global.isDatafono){
          reImprimirFacturas() 
        }else{
          global.imprimirTicket(false)
        }
      },500)
      
    //},1000)
  }
  function imprimirPedido(address:any){
    BluetoothManager.connect(address) 
      .then(
        async (s) => {
          const result = await captureRef(ticketRef, {
            result:'base64',
            quality: 1,
            format: 'png',
          });
          BluetoothEscposPrinter.setWidth(576)
          BluetoothEscposPrinter.printPic(result, {
            width: 576,
            left: 0,
          });
        },
        (e) => {
          abrirListadoBluetooth()
        }
    );
  }

  function conectarBluetooth(address:any){
    BluetoothManager.isBluetoothEnabled().then((enabled)=> {
        if(enabled){
          imprimirPedido(address)
        }else{
          BluetoothManager.enableBluetooth().then(
            (r) => {
             imprimirPedido(address) 
            },
            (err) => {
              alert('No se ha podido activar el bluetooth');
            }
          );
        }
    }, (err)=> {
        alert('Comprueba que el bluetooth está activado')
    });
  }
  function abrirListadoBluetooth(){
    // let aux={  todo remove comment
    //   imprimirPedido:imprimirPedido
    // }
    // props.navigation.navigate('listadoBluetooth',aux);
  }
  /*function pruebasBT(){
    DeviceEventEmitter.addListener(
      BluetoothManager.EVENT_DEVICE_ALREADY_PAIRED,
      (rsp) => {
        alert("EVENT_DEVICE_ALREADY_PAIRED"+JSON.stringify(rsp)) // rsp.devices would returns the paired devices array in JSON string.
      }
    );
    DeviceEventEmitter.addListener(BluetoothManager.EVENT_DEVICE_FOUND, (rsp) => {
      alert("EVENT_DEVICE_FOUND"+JSON.stringify(rsp)) // rsp.devices would returns the found device object in JSON string
    });
    
    BluetoothManager.isBluetoothEnabled().then((enabled)=> {
        if(enabled){
          BluetoothManager.connect('DC:0D:30:00:1F:55') // the device address scanned.
          .then(
            async (s) => {
              const result = await captureRef(ticketRef, {
                result:'base64',
                quality: 1,
                format: 'png',
              });
              BluetoothEscposPrinter.setWidth(576)
              BluetoothEscposPrinter.printPic(result, {
                width: 576,
                left: 0,
              });

            },
            (e) => {
              alert('err'+e);
            }
            );

        }
    }, (err)=> {
        alert(err)
    });
  }*/
  async function cogerTicket(){
    /*const result = await captureRef(ticketRef, {
      //result:'base64',
      quality: 1,
      format: 'png',
    });
    console.log("result",result)
    await MediaLibrary.createAssetAsync(result);*/
  }
  /*function pintarTicket(){
    return(
      <View collapsable={false} ref={ref => { ticketRef = ref }} style={{width:576,backgroundColor:'white',padding:10}}>
        <Logo width="200" height="100"/>
        <Text>
          #{props.pedidoSeleccionado.order_number}
        </Text>
        <Text>
          {props.pedidoSeleccionado.hora}
        </Text>
        <Text>
          {props.pedidoSeleccionado.shop.name}
        </Text>
        <View style={{marginBottom:10,marginTop:10,height:1,backgroundColor:'black',width:'100%'}}>
        </View>
        <View style={{flexDirection:'row',justifyContent:'space-between'}}>
          <Text>
            Uds producto
          </Text>
          <Text>
            Importe
          </Text>
        </View>
        <View style={{marginBottom:10,marginTop:10,height:1,backgroundColor:'black',width:'100%'}}>
        </View>
        <View>
          {
            props.pedidoSeleccionado.productos.map(function(item:any) {
              return (
                  <ProductoResumenCamarero disabled={true} producto={item}></ProductoResumenCamarero>

              )
            })
          }
          {
            props.pedidoSeleccionado.order_lines_commission.map(function(item:any,index:any) {

              if(item.amount>0){
                console.log("item",item)
                return(
                  <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                      <View style={{flex:1,flexDirection:'row'}}>
                        <Text style={{fontSize:16}}>{item.name}</Text>
                      </View>
                        <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                    </View>
                  </View>
                )
              }
            })
          }
          {
            props.pedidoSeleccionado.order_lines_service.map(function(item:any,index:any) {
              
              if(item.amount>0){
                console.log("item",item)
                return(
                  <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                      <View style={{flex:1,flexDirection:'row'}}>
                        <Text style={{fontSize:16}}>{item.name}</Text>
                      </View>
                        <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                    </View>
                  </View>
                )
              }
            })
          }
        </View>
        <View style={{marginBottom:10,marginTop:10,height:1,backgroundColor:'black',width:'100%'}}>
        </View>
        <View style={{flexDirection:'row',justifyContent:'space-between'}}>
          <Text>
            TOTAL (Impuestos Inc.)
          </Text>
          <Text>
            {props.pedidoSeleccionado.calcularPrecioPedido()+'€'}
          </Text>
        </View>
        <Text>
          Forma de pago: Efectivo
        </Text>
        <View style={{marginBottom:10,marginTop:10,height:1,backgroundColor:'black',width:'100%'}}>
        </View>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
            <Image
                style={{width: 200,height: 200,resizeMode:'cover'}}
                source={
                  {uri:'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl='+props.pedidoSeleccionado.gid+'&chld=H|0'}
                }
            />
        </View>
        <View style={{marginBottom:10,marginTop:10,height:1,backgroundColor:'black',width:'100%',justifyContent:'center',alignItems:'center'}}>
        </View>
        <Text style={{textAlign:'center'}}>
          GRACIAS POR SU COMPRA
        </Text>
        
      </View>
    )
  }*/
  /*const printToFile = async (html:any) => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    const { uri } = await Print.printToFileAsync({
      html
    });
    console.log('File has been saved to:', uri);
    //await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf' });
    const permission = await MediaLibrary.requestPermissionsAsync();
      if (permission.granted) {
        await MediaLibrary.createAssetAsync(uri);
      }
  }*/
  function getHoraToTimestamp(timestamp:any){
    let fecha = new Date(timestamp*1000)
    let horas  = "0" + fecha.getHours();
    let minutos = "0" + fecha.getMinutes();
    return horas.substr(-2)+':'+minutos.substr(-2)
  }
  return (
    
        <View style={{width:'100%',height:'100%'}}>
          
          <View style={{justifyContent:'center',flex:1,alignItems:'center'}}>
            {
              props.pedidoSeleccionado!=false ? 
                (
                  <View style={{flex:1,width:'100%'}}>
                    <View style={{height:'100%',width:'100%',backgroundColor:'white',padding:20}}>
                      <View style={{flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                        <View style={{flexDirection:'row',marginBottom:20}}>
                          <Text style={{marginRight:10,fontWeight:'bold',color:'#3AA597'}}>
                            #{props.pedidoSeleccionado.order_number}
                          </Text>
                          <Text>
                            {getHoraToTimestamp(props.pedidoSeleccionado.date)}
                          </Text>
                        </View>
                        <Text>
                          {props.pedidoSeleccionado.calcularPrecioPedido().toFixed(2)}€
                        </Text>
                      </View>
                      <View style={{flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                        <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>{props.pedidoSeleccionado.shop.name || ''}</Text>
                        {props.pedidoSeleccionado.payment_method=='dataphone'?
                          (
                          <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>
                            Tarjeta
                          </Text>)
                          :
                          (<Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>
                            Efectivo
                          </Text>)
                        }
                      </View>
                    
                      {props.pedidoSeleccionado.location!='' ? (

                            <View style={{alignItems:'flex-start',justifyContent:'flex-start'}}>
                                <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>Localización: {props.pedidoSeleccionado.location}</Text>
                            </View>
                        ) : null}
                      {
                        props.pedidoSeleccionado.comment!=undefined && props.pedidoSeleccionado.comment!=''?(
                            <View style={{justifyContent:'flex-start',alignItems:'flex-start',}}>
                             <Text style={{fontSize: 12,paddingTop:4,paddingBottom:4}}>Comentario: {props.pedidoSeleccionado.comment}</Text>
                            </View>
                        ):(null)
                    }
                    <View style={{marginTop:20,marginBottom:20,height:1,backgroundColor:'black',width:'100%'}}>
                    </View>
                    <View>
                      {
                        props.pedidoSeleccionado.productos.map(function(item:any) {
                          console.log("itreme",item)
                          if(!item.producto.oculto){
                            return (
                                <ProductoResumenCamarero disabled={true} lineaPedido={item}></ProductoResumenCamarero>
                            )
                          }
                        })
                      }
                      {
                        props.pedidoSeleccionado.order_lines_commission.map(function(item:any,index:any) {

                          if(item.amount>0){
                            console.log("item",item)
                            return(
                              <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                  <View style={{flex:1,flexDirection:'row'}}>
                                    <Text style={{fontSize:16}}>{item.name}</Text>
                                  </View>
                                    <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                </View>
                              </View>
                            )
                          }
                        })
                      }
                      {
                        props.pedidoSeleccionado.order_lines_service.map(function(item:any,index:any) {
                          
                          if(item.amount>0){
                            console.log("item",item)
                            return(
                              <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                  <View style={{flex:1,flexDirection:'row'}}>
                                    <Text style={{fontSize:16}}>{item.name}</Text>
                                  </View>
                                    <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                </View>
                              </View>
                            )
                          }
                        })
                      }
                      {
                        props.pedidoSeleccionado.order_lines_discount.map(function(item:any,index:any) {
                          
                           if(item.amount!=undefined && item.amount!=null){
                            if(item.amount<0){
                              return(
                                <View style={{marginBottom:1,backgroundColor:'white',padding:10}}>
                                  <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <View style={{flex:1,flexDirection:'row'}}>
                                      <Text style={{fontSize:16}}>{item.name}</Text>
                                    </View>
                                      <Text style={{fontSize:16}}>{(item.amount/100).toFixed(2)}€</Text>
                                  </View>
                                </View>
                              )
                            }
                          }
                        })
                      }

                    </View>
                  </View>
                </View>
                
                ) 

              : 
                (
                  <Text>Selecciona un pedido para ver sus detalles</Text>
                )
            }
            
            </View>
            {
              props.pedidoSeleccionado!=false ? 
                (
                  <View style={{backgroundColor:'white',position:'absolute',height:50,width:'100%',bottom:0}}>
                    <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                       <TouchableOpacity onPress={comprobarImpresora} style={{backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',flex:1}}>
                        <Text style={{color:'white'}}>
                          Imprimir ticket
                        </Text>
                      </TouchableOpacity>
                      <View style={{width:1,height:'100%',backgroundColor:'white'}}></View>
                      {!props.pedidoSeleccionado.sincronizado?(
                        <TouchableOpacity onPress={devolverPedido} style={{backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',flex:1}}>
                          <Text style={{color:'white'}}>
                            Devolver
                          </Text>
                        </TouchableOpacity>):(
                      null)}
                      
                    </View>
                  </View>
                ):(null)
              }
      </View>

        
  );
};
/*
<View style={{backgroundColor:'white',position:'absolute',height:50,width:'100%',bottom:0}}>
              <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                
                 <TouchableOpacity onPress={comprobarImpresora} style={{backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',flex:1}}>
                  <Text style={{color:'white'}}>
                    Imprimir ticket
                  </Text>
                </TouchableOpacity>

                
                <View style={{width:1,height:'100%',backgroundColor:'white'}}></View>
                <TouchableOpacity onPress={abrirDevolucion} style={{backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',flex:1}}>
                  <Text style={{color:'white'}}>
                    Devolver
                  </Text>
                </TouchableOpacity>
              </View>

            </View>*/
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    pedidoSeleccionado:state.pedidos.pedidoSeleccionado
  };
};
const mapDispatchToProps = {
  setPedidoImprimir,
  setReferenciaTicket,
  setFacturaProductos,
  setFacturaComision

};
export default connect(mapStateToProps,mapDispatchToProps)(DetallesPedidoCamarero);
