import React,{useEffect,useState} from 'react';

import {Dimensions,Image,StyleSheet,TouchableOpacity,Text,View} from "react-native";
import {Entypo} from '@expo/vector-icons'; 

import Swipeable from 'react-native-gesture-handler/Swipeable';
const ProductoResumen = (props:any) => {
    const {habilitarSwipe,deshabilitarSwipe,producto,addProducto,eliminarProducto,eliminarProductoCompleto} = props
    const [refSwipeable, setRefSwipeable] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    let renderRightActions = (progress:any, dragX:any) => {
      const trans = dragX.interpolate({
        inputRange: [0, 50, 100, 101],
        outputRange: [-20, 0, 0, 1],
      });

      return (
        <TouchableOpacity 
          onPress={borrarProducto}
          style={{backgroundColor:'#3AA597',justifyContent:'center',alignItems:'center'}}
        >
          <Text 
            style={[
              {color:'white',padding:10,fontSize:16},
              {
                transform: [{ translateX: 1 }],
              },
            ]}>
            Borrar
          </Text>
        </TouchableOpacity>
      );
    };
    let renderLeftActions = (progress:any, dragX:any) => {
      /*const trans = dragX.interpolate({
        inputRange: [0, 50, 100, 101],
        outputRange: [-20, 0, 0, 1],
      });*/

      return (
         <View style={{alignItems:'center',flexDirection:'row',justifyContent:'center'}}>
              <TouchableOpacity style={{}} onPress={eliminarProducto}>
                 <Entypo name="squared-minus" size={32} color="#3AA597" />
              </TouchableOpacity>
              <TouchableOpacity style={{}} 
                onPress={function(){
                  addProducto()
                  /*setTimeout(function(){
                    refSwipeable.openLeft()
                  },1000)
                  refSwipeable.openLeft()*/
                }}
              >
                  <Entypo name="squared-plus" size={32} color="#3AA597" />
              </TouchableOpacity>
          </View>
      );
    };

    function borrarProducto(){
      eliminarProductoCompleto()
    } 
    function pintarVariantes(producto:any){
   
     return(
        producto.variantesSel.map(function(grupoVariante:any,indice:number) {
          return(
            grupoVariante.opciones.map(function(opcion:any,index:number) {
              if(opcion.valor){
                let strCantidad = '-'
                if(opcion.cantidad>1){
                  strCantidad=opcion.cantidad
                }
                return(
                  <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
                    <View style={{flex:1,flexDirection:'row'}}>
                      <Text style={{fontSize:16}}>{strCantidad} </Text>
                      <Text style={{fontSize:16}}>{opcion.nombre}</Text>
                    </View>
                    <Text style={{fontSize:16,color:'#BABABA'}}>{opcion.cantidad*opcion.incremento}€</Text>
                  </View>
                )
              }
            })
          )
        })
      )
    
    }
    function pintarIngredintes(producto:any){
      let strIngredientes = ''
      let primerIng = true
      producto.ingredientesSinSel.map(function(ingrediente:any,indice:number) {
        var strComa=','
        if(primerIng){
          strComa=''
          primerIng=false
        }
        strIngredientes+=strComa+' '+ingrediente.nombre    
      })
      if(strIngredientes!=''){
        return(
          <View style={{marginLeft:20}}>
            <Text style={{fontSize:16}}>SIN: {strIngredientes}</Text>          
          </View>
        )
      }
      
    }
    function desactivarBtn(){
     
      setBtnDisabled(true)
    }
    function activarBtn(){

      setBtnDisabled(false)
    }
    return(
      <Swipeable 
        renderRightActions={renderRightActions}
        renderLeftActions={renderLeftActions}
        onSwipeableWillOpen={desactivarBtn}
        onSwipeableWillClose={activarBtn}
        ref={(c:any) => setRefSwipeable(c)}
      >
        <TouchableOpacity 
          onPress={addProducto}
          style={{}}
          disabled={btnDisabled}
          >
          <View style={{overflow:'hidden',borderBottomWidth:1,borderColor:'#BABABA',backgroundColor:'white',padding:10, minHeight:55,justifyContent:'center'}}>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
              <View style={{flex:1,flexDirection:'row',alignItems:'center'}}>
                <Text style={{fontSize:16}}>{producto.cantidad} </Text>
                <Text style={{fontSize:16}}>{producto.name}</Text>
              </View>
              <Text style={{fontSize:16}}>{(producto.cantidad*producto.calcularPrecioProducto()).toFixed(2)}€</Text>  
            </View>
            {
              pintarIngredintes(producto)
            }
            {
              pintarVariantes(producto)
            }
            {  
                producto.platosSel.map(function(plato:any,index:number) {
                  return(
                    <View>
                      {  
                        plato.productos.map(function(producto:any,index:number) {
                          console.log('producto',producto)
                          let srtVariantesAux = ''
                          return(
                            <View style={{marginLeft:20}}>
                              <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
                                  <Text style={{fontSize:16}}>{producto.name}</Text>
                                  <Text style={{fontSize:16,color:'#BABABA'}}>{producto.precio.toFixed(2)}€</Text>
                                </View>
                              {
                                pintarIngredintes(producto)
                              }
                              {
                                pintarVariantes(producto)
                              }
                            </View>
                          )
                        })
                      }
                    </View>
                  )
                  
                  
                })
              }
          </View>
        </TouchableOpacity>
      </Swipeable>
    );
    
}

export default (ProductoResumen);