import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

// Components
import Divider from "../../../../components/Divider";
import Actions from "./Actions";

//Methods
import getFormattedPrice from "../../../../methods/getFormattedPrice";

const Variant = ({
  moreAvailable,
  data,
  type,
  selected,
  onPress,
  allowQuantity,
  quantity,
  addHandler,
  removeHandler,
}) => {
  const { name, increment } = data;
  const clickHandler = () => {
    if (allowQuantity === 1 && quantity > 0) {
      addHandler(data);
    } else {
      onPress(data);
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.text}>{name}</Text>
        <Divider width={20} />
        {increment > 0 && (
          <Text style={styles.increment}>+{getFormattedPrice(increment)}</Text>
        )}
      </View>
      <Actions
        type={type}
        quantity={quantity}
        onAdd={clickHandler}
        onRemove={() => removeHandler(data)}
        selected={selected}
        moreAvailable={moreAvailable}
        allowQuantity={allowQuantity}
      />
    </TouchableOpacity>
  );
};

export default Variant;

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  text: {
    fontSize: 24,
    textAlign: "center",
    color: "black",
  },
  increment: {
    color: "#009683",
    fontSize: 20,
  },
});
