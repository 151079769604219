import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Text,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Components
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import List from "./components/ProductList/List";
import ExtendedList from "./components/ProductList/ExtendedList";

// Context
import { FoodStallContext } from "../../context/FoodStallContext";

// Methods
import getNumOrders from "../../methods/getNumOrders";
import getShopData from "./methods/getShopData";

// Reducers
import { vaciarCarrito } from "../../../../reducers";

// Objects
import Tienda from "../../../../objetos/Tienda";
import { LanguageContext } from "../../../../contexts/LanguageContext";

const ProductList = ({ tiendas, vaciarCarrito, navigation, route }) => {
  const tienda = route.params.tienda;
  const height = useWindowDimensions().height;
  const { shop, setShop } = useContext(FoodStallContext);
  const { language } = useContext(LanguageContext);
  const [shopData, setShopData] = useState<Tienda>();
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const orders = getNumOrders(tiendas);
  const footerRightTitle =
    t("goToCheckout") + " (" + orders + (orders === 1 ? " plato)" : " platos)");

  const footerShown = orders === 0 ? false : true;

  const goToOrderResume = () => {
    navigation.navigate("OrderResume");
  };

  const getData = async () => {
    const response = await getShopData(tienda?.gid, language);
    if (response.status === "success") {
      const newShop = new Tienda(response.data);
      setShop(newShop);
      setShopData(newShop);
      setStatus("success");
    } else {
      setError(response.error);
      setStatus("error");
    }
  };

  useEffect(() => {
    try {
      setStatus("loading");
      getData();
    } catch (error) {
      setError(error.message);
      setStatus("error");
    }
  }, []);

  useEffect(() => {
    if (shopData) {
      navigation.setOptions({
        header: () => <Header data={shopData} />,
      });
    }
  }, [shopData]);

  if (status === "loading" || status === "idle") {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size="small" color="#009683" />
      </View>
    );
  }

  if (status === "error") {
    return (
      <View style={styles.loading}>
        <Text>{error}</Text>
      </View>
    );
  }

  return (
    <ScrollView
      style={[{ backgroundColor: "white", height: height - 120 }]}
      contentContainerStyle={{ flex: 1 }}
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.screen}>
        <View style={styles.content}>
          {shop?.products.length > 5 ? (
            <ExtendedList
              shop={shop}
              setShop={setShop}
              products={shop.products}
            />
          ) : (
            <List shop={shop} setShop={setShop} products={shop?.products} />
          )}
        </View>
      </View>
      {footerShown && (
        <Footer
          leftTitle={t("removeSelection")}
          rightTitle={footerRightTitle}
          leftAction={vaciarCarrito}
          rightAction={goToOrderResume}
        />
      )}
    </ScrollView>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
  };
};

const mapDispatchToProps = {
  vaciarCarrito,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
  loading: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 850,
  },
  scrollContent: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 12,
  },
});
