import React,{useState, useEffect, useRef} from 'react';
import {Animated,Easing,Dimensions,StyleSheet,TouchableOpacity,Text,View} from "react-native";
import { setPedidosFiltrados,addPedido,eliminarPedido,editarPedido } from "../../application/reducers";
import { connect } from "react-redux";
import { AntDesign,SimpleLineIcons,FontAwesome } from '@expo/vector-icons'; 
import { Audio } from 'expo-av';
import ProductoPedido from "./ProductoPedido"
import Pedido from "../../application/objetos/Pedido";
import ProductoResumenCamareroGestor from "./ProductoResumenCamareroGestor";

const PedidoCocina = (props:any) => {
    let intervaloAlarma=''
    const {listado,navigation,cambiarEstado,key} = props
    
    const [btnBackground, setBtnBackground] = useState('transparent');
    const [pedido, setPedido] = useState(props.pedido);
    let lastPress = 0
    //const fadeAnim = useRef(new Animated.Value(1)).current;
    const animatedController = useRef(new Animated.Value(1)).current;
    let fadeAnim = animatedController.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
      });
    /*props.navigation.addListener('beforeRemove', () => {
      console.log("beforeRemove")
      if(pedido.intervaloAlarma!=''){
        clearInterval(pedido.intervaloAlarma)

      }
     
    });*/
    function cambiarEstadoPedido(){
       /*let pedidoAux = new Pedido(JSON.parse(JSON.stringify(pedido.cambiarEstado())))
       setPedido(pedidoAux)
       cambiarEstado(pedidoAux)*/
       
       if(pedido.state!='completed' && pedido.state!='cancelled'){
           pedido.cambiarEstado()
       }
       
    }
    
 
    useEffect(() => {
        //console.log("useefect")
        setPedido(props.pedido)
        switch (pedido.estado) {
            case 'Programado':
            case 'Espera':
            case 'Cancelado':
                setBtnBackground('#3AA597')
            break;
            case 'En proceso':
                setBtnBackground('#FEB241')
            break;
            case 'Listo para recoger':
            case 'Listo para enviar':
            case 'Entregado parcialmente':
            case 'Enviado':
            case 'Completado':
                setBtnBackground('green')
            break;
            
        }
        
       
        //comprobarAlarma()
       
            /*if(intervaloParpadeando!=''){
              clearInterval(intervaloParpadeando)

            }
            intervaloParpadeando = setInterval(function(){
                console.log("parpadelo")
                Animated.timing(
                  fadeAnim,
                  {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver:false
                  }
                ).start();
                
            },5000)
            
        }*/
           /* console.log("parpadelo")
            Animated.timing(
              fadeAnim,
              {
                toValue: 0,
                duration: 300,
                useNativeDriver:false
              }
            ).start();*/
        //}
     }, [props,pedido]);
    
    function abrirMenuPedido(){
        var aux = {
          pedido:pedido
        }
        navigation.navigate('menuPedido',aux);
    }
    async function playSound() {
        const { sound } = await Audio.Sound.createAsync(
           require('../../assets/sounds/enespera.mp3')
        ); 
        await sound.playAsync(); 

    }
    function parpadear(){
        Animated.timing(animatedController, {
            duration: 150,
            toValue: 0,
            easing: Easing.bezier(0.2, 0.4, 0.6, 1),
            useNativeDriver:false
        }).start();
        setTimeout(function(){
            Animated.timing(animatedController, {
                duration: 150,
                toValue: 1,
                easing: Easing.bezier(0.2, 0.4, 0.6, 1),
                useNativeDriver:false
            }).start();
        },150)
    }
    function comprobarAlarma(){
        if(pedido.intervaloAlarma!=''){
          clearInterval(pedido.intervaloAlarma)
        }
        pedido.intervaloAlarma = setInterval(function(){
            if(usuario.setting.alarm!=undefined){
                usuario.setting.alarm.map(function(alarm:any,index:number) {
                  if(pedido.state==alarm.status){
                    pedido.tiempoEstado=pedido.tiempoEstado+5
                    //if(pedido.tiempoEstado>(alarm.time*60)){
                    if(pedido.tiempoEstado>1){
                        playSound()
                        parpadear()
                        console.log("parpadelo")
                    }
                  }    
         
                })
            }
            
            
        },5000)

    }
    function imprimirTicket(pedido:any,cantidad:any,type:any){
        console.log("imprimo el ticket")
    }
    function comprobarImpresion(){
        if(pedido.intervaloImpresion!=''){
          clearInterval(pedido.intervaloImpresion)
        }
        pedido.intervaloImpresion = setInterval(function(){
            if(usuario.setting.print!=undefined){
                usuario.setting.print.map(function(print:any,index:number) {
                  if(pedido.state==print.status || pedido.prioridadesPedido[pedido.state]<pedido.prioridadesPedido[print.status]){
                      if(!pedido.impreso){
                        pedido.impreso=true
                        imprimirTicket(pedido,print.quantity,print.type)
                      }
                    }    
                })
            }
            
            
            
        },5000)

  }
    function onDoublePress(){
        /*const time = new Date().getTime();
        const delta = time - lastPress;

        const DOUBLE_PRESS_DELAY = 400;
        if (delta < DOUBLE_PRESS_DELAY) {
            //hago doble click
            abrirMenuPedido(pedido)
        }
        lastPress = time;*/
    };
    /*
    <View style={{alignItems:'center',justifyContent:'space-between',flexDirection:'row'}}>
                                        {!pedido.pagado ? (
                                            <Text style={[{fontSize: 14,color:'white'}]}>{pedido.amount}€</Text>
                                        ) : (<Text> </Text>)}
                                        {pedido.estado!='Completado' ? (
                                           <Text style={[{fontSize: 14,textAlign:'right'},!pedido.pagado ? {color:'white'} : {}]}>{ pedido.tiempo<0 ? '0':pedido.tiempo} min</Text>
                                        ) : (null)}

                                        
                                    </View>
    */
    return(
        <View style={{width:'100%',padding:8,justifyContent:'center',alignItems:'center'}} key={key}>
        { listado==true ? (
            <View style={[{width:'100%',backgroundColor:'white',borderRadius: 8},!pedido.pagado ? {backgroundColor:'#3AA597'} : {}]}>
              
                <TouchableOpacity style={{marginLeft:15,marginRight:15,marginTop:8,marginBottom:8,flex:1,flexDirection:'row'}}>
                    <View style={{width:120,borderRightWidth:1,borderRightColor:'#bdbdbd',paddingRight:10,alignItems:'flex-start',justifyContent:'center'}}>
                        <Text style={[{fontWeight:'bold',fontSize: 18,color:'#3AA597',paddingTop:4,paddingBottom:4},!pedido.pagado ? {color:'white'} : {}]}>#{pedido.order_number}</Text>
                        <View style={{alignItems:'center',justifyContent:'space-between',flexDirection:'row'}}>
                            {!pedido.pagado ? (
                                <Text style={[{fontSize: 15,paddingTop:4,paddingBottom:4,color:'white',paddingRight:4}]}>{pedido.amount}€</Text>
                            ) : null}
                            <Text style={[{fontSize: 15,paddingTop:4,paddingBottom:4,textAlign:'right'},!pedido.pagado ? {color:'white'} : {}]}>{pedido.hora}</Text>
                        </View>
                        
                    </View>
                   
                    <View style={{width:120,paddingLeft:10,paddingRight:10,alignItems:'flex-start',justifyContent:'center'}}>
                        <Text style={[{fontWeight:'bold',fontSize: 15,paddingTop:4,paddingBottom:4},!pedido.pagado ? {color:'white'} : {}]}>{pedido.event.name}</Text>
                        <Text style={[{fontSize: 15,paddingTop:4,paddingBottom:4},!pedido.pagado ? {color:'white'} : {}]}>{pedido.shop.name}</Text>
                    </View>
                    
                    <View style={{marginRight:55,flex:1,backgroundColor:'white',borderRadius: 8,margin:4,padding:8}}>
                        <View style={{flexWrap: 'wrap',flexDirection:'row',paddingTop:4,paddingBottom:4,alignItems:'center'}}>
                        {    
                            pedido.productos.map(function(item:any,index:any) {
                                return (
                                    <Text style={{paddingRight:8}} key={index.toString()}>
                                        <ProductoPedido producto={item}>
                                        </ProductoPedido>
                                    </Text>
                                )
                            })
                            
                        }
                        </View>
                    </View>
                   
                    
                </TouchableOpacity>
                <TouchableOpacity  onPress={cambiarEstadoPedido} style={{right:0,position: 'absolute',height:'100%',justifyContent:'center',alignItems:'center',width:60,backgroundColor:btnBackground,borderTopEndRadius:8,borderBottomRightRadius:8}}>
                          <AntDesign name="right" size={24} color="white" />
                    </TouchableOpacity>
            </View>
        ) : (
            <View style={[{borderWidth:1,borderRadius:8,borderColor:'#C9C9C9',width:'100%',minWidth:250,backgroundColor:'white',borderRadius: 8},!pedido.pagado ? {backgroundColor:'#3AA597'} : {}]}>

                <View style={{margin:8}}>
                    <View style={{borderBottomWidth:1,borderColor:'#C9C9C9'}}>
                        <View style={{paddingLeft:10,justifyContent:'center',flexDirection:'row',flex:1,marginBottom:4}}>
                            
                            <View style={{flex:1,justifyContent:'center'}}>
                                <Text style={[{fontSize: 15},!pedido.pagado ? {color:'white'} : {}]}>{pedido.shop.name}</Text>
                                {pedido.hora!='Lo antes posible' ? (
                                    <View style={{alignItems:'center',justifyContent:'flex-start',flexDirection:'row'}}>
                                       <AntDesign name="clockcircleo" size={15} color="black" />
                                       <Text style={[{fontWeight:'bold',fontSize: 15,flex:1,paddingLeft:5},!pedido.pagado ? {color:'white'} : {}]}>Programado: {pedido.hora}</Text>
                                    </View>
                                ) : null}
                            </View>
                            <View>
                                <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                                    <View style={{justifyContent:'flex-start'}}>
                                        <Text style={[{fontWeight:'bold',fontSize: 20,color:'#3AA597',textAlign:'right'},!pedido.pagado ? {color:'white'} : {}]}>#{pedido.order_number}</Text>
                                        <Text style={{fontSize: 15,flex:1,textAlign:'right'}}>{pedido.strHora}</Text>
                                    </View>
                                    <TouchableOpacity style={{padding:5,paddingLeft:7}} onPress={abrirMenuPedido}>
                                        <SimpleLineIcons name="options-vertical" size={24} color="black" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            
                         </View>
                     
                        {pedido.location!='' ? (
                            <View style={{marginBottom:10,paddingLeft:10,paddingRight:10,alignItems:'center',justifyContent:'flex-start',flexDirection:'row'}}>
                                <FontAwesome name="location-arrow" size={15} color="black" />
                                <Text style={{fontWeight:'bold',fontSize: 15,flex:1,paddingLeft:5}}>{pedido.location}</Text>
                            </View>
                        ) : null}
                    </View>
                    <View style={{backgroundColor:'white',borderRadius:8}}>
                    {
                        pedido.productos.map(function(item:any,index:any) {
                            /*return (
                                < ProductoPedido producto={item} key={index.toString()}>
                                    </ProductoPedido>
                            )*/
                            if(!item.producto.oculto){
                                return (<ProductoResumenCamareroGestor sinPrecio={true} disabled={true} lineaPedido={item}></ProductoResumenCamareroGestor>)
                            }
                        })
                    }
                    </View>
                    {
                        pedido.comment!=undefined && pedido.comment!=''?(
                            <View style={{paddingLeft:10,paddingRight:10,justifyContent:'center',flexDirection:'row'}}>
                             <Text style={{fontSize: 15}}>{pedido.comment}</Text>
                            </View>
                        ):(null)
                    }
                   

                   
                    <TouchableOpacity style={{justifyContent:'center',alignItems:'center',width:'100%'}} onPress={cambiarEstadoPedido}>
                      <View style={[{width:200,backgroundColor:btnBackground,justifyContent:'center',alignItems:'center',borderRadius:50,marginTop:15}]}>
                          <Text style={{fontWeight:'bold',fontSize: 16,color:'white',padding:10}}>{pedido.nombreBoton}</Text>
                      </View>
                    </TouchableOpacity>
                </View>
            </View>
        )}
        
       </View>
    );
    
}
const mapDispatchToProps = {
  setPedidosFiltrados,
  addPedido,
  eliminarPedido,
  editarPedido
};
export default connect(null,mapDispatchToProps )(PedidoCocina);