import { SET_PEDIDO_IMPRIMIR,SET_PEDIDOS_PENDIENTES,SET_IMPRESORA_DISPONIBLE,SET_REFERENCIA_TICKET,SET_FACTURA_PRODUCTOS,SET_FACTURA_COMISION } from "./pedidoImprimirTypes";


const initialState = {
  pedidoImprimir: null,
  pedidosPendientes: [],
  impresoraDisponible:true,
  referenciaTicket:false,
  facturaProductos:null,
  facturaComision:null
};

const pedidosReducer = (state = initialState, action:any) => {

  switch (action.type) {
    case SET_PEDIDO_IMPRIMIR: {
      state.pedidoImprimir = action.payload.pedido;
      return {...state}
    }
    case SET_PEDIDOS_PENDIENTES: {
      state.pedidosPendientes = action.payload.pedidosPendientes;
      return {...state}
    }
    case SET_IMPRESORA_DISPONIBLE: {
      state.impresoraDisponible = action.payload.impresoraDisponible;
      return {...state}
    }
    case SET_REFERENCIA_TICKET: {
      state.referenciaTicket = action.payload.referenciaTicket;
      return {...state}
    }
    case SET_FACTURA_PRODUCTOS: {
      state.facturaProductos = action.payload.facturaProductos;
      return {...state}
    }
    case SET_FACTURA_COMISION: {
      state.facturaComision = action.payload.facturaComision;
      return {...state}
    }
    default:
      return state;
  }
};

export default pedidosReducer;