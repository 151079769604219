import React, { useEffect, useState } from "react";
import { TextInput, StyleSheet,ScrollView, Text, View, TouchableOpacity } from "react-native";
import * as Location from "expo-location";

const GoogleLocationsInput = ({ placeholder, setData,style,textInit }) => {

  const [input, setInput] = useState(textInit || '');
  const [locations, setLocations] = useState([]);
  let apiKey= 'AIzaSyC7OfmUcvSfyz8dis5U1zm17_DWQdoIQ6Y'
  useEffect(() => {
    const getData = async () => {
      try {
        Location.setGoogleApiKey(apiKey)
        const responseGeocode = await Location.geocodeAsync(input);
        if (responseGeocode.length > 0) {
          const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+responseGeocode[0].latitude+','+responseGeocode[0].longitude+'&key='+apiKey;
          const response = await fetch(url);
          if (!response.ok) {
            console.log("Error response proxy", response.status);
            setLocations([]);
          } else {
            const resData = await response.json();
            if (resData.status === "ZERO_RESULTS") {
              setData(null);
              setLocations([]);
            }
            if (resData.status === "OK") {
              let array = [];
              resData.results.map((result) => {
                let add = false;
                result.address_components.map((comp) => {
                  if (comp.short_name === "ES") {
                    add = true;
                  }
                });
                if (add === true) {
                  array.push(result);
                }
              });
              setLocations(array);
            }
          }
        }
      } catch (err) {
        console.log("error fetching google location: ", err);
      }
    };
    if (input !== "") {
      getData();
    }
  }, [input]);
  

  const clickHandler = (item) => {
    setData(item);
    setLocations([]);
  };

  return (
    <View style={{width:'100%', alignItems:'center'}}>
      <TextInput style={style} placeholder={placeholder} value={input} onChangeText={setInput} />
      {locations.length > 0 && (
        <ScrollView
          contentContainerStyle={{ maxHeight: 280, }}
          style={[
            {position: "absolute",
              width: "100%",
              maxHeight: 300,
              borderRadius: 15},
            locations.length > 7
              ? { bottom: -40 * 7 }
              : { bottom: -40 * locations.length },
          ]}
        >
          {locations.map((location) => {
            return (
              <TouchableOpacity
                onPress={() => clickHandler(location)}
                style={[{
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "white",
    height: 40,
    padding: 10,
  }]}
              >
                <Text>{location.formatted_address}</Text>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      )}
    </View>
  );
};

export default GoogleLocationsInput;
