import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, StyleSheet } from "react-native";

// Components
import Divider from "../../../../components/Divider";
import AddProduct from "./AddProduct";
import MenuLines from "./MenuLines";
import RemoveProduct from "./RemoveProduct";

// Methods
import { getIngredientsText } from "../../methods/getIngredientsText";
import { getVariantsText } from "../../methods/getVariantsText";

const ProductItem = ({ product, onAdd, onDecrease }) => {
  const { cantidad, name, ingredientesSinSel, variantesSel } = product;
  const { t } = useTranslation();
  const addHandler = () => {
    onAdd();
  };

  const removeHandler = () => {
    onDecrease();
  };

  const variantText = getVariantsText(variantesSel) || null;
  const ingredientsText = getIngredientsText(ingredientesSinSel) || null;

  return (
    <View style={styles.container}>
      <RemoveProduct
        quantity={cantidad}
        onPress={removeHandler}
        buttonSize={28}
        iconSize={24}
      />
      <Divider width={10} />
      <View style={styles.detailsColumn}>
        <Text style={styles.title}>{name}</Text>
        {variantText && <Text style={styles.subtitle}>{variantText}</Text>}
        <MenuLines plates={product.platosSel} />
        {ingredientsText && (
          <Text style={styles.subtitle}>
            {t("without") + ": " + ingredientsText}
          </Text>
        )}
      </View>
      <AddProduct onAdd={addHandler} buttonSize={28} iconSize={24} />
    </View>
  );
};

export default ProductItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  detailsColumn: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: 10,
    flexDirection: "column",
  },
  title: {
    fontSize: 30,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "200",
  },
});
