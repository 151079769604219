export const SET_PEDIDOS_FILTRADOS = "SET_PEDIDOS_FILTRADOS"
export const ADD_PEDIDOS = "ADD_PEDIDOS"
export const DELETE_PEDIDO = "DELETE_PEDIDO"
export const EDIT_PEDIDOS = "EDIT_PEDIDOS"
export const SET_PEDIDOS = "SET_PEDIDOS"
export const ORDENAR_FILTRADOS = "ORDENAR_FILTRADOS"
export const SET_SOCKET_CONECTADO = "SET_SOCKET_CONECTADO"
export const SET_PEDIDOS_PERDIDOS = "SET_PEDIDOS_PERDIDOS"
export const SET_PEDIDO_SELECCIONADO = "SET_PEDIDO_SELECCIONADO"
export const SET_PEDIDOS_ENTREGAR = "SET_PEDIDOS_ENTREGAR"
export const SET_PEDIDOS_ENVIAR = "SET_PEDIDOS_ENVIAR"
export const SET_PEDIDOS_COMPLETADOS = "SET_PEDIDOS_COMPLETADOS"
export const SET_PEDIDOS_PREPARAR = "SET_PEDIDOS_PREPARAR"
export const SET_ULTIMO_PEDIDO = "SET_ULTIMO_PEDIDO"