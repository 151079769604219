import React, { useState, useEffect, useRef } from 'react';
import {Text,TextInput,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';
import {CheckBox} from "react-native-elements";
import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import { FontAwesome,AntDesign,Entypo } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
//import {loadAsync} from 'expo-font';

const devolucion = (props:any) => {
  var pedido=props.route.params.pedido
   let initArray:Array<any>=[]
  const [text, setText] = useState('');
  const [opcionImporteSel, setOpcionImporteSel] = useState(false);
  const [checksProductos, setChecksProductos] = useState(initArray);
  function goBack(){
    props.navigation.goBack()
  }
  function updateText(text:any){
    setText(text)
  }
  function changeOpcion(importeSel:any){
    setOpcionImporteSel(importeSel)
  }
  useEffect(() => {
    let checkProductosAux:Array<any>=[]
    if(pedido.productos!=undefined){
      pedido.productos.map(function(producto:any,indice:any) {
        console.log("producto",producto)
        let aux={
          producto:producto.producto,
          cantidadMax:producto.cantidad,
          cantidadSel:producto.cantidad,
          valor:true
        }
        console.log("aux",aux)
        checkProductosAux.push(aux)
      })
    }
    setChecksProductos(checkProductosAux)
  }, []);
  function changeProductosSel(indice:any){
    let checksProductosAux = checksProductos.slice()
    checksProductosAux[indice].valor=!checksProductosAux[indice].valor
    setChecksProductos(checksProductosAux)
  }
   function addCantidad(indice:number){
    let cantidadMax = checksProductos[indice].cantidadMax
    let cantidadSel = checksProductos[indice].cantidadSel
    if(cantidadSel<cantidadMax){
      let checksProductosAux = checksProductos.slice()
      checksProductosAux[indice].cantidadSel=cantidadSel+1
      setChecksProductos(checksProductosAux)
    }
  }
  function eliminarCantidad(indice:number){
   
    let cantidadSel = checksProductos[indice].cantidadSel
    if(cantidadSel>1){
      let checksProductosAux = checksProductos.slice()
      checksProductosAux[indice].cantidadSel=cantidadSel-1
      setChecksProductos(checksProductosAux)
    }
  }
  return (
    <View style={{backgroundColor:'transparent',flex:1,alignItems:'center',justifyContent:'center'}}>
      <View style={{maxHeight:700,width:'90%',height:'80%',borderRadius:8,backgroundColor:'white',marginLeft:20,marginRight:20,padding:40}}>
        <View style={{position:'absolute',top:20,width:'100%',flex:1,flexDirection:'row',justifyContent:'space-between',zIndex:2}}>
          <Text style={{color:'#3AA597',fontSize:32,marginLeft:20}}>{pedido.order_id}</Text>
         
          <TouchableOpacity style={{marginRight:60}} onPress={goBack}>
            <AntDesign name="close" size={32} color="#3AA597" />
          </TouchableOpacity>

        </View>
        <View style={{paddingTop:60,height:'100%',width:'100%'}}>
          <View style={{flexDirection:'row',justifyContent:'space-around'}}>
            <CheckBox
                title={'Devolver productos'}
                checked={!opcionImporteSel}
                textStyle={{fontWeight:'normal',color:'black'}}
                containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                onPress={(data) => {changeOpcion(false)}} 
            />
            <CheckBox
                title={'Devolver importe'}
                checked={opcionImporteSel}
                textStyle={{fontWeight:'normal',color:'black'}}
                containerStyle={{backgroundColor:'transparent',borderWidth:0}}
                checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                onPress={(data) => {changeOpcion(true)}} 
            />
          </View>
          { opcionImporteSel ? 
            (
              <View style={{width:'100%',justifyContent:'center',alignItems:'center',flex:1}}>
                <TextInput
                  placeholder="Escribe el importe..."
                  onChangeText={text => updateText(text)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  style={[styles.inputForm,{backgroundColor:"#DCDCDC"}]}
                />
              </View>
            )
            :
            (
              <View style={{width:'100%',justifyContent:'center',alignItems:'center',flex:1}}>
                {
                  checksProductos.map(function(item:any,indice:any) {
                    return(
                    <View style={[{flexDirection:'row',width:'100%',justifyContent:'center',alignItems:'center',borderLeftWidth:1,borderRightWidth:1,borderBottomWidth:1,borderColor:'black'},indice==0?{borderTopWidth:1}:{}]}>
                      <View style={{flexDirection:'row',justifyContent:'space-around',flex:1}}>
                        <CheckBox
                          title={item.producto.name}
                          checked={item.valor}
                          textStyle={{fontWeight:'normal',color:'black'}}
                          containerStyle={{backgroundColor:'transparent',borderWidth:0,flex:1}}
                          checkedIcon={<FontAwesome size={20} name="check-square" color="black" />}
                          uncheckedIcon={<FontAwesome size={20} name="square-o" color="black" />}
                         
                          onPress={(data) => {changeProductosSel(indice)}} 
                        />
                        { (item.cantidadMax>1 && item.valor) ?
                          (<View style={{alignItems:'center',flexDirection:'row',justifyContent:'center',marginRight:20}}>
                              <TouchableOpacity style={{}} onPress={eliminarCantidad.bind(this,indice)}>
                                <Entypo name="squared-minus" size={32} color="#3AA597" />
                              </TouchableOpacity>
                              <Text style={{color:'#3AA597',fontWeight:'bold',fontSize:28,lineHeight:28,textAlignVertical:'center',textAlign:'center'}}> {item.cantidadSel} </Text>
                              <TouchableOpacity style={{}} onPress={addCantidad.bind(this,indice)}>
                                <Entypo name="squared-plus" size={32} color="#3AA597" />
                              </TouchableOpacity>
                          </View>)
                          : (null)
                        }
                        </View>
                    </View>
                    )
                  })
                }
              </View>
            )
          }
          
        <View style={{width:'100%',justifyContent:'space-between',alignItems:'center',flexDirection:'row'}}>
          <TouchableOpacity activeOpacity={1} style={[styles.btnLogin,{flex:1,margin:10}]}>
              <Text style={styles.textoBtn}>Devolver</Text>       
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={1} style={[styles.btnLogin,{flex:1,margin:10}]}>
              <Text style={styles.textoBtn}>Devolver todo</Text>       
          </TouchableOpacity>
        </View>
      </View>
        
      </View>
    </View>
  );
};

export default (devolucion);
