async function getPedidoById(idPedido:any,){
  return new Promise(function(resolve, reject) {
   
    var url = global.endpoint+'order/orders/get/'+idPedido

    console.log("getPedidoById ",url)

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':"Bearer "+global.usuario.jwt
      }
      
    })
      .then(response => {
          console.log('devuelve llamada result--->', response.status)
          if (response.status == 200) {
              resolve(response.json());
          }else{
              reject();
          }
      })
      .catch((error) => {
          console.error('Error getPedidoById -> '+error);
          reject(error);
      })
    });
}


export default {getPedidoById};


