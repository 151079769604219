import React, { useState } from "react";
import { connect } from "react-redux";

import splashScreen from "../screens/splashScreen";
import login from "../screens/login";
import menu from "../screens/menu";

import menuPedido from "../screens/menuPedido";
import enviarMensaje from "../screens/enviarMensaje";
import vistaCalculadora from "../screens/vistaCalculadora";
import descripcionLarga from "../screens/descripcionLarga";
import listadoVariantes from "../screens/listadoVariantes";
import vistaProgramacionTiendas from "../screens/vistaProgramacionTiendas";
import vistaCerrarTiendas from "../screens/vistaCerrarTiendas";
import vistaSeleccionProgramacion from "../screens/vistaSeleccionProgramacion";
import productosAgrupados from "../screens/productosAgrupados";

import seleccionarLocalizacion from "../screens/seleccionarLocalizacion";
import seleccionServicios from "../screens/seleccionServicios";
import devolucion from "../screens/devolucion";
import filtros from "../screens/filtros";
import vistaEscaner from "../screens/vistaEscaner";
import vistaEscanerCashLess from "../screens/vistaEscanerCashLess";
import VistaMenuInterior from "../screens/vistaMenuInterior";
import gestionPedidos from "../screens/gestionPedidos";
import VistaFamiliaProductos from "../screens/vistaFamiliaProductos";
import VistaFamilias from "../screens/vistaFamilias";
import VistaDetallesPedido from "../screens/vistaDetallesPedido";
import VistaTicket from "../screens/vistaTicket";
import VistaResumenPedido from "../screens/vistaResumenPedido";
import detallesTienda from "../screens/detallesTienda";
import FoodStallOrdersScreen from "../screens/FoodStallOrdersScreen";
import listadoPedidosCamarero from "../screens/listadoPedidosCamarero";
import listadoPedidosDatafono from "../screens/listadoPedidosDatafono";
import listadoTiendas from "../screens/listadoTiendas";
import productoMenu from "../screens/productoMenu";
import listadoProductos from "../screens/listadoProductos";
import vistaStock from "../screens/vistaStock";
import listadoBluetooth from "../screens/listadoBluetooth";
import vistaDelivery from "../screens/vistaDelivery";
import recargarCredito from "../screens/recargarCredito";
import listadoFamilias from "../screens/listadoFamilias";

import DetallesPedidoCamarero from "../screens/detallesPedidoCamarero";
import { Text, TouchableOpacity, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { AntDesign, FontAwesome, Feather } from "@expo/vector-icons";

import ProductList from "../screens/FoodStallScreen/screens/ProductList";
import OrderResume from "../screens/FoodStallScreen/screens/OrderResume";
import ProductDetail from "../screens/FoodStallScreen/screens/ProductDetail";

const Stack = createStackNavigator();

const forFade = ({ current }: any) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

const MyStack = (props: any) => {
  function abrirMenu() {
    props.navigationRedux.navigate("vistaMenuInterior");
  }

  function abrirEscaner() {
    props.navigationRedux.navigate("vistaEscaner");
  }

  function goBack() {
    props.navigationRedux.goBack();
  }

  let optionsCalculadora = {
    headerShown: false,
    gestureEnabled: false,
    presentation: "transparentModal",
    cardStyle: { backgroundColor: "transparent" },
    title: "Pago efectivo",
  };

  let opcionesScreen = {
    gestureEnabled: false,
    headerTitleStyle: {
      color: "black",
      fontSize: 22,
    },
    headerTintColor: "black",
    headerStyle: {
      backgroundColor: "white",
      height: 60,
    },
    headerBackTitleVisible: false,
    headerTitleAlign: "left",
    headerLeft: () => {
      return (
        <View
          style={{
            paddingLeft: 20,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {props.conexion ? (
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 20,
                backgroundColor: "#73E13F",
              }}
            ></View>
          ) : (
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 20,
                backgroundColor: "red",
              }}
            ></View>
          )}
          <TouchableOpacity style={{ paddingLeft: 10 }} onPress={goBack}>
            <AntDesign name="left" size={24} color="black" />
          </TouchableOpacity>
        </View>
      );
    },
    headerRight: () => {
      return (
        <View
          style={[
            {
              flex: 1,
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row",
              paddingRight: 20,
            },
          ]}
        >
          <TouchableOpacity
            style={{
              borderWidth: 1,
              borderRadius: 50,
              height: 35,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 10,
            }}
            onPress={abrirListadoPedidosCamarero}
          >
            <FontAwesome name="th-list" size={20} color="black" />
            <Text style={{ paddingLeft: 5 }}>Histórico</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              marginLeft: 20,
              borderWidth: 1,
              borderRadius: 50,
              height: 35,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: 10,
            }}
            onPress={abrirEscaner}
          >
            <AntDesign name="scan1" size={20} color="black" />
            <Text style={{ paddingLeft: 5 }}>Escanear QR</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={abrirMenu} style={{ padding: 10 }}>
            <Feather name="menu" size={24} color="black" />
          </TouchableOpacity>
        </View>
      );
    },
  };

  if (!global.pantallaDividida) {
    optionsCalculadora = {
      headerShown: true,
      gestureEnabled: false,
      title: "Pago efectivo",
    };
    opcionesScreen = {
      gestureEnabled: false,
      headerTitleStyle: {
        color: "black",
        fontSize: 22,
      },
      headerTintColor: "black",
      headerStyle: {
        backgroundColor: "white",
        height: 60,
      },
      headerBackTitleVisible: false,
      headerTitleAlign: "left",
      headerLeft: () => {
        return (
          <View
            style={{
              paddingLeft: 20,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <TouchableOpacity style={{ paddingLeft: 10 }} onPress={goBack}>
              <AntDesign name="left" size={24} color="black" />
            </TouchableOpacity>
          </View>
        );
      },
      headerRight: () => {
        return (
          <View>
            <View
              style={[
                {
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  paddingRight: 20,
                  minWidth: 80,
                },
              ]}
            >
              <TouchableOpacity
                onPress={abrirListadoPedidosCamarero}
                style={{
                  padding: 5,
                  marginRight: 10,
                  width: 34,
                  height: 34,
                  borderRadius: 6,
                  borderWidth: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: "#3AA597",
                }}
              >
                <FontAwesome name="list" size={24} color="#3AA597" />
              </TouchableOpacity>
              <View>
                {props.conexion ? (
                  <View
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 20,
                      backgroundColor: "#73E13F",
                    }}
                  ></View>
                ) : (
                  <View
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 20,
                      backgroundColor: "red",
                    }}
                  ></View>
                )}
              </View>
            </View>
          </View>
        );
      },
    };
  }

  function abrirListadoPedidosCamarero() {
    props.navigationRedux.navigate("listadoPedidosDatafono");
  }

  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={opcionesScreen}>
        <Stack.Screen
          name="splashScreen"
          component={splashScreen}
          options={{
            headerShown: false,
            gestureEnabled: false,
            cardStyleInterpolator: forFade,
          }}
        />
        <Stack.Screen
          name="login"
          component={login}
          options={{
            headerShown: false,
            gestureEnabled: false,
            cardStyleInterpolator: forFade,
          }}
        />
        <Stack.Screen name="menu" component={menu} />
        <Stack.Screen
          name="gestionPedidos"
          component={gestionPedidos}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen name="vistaEscaner" component={vistaEscaner} />
        <Stack.Screen
          name="vistaEscanerCashLess"
          component={vistaEscanerCashLess}
        />
        <Stack.Screen
          name="filtros"
          component={filtros}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="productosAgrupados"
          component={productosAgrupados}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="menuPedido"
          component={menuPedido}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="enviarMensaje"
          component={enviarMensaje}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="listadoTiendas"
          component={listadoTiendas}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="listadoFamilias"
          component={listadoFamilias}
          options={{
            title: "FAMILIAS",
          }}
        />
        <Stack.Screen
          name="productoMenu"
          component={productoMenu}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
          }}
        />
        <Stack.Screen
          name="detallesTienda"
          component={detallesTienda}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="ProductList"
          options={{
            title: "KIOSKO",
            headerTitle: "",
          }}
          component={ProductList}
        />
        <Stack.Screen
          name="OrderResume"
          options={{
            title: "KIOSKO",
            headerTitle: "",
          }}
          component={OrderResume}
        />
        <Stack.Screen
          name="ProductDetail"
          options={{
            title: "KIOSKO",
            headerTitle: "",
          }}
          component={ProductDetail}
        />
        <Stack.Screen
          name="FoodStallOrdersScreen"
          component={FoodStallOrdersScreen}
          options={{
            title: "Comandero",
          }}
        />
        <Stack.Screen
          name="VistaTicket"
          component={VistaTicket}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="VistaResumenPedido"
          component={VistaResumenPedido}
          options={{
            title: "Resumen pedido",
          }}
        />
        <Stack.Screen
          name="VistaDetallesPedido"
          component={VistaDetallesPedido}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="VistaFamiliaProductos"
          component={VistaFamiliaProductos}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="VistaFamilias"
          component={VistaFamilias}
          options={{
            title: "TIENDAS",
          }}
        />
        <Stack.Screen
          name="listadoVariantes"
          component={listadoVariantes}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="devolucion"
          component={devolucion}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="seleccionarLocalizacion"
          component={seleccionarLocalizacion}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="seleccionServicios"
          component={seleccionServicios}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="descripcionLarga"
          component={descripcionLarga}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="listadoProductos"
          component={listadoProductos}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="vistaCalculadora"
          component={vistaCalculadora}
          options={optionsCalculadora}
        />
        <Stack.Screen
          name="listadoPedidosCamarero"
          component={listadoPedidosCamarero}
          options={{
            title: "Pedidos",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="listadoPedidosDatafono"
          component={listadoPedidosDatafono}
          options={{
            title: "Pedidos",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="DetallesPedidoCamarero"
          component={DetallesPedidoCamarero}
          options={{
            title: "Detalle pedido",
            headerRight: undefined,
          }}
        />
        <Stack.Screen
          name="vistaStock"
          component={vistaStock}
          options={{
            title: "Stock",
          }}
        />
        <Stack.Screen
          name="listadoBluetooth"
          component={listadoBluetooth}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="vistaCerrarTiendas"
          component={vistaCerrarTiendas}
          options={{
            title: "Tiendas",
          }}
        />
        <Stack.Screen
          name="vistaProgramacionTiendas"
          component={vistaProgramacionTiendas}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="vistaDelivery"
          component={vistaDelivery}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="vistaSeleccionProgramacion"
          component={vistaSeleccionProgramacion}
          options={{
            headerShown: false,
            presentation: "transparentModal",
            cardStyle: { backgroundColor: "transparent" },
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="recargarCredito"
          component={recargarCredito}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="vistaMenuInterior"
          component={VistaMenuInterior}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    conexion: state.dimensions.conexion,
    navigationRedux: state.config.navigationRedux,
  };
};

export default connect(mapStateToProps)(MyStack);
