import React, { useEffect, useState } from "react";
import { Text, View,TouchableOpacity } from "react-native";
import CustomCheckBox from "./CustomCheckBox";

const ProductoPedido = (props: any) => {
  let arrayInit: Array<any> = [];
  const { producto } = props;
  const [strVariantes, setStrVariantes] = useState("");
  const [strIngredientes, setStrIngredientes] = useState("");
  const [menu_lines, setMenu_lines] = useState(arrayInit);

  function getStrVariantes(variantes: any) {
    let srtVariantesAux = "";
    let primero = true;
    if (variantes != undefined) {
      variantes.map(function (grupoVariante: any, indice: number) {
        if (grupoVariante != null && grupoVariante != undefined) {
          let strOpciones = "";
          if (grupoVariante.variants != undefined) {
            grupoVariante.variants.map(function (opcion: any, index: number) {
              //if(opcion.valor){
              var strComa = ",";
              if (primero) {
                strComa = "";
                primero = false;
              }
              let strCantidad = "";
              if (opcion.quantity > 1) {
                strCantidad = opcion.quantity + "x ";
              }
              strOpciones += strComa + " " + opcion.name;
              //}
            });
          }
          srtVariantesAux += strOpciones;
        }
      });
    }
    return srtVariantesAux;
  }
  function getStrIngredientes(ingredientes: any) {
    let srtIngredientesAux = "";
    let primerIng = true;
    if (ingredientes != undefined) {
      ingredientes.map(function (ingrediente: any, indice: number) {
        var strComa = ",";
        if (primerIng) {
          strComa = "";
          primerIng = false;
        }
        srtIngredientesAux += strComa + " " + ingrediente;
      });
    }
    return srtIngredientesAux;
  }
  useEffect(() => {
    setStrVariantes(getStrVariantes(producto.producto.groups));
    setStrIngredientes(
      getStrIngredientes(producto.producto.ingredientesSinSel)
    );
    setMenu_lines(producto.producto.menu_lines || []);
  }, [producto]);
  /*
  {props.productoAgrupado?(
             <View
                style={{
                  flexDirection: "column"
                }}

              >
                <CustomCheckBox value={props.marcado} onPressCheck={props.onPressCheck}/>
              </View>
            ):(null)}
  */
  return (
    <View style={{width:'100%'}}>
      {producto.oculto ? null : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingTop: 4,
            paddingBottom: 4,
            alignItems: "center"
          }}
        >
          <View style={{ flex: 1,paddingRight:20 }}>
            {menu_lines.length > 0 ? (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={[
                    {
                      lineHeight: 21,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: "#3AA597",
                    },
                    producto.producto.agotado ||
                    producto.producto.state == "out_of_stock"
                      ? {
                          textDecorationColor: "black",
                          textDecorationLine: "line-through",
                        }
                      : {},
                  ]}
                >
                  {producto.cantidad}x{" "}
                </Text>
                <Text
                  style={[
                    { fontSize: 15,color:'#3AA597',fontWeight:'bold'},
                    producto.producto.agotado ||
                    producto.producto.state == "out_of_stock"
                      ? {
                          textDecorationColor: "black",
                          textDecorationLine: "line-through",
                        }
                      : {},
                  ]}
                >
                 {producto.producto.name}
                </Text>
              </View>
            ) : (
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={[
                    {
                      lineHeight: 21,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: "#3AA597",
                    },
                    producto.producto.agotado ||
                    producto.producto.state == "out_of_stock"
                      ? {
                          textDecorationColor: "black",
                          textDecorationLine: "line-through",
                        }
                      : {},
                  ]}
                >
                  {producto.cantidad}x{" "}
                </Text>
                <Text
                  style={[
                    { fontSize: 15, fontWeight: "bold",color: "black" },
                    producto.producto.agotado ||
                    producto.producto.state == "out_of_stock"
                      ? {
                          textDecorationColor: "black",
                          textDecorationLine: "line-through",
                        }
                      : {},
                  ]}
                >
                  {producto.producto.name}
                </Text>
              </View>
            )}

            {strIngredientes != "" ? (
              <View style={{}}>
                <Text
                  style={{ paddingRight: 4, fontSize: 14, color: "#666666" }}
                >
                  SIN: {strIngredientes}
                </Text>
              </View>
            ) : null}
            {strVariantes != "" ? (
              <View style={{}}>
                <Text style={{ paddingRight: 4, fontSize: 14 }}>
                  {strVariantes}
                </Text>
              </View>
            ) : null}
            {menu_lines.length > 0
              ? menu_lines.map(function (productoPlato: any, index: number) {
                  if (productoPlato != null) {
                    if (!productoPlato.oculto) {
                      let strVariantesAux = getStrVariantes(
                        productoPlato.variants
                      );
                      let strIngredientesAux = getStrIngredientes(
                        productoPlato.ingredients_removed
                      );
                      return (
                        <View style={{marginLeft:10}} key={index.toString()}>
                        <View style={{ flexDirection: "row" ,flex:1}}>
                          <View style={{ flexDirection: "row" ,flex:1}}>
                            <Text
                              style={[
                                {
                                  lineHeight: 21,
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  color: "#3AA597",
                                },
                                productoPlato.agotado ||
                                productoPlato.state == "out_of_stock"
                                  ? {
                                      textDecorationColor: "black",
                                      textDecorationLine: "line-through",
                                    }
                                  : {},
                              ]}
                            >
                              {"1"}x{" "}
                            </Text>
                            <Text
                              style={[
                                { fontSize: 16, fontWeight: "bold",color: "black"},
                                productoPlato.agotado ||
                                productoPlato.state == "out_of_stock"
                                  ? {
                                      textDecorationColor: "black",
                                      textDecorationLine: "line-through",
                                    }
                                  : {},
                              ]}
                            >
                              {productoPlato.name}
                            </Text>
                            </View>
                            <View>
                            {!props.productoAgrupado?(
                               <View
                              style={{
                                flexDirection: "column",
                              }}
                            >

                            </View>
                              ):(null)}
                          </View>
                          </View>
                          <View style={{ marginLeft: 20}}>
                            {strIngredientesAux != "" ? (
                              <View style={{}}>
                                <Text
                                  style={{
                                    paddingRight: 4,
                                    fontSize: 15,
                                    color: "#666666",
                                  }}
                                >
                                  SIN: {strIngredientesAux}
                                </Text>
                              </View>
                            ) : null}
                            {strVariantesAux != "" ? (
                              <View style={{}}>
                                <Text style={{ paddingRight: 4, fontSize: 15 }}>
                                  {strVariantesAux}
                                </Text>
                              </View>
                            ) : null}
                            
                          </View>
                          
                        </View>
                      );
                    }
                  }
                })
              : null}
          </View>
          {!props.productoAgrupado && menu_lines.length==0?(
             <View
            style={{
              flexDirection: "column",
            }}
          >
          </View>
            ):(null)}
         
        </View>
      )}
    </View>
  );
};
export default ProductoPedido;
