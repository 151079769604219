import React, { useState, useEffect } from 'react';
import { Alert,Platform,StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
//import { BarCodeScanner } from 'expo-barcode-scanner';
import { connect } from "react-redux";
import {FontAwesome,Ionicons,AntDesign} from '@expo/vector-icons';
import VistaDetallesPedido from './vistaDetallesPedido';
import Pedido from '../objetos/Pedido';
import { BarCodeScanner } from 'expo-barcode-scanner';
import {setCashLess} from "../../application/reducers";
const vistaEscanerBonos = (props:any) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  
  const [barCodeType, setBarCodeType] = useState([BarCodeScanner.Constants.BarCodeType.qr]);

  const [horizontal, setHorizontal] = useState(true);
  const [uuid, setUuid] = useState('');
    const [flash, setFlash] = useState(Camera.Constants.FlashMode.off);
  
  function goBack(){
    props.navigation.goBack()
  }
  function onPressBack(){
    props.setCashLess('')
    goBack()
  }
  function borrarCashLess(){
    console.log("borro")
    setBarCodeType([BarCodeScanner.Constants.BarCodeType.qr])
    props.setCashLess('')
    console.log("borro2",barCodeType)
    props.navigation.replace('vistaEscanerCashLess');
  }
  useEffect(() => {
    
    /*props.navigation.addListener('beforeRemove', (e) => {
      console.log("beforeRemove")
       props.setCashLess('')
    })*/
  }, []);
  useEffect(() => {
     
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
      
     props.navigation.setOptions({
             
            headerTitle:'Escanea la pulsera',
            headerLeft: () => (
              <TouchableOpacity style={{padding:20}} onPress={onPressBack}>
                <Ionicons name="arrow-back" size={24} color="white" />
              </TouchableOpacity>
             
            ),
           
        });
    
  }, []);
  useEffect(() => {
   if(props.width>props.height){
    setHorizontal(true)
   }else{
    setHorizontal(false)
   }
   
  }, [props.width]);
  
  useEffect(() => {
      global.socket.socketProductos.off("products:getCreditAndBono:result")
      global.socket.socketProductos.on("products:getCreditAndBono:result",(res:any)=>{
            console.log("res",res)
            res.data.uuid=uuid
            if(res.data.credits.length>0){
              props.setCashLess(res.data)
            setBarCodeType([''])
            }

           
            //setBarCodeType([BarCodeScanner.Constants.BarCodeType.qr])
      })
     /*let datastr ='1566055e-23a6-4db7-9cc9-a6fabe78def5'
     
      setUuid(datastr)
      global.socket.obtenerCreditosYBonos(datastr,global.usuario.shops)*/
  }, [uuid]);

  function onBarCodeRead (data:any) {
      
      let datastr = data.trim()
      setUuid(datastr)
      console.log("hago la llamada",datastr, global.usuario)

      global.socket.obtenerCreditosYBonos(datastr,global.usuario.shops)
      
  }
  function usarCreditos(){
    props.navigation.removeListener('beforeRemove')
    goBack()
  }
  function recargarCreditos(){
    var aux = {
      
    }
    props.navigation.navigate('recargarCredito',aux);
  }
  function toggleFlash() {
    if(flash==Camera.Constants.FlashMode.off){
      setFlash(Camera.Constants.FlashMode.torch)
    }else{
      setFlash(Camera.Constants.FlashMode.off)
    }
    
  }
  return (
    <View style={[{flex: 1,backgroundColor:'black'}]}>
      <Camera 
        style={[styles.camera,horizontal?{width:props.width,height:props.width}:{width:props.height,height:props.height}]}
        type={type} 
        onBarCodeScanned={({ type, data }:any ) =>{
          if(data!=undefined && data!=null && data!=''){
            onBarCodeRead(data)
          }
        }}
        barCodeScannerSettings={{
          barCodeTypes: barCodeType,
        }}
        ratio={'1:1'}
        flashMode={flash}
        autoFocus={Camera.Constants.AutoFocus.on}
        focusDepth={1}
        >
        <View style={{position:'absolute', top:0,zIndex:2,width:props.width,height:100,justifyContent:'center', padding:20,alignItems:'flex-end'}}>
          <TouchableOpacity
            onPress={toggleFlash}>
            {flash==Camera.Constants.FlashMode.off?(
              <Ionicons name="flash" size={28} color="white" />
              ):(
              <Ionicons name="flash-off" size={28} color="white" />
            )}
            
          </TouchableOpacity>
        </View>
      </Camera>
       {
              props.cashLess!='' ?
                (
                  <View style={{backgroundColor:'rgba(0, 0, 0, 0.8)',position:'absolute',width:'100%',height:'100%',justifyContent:'center',alignItems:'center'}}>
                    <View style={{width:'90%',justifyContent:'center',alignItems:'center'}}>
                      <View style={{height:props.height-100,borderColor:'#BABABA',borderWidth:1,backgroundColor:'white',width:'100%',alignItems:'center',maxWidth:400,justifyContent:'center',borderRadius:8}}>
                      <TouchableOpacity  onPress={borrarCashLess} style={{position:'absolute',top:20,right:20,zIndex:2}}>
                        <AntDesign name="close" size={32} color="#3AA597" />
                      </TouchableOpacity>
                      <View style={{height:'100%',width:'100%',maxWidth:400}}>
                        <View style={{height:'100%',flex:1,width:'100%',marginTop:20}}>
                          <Text style={{fontSize:24,color:'#3AA597',textAlign:'center'}}>
                            Crédito
                          </Text>
                          <View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
                            {props.cashLess.user!=undefined && props.cashLess.user.email!='' && props.cashLess.user.email!=null && props.cashLess.user.email!=undefined ? 
                              (
                                <Text style={{fontSize:26,color:'#3AA597',textAlign:'center'}}>
                                  {props.cashLess.user.email}
                                </Text>
                              )
                              :
                              (
                                <Text style={{fontSize:26,color:'#3AA597',textAlign:'center'}}>
                                  {uuid}
                                </Text>
                              )
                            }
                            <Text style={{fontSize:28,fontWeight:'bold',color:'#3AA597',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                              {props.cashLess.credits[0]!=undefined?
                               (props.cashLess.credits[0].amount_available/100).toFixed(2):(0)}€
                            </Text>
                          </View>
                        </View>
                        
                        <View style={{alignItems:'center',flexDirection:'row'}}>
                          <TouchableOpacity onPress={recargarCreditos.bind(this)} style={{flex:1,backgroundColor:'#3AA597',width:'80%',margin:20,borderRadius:50}}>
                             <Text style={{fontWeight:'bold',color:'white',textAlign:'center',padding:10}}>Recargar crédito</Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{alignItems:'center',flexDirection:'row'}}>
                          <TouchableOpacity onPress={usarCreditos.bind(this)} style={{flex:1,backgroundColor:'#3AA597',width:'80%',margin:20,marginTop:0,borderRadius:50}}>
                             <Text style={{fontWeight:'bold',color:'white',textAlign:'center',padding:10}}>Utilizar crédito</Text>
                          </TouchableOpacity>
                        </View>
                        
                      </View>
            
                     </View>
                    </View>
                  </View>
                )
              :
                (
                  null
                )
            }
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    //flex: 1,
  },
  
});
const mapStateToProps = (state:any) => {
  return {
    cashLess: state.carrito.cashLess,
    width:state.dimensions.width,
    height:state.dimensions.height
  };
};
const mapDispatchToProps = {
  setCashLess
};
export default connect(mapStateToProps,mapDispatchToProps)(vistaEscanerBonos);
