export function getIngredientsText(ingredients: any) {
  console.log("getIngredientsText", ingredients);
  let srtIngredientsAux = "";
  let primerIng = true;
  if (ingredients != undefined) {
    ingredients.map(function (ingredient: any) {
      var strComa = ", ";
      if (primerIng) {
        strComa = "";
        primerIng = false;
      }
      srtIngredientsAux += strComa + ingredient;
    });
  }

  return srtIngredientsAux;
}
