import React, { useState,useEffect } from "react";
import { View,ScrollView,TouchableOpacity,TextInput ,Text} from "react-native";
import GoogleLocationsInput from "../components/GoogleLocationsInput";
import { connect } from "react-redux";
import { styles } from '../../assets/styles/styles';
const vistaDelivery = (props:any) => {
  let tienda = props.route.params.tienda
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [localization, setLocalization] = useState("");
  const [province, setProvince] = useState("");
  const [code, setCode] = useState("");
  const [info, setInfo] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const active =
    street !== "" &&
    number !== "" &&
    localization !== "" &&
    province !== "" &&
    phone !== "" &&
    code !== ""
      ? true
      : address !== "" && info !== "" && phone !== ""
      ? true
      : false;

  

  const locationHandler = (item) => {
    console.log("direccion",item)
    item.address_components.map((el) => {
      if (el.types.includes("route")) {
        setStreet(el.long_name);
      }
      if (el.types.includes("locality")) {
        setLocalization(el.long_name);
      }
      if (el.types.includes("street_number")) {
        setNumber(el.long_name);
      }
      if (el.types.includes("administrative_area_level_2")) {
        setProvince(el.long_name);
      }
      if (el.types.includes("postal_code")) {
        setCode(el.long_name);
      }
    });
    setAddress(item.formatted_address);
  };
  function goBack(){
    props.navigation.goBack()
  }
  function seleccionarDireccion(){
    props.route.params.onGoBack(address);
    goBack()
  }
  
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(props.height-80),marginTop:80,width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{maxWidth:400,width:'80%',height:'90%',borderRadius:8,backgroundColor:'#E1E1E1'}}>
       <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:20,width:'100%',textAlign:'center'}}>Elija la dirección</Text>
        <ScrollView 
          contentContainerStyle={{paddingBottom:90,paddingTop:32}}
          showsVerticalScrollIndicator={false}
        >
      <View style={{alignItems:'center'}}>

        <GoogleLocationsInput
          placeholder="Escribe aquí dirección, calle, ciudad..."
          setData={locationHandler}
          style={styles.inputFormDelivery}
          textInit={tienda.deliveryAddress}
        />
        <TextInput
          placeholder="Calle"
          value={street}
          style={styles.inputFormDelivery}
          onChangeText={setStreet}
          editable={false}
          
        />
        <TextInput
          placeholder="Número"
          value={number}
          style={styles.inputFormDelivery}
          onChangeText={setNumber}
          editable={false}
        />
        <TextInput
          placeholder="Localidad"
          value={localization}
          style={styles.inputFormDelivery}
          onChangeText={setLocalization}
          editable={false}
        />
        <TextInput
          placeholder="Provincia"
          value={province}
          style={styles.inputFormDelivery}
          onChangeText={setProvince}
          editable={false}
        />
        <TextInput
          placeholder="Código Postal"
          value={code}
          style={styles.inputFormDelivery}
          onChangeText={setCode}
          editable={false}
        />
        <TextInput
          placeholder="Información adicional (piso, puerta...)"
          value={info}
          style={styles.inputFormDelivery}
          onChangeText={setInfo}
        />

      <TextInput 
        placeholder="Teléfono" 
        value={phone}
        style={styles.inputFormDelivery}
        onChangeText={setPhone} />
      </View>
      </ScrollView>
          <TouchableOpacity onPress={seleccionarDireccion} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
            <View style={{backgroundColor:'#3AA597',borderRadius:50}}>
              <Text style={{padding:10,fontWeight:'bold',color:'white',paddingLeft:20,paddingRight:20}}>Aceptar</Text>
            </View>
          </TouchableOpacity>
      </TouchableOpacity>
    </View>
    </TouchableOpacity>
  );
};

const mapStateToProps = (state:any) => {
  return {
    height: state.dimensions.height
  };
};
export default connect(mapStateToProps)(vistaDelivery);