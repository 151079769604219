import React, { useState, useEffect, useRef } from 'react';
import { StatusBar,Platform,Alert,FlatList,ScrollView,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';

import {Octicons,Ionicons,MaterialIcons,FontAwesome,AntDesign  } from '@expo/vector-icons';
import {addProducto,eliminarProducto,setPedidoImprimir,setPedidoSeleccionado,setFooterVisible} from "../../application/reducers";

import PedidoCamarero from '../components/PedidoCamarero';
import Pedido from '../objetos/Pedido';
import DetallesPedidoCamarero from './detallesPedidoCamarero';
import { connect } from "react-redux";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import ProgressBar from '../components/ProgressBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
const TopBar = createMaterialTopTabNavigator();

const listadoPedidosDatafono = (props:any) => {


  const [btnSel, setBtnSel] = useState('Tarjeta');

  const [pedidosCamarero, setPedidosCamarero] = useState([]);
  const [carritosBBDD, setCarritosBBDD] = useState([]);
  const [carritosDatafonoBBDD, setCarritosDatafonoBBDD] = useState([]);


  const [pedidoSel, setPedidoSel] = useState(false);
  const [cargando, setCargando] = useState(-1);
  const [precioTotalTarjeta, setPrecioTotalTarjeta] = useState(0);
  const [precioTotalEfectivo, setPrecioTotalEfectivo] = useState(0);
  const [widthTab, setWidthTab] = useState(global.pantallaDividida ? props.width*0.4-28 : props.width-56);
  

  const [pedidosCamareroSincronizados, setPedidosCamareroSincronizados] = useState([]);
  const [pedidosCamareroNoSincronizados, setPedidosCamareroNoSincronizados] = useState([]);

  const [pedidosDatafonoSincronizados, setPedidosDatafonoSincronizados] = useState([]);
  const [pedidosDatafonoNoSincronizados, setPedidosDatafonoNoSincronizados] = useState([]);

  const [pedidosSincronizados, setPedidosSincronizados] = useState([]);
  const [pedidosNoSincronizados, setPedidosNoSincronizados] = useState([]);

  const [pedidoSelSincronizado, setPedidoSelSincronizado] = useState(false);
  const [pedidoSelNoSincronizado, setPedidoSelNoSincronizado] = useState(false);

  const [precioTotalSinc, setPrecioTotalSinc] = useState(0);
  const [precioTotalNoSinc, setPrecioTotalNoSinc] = useState(0);

  const [precioTotalTarjetaSincronizado, setPrecioTotalTarjetaSincronizado] = useState(0);
  const [precioTotalEfectivoSincronizado, setPrecioTotalEfectivoSincronizado] = useState(0);

  const [precioTotalTarjetaNoSincronizado, setPrecioTotalTarjetaNoSincronizado] = useState(0);
  const [precioTotalEfectivoNoSincronizado, setPrecioTotalEfectivoNoSincronizado] = useState(0);

  function onPressBtn(btn:any){
      setBtnSel(btn)
    }
  function goBack(){

    if(cargando==-1){

        props.navigation.goBack()
      }
    
  }
  
  function calcularPrecioNoSincronizados(pedidos:any){
    let precioTotalTarjetaAuxNoSincronizados=0
    let precioTotalEfectivoAuxNoSincronizados=0
    pedidos.map(function(pedidoAux:any,index:any){
      if(pedidoAux.payment_method=='dataphone'){
        precioTotalTarjetaAuxNoSincronizados=precioTotalTarjetaAuxNoSincronizados+pedidoAux.calcularPrecioPedido()
      }else{
        precioTotalEfectivoAuxNoSincronizados=precioTotalEfectivoAuxNoSincronizados+pedidoAux.calcularPrecioPedido()
      }
      
    })
    setPrecioTotalTarjetaNoSincronizado(precioTotalTarjetaAuxNoSincronizados)
    setPrecioTotalEfectivoNoSincronizado(precioTotalEfectivoAuxNoSincronizados)
  }
  function calcularPrecioSincronizados(pedidos:any){
    let precioTotalTarjetaAuxSincronizados=0
    let precioTotalEfectivoAuxSincronizados=0
    pedidos.map(function(pedidoAux:any,index:any){
      if(pedidoAux.payment_method=='dataphone'){
          precioTotalTarjetaAuxSincronizados=precioTotalTarjetaAuxSincronizados+pedidoAux.calcularPrecioPedido()
      }else{
        precioTotalEfectivoAuxSincronizados=precioTotalEfectivoAuxSincronizados+pedidoAux.calcularPrecioPedido()
      }
      
    })
    setPrecioTotalTarjetaSincronizado(precioTotalTarjetaAuxSincronizados)
    setPrecioTotalEfectivoSincronizado(precioTotalEfectivoAuxSincronizados)
  }
  

   let cambiarSincronizados = function(pedidosCorrectos:any){
     let pedidosCamareroAux = pedidosCamareroNoSincronizados
      let pedidosAux=[]
      console.log("pedidosCorrectos",pedidosCorrectos)
      console.log("pedidosCamareroAux",pedidosCamareroAux)
      pedidosCamareroAux.map(function(pedido:any,index:any){
        if(pedidosCorrectos.indexOf(pedido.note_id)!=-1){
          console.log("hola",pedido)
          pedido.sincronizado=true
          pedidosCamareroSincronizados.unshift(pedido)
          guardarPedidoSincronizadoBBDD(pedido)
        }else{;

          pedido.sincronizado=false
          pedidosAux.push(pedido)
        }
      })
      setPedidosCamareroSincronizados(pedidosCamareroSincronizados)
      setPedidosCamareroNoSincronizados(pedidosAux)
      eliminarPedidosBBDD(pedidosCorrectos)
   }
   function guardarPedidoSincronizadoBBDD(pedidoAux:any){
    return new Promise(async function(resolve, reject) {
      await global.db.transaction((tx:any) => {
         tx.executeSql(
           "CREATE TABLE IF NOT EXISTS pedidosSincronizados("+
             "pedidosSincronizados TEXT"+
           ");"
         );
      },function(err:any){console.log("err",err)});

      await global.db.transaction((tx:any) => {
         tx.executeSql(
           "INSERT OR REPLACE INTO pedidosSincronizados("+
           "pedidosSincronizados"+
           ") VALUES ('"+
           JSON.stringify(pedidoAux)+
           "');"
         );
      },function(err:any){console.log("err",err)});

      
    });
   }
  let eliminarPedidosBBDD = function(pedidosCorrectos:any,pedidosCamareroParam:any){
    setPedidoSel(false)
    props.setPedidoSeleccionado(false)
    return new Promise(function(resolve, reject) {
   
      let pedidosAux=[]
      pedidosCamareroNoSincronizados.map(function(pedido:any,index:any){
        if(pedidosCorrectos.indexOf(pedido.note_id)==-1){
          pedidosAux.push(pedido)
        }
      })

      let carritosAux = []
      carritosBBDD.map(function(carrito:any,index:any){
        if(pedidosCorrectos.indexOf(carrito.note_id)==-1){
          carritosAux.push(carrito)
        }
      })
      setPedidosCamareroNoSincronizados(pedidosAux)
      setCarritosBBDD(carritosAux)

      global.db.transaction((tx:any) => {
        tx.executeSql(
          "DELETE FROM carritos WHERE rowid IN ("+pedidosCorrectos.toString()+");",[],
          function(trans:any, result:any){
            console.log("result",result)
            resolve(true)
          }
        );
      },function(err:any){console.log("err",err)});
    });
  }
  useEffect(() => {
    
    if(btnSel=='Tarjeta'){
      setPedidosNoSincronizados(pedidosDatafonoNoSincronizados)
      setPedidosSincronizados(pedidosDatafonoSincronizados)
      let precioTotalSincAux=0
      let precioTotalNoSincAux=0
      pedidosDatafonoSincronizados.map(function(pedidoAux:any,index:any){
        precioTotalSincAux=precioTotalSincAux+pedidoAux.calcularPrecioPedido()
      })
      pedidosDatafonoNoSincronizados.map(function(pedidoAux:any,index:any){
        precioTotalNoSincAux=precioTotalNoSincAux+pedidoAux.calcularPrecioPedido()
      })
      setPrecioTotalSinc(precioTotalSincAux)
      setPrecioTotalNoSinc(precioTotalNoSincAux)
    }else{
      setPedidosNoSincronizados(pedidosCamareroNoSincronizados)
      setPedidosSincronizados(pedidosCamareroSincronizados)
      let precioTotalSincAux=0
      let precioTotalNoSincAux=0
      pedidosCamareroSincronizados.map(function(pedidoAux:any,index:any){
        precioTotalSincAux=precioTotalSincAux+pedidoAux.calcularPrecioPedido()
      })
      pedidosCamareroNoSincronizados.map(function(pedidoAux:any,index:any){
        precioTotalNoSincAux=precioTotalNoSincAux+pedidoAux.calcularPrecioPedido()
      })
      setPrecioTotalSinc(precioTotalSincAux)
      setPrecioTotalNoSinc(precioTotalNoSincAux)
    }
  }, [btnSel]);
  useEffect(() => {
    calcularPrecioSincronizados(pedidosCamareroSincronizados)
  }, [pedidosCamareroSincronizados]);
  useEffect(() => {
    calcularPrecioNoSincronizados(pedidosCamareroNoSincronizados)
  }, [pedidosCamareroNoSincronizados]);
  useEffect(() => {
      props.navigation.addListener('beforeRemove', () => {
        props.setFooterVisible(true)
      });
      props.setFooterVisible(false)
      if(global.db!=undefined && global.db!=null && global.db!=false){
      /*global.usuario.cargarPedidosBBDD().then(function(pedidos:any){
        setPedidosCamareroNoSincronizados(pedidos)
      })
      global.usuario.cargarPedidosSincronizadosBBDD().then(function(pedidos:any){
        setPedidosCamareroSincronizados(pedidos)
      })*/
      console.log("cargar pedidos datafono")
      global.usuario.cargarPedidosDatafonoBBDD().then(function(resultado:any){
        console.log("Resultado cargar pedidos datafono",resultado)
        let pedidosEfectivoSinc=[]
        let pedidosTarjetaSinc=[]
        resultado.pedidosSinc.map(function(pedidoAux:any,index:any){
          if(pedidoAux.payment_method=='cash'){
            pedidosEfectivoSinc.push(pedidoAux)
          }else{
            pedidosTarjetaSinc.push(pedidoAux)
          }
        })
       
        setPedidosCamareroSincronizados(pedidosEfectivoSinc)
        setPedidosDatafonoSincronizados(pedidosTarjetaSinc)


        let pedidosEfectivoNoSinc=[]
        let pedidosTarjetaNoSinc=[]
        resultado.pedidosNoSinc.map(function(pedidoAux:any,index:any){
          if(pedidoAux.payment_method=='cash'){
            pedidosEfectivoNoSinc.push(pedidoAux)
          }else{
            pedidosTarjetaNoSinc.push(pedidoAux)
          }
        })
        setPedidosCamareroNoSincronizados(pedidosEfectivoNoSinc)
        setPedidosDatafonoNoSincronizados(pedidosTarjetaNoSinc)


        setPedidosNoSincronizados(pedidosTarjetaNoSinc)
        setPedidosSincronizados(pedidosTarjetaSinc)

        let precioTotalSincAux=0
        let precioTotalNoSincAux=0
        pedidosTarjetaSinc.map(function(pedidoAux:any,index:any){
          precioTotalSincAux=precioTotalSincAux+pedidoAux.calcularPrecioPedido()
        })
        pedidosTarjetaNoSinc.map(function(pedidoAux:any,index:any){
          precioTotalNoSincAux=precioTotalNoSincAux+pedidoAux.calcularPrecioPedido()
        })
        setPrecioTotalSinc(precioTotalSincAux)
        setPrecioTotalNoSinc(precioTotalNoSincAux)
      })
    }
  }, []);
  useEffect(() => {
    
    props.navigation.setOptions({
            headerLeft: () => (
              <TouchableOpacity style={{padding:20}} onPress={goBack}>
                <AntDesign name="left" size={24} color="black" />
              </TouchableOpacity>
             
            ),
            headerRight: () => (
              <View style={{paddingRight:20}}>
                {props.conexion?(
                  <View style={{height:20,width:20, borderRadius:20,backgroundColor:'#73E13F'}}>
                  </View>
                  ):(
                  <View style={{height:20,width:20, borderRadius:20,backgroundColor:'red'}}>
                  </View>
                )}
              </View>
            ),
        });
  }, [cargando]);

  useEffect(() => {
    setWidthTab(global.pantallaDividida ? props.width*0.4-28 : props.width-56)
  }, [props.width]);
  
  
  function seleccionarPedido(pedido:any){
    setPedidoSel(pedido)
    props.setPedidoSeleccionado(pedido)
    if(!global.pantallaDividida){
      let aux={
          pedidoSel:pedido,
          eliminarPedidosBBDD: (pedidosOk:any,pedidosCamarero) => eliminarPedidosBBDD(pedidosOk,pedidosCamarero),
      }
      props.navigation.navigate('DetallesPedidoCamarero',aux)
    }
  }
  
  
  function createTabSincronizados(propsInterno){
     console.log("props.height-80-50-48",props.height-80-50-48)
     return(
        <View style={[{height:props.height-80-50},global.pantallaDividida?{height:props.height-80-50-60}:{height:props.height-80-50}]}>
          <ScrollView style={{height:props.height-80-50-48,padding:8}} contentContainerStyle={[{paddingBottom:60},!global.isDatafono?{paddingBottom:60}:{paddingBottom:160}]} showsVerticalScrollIndicator={false}>
                {
                  pedidosSincronizados.map(function(item:any) {
                    return (
                      <PedidoCamarero pedido={item} seleccionarPedido={seleccionarPedido.bind(this,item)}></PedidoCamarero>
                    )
                  })
                }

            </ScrollView>
            <View style={[{backgroundColor:'white',height:50,width:'100%',zIndex:3,position:'absolute'},!global.isDatafono?{bottom:0}:{bottom:100}]}>
              <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                <View style={{flex:1}}>
                  <View style={{backgroundColor:'#3AA597',alignItems:'center',flex:1,flexDirection:'row',paddingLeft:20}}>
                    <Text style={{color:'white',paddingRight:4}}>
                     Total: 
                    </Text>
                    <Text style={{color:'white'}}>
                     {(precioTotalSinc).toFixed(2)}€
                    </Text>
                  </View>
                  <View style={{backgroundColor:'#3AA597',alignItems:'center',flex:1,flexDirection:'row',paddingLeft:20}}>
                    <Text style={{color:'white',paddingRight:4}}>
                     Nº Pedidos: 
                    </Text>
                    <Text style={{color:'white'}}>
                     {pedidosSincronizados.length}
                    </Text>
                  </View>
                </View>
              </View>

          </View>
          </View>)
  }
  function createTabNoSincronizados(propsInterno){
     return(
    <View style={[{height:props.height-80-50},global.pantallaDividida?{height:props.height-80-50-60}:{height:props.height-80-50}]}>
          <ScrollView style={{height:props.height-80-50-48,padding:8}} contentContainerStyle={[{alignItems:'center',paddingBottom:60},!global.isDatafono?{paddingBottom:60}:{paddingBottom:160}]}
          showsVerticalScrollIndicator={false}
          >

                      
                {
                  pedidosNoSincronizados.map(function(item:any) {
                    return (
                      <PedidoCamarero pedido={item} seleccionarPedido={seleccionarPedido.bind(this,item)}></PedidoCamarero>
                    )
                  })
                }

            </ScrollView>
            <View style={[{backgroundColor:'white',height:50,width:'100%',zIndex:3,position:'absolute'},!global.isDatafono?{bottom:0}:{bottom:100}]}>
              <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                <View style={{flex:1}}>
                  <View style={{backgroundColor:'#3AA597',alignItems:'center',flex:1,flexDirection:'row',paddingLeft:20}}>
                    <Text style={{color:'white',paddingRight:4}}>
                     Total: 
                    </Text>
                    <Text style={{color:'white'}}>
                     {(precioTotalNoSinc).toFixed(2)}€
                    </Text>
                  </View>
                  <View style={{backgroundColor:'#3AA597',alignItems:'center',flex:1,flexDirection:'row',paddingLeft:20}}>
                    <Text style={{color:'white',paddingRight:4}}>
                     Nº Pedidos: 
                    </Text>
                    <Text style={{color:'white'}}>
                     {pedidosNoSincronizados.length}
                    </Text>
                  </View>
                </View>
              </View>

          </View>
          </View>)
  }
  return (
     <View style={{height: '100%',flexDirection:'row'}}>
       <View style={[{borderColor:'#BABABA',borderWidth:1,backgroundColor:'#E1E1E1',height:props.height-60},global.pantallaDividida?{width:'40%'}:{width:'100%'}]}>
         <View style={{height:40,flexDirection:'row',borderRadius:40,backgroundColor:'white',marginBottom:8,marginTop:8,marginHorizontal:20,borderWidth:1,borderColor:'#757575'}}>
          <TouchableOpacity onPress={onPressBtn.bind(this,'Tarjeta')} style={[styles.btn,btnSel=='Tarjeta'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
            <Text style={[styles.textBtn,btnSel=='Tarjeta'?{color:'white'}:{color:'black'}]}>Tarjeta</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressBtn.bind(this,'Efectivo')} style={[styles.btn,btnSel=='Efectivo'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
            <Text style={[styles.textBtn,btnSel=='Efectivo'?{color:'white'}:{color:'black'}]}>Efectivo</Text>
          </TouchableOpacity>
        </View>

         <TopBar.Navigator
          sceneContainerStyle={{backgroundColor: 'transparent'}}
          style={{backgroundColor: 'transparent'}}
          screenOptions={{
            tabBarActiveTintColor: '#3AA597',
            tabBarInactiveTintColor: '#929292',
            tabBarLabelStyle: { fontSize: 16,fontWeight:'bold' },
            tabBarItemStyle: [{ width: props.width*0.4/2 },global.pantallaDividida ?{}:{width: props.width/2}],
            tabBarStyle:  [{ backgroundColor: 'white'},global.pantallaDividida ?{}:{width: props.width}],
            tabBarIndicatorStyle:{backgroundColor:'#3AA597',height:3},
            tabBarPressColor:'white'
          }}  >
          <TopBar.Screen name="Sincronizados" component={createTabSincronizados} options={{title:"Sincronizados"}}/>
          <TopBar.Screen name="noSincronizados" component={createTabNoSincronizados} options={{title:"No sincronizados"}}/> 
       
        </TopBar.Navigator>
        
        </View>
        <View style={{width:'60%'}}>
           <DetallesPedidoCamarero navigation={props.navigation} eliminarPedidosBBDD={eliminarPedidosBBDD}></DetallesPedidoCamarero>
        </View>
    </View>
        
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width: state.dimensions.width,
    height: state.dimensions.height,
    conexion:state.dimensions.conexion,
  };
};
const mapDispatchToProps = {
  setPedidoImprimir,
  addProducto,
  eliminarProducto,
  setPedidoSeleccionado,
  setFooterVisible
};
export default connect(mapStateToProps,mapDispatchToProps)(listadoPedidosDatafono);
export const styles = StyleSheet.create({
  btn:{
    flex:1,
    borderRadius:40,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center'
  },
  
  textBtn:{
    fontWeight:'bold'
  }
});