import React, { useState, useEffect, useRef } from 'react';
import {TouchableOpacity,Text,View,TextInput,Image} from "react-native";
import { connect } from "react-redux";
import {AntDesign,EvilIcons,Ionicons ,FontAwesome,FontAwesome5,Feather } from '@expo/vector-icons';

const Footer = (props:any) => {
    const [numeroRecoger, setNumeroRecoger] = useState(0);
    const {navigation} = props
    function abrirMenu(){
      props.navigationRedux.navigate('vistaMenuInterior');
    }
    function abrirEscaner(){
        props.navigationRedux.navigate('vistaEscaner');
    }
    function abrirPedidosRecoger(){
      props.navigationRedux.navigate('gestionPedidos',{ 
          opcion:'recoger' 
        }
      )
    }
    useEffect(() => {
      obtenerNumeros(props.pedidos)
    }, [props.pedidos]);
    function obtenerNumeros(pedidos:any){
    
    let pedidosAux:Array<any>=[]
    pedidosAux=pedidos

    
    
    let numRecoger=0


    pedidosAux.map(function(pedido:any,index:any){
      if(pedido.state=='await_pickup' || pedido.state=='await_shipment'){
        numRecoger=numRecoger+1
      }  
    })
    setNumeroRecoger(numRecoger)
  }
    return(
        <View style={[{borderColor:'#D9D9D9',borderTopWidth:1,zIndex:2,width:'100%',bottom:0,height:60,backgroundColor:'white',position:'absolute',flex:1,justifyContent:'space-between',alignItems:'center',flexDirection:'row',paddingLeft:20,paddingRight:20},props.footerVisible?{height:60,overflow:'visible'}:{height:0,overflow:'hidden'}]}>
              <View style={[{flex:1,alignItems:'center',justifyContent:'space-around',flexDirection:'row'}]}>
                <TouchableOpacity  onPress={abrirMenu} style={{padding:10}}>
                  <Feather name="menu" size={24} color="black" />
                </TouchableOpacity>
              </View>
              <View style={[{flex:1,alignItems:'center',justifyContent:'space-around',flexDirection:'row'}]}>
                <View style={{height:70,width:70,borderRadius:70,backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',marginBottom:30}}>
                  <Image
                      style={{width: 50,height: 50,resizeMode:'cover'}}
                      source={
                        require('../../assets/icons/cbimage.png')
                      }
                  />
                </View>
              </View>
              <View style={[{flex:1,alignItems:'center',justifyContent:'space-around',flexDirection:'row'}]}>
                <TouchableOpacity  onPress={abrirEscaner} style={{padding:10}}>
                  <AntDesign name="scan1" size={24} color="black"/>
                </TouchableOpacity>
              </View>
        </View>
    );
    
}
const mapStateToProps = (state:any) => {
  return {
   pedidos:state.pedidos.pedidos,
   navigationRedux:state.config.navigationRedux,
   footerVisible:state.config.footerVisible
  };
};
export default connect(mapStateToProps)(Footer);
