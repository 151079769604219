import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../../../components/Divider";
import GroupVariants from "./GroupVariants";

// Methods
import hasVariants from "../../methods/hasVariants";

// Methods
import radioType from "./methods/radioType";
import checkType from "./methods/checkType";

const Variants = ({ data, selectedVariants, setSelectedVariants }) => {
  const { variants } = data;

  useEffect(() => {
    if (hasVariants(data)) {
      for (const group of variants) {
        for (const option of (group.options || group.variants)) {
          if (option.default === 1) {
            if (group.type === "radio") {
              radioType({
                group,
                option,
                currentGroup: null,
                setSelectedVariants,
              });
            } else {
              checkType({
                group,
                option,
                currentGroup: null,
                setSelectedVariants,
              });
            }
          }
        }
      }
    }
  }, []);

  if (hasVariants(data)) {
    return (
      <View style={styles.container}>
        {variants.map((variant) => (
          <>
            <GroupVariants
              data={variant}
              setSelectedVariants={setSelectedVariants}
              selectedVariants={selectedVariants}
            />
            <Divider height={40} />
          </>
        ))}
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default Variants;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 0,
    padding: 28,
  },
});
