import { Feather } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Platform, View, TouchableOpacity } from "react-native";
import Divider from "../../../../components/Divider";
import ActionButton from "./components/ActionButton";
import AddedInfoContainer from "./components/AddedInfoContainer";
import RemoveProductStatic from "./components/RemoveProductStatic";

const AddedProduct = ({ data, onAdd, quantity, onRemove }) => {
  return (
    <TouchableOpacity onPress={onAdd} style={styles.container}>
      <View style={styles.content}>
        <RemoveProductStatic quantity={quantity} onPress={onRemove} />
        <Divider width={12} />
        <AddedInfoContainer data={data} />
        <View style={styles.buttonContainer}>
          <ActionButton color={"#D2F3C2"} onPress={onAdd}>
            <Feather name="plus" size={30} color={"#4AA81E"} />
          </ActionButton>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default AddedProduct;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#F8F8F8",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    flex: 1,
    minHeight: 55,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingRight: 12
  },
  buttonContainer: {
    height: "100%",
    maxWidth: 60,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});
