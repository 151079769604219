import React, { useState, useEffect, useRef } from 'react';
import {ScrollView, StatusBar,Platform,FlatList,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';

import {Octicons,MaterialIcons,FontAwesome,AntDesign  } from '@expo/vector-icons';
import { connect } from "react-redux";

const vistaCalculadora = (props:any) => {
  let precioTotal=props.route.params.precioTotal
  let prepararPedido = props.route.params.prepararPedido
  const [valuePagado, setValuePagado] = useState('0.00');
  const [valueImportePagar, setValueImportePagar] = useState(parseFloat(precioTotal).toFixed(2));
  const [valueCambio, setValueCambio] = useState('0.00');
  const [valueIntroducido, setValueIntroducido] = useState('0.00');
  const [numerosActivados, setNumerosActivados] = useState(true);
  function goBack(){
    props.navigation.goBack()
  }
  function pagar(){
    setValuePagado('0.00')
    setValueImportePagar('0.00')
    setValueCambio('0.00')
    setValueIntroducido('0.00')
    prepararPedido('cash').then(function(res:any){
      goBack()
    })
    
  }
  function addMoneda(value:any){
    let valor =  parseFloat(valueIntroducido)+value
    valor =valor.toFixed(2)
    setValueIntroducido(valor)
    actualizarCambio(valor)
  }
  function actualizarCambio(valueIntroducido:any){
    let valor = parseFloat(valueIntroducido) - parseFloat(valueImportePagar)
    if(valor>0){
      valor =valor.toFixed(2)
       setValueCambio(valor)
    }
   
  }
  function addNumero(value:any){
    if(numerosActivados){
      let valorAux;
      if(value=='<'){
        let valor =(valueIntroducido.slice(0, valueIntroducido.length-1)).split('.').join('')
        valorAux = valor.slice(0, valor.length-2) + "." + valor.slice(valor.length-2);
        setValueIntroducido(parseFloat(valorAux).toFixed(2))
      }else{
        let valor = (valueIntroducido+value).split('.').join('')
        valorAux = valor.slice(0, valor.length-2) + "." + valor.slice(valor.length-2);
        setValueIntroducido(parseFloat(valorAux).toFixed(2))
      }
      actualizarCambio(valorAux)
    }
  }
  function restablecer(value:any){
    setValueIntroducido('0.00')
    setValueCambio('0.00')
  }
  return (
   
    <TouchableOpacity activeOpacity={1} onPress={goBack} style={{width:'100%',height:'100%'}}>
     <TouchableOpacity activeOpacity={1} style={[{backgroundColor:'black',height:(props.height-80),marginTop:80},Platform.OS!='web'?{height:(props.height-80-StatusBar.currentHeight)}:{},global.pantallaDividida?{width:'60%',marginLeft:'40%'}:{width:'100%',height:'100%',marginTop:0}]}>

      {!global.pantallaDividida?(null):(
        <TouchableOpacity  onPress={goBack} style={{backgroundColor:'#293133',width:'100%',height:60,alignItems:'flex-end',justifyContent:'center'}}>
          <AntDesign name="close" size={32} color="white" />
        </TouchableOpacity>
      )}
      
      <View style={{backgroundColor:'#4E3B31',width:'100%',flexDirection:'row',justifyContent:'space-between',height:80,paddingLeft:10,paddingRight:10}}>

        <View style={{flex:1,borderWidth:1,borderColor:'black',alignItems:'center',justifyContent:'center',margin:10}}>
          <Text style={{color:'white'}}>
           Importe a pagar
          </Text>
          <Text style={{color:'white'}}>
            {valueImportePagar}
          </Text>
        </View>
        <View style={{flex:1,borderWidth:1,borderColor:'black',alignItems:'center',justifyContent:'center',margin:10}}>
          <Text style={{color:'white'}}>
            Cambio
          </Text>
          <Text style={{color:'white'}}>
            {valueCambio}
          </Text>
        </View>
      </View>
      <View style={{borderWidth:1,borderColor:'black',backgroundColor:'white',width:'100%',justifyContent:'center',height:40,alignItems:'center'}}>
        <Text>
            {valueIntroducido}
        </Text>
      </View>
      <View style={{width:'100%',height:'100%',flexDirection:'row',flex:1}}>
        

        <View style={{width:'100%',height:'100%',flex:1}}>
          <View style={{flexDirection:'row',flex:1,height:'100%'}}>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,0.05)}>
              <Text style={styles.txtMoneda}>
                0.05
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,0.10)}>
              <Text style={styles.txtMoneda}>
                0.10
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,0.20)}>
              <Text style={styles.txtMoneda}>
                0.20
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,0.50)}>
              <Text style={styles.txtMoneda}>
                0.50
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,1)}>
              <Text style={styles.txtMoneda}>
                1
              </Text>
            </TouchableOpacity>
          </View>
         
          <View style={{flexDirection:'row',flex:1,height:'100%'}}>
            
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,2)}>
              <Text style={styles.txtMoneda}>
                2
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,5)}>
              <Text style={styles.txtMoneda}>
                5
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,10)}>
              <Text style={styles.txtMoneda}>
                10
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,20)}>
              <Text style={styles.txtMoneda}>
                20
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnMoneda} onPress={addMoneda.bind(this,50)}>
              <Text style={styles.txtMoneda}>
                50
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row',width:'100%',height:50}}>
            <TouchableOpacity  onPress={restablecer.bind(this)} style={[styles.btnMoneda,{flexDirection:'row',alignItems:'center',backgroundColor:'#293133'}]}>
              <Text style={[styles.txtMoneda,{flex:1}]}>
                Restablecer
              </Text>
            </TouchableOpacity>
            
          </View>
          <View style={{flexDirection:'row',width:'100%',height:50}}>
            <TouchableOpacity onPress={pagar.bind(this)} style={[styles.btnMoneda,{flexDirection:'row',alignItems:'center',backgroundColor:'#293133'}]}>
              <Text style={[styles.txtMoneda,{flex:1}]}>
                Pagar
              </Text>
            </TouchableOpacity>
            
          </View>
        </View>
      </View>

    </TouchableOpacity>
    </TouchableOpacity>
  );
};
const mapStateToProps = (state:any) => {
  return {
    height: state.dimensions.height
  };
};

export default connect(mapStateToProps)(vistaCalculadora);
/*
<View style={[{minHeight:450,maxHeight:450,flex:1,justifyContent:'center'}]}>
          <View style={{flexDirection:'row',justifyContent:'space-around',flex:1}}>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,1)}>
              <Text style={styles.txtCalculadora}>
              1
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,2)}>
              <Text style={styles.txtCalculadora}>
              2
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,3)}>
              <Text style={styles.txtCalculadora}>
              3
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row',justifyContent:'space-around',flex:1}}>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,4)}>
              <Text style={styles.txtCalculadora}>
              4
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,5)}>
              <Text style={styles.txtCalculadora}>
              5
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,6)}>
              <Text style={styles.txtCalculadora}>
              6
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row',justifyContent:'space-around',flex:1}}>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,7)}>
              <Text style={styles.txtCalculadora}>
              7
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,8)}>
              <Text style={styles.txtCalculadora}>
              8
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,9)}>
              <Text style={styles.txtCalculadora}>
              9
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row',justifyContent:'space-around',flex:1}}>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,'<')}>
              <Text style={styles.txtCalculadora}>
              {'<'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,0)}>
              <Text style={styles.txtCalculadora}>
              0
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.btnCalculadora}  onPress={addNumero.bind(this,'00')}>
              <Text style={styles.txtCalculadora}>
              00
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection:'row',justifyContent:'center'}}>
            <TouchableOpacity style={{borderRadius:8,backgroundColor:'#5C3D2E',justifyContent:'center',marginBottom:10}}  onPress={restablecer.bind(this)}>
              <Text style={[styles.txtCalculadora,{padding:10}]}>
              {'Restablecer'}
              </Text>
            </TouchableOpacity>
            
          </View>
        </View>

*/