import React from "react";
import { StyleSheet, View } from "react-native";
import CountryFlag from "react-native-country-flag";

const Flag = ({ code }) => {
  const normalizedCode = code.toUpperCase() === "EN" ? "GB" : code;

  return (
    <View style={styles.flagContainer}>
      <CountryFlag isoCode={normalizedCode} size={30} />
    </View>
  );
};

export default Flag;

const styles = StyleSheet.create({
  flagContainer: {
    width: 30,
    height: 30,
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "grey",
  },
});
