import React, { createContext, useState } from "react";

const FoodStallContext = createContext(null);

type SECTION = "ProductList" | "ProductDetail";

const FoodStallProvider = ({ children }) => {
  const [shop, setShop] = useState();
  const [section, setSection] = useState<SECTION>("ProductList");
  const [selectedProduct, setSelectedProduct] = useState();

  return (
    <FoodStallContext.Provider
      value={{
        section,
        setSection,
        selectedProduct,
        setSelectedProduct,
        shop,
        setShop,
      }}
    >
      {children}
    </FoodStallContext.Provider>
  );
};

export { FoodStallProvider, FoodStallContext };
