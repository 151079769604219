import React from "react";
import { StyleSheet, View, Text } from "react-native";

// Constants
import MenuLines from "../../../../OrderResume/components/ProductItem/MenuLines";
import { getVariantsText } from "../../../../OrderResume/methods/getVariantsText";
import { getIngredientsText } from "../../../../OrderResume/methods/getIngredientsText";

const AddedInfoContainer = ({ data }) => {
  const { name, ingredientesSinSel, variantesSel } = data;

  const variantText = getVariantsText(variantesSel) || null;
  const ingredientsText = getIngredientsText(ingredientesSinSel) || null;

  return (
    <View style={styles.detailsColumn}>
      <Text style={styles.title}>{name}</Text>
      {variantText && <Text style={styles.subtitle}>{variantText}</Text>}
      <MenuLines plates={data.platosSel} />
      {ingredientsText && (
        <Text style={styles.subtitle}>{"Sin: " + ingredientsText}</Text>
      )}
    </View>
  );
};

export default AddedInfoContainer;

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  detailsColumn: {
    flex: 1,
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingRight: 10,
    flexDirection: "column",
  },
  title: {
    fontSize: 22,
    fontWeight: "500",
  },
  subtitle: {
    fontSize: 18,
    fontWeight: "200",
  },
});
