import React, { useState, useEffect } from "react";
import { Text, ScrollView, View, Image } from "react-native";
import { connect } from "react-redux";
import { Picker } from "@react-native-picker/picker";
import { Switch } from "react-native-switch";

const vistaCerrarTiendas = (props: any) => {
  const [listadoTiendas, setListadoTiendas] = useState([]);
  let arrayRangosTiempo = [
    "",
    "10 min",
    "20  min",
    "30  min",
    "40  min",
    "50  min",
    "60  min",
  ];
  function goBack() {
    props.navigation.goBack();
  }
  function modificarTienda(idTienda: any, opcion: any, tiempoEspera: any) {
    global.usuario.modificarTienda(idTienda, opcion, tiempoEspera);
  }
  useEffect(() => {
    let tiendasAux = [];
    props.route.params.tiendas.map(function (tienda: any) {
      let aux = {
        tienda: tienda,
        abierta: tienda.active,
        tiempoEspera: tienda.wait_time,
      };
      tiendasAux.push(aux);
    });
    setListadoTiendas(tiendasAux);
  }, []);
  function changeTiempoEspera(idTienda: any, tiempoEspera: any) {
    let listadoTiendasAux = listadoTiendas.slice();
    listadoTiendasAux.map(function (tienda: any) {
      if (tienda.tienda.gid == idTienda) {
        tienda.tiempoEspera = tiempoEspera;
        modificarTienda(idTienda, tienda.abierta, tiempoEspera);
      }
    });
    setListadoTiendas(listadoTiendasAux);
  }
  function changeAbrirCerrarTienda(idTienda: any, abierta: any) {
    let listadoTiendasAux = listadoTiendas.slice();
    listadoTiendasAux.map(function (tienda: any) {
      if (tienda.tienda.gid == idTienda) {
        tienda.abierta = abierta;
        modificarTienda(idTienda, abierta, tienda.tiempoEspera);
      }
    });

    setListadoTiendas(listadoTiendasAux);
  }
  function abrirCerrarTienda(indiceTienda: any, abierta: any) {
    let listadoTiendasAux = listadoTiendas.slice();
    let tienda = listadoTiendasAux[indiceTienda];
    console.log("tienda)", tienda);
    console.log("abierta", abierta);
    tienda.abierta = abierta;

    modificarTienda(tienda.tienda.gid, abierta, tienda.tiempoEspera);
    setListadoTiendas(listadoTiendasAux);
  }
  return (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 90 }}
        style={{ height: props.height - 80 }}
      >
        {listadoTiendas.length > 0 ? (
          <View
            style={{
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {listadoTiendas.map(function (tienda: any, indiceTienda: any) {
              console.log("tineda", tienda);

              return (
                <View
                  style={[
                    {
                      height: 150,
                      alignItems: "center",
                      borderColor: "#C9C9C9",
                      borderWidth: 1,
                      borderRadius: 8,
                      flexDirection: "row",
                      marginBottom: 20,
                      overflow: "hidden",
                    },
                    global.pantallaDividida
                      ? { width: 500 }
                      : { width: "100%" },
                  ]}
                >
                  <View
                    style={[
                      { width: 100 },
                      global.pantallaDividida ? { width: 150 } : { width: 100 },
                    ]}
                  >
                    <Image
                      style={[
                        { height: 150, width: 100, resizeMode: "cover" },
                        global.pantallaDividida
                          ? { width: 150 }
                          : { width: 100 },
                      ]}
                      source={{ uri: tienda.tienda.image }}
                    />
                  </View>
                  <View style={{ flex: 1, paddingHorizontal: 10 }}>
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        paddingBottom: 10,
                      }}
                    >
                      <Text style={{ fontSize: 20 }}>{tienda.tienda.name}</Text>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        alignItems: "center",
                        flexDirection: "row",
                        paddingBottom: 10,
                      }}
                    >
                      <View
                        style={{ justifyContent: "center", paddingRight: 20 }}
                      >
                        <Text style={{ fontSize: 14 }}>Tiempo de espera</Text>
                      </View>
                      <View style={{ width: 140, justifyContent: "center" }}>
                        <Picker
                          style={{ fontSize: 14 }}
                          mode={"dropdown"}
                          selectedValue={tienda.tiempoEspera}
                          onValueChange={function (itemValue, itemIndex) {
                            changeTiempoEspera(tienda.tienda.gid, itemValue);
                          }}
                        >
                          {arrayRangosTiempo.map(function (
                            item: any,
                            index: any
                          ) {
                            if (item == "") {
                              return (
                                <Picker.Item label={"Tiempo"} value={item} />
                              );
                            } else {
                              return (
                                <Picker.Item
                                  label={item.toString()}
                                  value={item}
                                />
                              );
                            }
                          })}
                        </Picker>
                      </View>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {tienda.abierta ? (
                        <View style={{ alignItems: "center" }}>
                          <Text
                            style={{
                              paddingLeft: 5,
                              color: "#73E13F",
                              fontSize: 14,
                            }}
                          >
                            Tienda abierta
                          </Text>
                        </View>
                      ) : (
                        <View style={{ alignItems: "center" }}>
                          <Text
                            style={{
                              paddingLeft: 5,
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            Tienda cerrada
                          </Text>
                        </View>
                      )}
                      <View style={{ width: 34, paddingLeft: 8 }}>
                        <Switch
                          value={
                            tienda.abierta == 1 || tienda.abierta ? true : false
                          }
                          onValueChange={(value) => {
                            console.log("value", value);
                            abrirCerrarTienda(indiceTienda, value);
                          }}
                          backgroundActive={"#73E13F"}
                          backgroundInactive={"red"}
                          circleActiveColor={"#D5D5D5"}
                          circleInActiveColor={"#D5D5D5"}
                          activeText={""}
                          inActiveText={""}
                          circleSize={20}
                          barHeight={14}
                          circleBorderWidth={0}
                          switchBorderRadius={20}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
};
const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height,
  };
};
export default connect(mapStateToProps)(vistaCerrarTiendas);
