import Producto from "./Producto";
import LineaPedido from "./LineaPedido";
import pedidoCalls from "../funcionalidades/pedidoCalls";

export default class Pedido {
  gid = "";
  order_id = "";
  date = "23/11/2021 - 11:12";
  date_delivery = "23/11/2021 - 11:30";
  event = {
    gid: "71076",
    name: "Festival",
  };
  shop = {
    gid: "68338",
    name: "Festival bar",
  };
  amount = 750;
  comment = "";
  user = {
    gid: "71076",
    email: "Festival",
  };
  state = "await_pickup";
  status = "await_pickup";
  order_lines = [];
  qr = "";
  payment_method = {
    key: "card",
    label: "Tarjeta",
  };
  service = {
    key: "pick_up",
    label: "Recoger",
  };
  card = "***** 1234";
  fecha = "";

  id = "";
  fechaEntrega = 1;
  hora = "";
  localizacion = "";
  tienda = "";
  estado = "";
  pagado = true;
  productos = [];
  precio = 0;
  timestampEntrega = 0;
  imparcial = false;
  prioridadesPedido = {};
  prioridadesPedidoImpresion = {};
  impreso = false;
  tiempoEstado = 0;
  intervaloAlarma = "";
  intervaloImpresion = "";
  order_number = "";
  created = 0;
  order_date = 0;
  order_lines_commission = [];
  order_lines_service = [];
  order_lines_discount = [];
  order_lines_ticket = [];
  note_id = "";
  location = "";
  puntosDeVenta = [];
  precioTotal = 0;
  tiempo = 0;
  nombreBoton = "Preparar";
  intervaloTiempo = "";
  fechaEntrada = "";
  cashPaid = null;
  sincronizado = true;
  strHora = "";
  id_shop = "";
  constructor(obj: any) {
    let t = this;
    this.id = obj.id || "";
    this.cashPaid = obj.cashPaid || null;
    this.note_id = obj.note_id || "";
    this.order_number = obj.order_number || "";
    if (obj.orderNumber != undefined) {
      this.order_number = obj.orderNumber || [];
    }
    this.created = obj.created || 0;
    this.order_date = obj.order_date || 0;
    this.fechaEntrega = obj.fechaEntrega || "";
    this.hora = obj.hora || "";
    this.localizacion = obj.localizacion || "";
    this.tienda = obj.tienda || "";
    this.estado = obj.estado || "";
    this.pagado = obj.pagado;
    this.productos = obj.productos || [];
    this.precio = obj.precio || 0;

    this.gid = obj.gid || "";
    this.order_id = obj.order_id;
    this.date = obj.date;

    if (
      obj.order_date != undefined &&
      obj.order_date != 0 &&
      obj.order_date != ""
    ) {
      this.date = obj.order_date;
    }
    if (
      obj.scheduledAt != undefined &&
      obj.scheduledAt != 0 &&
      obj.scheduledAt != ""
    ) {
      this.date = obj.scheduledAt;
    }
    this.date_delivery = obj.date_delivery;
    this.event = obj.event || { name: "", gid: "" };
    this.shop = obj.shop || { name: "", gid: "" };
    this.amount = obj.amount;
    this.comment = obj.comment;
    this.user = obj.user;
    if (obj.status != undefined) {
      if (obj.status.key != undefined) {
        this.state = obj.status.key;
      } else {
        this.state = obj.status;
      }
    }
    this.status = this.state;
    this.order_lines = obj.order_lines || [];
    if (obj.orderLines != undefined) {
      this.order_lines = obj.orderLines || [];
    }
    this.qr = obj.qr;
    this.payment_method = obj.payment_method;
    this.service = obj.service;
    this.card = obj.card;
    this.id_shop = obj.id_shop;
    if (obj.date_delivery != undefined) {
      this.dateToTimestamp(obj.date_delivery);
    }
    if (obj.payment_method != undefined) {
      this.pagado = true;
    }
    if (this.shop.name == "") {
      if (
        obj.shop_name != undefined &&
        obj.shop_name != null &&
        obj.shop_name != ""
      ) {
        this.shop.name = obj.shop_name;
      }
    }
    if (this.event.name == "") {
      if (
        obj.event_name != undefined &&
        obj.event_name != null &&
        obj.event_name != ""
      ) {
        this.event.name = obj.event_name;
      }
    }
    this.impreso = false;
    this.tiempoEstado = 0;
    this.tiempo = 0;
    //this.cargarProductos()
    this.prioridadesPedido = {
      scheduled: 3,
      waiting: 2,
      processing: 1,
      await_pickup: 4,
      await_shipment: 5,
      partially_delivered: 6,
      shipped: 7,
      completed: 8,
      cancelled: 9,

      Programado: 3,
      Espera: 2,
      "En proceso": 1,
      "Listo para recoger": 4,
      "Listo para enviar": 5,
      "Entregado parcialmente": 6,
      Enviado: 7,
      Completado: 8,
      Cancelado: 9,
    };
    this.prioridadesPedidoImpresion = {
      scheduled: 1,
      waiting: 2,
      processing: 3,
      await_pickup: 4,
      await_shipment: 5,
      partially_delivered: 6,
      shipped: 7,
      completed: 8,
      cancelled: 9,

      Programado: 1,
      Espera: 2,
      "En proceso": 3,
      "Listo para recoger": 4,
      "Listo para enviar": 5,
      "Entregado parcialmente": 6,
      Enviado: 7,
      Completado: 8,
      Cancelado: 9,
    };
    this.order_lines_commission = obj.order_lines_commission || [];
    if (obj.orderLineCommissions != undefined) {
      this.order_lines_commission = obj.orderLineCommissions || [];
    }
    this.order_lines_service = obj.order_lines_service || [];
    if (obj.orderLineServices != undefined) {
      this.order_lines_service = obj.orderLineServices || [];
    }
    this.order_lines_discount = obj.order_lines_discount || [];
    if (obj.orderLineDiscounts != undefined) {
      this.order_lines_discount = obj.orderLineDiscounts || [];
    }
    this.order_lines_ticket = obj.order_lines_ticket || [];
    if (obj.orderLineTickets != undefined) {
      this.order_lines_ticket = obj.orderLineTickets || [];
    }
    this.location = obj.location || "";
    this.puntosDeVenta = obj.puntosDeVenta || [];
    this.tiempo = obj.tiempo || 0;
    this.nombreBoton = obj.nombreBoton || "Preparar";
    this.fechaEntrada = obj.fechaEntrada || "";
    this.sincronizado = obj.sincronizado || false;
    this.strHora = obj.strHora || "";
    this.cargarListadoProductos();

    t.precioTotal = t.calcularPrecioPedido();

    this.cargarEstado();
    this.calcularHora();
    this.calcularTiempo();
    this.calcularFechaEntrada();
  }
  calcularFechaEntrada() {
    let thisPedido = this;
    let fechaAux = "";

    let fecha = new Date(thisPedido.order_date * 1000);
    let horas = "0" + fecha.getHours();
    let minutos = "0" + fecha.getMinutes();
    let anio = fecha.getFullYear();
    let mes = "0" + (fecha.getMonth() + 1);
    let dia = "0" + fecha.getDate();

    fechaAux =
      dia.substr(-2) +
      "/" +
      mes.substr(-2) +
      "/" +
      anio +
      " " +
      horas.substr(-2) +
      ":" +
      minutos.substr(-2);

    thisPedido.fechaEntrada = fechaAux;
  }
  async cargarPedidoById() {
    let thisPedido = this;
    return new Promise(function (resolve, reject) {
      pedidoCalls
        .getPedidoById(thisPedido.gid)
        .then(function (result: any) {
          console.log("result.data", result.data);
          let pedidoAux = new Pedido(result.data);

          resolve(pedidoAux);
        })
        .catch((error: any) => {
          console.error("Error cargarPedidoById -> " + error);
          reject();
        });
    });
  }
  async filtrarPorProductos(filtros: any) {
    let thisPedido = this;
    if (filtros != "" && filtros != undefined && filtros != null) {
      filtros?.products?.map(function (punto: any) {
        punto?.products?.map(function (productoFiltro: any) {
          thisPedido?.order_lines?.map(function (producto: any, index: number) {
            if (punto.gid == producto.fk_point_sale) {
              if (punto.active == 1 || punto.active == true) {
                if (
                  producto.menu_selection != undefined &&
                  producto.menu_selection != null
                ) {
                  producto.menu_selection.map(function (
                    productoMenu: any,
                    indice: number
                  ) {
                    if (productoFiltro.gid == productoMenu.product.gid) {
                      if (
                        productoFiltro.active == 1 ||
                        productoFiltro.active == true
                      ) {
                        productoMenu.oculto = false;
                      } else {
                        productoMenu.oculto = true;
                      }
                    }
                  });
                }
              }
            }
            if (punto.gid == producto.fk_point_sale) {
              if (punto.active == 1 || punto.active == true) {
                if (productoFiltro.gid == producto.fk_product) {
                  if (
                    productoFiltro.active == 1 ||
                    productoFiltro.active == true
                  ) {
                    producto.oculto = false;
                  } else {
                    producto.oculto = true;
                  }
                }
              } else {
                producto.oculto = true;
              }
            }
          });
        });
      });
    }
  }
  calcularTiempo() {
    let thisPedido = this;
    let tiempoAux = "0";
    let fechaHoy = new Date().getTime();
    let fechaPedido = new Date(thisPedido.order_date * 1000).getTime();
    let restante = fechaHoy - fechaPedido;
    thisPedido.tiempo = parseInt(restante / 1000 / 60);
    return thisPedido.tiempo;
  }
  calcularHora() {
    let thisPedido = this;
    let horaAux = "Lo antes posible";
    let fecha = new Date(thisPedido.order_date * 1000);
    let horas = "0" + fecha.getHours();
    let minutos = "0" + fecha.getMinutes();
    thisPedido.strHora = horas.substr(-2) + ":" + minutos.substr(-2);
    if (thisPedido.created < thisPedido.order_date - 300) {
      horaAux = horas.substr(-2) + ":" + minutos.substr(-2);
    }
    thisPedido.hora = horaAux;
  }
  cargarEstado() {
    let thisPedido = this;
    let estadoAux = thisPedido.state;
    switch (estadoAux) {
      case "scheduled":
        thisPedido.estado = "Programado";
        thisPedido.nombreBoton = "Preparar";
        break;
      case "waiting":
        thisPedido.estado = "Espera";
        thisPedido.nombreBoton = "Preparar";
        break;
      case "processing":
        thisPedido.estado = "En proceso";
        thisPedido.nombreBoton = "Hecho";
        break;
      case "await_pickup":
        thisPedido.estado = "Listo para recoger";
        thisPedido.nombreBoton = "Entregado";
        break;
      case "await_shipment":
        thisPedido.estado = "Listo para enviar";
        thisPedido.nombreBoton = "Preparado";
        break;
      case "partially_delivered":
        thisPedido.estado = "Entregado parcialmente";
        thisPedido.nombreBoton = "Entregado";
        break;
      case "shipped":
        thisPedido.estado = "Enviado";
        thisPedido.nombreBoton = "Entregado";
        break;
      case "completed":
        thisPedido.estado = "Completado";
        thisPedido.nombreBoton = "Completado";
        break;
      case "cancelled":
        thisPedido.estado = "Cancelado";
        thisPedido.nombreBoton = "Cancelado";
        break;
    }
  }
  dateToTimestamp(fecha: any) {
    var thisPedido = this;
    thisPedido.hora = fecha.split(" - ")[1];
    thisPedido.fecha = fecha.split(" - ")[0];
    let fechaAux = thisPedido.fecha.split("/");
    let horaAux = thisPedido.hora.split(":");
    thisPedido.timestampEntrega = new Date(
      parseInt(fechaAux[2]),
      parseInt(fechaAux[1]) - 1,
      parseInt(fechaAux[0]),
      parseInt(horaAux[0]),
      parseInt(horaAux[1])
    ).getTime();
  }
  cambiarEstado(estado: any, state: any) {
    let imparcial = false;
    let thisPedido = this;
    let estadoAntiguo = thisPedido.state;
    if (estado != undefined || state != undefined) {
      thisPedido.estado = estado;
      thisPedido.state = state;
      thisPedido.productos.map(function (producto: any, index: number) {
        if (!producto.oculto) {
          producto.estado = estado;
          producto.state = state;
        } else {
          imparcial = true;
        }
      });
      global.socket.cambiarEstadoPedidoConcreto(thisPedido, estadoAntiguo);
    } else {
      global.socket.cambiarEstadoPedido(thisPedido, estadoAntiguo);
    }
    if (global.usuario.pedidosEstadosCambiados.indexOf(thisPedido.gid) == -1) {
      global.usuario.pedidosEstadosCambiados.push(thisPedido.gid);
    }

    thisPedido.imparcial = imparcial;
    return thisPedido;
  }
  /*cambiarEstado(estado:any,state:any){
    
    let thisPedido = this
    let estadoAntiguo = thisPedido.state
    let imparcial = false
    thisPedido.productos.map(function(producto:any,index:number) {
      if(!producto.oculto){
        if(estado!=undefined || state!=undefined){
         producto.estado=estado
         producto.state=state
        }else{
           switch (thisPedido.estado) {
              case "Programado":
                producto.estado='Espera'
                producto.state='waiting'
              break;
              case "Espera":
                producto.estado='En proceso'
                producto.state='processing'
              break;
              case "En proceso":
                producto.estado='Recoger'
                producto.state='await_pickup'
              break;
              case "Recoger":
                producto.estado='Recoger envío'
                producto.state='await_shipment'
              break;
              case "Recoger envío":
                producto.estado='Enviado'
                producto.state='partially_delivered'
              break;
              case "Entregado parcialmente":
                producto.estado='Enviado'
                producto.state='shipped'
              break;
              case "Enviado":
                producto.estado='Completado'
                producto.state='completed'
              break;
              
            }
        }

      }else{
        imparcial=true
      }
    
    })
      if(estado!=undefined){
         thisPedido.estado=estado
         thisPedido.state=state
      }else{
       switch (thisPedido.estado) {
              case "Programado":
                thisPedido.estado='Espera'
                thisPedido.state='waiting'
              break;
              case "Espera":
                thisPedido.estado='En proceso'
                thisPedido.state='processing'
              break;
              case "En proceso":
                thisPedido.estado='Recoger'
                thisPedido.state='await_pickup'
              break;
              case "Recoger":
                thisPedido.estado='Recoger envío'
                thisPedido.state='await_shipment'
              break;
              case "Recoger envío":
                thisPedido.estado='Enviado'
                thisPedido.state='partially_delivered'
              break;
              case "Entregado parcialmente":
                thisPedido.estado='Enviado'
                thisPedido.state='shipped'
              break;
              case "Enviado":
                thisPedido.estado='Completado'
                thisPedido.state='completed'
              break;
              
            }
      }
      console.log("cambiar estado pedido",thisPedido.estado)
      global.socket.cambiarEstadoPedido(thisPedido,estadoAntiguo)
    thisPedido.imparcial=imparcial
    return thisPedido;
  }*/
  cargarProductos() {
    let thisPedido = this;
    var productosEjemplo = [
      {
        gid: 866357,
        name: "Producto pruebas cantidad variantes",
        stock: true,

        descripcion: "",
        imagen: "",
        variantesSel: [],
        tipo: "Producto individual",
        precio: 4.0,
        variantes: [
          {
            nombre_grupo: "Variantes cantidad",
            tipo: "check",
            num_max: 3,
            permitir_cantidad: true,
            opciones: [
              {
                id: "829521",
                nombre: "opcion1",
                incremento: 1,
                activo: false,
              },
              {
                id: "829522",
                nombre: "Opcion 2",
                incremento: 2,
                activo: false,
              },
              {
                id: "829523",
                nombre: "Opcion 3",
                incremento: 3,
                activo: false,
              },
              {
                id: "829524",
                nombre: "Opcion 4",
                incremento: 4,
                activo: false,
              },
            ],
          },
          {
            nombre_grupo: "Variantes ilimitadas con cantidad",
            tipo: "check",
            num_max: true,
            permitir_cantidad: true,
            opciones: [
              {
                id: "829526",
                nombre: "Opcion 1",
                incremento: 0,
                activo: false,
              },
              {
                id: "829527",
                nombre: "Opcion 2",
                incremento: 0,
                activo: false,
              },
            ],
          },
          {
            nombre_grupo: "Variantes ilimitadas sin cantidad",
            tipo: "check",
            num_max: true,
            permitir_cantidad: false,
            opciones: [
              {
                id: "829526",
                nombre: "Opcion 1",
                incremento: 0,
                activo: false,
              },
              {
                id: "829527",
                nombre: "Opcion 2",
                incremento: 0,
                activo: false,
              },
            ],
          },
          {
            nombre_grupo: "Variante obligatoria",
            tipo: "radio",
            opciones: [
              {
                id: "829529",
                nombre: "Opcion 1",
                incremento: 0,
                activo: false,
              },
              {
                id: "829530",
                nombre: "Opcion 2",
                incremento: 0,
                activo: false,
              },
            ],
          },
        ],
      },
      {
        gid: 68410,
        name: "Menu Classic Burger",
        stock: true,

        sort_description: "Incluye patatas y bebida",
        image: "",
        product_type: "Menú",
        precio_modificado: "1.00",
        variants: [],
        variantesSel: [],
        platos_elegir: [
          {
            tid: "258",
            nombre: "Principal",
            productos: [
              {
                producto: {
                  nid: "67045",
                  nombre: "Classic Burger",
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/hamburguesa_clasica.png?itok=Zm96bxPK",
                  ingredientes: {
                    nombre_grupo: "Ingredientes",
                    ingredientes: [
                      {
                        nombre: "Hamburguesa 100% de ternera",
                        activo: true,
                      },
                      {
                        nombre: "Ketchup",
                        activo: true,
                      },
                    ],
                  },
                  variantes: [
                    {
                      nombre_grupo: "Tipo de pan",
                      tipo: "radio",
                      opciones: [
                        {
                          id: "82871",
                          nombre: "Pan Normal",
                          incremento: "1.00",
                          activo: false,
                        },
                        {
                          id: "82872",
                          nombre: "Pan CELIACO",
                          incremento: "2.00",
                          activo: false,
                        },
                      ],
                    },
                  ],
                },
                incremento: "3.00",
              },
            ],
          },
          {
            tid: "208",
            nombre: "Bebida",
            productos: [
              {
                producto: {
                  nid: "3448",
                  nombre: "CocaCola 50cl",
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/coca_cola_50_cl_0.png?itok=buOtCaGQ",
                },
                incremento: "0.00",
              },
              {
                producto: {
                  nid: "3368",
                  nombre: "Coca-Cola Zero 50cl",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/botella_coca_cola_zero_0.jpg?itok=CLQJvCvW",
                },
                incremento: "1.00",
              },
              {
                producto: {
                  nid: "3367",
                  nombre: "Fanta de Limon",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/fanta_de_limon_50_cl_1.png?itok=FlMeYgoh",
                },
                incremento: "0.00",
              },
              {
                producto: {
                  nid: "3366",
                  nombre: "Fanta de Naranja",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/fanta_de_naranja_50_cl_1.png?itok=LujQUExH",
                },
                incremento: "2.00",
              },
              {
                producto: {
                  nid: "3401",
                  nombre: "Botella de Agua",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/botella_de_agua.png?itok=8ahPAzqn",
                },
                incremento: "6.00",
              },
              {
                producto: {
                  nid: "3396",
                  nombre: "Heineken 33cl",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/botellin_heineken.jpg?itok=XfW4A2nF",
                },
                incremento: "0.00",
              },
              {
                producto: {
                  nid: "3362",
                  nombre: "Aquarius limon",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/botella_aquarius_limon_0.jpg?itok=UrZgKsOX",
                },
                incremento: "0.00",
              },
              {
                producto: {
                  nid: "3361",
                  nombre: "Aquarius naranja",
                  no_mostrar_nuevos: true,
                  stock: true,
                  informacion: "",
                  imagen:
                    "https://app.gopick-app.com/sites/default/files/styles/productos/public/imgs-productos/botella_aquarius_naranja_0.jpg?itok=Hwot_3RI",
                },
                incremento: "0.00",
              },
            ],
          },
        ],
        precio: "1.00",
      },
      {
        gid: 2,
        name: "producto2",
        amount: 20,
        sort_description: "desc2",
        image: "",
        variants: [],
        variantesSel: [],
      },
      {
        gid: 3,
        name: "producto largo",
        amount: 30,
        sort_description: "Descripcion corta",
        long_description:
          "mas larga mas larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga",
        image: "",
        variants: [],
        variantesSel: [],
      },
      {
        gid: 4,
        name: "producto ingredientes",
        amount: 30,
        sort_description: "Descripcion corta",
        long_description:
          "mas larga mas larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga descripcion Larga",
        image: "",
        variants: [],
        variantesSel: [],
        ingredients: [
          { id: 1, nombre: "Tomate" },
          { id: 2, nombre: "Lechuga" },
        ],
        ingredientesSinSel: [{ id: 1, nombre: "Tomate" }],
      },
    ];

    productosEjemplo.map(function (producto: any, indice: number) {
      let lineaPedido = new LineaPedido({
        producto: new Producto(producto),
        cantidad: 1,
      });
      thisPedido.productos.push(lineaPedido);
    });
  }
  async cargarListadoProductos() {
    let thisPedido = this;
    thisPedido.productos = [];
    let puntosDeVenta = [];
    thisPedido.filtrarPorProductos(usuario.filtros);
    thisPedido.order_lines.map(function (order_line: any, indice: number) {
      if (order_line != null) {
        let variantesSel: Array<any> = [];

        /*order_line.order_line_group.map(function(order_line_group:any,indice:number) {
          let opcionesSel:Array<any>=[]
          order_line_group.order_line_group_variant.map(function(opcionSel:any,indice:number) {
            let opcionAux={
              valor:true,
              nombre:opcionSel.name,
              cantidad:opcionSel.quantity,
              incremento:opcionSel.amount
            }
            opcionesSel.push(opcionAux)
          });
          variantesSel.push({opciones:opcionesSel})
        });*/
        let menu_lines = [];
        if (order_line.menu_selection) {
          order_line.menu_selection.map(function (item: any, i: any) {
            if (item != null) {
              menu_lines.push(item);
            }
          });
        }
        if (order_line.orderLineMenus) {
          order_line.orderLineMenus.map(function (orderLineMenu: any, i: any) {
            if (orderLineMenu != null) {
              if (orderLineMenu.orderLineMenuGroups) {
                orderLineMenu.orderLineMenuGroups.map(function (
                  item: any,
                  index: number
                ) {
                  if (item.orderLineMenuGroupVariants) {
                    item.variants = item.orderLineMenuGroupVariants;
                  }
                });
                orderLineMenu.variants = orderLineMenu.orderLineMenuGroups;
              }
              menu_lines.push(orderLineMenu);
            }
          });
        }
        let ingredientsRemoved = order_line.ingredients_removed;
        if (order_line.ingredientsRemoved) {
          ingredientsRemoved = order_line.ingredientsRemoved;
        }

        let variantes = order_line.groups;

        if (order_line.orderLineGroups) {
          order_line.orderLineGroups.map(function (item: any, index: number) {
            if (item.orderLineGroupVariants) {
              item.variants = item.orderLineGroupVariants;
            }
          });

          variantes = order_line.orderLineGroups;
        }

        let productoAux = {
          gid: order_line.gid,
          name: order_line.name,
          amount: order_line.amount,
          cantidad: order_line.quantity,
          variants: [],
          variantesSel: variantesSel,
          ingredients: [],
          ingredientesSinSel: ingredientsRemoved,
          estado: order_line.estado,
          groups: variantes,
          menu_lines: menu_lines,
          fk_product: order_line.fk_product,
          state: order_line.state,
          unit_price: order_line.unit_price,
          fk_point_sale: order_line.fk_point_sale,
          oculto: order_line.oculto,
          marcado: order_line.marcado,
          active: order_line.active,
        };
        if (puntosDeVenta.indexOf(order_line.fk_point_sale) == -1) {
          puntosDeVenta.push(order_line.fk_point_sale);
        }
        thisPedido.productos.push({
          producto: new Producto(productoAux),
          cantidad: order_line.quantity,
        });
      }
    });
    thisPedido.puntosDeVenta = puntosDeVenta;
  }
  calcularPrecioPedido() {
    let thisPedido = this;
    let precioTotal = 0;

    thisPedido.productos.map(function (producto: any, indice: number) {
      if (!producto.producto.oculto) {
        let precioProducto = producto.producto.calcularPrecioProductoTicket();
        precioTotal = precioTotal + precioProducto * producto.cantidad;
      }
    });
    thisPedido.order_lines_ticket.map(function (item: any, index: any) {
      if (item.amount > 0) {
        precioTotal = precioTotal + parseFloat((item.amount / 100).toFixed(2));
      }
    });
    thisPedido.order_lines_commission.map(function (item: any, index: any) {
      if (item.amount > 0) {
        precioTotal = precioTotal + parseFloat((item.amount / 100).toFixed(2));
      }
    });
    thisPedido.order_lines_service.map(function (item: any, index: any) {
      if (item.amount > 0) {
        precioTotal = precioTotal + parseFloat((item.amount / 100).toFixed(2));
      }
    });
    thisPedido.order_lines_discount.map(function (item: any, index: any) {
      if (item.amount != undefined && item.amount != null) {
        if (item.amount < 0) {
          precioTotal =
            precioTotal + parseFloat((item.amount / 100).toFixed(2));
        }
      }
    });
    return precioTotal;
  }
}
