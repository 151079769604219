import React, { useState, useEffect, useRef } from 'react';
import {ToastAndroid, Platform,Alert,Text,TextInput,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';
import {CheckBox} from "react-native-elements";
import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';
import { FontAwesome,AntDesign,Entypo } from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
//import {loadAsync} from 'expo-font';
import { connect } from "react-redux";
import {setCashLess} from "../../application/reducers";
const recargarCredito = (props:any) => {

 
  const [importeSel, setImporteSel] = useState(0);
  const [disabled, setDisabled] = useState(false);

  function goBack(){
    props.navigation.goBack()
  }
  function updateText(text:any){
    text = text.replace(',','.')
    setImporteSel(text)
  }
  function recargarImporte(){
    let id_linea_pedido_credito= props.cashLess.credits[0].gid_order_line_credit
    let uuid=  props.cashLess.uuid
    console.log("importeSel",importeSel)
    setDisabled(true)
    global.usuario.recargarImporte(Math.round(parseFloat(importeSel).toFixed(2)*100), id_linea_pedido_credito, uuid).then(function(res){
       
       if(Platform.OS!='web'){
          ToastAndroid.show(res.data, ToastAndroid.SHORT);
        }else{
          alert(res.data)
        }
       setDisabled(false)
       console.log("credito",props.cashLess.credits[0].amount_available+Math.round(parseFloat(importeSel).toFixed(2)*100))
       let cashLessAux = JSON.parse(JSON.stringify(props.cashLess))
       cashLessAux.credits[0].amount_available=cashLessAux.credits[0].amount_available+Math.round(parseFloat(importeSel).toFixed(2)*100)
       props.setCashLess(cashLessAux)
       goBack()
    })
  }
  function onPressRecargar(){
    if(Platform.OS === 'web'){
        recargarImporte()
      }else{
        Alert.alert(
          '',
          '¿Desea recargar '+importeSel+'€?',
          [
            {
              text: 'Aceptar',
              onPress: () => {
                recargarImporte()
              },
            },
            {
              text: 'Cancelar',
              onPress: () => {console.log('Cancel Pressed')},
              style: 'cancel'
            },
          ],
          { cancelable: true }
        );
      }
  }
  
  return (
    


    <View style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}}>
                   
                      <View style={{marginTop:90,height:props.height-90,borderColor:'#BABABA',borderWidth:1,backgroundColor:'white',width:'90%',alignItems:'center',maxWidth:400,justifyContent:'center',borderRadius:8}}>
                      <View style={{position:'absolute',top:0,flex:1,flexDirection:'row',justifyContent:'space-between',marginTop:20,width:'100%',zIndex:2}}>
                        <View style={{marginLeft:20,width:32}}></View>
                        <Text style={{fontSize:24,color:'#3AA597',textAlign:'center',flex:1}}>
                            Importe
                        </Text>
                        <TouchableOpacity  onPress={goBack} style={{marginRight:20,zIndex:2}}>
                          <AntDesign name="close" size={32} color="#3AA597" />
                        </TouchableOpacity>
                        
                      </View>
                      <View style={{height:'100%',width:'100%'}}>
                        <View style={{width:'100%',justifyContent:'center',alignItems:'center',flex:1}}>
                          <TextInput
                            placeholder="Escribe el importe..."
                            onChangeText={text => updateText(text)}

                            keyboardType="numeric"
                            style={[styles.inputForm,{backgroundColor:"#DCDCDC"}]}
                          />
                        </View>
                        <View style={{width:'100%',justifyContent:'space-between',alignItems:'center',flexDirection:'row'}}>
                          <TouchableOpacity activeOpacity={1} style={[styles.btnLogin,{flex:1,margin:20}]} onPress={onPressRecargar.bind(this)} disabled={disabled}>
                              <Text style={styles.textoBtn}>Recargar Crédito</Text>       
                          </TouchableOpacity>
                        </View>
                      </View>
                      
                     </View>
                    
                  </View>

  );
};
const mapStateToProps = (state:any) => {
  return {
    width:state.dimensions.width,
    height:state.dimensions.height,
    cashLess: state.carrito.cashLess
  };
};
const mapDispatchToProps = {
  setCashLess
};
export default connect(mapStateToProps,mapDispatchToProps)(recargarCredito);
