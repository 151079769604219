import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import RemoveProduct from "./RemoveProduct";
import ProductImage from "./ProductImage";
import InfoContainer from "./InfoContainer";
import Divider from "../../../../../components/Divider";

const Plate = ({
  data,
  onPress,
  quantity,
  onRemove,
  isMenu,
  showActions = true,
}) => {
  const { image, sold_out, stock } = data;
  const soldOut = stock === 0 || sold_out === 1 ? 1 : 0;

  const showAddButton = soldOut === 0;

  const clickHandler = () => {
    if (showAddButton) {
      onPress(data);
    }
  };

  const removeHandler = () => {
    onRemove(data);
  };

  const addHandler = () => {
    onPress(data);
  };

  return (
    <TouchableOpacity onPress={clickHandler} style={styles.container}>
      <View style={styles.content}>
        {showActions ? (
          <RemoveProduct quantity={quantity} onPress={removeHandler} />
        ) : (
          <Divider width={20} />
        )}
        <ProductImage image={image} soldOut={soldOut} />
        <InfoContainer
          isMenu={isMenu}
          data={data}
          showAddButton={showAddButton}
          onAdd={addHandler}
        />
      </View>
    </TouchableOpacity>
  );
};

export default Plate;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  content: {
    width: "100%",
    flex: 1,
    minHeight: 55,
    paddingVertical: 12,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
});
