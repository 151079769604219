import React, { useState, useEffect, useRef } from 'react';
import {Platform,Text,FlatList,Alert,ScrollView,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';

import * as Animatable from 'react-native-animatable';
import {Picker} from '@react-native-picker/picker';
import { styles } from '../../assets/styles/styles';
import { Ionicons,FontAwesome,MaterialIcons,AntDesign  } from '@expo/vector-icons'; 
import {CheckBox} from "react-native-elements";
import { connect } from "react-redux";
const seleccionarLocalizacion = (props:any) => {
  let servicioEnMiSitio=props.route.params.servicioEnMiSitio
  let initArray:Array<any>=[]

  const [servicioSel, setServicioSel] = useState({});
  const [localizacionesSel, setLocalizacionesSel] = useState(initArray);
  const [opcionesSel, setOpcionesSel] = useState(initArray);
  function goBack(){
    props.navigation.goBack()
  }
  
  useEffect(() => {
   setServicioSel(servicioEnMiSitio)
    setLocalizacionesSel([servicioEnMiSitio])
  }, []);
  
  function seleccionarValor(localizacion:any,indice:any,valor:any){
    if(valor!=-1){
      //localizaciones[servicioId]=valor
      let localizacionesSelAux = localizacionesSel.slice()
      //if(localizacion.childs!=undefined && localizacion.childs.length>0){
        localizacionesSelAux[indice+1]=localizacion
      //}

      localizacionesSelAux.splice(indice+2, localizacionesSelAux.length-indice+2)
      
      setLocalizacionesSel(localizacionesSelAux)


       let opcionesSelAux = opcionesSel.slice()
      
        opcionesSelAux[indice]=valor

      opcionesSelAux.splice(indice+1, opcionesSelAux.length-indice+1)
      
      setOpcionesSel(opcionesSelAux)
    }else{
      let localizacionesSelAux = localizacionesSel.slice()
      let opcionesSelAux = opcionesSel.slice()
      localizacionesSelAux[indice+1]=localizacion
      opcionesSelAux[indice]=valor
      localizacionesSelAux.splice(indice+1, localizacionesSelAux.length-indice+1)
      opcionesSelAux.splice(indice, opcionesSelAux.length-indice)
      setLocalizacionesSel(localizacionesSelAux)
      setOpcionesSel(opcionesSelAux)
    }
  }
  function cargarOpciones(localizacion:any){
    let arrayOpciones=[]
    switch (localizacion.structure.type) {
      case "numeric":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
           arrayOpciones.push(
           {
             label:localizacion.name+' '+i,
             value:localizacion.name+' '+i
           }
          )
        }
      break;
       case "numeric_even_odd":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          if(localizacion.structure.even_odd=='even'){
            if(i%2==0){
              arrayOpciones.push(
               {
                 label:localizacion.name+' '+i,
                 value:localizacion.name+' '+i
               }
              )
            }
          }else{
            if(i%2!=0){
              arrayOpciones.push(
               {
                 label:localizacion.name+' '+i,
                 value:localizacion.name+' '+i
               }
              )
            }
          }
           
        }
      break;
       case "alphanumeric":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
            arrayOpciones.push(
             {
               label:localizacion.name+' '+i+' '+String.fromCharCode(j),
               value:localizacion.name+' '+i+' '+String.fromCharCode(j)
             }
            )        
          }
        }
      break;
      case "alphanumeric_even_odd":
        for (var i = parseInt(localizacion.structure.number_from); i <= parseInt(localizacion.structure.number_to); i++) {
          if(localizacion.structure.even_odd=='even'){
            if(i%2==0){
              for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
                arrayOpciones.push(
                 {
                   label:localizacion.name+' '+i+' '+String.fromCharCode(j),
                   value:localizacion.name+' '+i+' '+String.fromCharCode(j)
                 }
                )      
              }
            }
          }else{
            if(i%2!=0){
              for (var j = localizacion.structure.letter_from.charCodeAt(0); j <= localizacion.structure.letter_to.charCodeAt(0); j++) {
                arrayOpciones.push(
                 {
                   label:localizacion.name+' '+i+' '+String.fromCharCode(j),
                   value:localizacion.name+' '+i+' '+String.fromCharCode(j)
                 }
                )      
              }
            }
          }
        }
      break;
      case "alphabetical":
        for (var i = localizacion.structure.letter_from.charCodeAt(0); i <= localizacion.structure.letter_to.charCodeAt(0); i++) {
          arrayOpciones.push(
           {
             label:localizacion.name+' '+String.fromCharCode(i),
             value:localizacion.name+' '+String.fromCharCode(i)
           }
          )        
        }
      break;
      case "writing":
        localizacion.structure.writing.map(function(opcion:any,indice:number) {
          arrayOpciones.push(
            {
              label:localizacion.name+' '+opcion,
              value:localizacion.name+' '+opcion
            }
          )
        })
      break;
    }
    return arrayOpciones
  }
  function pintarSelect(localizacion:any,indice:any){
   
    let arrayHijos:Array<any>=[]
    if(localizacion.childs!=undefined){
      localizacion.childs.map(function(hijo:any,indice:number) {
        hijo.arrayOpciones=cargarOpciones(hijo)
        arrayHijos.push(hijo) 
      })
    }
    if(localizacion.structure!=undefined){
      if(localizacion.structure.length>0){
          localizacion.structure.map(function(hijo:any,indice:number) {
            hijo.arrayOpciones=cargarOpciones(hijo)
            arrayHijos.push(hijo)
          })
      }
    }
   
    if(arrayHijos.length>0){

      arrayHijos[0].arrayOpciones.unshift({
        label:'Seleccionar localización',
        value:-1
      })
      return( 
        <View style={[{marginBottom:20,paddingLeft:20},Platform.OS!='web'?{borderRadius:8,borderWidth:1,borderColor:'gray'}:{}]}>

          <Picker
            style={{height:50}}
            mode={"dropdown"}
           
            selectedValue={opcionesSel[indice]}
            onValueChange={
              function(itemValue, itemIndex) {

                  let limiteZona = 0
                  let zonaSel = -1
                  arrayHijos.map(function(hijo:any,indice:number) {
                    limiteZona= limiteZona + hijo.arrayOpciones.length
                    if(zonaSel==-1 && limiteZona>itemIndex){
                      zonaSel=indice
                    }
                  })   
                  seleccionarValor(arrayHijos[zonaSel],indice,itemValue)    
                       
                /*if(servicioSel.gid_active.indexOf(parseInt(arrayHijos[zonaSel].gid))!=-1){
                  seleccionarValor(arrayHijos[zonaSel],indice,itemValue)
                }else{
                  
                  alert('La localización seleccionada no está disponible')
                  if(opcionesSel[indice]==undefined || opcionesSel[indice]==null){
                      let opcionesSelAux = opcionesSel.slice()
                      opcionesSelAux[indice]=arrayHijos[0].arrayOpciones[0].value
                      setOpcionesSel(opcionesSelAux)
                  }

                }*/
                
                
              }
            }
          >
            
            {
              arrayHijos.map(function(hijo:any,indice:number) {
                
                let color='black'
                if(servicioSel.gid_active!=undefined){
                  if(servicioSel.gid_active.indexOf(parseInt(hijo.gid))==-1){
                    color='#D7D7D7'
                  }
                }

                return(
                    hijo.arrayOpciones.map(function(opcion:any,indice:number) {
                      return(<Picker.Item color={color} label={opcion.label.toString()} value={opcion.value} />)
                    })
                )
              })
              
            }
            
          </Picker>
        </View>

      )
      
    }
  }
  function seleccionarLocalizacionUsuario(){

    let localizacionUsuario = ''
      localizacionesSel.map(function(localizacion:any,indice:number) {
        if(indice!=0){
          localizacionUsuario= localizacionUsuario+' '+(opcionesSel[indice-1])
        }
      })
      props.route.params.onGoBack(localizacionUsuario);
      goBack()
  }
  return (
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(props.height-80),marginTop:80}]}>
      <View style={{maxWidth:600,width:'90%',height:'80%',borderRadius:8,backgroundColor:'white'}}>
        <TouchableOpacity  onPress={goBack} style={{position:'absolute',top:20,right:20,zIndex:2}}>
          <AntDesign name="close" size={32} color="#3AA597" />
        </TouchableOpacity>

        <ScrollView 
          contentContainerStyle={{paddingBottom:90,'alignItems':'center'}}
          showsVerticalScrollIndicator={false}

        >
            
            { (servicioSel!=null && servicioSel.structure!=undefined && servicioSel.structure.length>0) ? (
              <View style={{marginTop:30,width:'80%',maxWidth:350}}>
                <Text style={{padding:10,fontWeight:'bold',color:'#3AA597',fontSize:20,width:'100%',textAlign:'center'}}>Elige el sitio</Text>
                <View style={{flex:1,paddingRight:20,paddingLeft:20}}>
                { (localizacionesSel!=undefined &&  localizacionesSel.length>0) ?
                    localizacionesSel.map(function(localizacion:any,indice:number) {
                     
                     
                         return(pintarSelect(localizacion,indice))
                      
                     
                    })
                  :
                  (null)
                }
               </View>
            </View>
            ) : null}
        </ScrollView>
        <TouchableOpacity onPress={seleccionarLocalizacionUsuario} style={{position:'absolute',bottom:20,width:'100%',alignItems:'center',justifyContent:'center'}}>
          <View style={{minWidth:200,backgroundColor:'#3AA597',borderRadius:50,justifyContent:'center',alignItems:'center'}}>
            <Text style={{padding:10,fontWeight:'bold',color:'white'}}>Aceptar</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
const mapStateToProps = (state:any) => {
  return {
    height: state.dimensions.height,
    width: state.dimensions.width,
    tiendas: state.carrito.tiendas
  };
};
export default connect(mapStateToProps)(seleccionarLocalizacion);
