import React, { useState, useRef } from 'react';
import {Text,FlatList,ScrollView,TouchableOpacity, View } from 'react-native';

import { styles } from '../../assets/styles/styles';
import { AntDesign } from '@expo/vector-icons';

import { connect } from "react-redux";
import { setTiendas,setPedidoImprimir } from "../../application/reducers";

import myCrypto from '../funcionalidades/myCrypto';
const menuPedido = (props:any) => {
  var ticketRefCliente = useRef(null)
  var ticketRefBasico = useRef(null)
  var pedido=props.route.params.pedido
  const [arrayBotonesSup, setarrayBotonesSup] = useState(
    [
      {id:'1',name:'Productos agotados'},
      {id:'6',name:'Entregar pedido'}
     
    ]
  );
  let arrayBotones = [

  ]

   arrayBotones = [
     {id:'4',name:'Imprimir ticket básico'},
     {id:'5',name:'Imprimir ticket cliente'},
     
   ]
  //}
  const [arrayBotonesInf, setarrayBotonesInf] = useState(
    arrayBotones
  )
  function goBack(){
    props.navigation.goBack()
  }
  function entregarPedido(){
    pedido.cambiarEstado('Completado','completed')
    //props.setTiendas(props.tiendas)
    goBack()
  }
  function cancelarPedido(){
    pedido.cambiarEstado('Cancelado','cancelled')
    //props.setTiendas(props.tiendas)
    goBack()
  }
  function abrirDevolucion(){
    var aux = {
      pedido:pedido
    }
    props.navigation.navigate('devolucion',aux);
  }
  function abrirEnviarMensaje(){
    var aux = {
      pedido:pedido
    }
    props.navigation.navigate('enviarMensaje',aux);
  }
  function toDataURL(url:any, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  function notificarAgotados(){
    console.log("pedido",pedido)
    let arrayLineasPedido=[]
    pedido.productos.map(function(producto:any,index:any){
      if(producto.producto.agotado){
        arrayLineasPedido.push(producto.producto.gid)
      }
    })
    global.socket.notificarProductosAgotados(pedido.gid,arrayLineasPedido)
    alert("Lineas del pedido notificadas correctamente")

  }
  
  function onPressBotones(idBtn:any){
    switch (idBtn) {
      case "1":
        notificarAgotados()
      break;
      case "2":
       abrirEnviarMensaje()
      break;
      case "3":
       abrirDevolucion()
      break;
      case "4":
        props.setPedidoImprimir(pedido)
        if(global.isDatafono){
          myCrypto.imprimirAdyen()
        }else{
          global.imprimirTicket(true)
        }
        
      break;
      case "5":
        props.setPedidoImprimir(pedido)
        if(global.isDatafono){
          myCrypto.imprimirAdyen()
        }else{
          global.imprimirTicket(false)
        }
      break;
      case "6":
       entregarPedido()
      break;
      case "7":
        cancelarPedido()
      break;
    }
  }
  
  return (
    <View style={{backgroundColor:'rgba(255,255,255,0.3)',flex:1,alignItems:'center',justifyContent:'center'}}>
      <View style={{maxWidth:700,width:'90%',height:'90%',borderRadius:8,backgroundColor:'white',marginLeft:20,marginRight:20,justifyContent:'center',alignItems:'center'}}>
        <View style={{position:'absolute',top:20,width:'100%',flex:1,flexDirection:'row',justifyContent:'flex-end',zIndex:2}}>
          <TouchableOpacity style={{marginRight:20}} onPress={goBack}>
            <AntDesign name="close" size={32} color="#3AA597" />
          </TouchableOpacity>
        </View>
        <View style={{width:'100%',justifyContent:'center',alignItems:'center',position:'absolute',top:20}}>
          <Text style={{color:'#3AA597',fontSize:32,fontWeight:'bold'}}>#{pedido.order_number}</Text>
          <Text style={{fontSize:28}}>{pedido.calcularPrecioPedido()}€</Text>
        </View>
        <ScrollView 
          style={{marginTop:100,height:props.height-200,width:'100%',zIndex:2,position:'absolute'}}
          contentContainerStyle={{justifyContent:'flex-start',flexDirection:'column',alignItems:'center',paddingBottom:50}}
        >
          
          <View style={{flexDirection:"row",justifyContent:'center',margin:0,padding:0,alignItems:'center'}}>

            <FlatList
                  key={props.width}
                  contentContainerStyle={{alignItems:'center'}}
                  data={arrayBotonesSup}              
                  numColumns={parseInt(props.width/250)}
                  showsVerticalScrollIndicator={false}
                  renderItem={({item,index}:any) =>  

                  <TouchableOpacity style={[styles.btnMenu,{margin:10}]} onPress={onPressBotones.bind(this,item.id)}>

                  <Text style={{fontSize: 18,fontWeight: "bold"}}>{item.name}</Text>
                </TouchableOpacity>
              }
            />
          </View>
          <View style={{flexDirection:"row",justifyContent:'center',margin:0,padding:0,alignItems:'center'}}>
            <FlatList
                  key={props.width}
                  contentContainerStyle={{alignItems:'center'}}
                  data={arrayBotonesInf}              
                  numColumns={parseInt(props.width/250)}
                  showsVerticalScrollIndicator={false}
                  renderItem={({item,index}:any) =>  
                  <TouchableOpacity style={[styles.btnMenu,{margin:10}]} onPress={onPressBotones.bind(this,item.id)}>
                
                  <Text style={{fontSize: 18,fontWeight: "bold"}}>{item.name}</Text>
                </TouchableOpacity>
              }
            />
            
          </View>
        </ScrollView>
        
      </View>
      
    </View>
  );
};
const mapDispatchToProps = {
  setTiendas,
  setPedidoImprimir
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width:state.dimensions.width,
    height:state.dimensions.height
  };
};
export default connect(mapStateToProps,mapDispatchToProps ) (menuPedido);
