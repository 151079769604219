import React, { useState, useEffect, useRef } from 'react';
import { FlatList,TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';
import { StatusBar } from 'expo-status-bar';
import {Octicons,MaterialIcons,FontAwesome  } from '@expo/vector-icons';

import MasonryList from '@react-native-seoul/masonry-list';

import ProductoTicket from '../components/ProductoTicket';
import { connect } from "react-redux";
const VistaTicket = (props:any) => {
  let tienda
  if(props.route!=undefined){
    tienda=props.route.params.tienda
    props.navigation.setOptions({
            headerTitle:tienda.nombre
        });
  }else{
    tienda=props.tienda
  }
 
  
  const [precioTotal, setPrecioTotal] = useState(0);
  useEffect(() => {
    let precioTotalAux = 0
    props.tiendas.map(function(tienda:any) {
      tienda.productos.map(function(item:any) {
        precioTotalAux = precioTotalAux + item.cantidad*item.precio
      })
      if(tienda.waiter_comission!='' && tienda.type_waiter_comission!=''){
        precioTotalAux = precioTotalAux + parseFloat(tienda.waiter_comission)
      }
    })
    setPrecioTotal(precioTotalAux)
  }, [props.tiendas]);

 
  return (
    
        <View style={{borderColor:'#BABABA',borderWidth:1,backgroundColor:'#E1E1E1',width:'100%',height:'100%',padding:20}}>
            <View>
              <View style={{paddingBottom:20}}>
                <Text style={{fontWeight:'bold',textAlign:'center'}}>Detalles del pedido</Text>
              </View>
              <View style={{paddingBottom:10}}>
                <Text style={{fontWeight:'bold',color:'#3AA597'}}>Créditos/bonos disponibles</Text>
              </View>
              <View style={{paddingBottom:10,flexDirection:'row',justifyContent:'space-between'}}>
                <Text style={{fontWeight:'bold'}}>Credito</Text>
                <Text style={{color:'#3AA597'}}>50€</Text>
              </View>
              <View style={{paddingBottom:10,flexDirection:'row',justifyContent:'space-between'}}>
                <Text style={{fontWeight:'bold'}}>Bono 1</Text>
                <Text style={{color:'#3AA597'}}>3 und.</Text>
              </View>
            </View>
            <View style={{marginTop:10,marginBottom:20,height:1,backgroundColor:'black'}}></View>
            {
              props.tiendas.map(function(tienda:any) {
                
                if(tienda.productos.length>0){
                  return(
                    <View>
                      <Text>{tienda.nombre}</Text>
                    {
                      tienda.productos.map(function(item:any) {
                        return (
                          <ProductoTicket producto={item}></ProductoTicket>
                        )
                      })
                    }

                    { tienda.waiter_comission!='' && tienda.type_waiter_comission!='' ? 
                      (
                        <View style={{paddingBottom:10}}>
                          <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                            <View style={{flex:1,flexDirection:'row'}}>
                              <Text style={{fontWeight:'bold'}}>Servicio</Text>
                            </View>
                            <Text style={{color:'#3AA597'}}>{tienda.waiter_comission}€</Text>
                          </View>
                        </View>
                      )
                      :
                      (null)
                    }

                      
                    </View>
                  )
                }
              })
            }
            <View style={{alignItems:'center'}}>
              <View style={{backgroundColor:'#3AA597',width:'80%'}}>
                 <Text style={{fontWeight:'bold',color:'white',textAlign:'center',padding:10}}>TOTAL {precioTotal}€</Text>
              </View>
            </View>
            <View style={{marginTop:10,marginBottom:20,height:1,backgroundColor:'black'}}></View>
            <View style={{backgroundColor:'white',width:'100%',padding:10}}>
              <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <View style={{backgroundColor:'#C4C4C4',alignItems:'center',justifyContent:'center',width:100,height:100}}>
                  <FontAwesome name="dollar" size={50} color="white" />
                </View>
                <View style={{backgroundColor:'#C4C4C4',alignItems:'center',justifyContent:'center',width:100,height:100}}>
                  <FontAwesome name="credit-card" size={50} color="white" />
                </View>
              </View>
            
              <View style={{flexDirection:'row',justifyContent:'flex-start',marginTop:10}}>
                <View style={{backgroundColor:'#C4C4C4',alignItems:'center',justifyContent:'center',width:100,height:100}}>
                   <MaterialIcons name="dashboard" size={50} color="white" />
                </View>
                
              </View>
            </View>
        </View>
  );
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.carrito.tiendas,
  };
};
export default connect(mapStateToProps)(VistaTicket);
