import React from "react";
import { Feather } from "@expo/vector-icons";
import { StyleSheet, View } from "react-native";

// Components
import Divider from "../../../../../components/Divider";
import ActionButton from "./ActionButton";
import Price from "./Price";

const AddProduct = ({
  amount,
  showButton = true,
  onAdd,
  buttonSize = 35,
  iconSize = 30,
}) => {
  return (
    <View style={styles.container}>
      <Price amount={amount} />
      {showButton && (
        <>
          <Divider height={10} />
          <ActionButton color={"#D2F3C2"} onPress={onAdd} size={buttonSize}>
            <Feather name="plus" size={iconSize} color={"#4AA81E"} />
          </ActionButton>
        </>
      )}
    </View>
  );
};

export default AddProduct;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 60,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
