import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";

// Components
import Category from "./components/Category";

// Methods
import isComplex from "../../../ProductList/methods/isComplex";

// Objects
import Producto from "../../../../../../objetos/Producto";

const Plates = ({ data, selectedPlates, setSelectedPlates }) => {
  const navigation = useNavigation();

  const addPlate = (plate) => {
    let productoAux = new Producto(plate);
    if (isComplex(productoAux)) {
      navigation.push(
        "ProductDetail" as never,
        {
          product: productoAux,
          parent: data,
          setPlate: setSelectedPlates,
        } as never
      );
    } else {
      const plateIndex = selectedPlates.findIndex(
        (item) => item.gid === plate.gid
      );

      if (plateIndex === -1) {
        setSelectedPlates((prevState) => [
          ...prevState,
          { ...plate, cantidad: 1 },
        ]);
      } else {
        setSelectedPlates((prevSelectedPlates) =>
          prevSelectedPlates.map((item, index) =>
            index === plateIndex
              ? { ...item, cantidad: item.cantidad + 1 }
              : item
          )
        );
      }
    }
  };

  const removePlate = (plate) => {
    const plateIndex = selectedPlates.findIndex(
      (item) => item.gid === plate.gid
    );

    if (plateIndex !== -1) {
      const updatedPlate = { ...selectedPlates[plateIndex] };
      if (updatedPlate.cantidad === 1) {
        setSelectedPlates((prevSelectedPlates) =>
          prevSelectedPlates.filter((item) => item.gid !== plate.gid)
        );
      } else {
        updatedPlate.cantidad--;
        setSelectedPlates((prevSelectedPlates) =>
          prevSelectedPlates.map((item) =>
            item.gid === plate.gid ? updatedPlate : item
          )
        );
      }
    }
  };

  return (
    <View style={styles.platesContainer}>
      {data?.platos_elegir?.map((category) => {
        return (
          <Category
            category={category}
            selectedPlates={selectedPlates}
            addPlate={addPlate}
            removePlate={removePlate}
          />
        );
      })}
    </View>
  );
};

export default Plates;

const styles = StyleSheet.create({
  platesContainer: {
    width: "100%",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 0,
  },
});
