import React from "react";
import { Image, View, StyleSheet } from "react-native";

// Components
import Divider from "../../../../../components/Divider";

const ProductImage = ({ image, soldOut }) => {
  return (
    <View style={styles.imageContainer}>
      {image ? (
        <Image style={styles.image} source={{ uri: image }} />
      ) : soldOut === 1 ? (
        <Divider width={100} />
      ) : (
        <Divider width={20} />
      )}
      {soldOut === 1 && (
        <Image
          style={[styles.soldOut, !image && styles.noImage]}
          source={require("../../../../../../../assets/images/sold_out.png")}
        />
      )}
    </View>
  );
};

export default ProductImage;

const styles = StyleSheet.create({
  image: {
    width: 90,
    height: 90,
    borderRadius: 5,
    marginRight: 10,
  },
  imageContainer: {
    position: "relative",
  },
  soldOut: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 90,
    height: 90,
    borderRadius: 5,
    marginRight: 10,
  },
  noImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 90,
    height: 90,
    marginRight: 10,
  },
});
