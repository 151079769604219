import {SET_ULTIMO_PEDIDO, SET_PEDIDOS_PREPARAR,ORDENAR_FILTRADOS,SET_PEDIDOS,SET_PEDIDOS_FILTRADOS,ADD_PEDIDOS,DELETE_PEDIDO,EDIT_PEDIDOS,SET_SOCKET_CONECTADO,SET_PEDIDOS_PERDIDOS,SET_PEDIDO_SELECCIONADO,SET_PEDIDOS_ENTREGAR,SET_PEDIDOS_ENVIAR,SET_PEDIDOS_COMPLETADOS } from "./pedidosTypes";

let pedidosAUX=[]
const initialState = {
  pedidos: pedidosAUX,
  pedidosFiltrados: pedidosAUX,
  socketConectado: false,
  pedidosPerdidos:false,
  pedidoSeleccionado:false,
  pedidosParaEntregar: pedidosAUX,
  pedidosEnviados: pedidosAUX,
  pedidosCompletados: pedidosAUX,
  pedidosPreparar: pedidosAUX,
  ultimoPedido:''
};

const pedidosReducer = (state = initialState, action:any) => {

  switch (action.type) {
    case SET_PEDIDOS_FILTRADOS: {
      state.pedidosFiltrados = action.payload.pedidos.slice();
      return {...state}
    }
    case ADD_PEDIDOS: {
      let pedidosAux = state.pedidos.slice();
      //pedidosAux.splice( action.payload.posicion, 0, action.payload.pedido );
      pedidosAux.push(action.payload.pedido)
      state.pedidos = pedidosAux
      state.pedidosFiltrados=pedidosAux

      return { ...state }
      
    }
    case DELETE_PEDIDO: {
      let pedidosAux = state.pedidos.slice();
      let indice = -1
      pedidosAux.map(function(item:any,index:number) {
        if(item.id ==  action.payload.id){
          indice=index
        }
      })
      if(indice!=-1){
        pedidosAux.splice(indice, 1);
        state.pedidos = pedidosAux
      }
      
      return { 
        ...state
      }
    }
    case EDIT_PEDIDOS: {
      let pedidosAux = state.pedidos.slice();
      let indice=-1
      pedidosAux.map(function(item:any,index:number) {
        if(item.gid ==  action.payload.pedido.gid){
          indice=index
           
        }
      })
      if(indice!=-1){
        pedidosAux[indice]=action.payload.pedido
        state.pedidos = pedidosAux
         state.pedidosFiltrados=pedidosAux
      }
      return { ...state }
      
    }
    case SET_PEDIDOS: {
      
      let pedidosAux = action.payload.pedidos.slice();

      state.pedidos = pedidosAux
      state.pedidosFiltrados = pedidosAux
      return {...state}
    }
    case SET_SOCKET_CONECTADO: {
      state.socketConectado = action.payload.socketConectado
      return {...state}
    }
    case SET_PEDIDOS_PERDIDOS: {
      state.pedidosPerdidos = action.payload.pedidosPerdidos
      return {...state}
    }
    case SET_PEDIDO_SELECCIONADO: {
      state.pedidoSeleccionado = action.payload.pedidoSeleccionado
      return {...state}
    }
    case ORDENAR_FILTRADOS: {
      let pedidosAux
      if(action.payload.opcion=='asc'){
        pedidosAux = state.pedidosFiltrados.sort(function (a, b) {
          if (a.fechaEntrega < b.fechaEntrega) {
              return -1;
          }
          if (a.fechaEntrega > b.fechaEntrega) {
              return 1;
          }
          return 0;
        }).slice();
      }else{
        pedidosAux = state.pedidosFiltrados.sort(function (a, b) {
           if (a.fechaEntrega > b.fechaEntrega) {
                return -1;
            }
            if (a.fechaEntrega < b.fechaEntrega) {
                return 1;
            }
            return 0;
        }).slice();
      }
      state.pedidosFiltrados = pedidosAux
      return {...state}
    }
    case SET_PEDIDOS_ENTREGAR: {
      
      let pedidosAux = action.payload.pedidos.slice();

      state.pedidosParaEntregar = pedidosAux
      return {...state}
    }
    case SET_PEDIDOS_ENVIAR: {
      
      let pedidosAux = action.payload.pedidos.slice();

      state.pedidosEnviados = pedidosAux
      return {...state}
    }
    case SET_PEDIDOS_COMPLETADOS: {
      
      let pedidosAux = action.payload.pedidos.slice();

      state.pedidosCompletados = pedidosAux
      return {...state}
    }
    case SET_PEDIDOS_PREPARAR: {
      
      let pedidosAux = action.payload.pedidos.slice();

      state.pedidosPreparar = pedidosAux
      return {...state}
    }
    case SET_ULTIMO_PEDIDO: {
      state.ultimoPedido = action.payload.ultimoPedido;
      return {...state}
    }
    default:
      return state;
  }
};

export default pedidosReducer;