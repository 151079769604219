import React, { useState, useEffect, useRef } from 'react';
import {Text,TouchableOpacity, ActivityIndicator, Dimensions, View, StyleSheet, Image, ImageBackground } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';

import { styles } from '../../assets/styles/styles';

import {addProducto} from "../../application/reducers";
import { connect } from "react-redux";
import MasonryList from '@react-native-seoul/masonry-list';
import ProductoFoto from '../components/ProductoFoto';
const windowHeight = Dimensions.get('window').height;

const listadoProductos = (props:any) => {
  var familiaSel=props.route.params.familia
  var tienda=props.route.params.tienda
  function goBack(){
    props.navigation.goBack()
  }
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
    <View style={[{backgroundColor:'rgba(255,255,255,0.3)',alignItems:'center',justifyContent:'center',height:(windowHeight-80),marginTop:80},pantallaDividida?{width:'60%',marginLeft:'40%'}:{width:'100%'}]}>
      <TouchableOpacity activeOpacity={1} style={{width:'80%',height:'90%',borderRadius:8,backgroundColor:'#E1E1E1'}}>
        <TouchableOpacity  onPress={goBack}  style={{position:'absolute',top:20,right:20,zIndex:2}}>
          <AntDesign name="close" size={32} color="#3AA597" />
        </TouchableOpacity>
        <View style={{paddingTop:50,height:'100%'}}>
            <MasonryList
              data={familiaSel.products}
              numColumns={3}
              showsVerticalScrollIndicator={false}
              renderItem={({item}:any) => {
                return(<ProductoFoto tienda={tienda} navigation={props.navigation} producto={item}></ProductoFoto>)
                //return(<ProductoFamilia tienda={tienda} navigation={props.navigation} producto={item}></ProductoFamilia>)
              }}
            />
          </View>
        
      </TouchableOpacity>
    </View>
    </TouchableOpacity>
  );
};
const mapDispatchToProps = {
  addProducto
};
export default connect(null,mapDispatchToProps)(listadoProductos);
