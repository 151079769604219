import io from "socket.io-client"
import Pedido from './Pedido'
import AsyncStorage from '@react-native-async-storage/async-storage';
export default class Socket{
   socket
   socketProductos
   constructor() {
      //this.conectarSocket()
   }
   async conectarSocket(){
      var t = this;
      return new Promise(function(resolve, reject) {
         console.log("global.usuario.jwt",global.usuario.jwt)
         t.socket = io(global.endpoint+'orders', {
           transports: ['websocket'],
           path:"/sockets",
           extraHeaders:{
            Authorization:"Bearer "+global.usuario.jwt
           },
           auth:{
            authorization:"Bearer "+global.usuario.jwt
           },
           reconnectionAttempts: 15 
         });
         
         //t.cargarEscuchaPedidos()
         //t.cargarEscuchaCrearPedido()
        
         resolve(true)
      });
   }
   async cargarEscuchaPedidos(){
      var t = this;
      console.log(",cargarEscuchaPedidos,recent")
      return new Promise(function(resolve, reject) {
         t.socket.on("orders:recent",(res:any)=>{
           console.log(",orders,recent",res)
            let pedido = new Pedido(res.data);
            
            resolve(pedido)
         })

      });
   }
   guardarPedidoBBDD(carritoAux:any){
      global.db.transaction((tx:any) => {
         tx.executeSql(
           "CREATE TABLE IF NOT EXISTS misPedidos ("+
             "pedidos TEXT"+
           ");"
         );
      },function(err:any){console.log("err",err)});

      global.db.transaction((tx:any) => {
         tx.executeSql(
           "INSERT OR REPLACE INTO misPedidos("+
           "pedidos"+
           ") VALUES ('"+
           JSON.stringify(carritoAux)+
           "');"
         );
      },function(err:any){console.log("err",err)});
   }

   async cargarEscuchaCrearPedido(){
      var t = this;
      return new Promise(function(resolve, reject) {
         t.socket.off("orders:createPaid:result")
         t.socket.on("orders:createPaid:result",(res:any)=>{
            console.log("result crear pedido",res)
            if(res.status=="fail"){
               //alert(res.message)
            }else{
               //alert("Pedido creado correctamente")
               let pedido = new Pedido(res.data[0]);
               if(global.db!=undefined && global.db!=null && global.db!=false){
                  t.guardarPedidoBBDD(pedido)
               }
               
            }
            
         })
      });
   }
   
   async conectarSocketProductos(idPedido:any,lineasPedido:any){
      var t = this;
      t.socketProductos = io(global.endpoint+'products', {
        transports: ['websocket'],
        path:"/sockets",
        extraHeaders:{
         Authorization:"Bearer "+global.usuario.jwt
        },
        auth:{
         authorization:"Bearer "+global.usuario.jwt
        },
        reconnectionAttempts: 15 
      });
      t.socketProductos.on("connect",()=>{
         
         
       t.socketProductos.conectado=true
      })
      t.socketProductos.on("connect_error",(err:any)=>{
       console.log("connect_error",err)
      })
   }
   async notificarProductosAgotados(idPedido:any,lineasPedido:any){
      var t = this;
      console.log("emit products:runout",{
         order_gid: idPedido,
         order_lines_gids: lineasPedido
      })
      t.socketProductos.emit("products:runout",{
         order_gid: idPedido,
         order_lines_gids: lineasPedido
      })
   }
   async crearPedido(pedido:any){
      var t = this;
     
      pedido.orders.map(function(order:any,indice:number) {
         order.gid=undefined
        });
       console.log("hago el emit del crear pedido",pedido)
      t.socket.emit("orders:createPaid",pedido)
        
   }
   async pedirPedidos(){
      var t = this;
      return new Promise(function(resolve, reject) {
         t.socket.on("orders:read:result",async (res:any)=>{
            console.log(",readread,result",res)
            let pedidos = [];
            let pedidosAux = res.data.orders || []
            let ultimoCierre = await AsyncStorage.getItem('@ultimoCierre')

            pedidosAux.map(function(pedido:any,indice:number) {
               if(pedido.order_date>=parseFloat(ultimoCierre) || parseFloat(ultimoCierre || '0')==0){
                  if(pedido.order_lines!=undefined){
                     if(pedido.order_lines.length>0){
                      pedidos.push(new Pedido(pedido))
                     }
                  }
               }
             
            });

            resolve(pedidos)
         })
         t.socket.emit("orders:read",{})
      });
   }
   async cambiarEstadoPedido(pedido,estadoAntiguo){
      var t = this;
      console.log("cambiarEstadoPedido", pedido)
      t.socket.emit("orders:update",
      {
         "gid": pedido.gid,
         "status":estadoAntiguo,
         //"new_status":pedido.state
      })
   }
   async cambiarEstadoPedidoConcreto(pedido,estadoAntiguo){
      var t = this;
      console.log("cambiarEstadoPedidoConcreto", pedido)
      t.socket.emit("orders:update",
      {
         "gid": pedido.gid,
         "status":estadoAntiguo,
         "new_status":pedido.state
      })
   }
    async obtenerCreditosYBonos(uuid:any,shops:any){
      var t = this;
      console.log("emit products:getCreditAndBono",{
         "uuid_user":uuid,
         "shops": shops
      })
      t.socketProductos.emit("products:getCreditAndBono",{
         "uuid_user":uuid,
         "shops": shops
      })
   }   

} 