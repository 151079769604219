import React, { useContext } from "react";
import {
  ImageBackground,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { connect } from "react-redux";

// Context
import { FoodStallContext } from "../../../context/FoodStallContext";

// Methods
import isComplex from "../methods/isComplex";

// Objects
import Producto from "../../../../../objetos/Producto";

// Reducers
import { addProducto } from "../../../../../reducers";

const ProductItem = ({ data, addProducto }) => {
  const navigation = useNavigation();
  const { image, name } = data;
  const { shop } = useContext(FoodStallContext);

  const addProduct = () => {
    let productoAux = new Producto(data);
    productoAux.amount = productoAux.amount * 100;
    addProducto(shop, productoAux);
  };

  const clickHandler = () => {
    if (isComplex(data)) {
      navigation.navigate("ProductDetail" as never, { product: data } as never);
    } else {
      addProduct();
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={clickHandler}>
      <ImageBackground source={{ uri: image }} style={styles.image}>
        <View style={styles.opacity}>
          <Text style={styles.text}>{name}</Text>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const mapDispatchToProps = {
  addProducto,
};

export default connect(null, mapDispatchToProps)(ProductItem);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 650,
    height: 300,
    marginBottom: 30,
    overflow: "hidden",
    borderRadius: 10,
  },
  image: {
    width: "100%",
    height: "100%",
  },
  opacity: {
    resizeMode: "cover",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.25)",
  },
  text: {
    fontSize: 48,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
});
