import React, { useContext } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { useNavigation } from "@react-navigation/native";

// Components
import Plate from "../../ProductDetail/components/plates/components/Plate";

// Context
import { FoodStallContext } from "../../../context/FoodStallContext";

// Objects
import Producto from "../../../../../objetos/Producto";

// Methods
import isComplex from "../methods/isComplex";

// Reducers
import { addProducto, eliminarProducto } from "../../../../../reducers";
import AddedProduct from "./AddedProduct";

const SmallProductItem = ({ data, addProducto, eliminarProducto, tiendas }) => {
  const { shop } = useContext(FoodStallContext);
  const navigation = useNavigation();
  const quantity =
    tiendas.length === 0
      ? 0
      : tiendas[0].productos.find((item) => item.gid === data.gid)?.cantidad ||
        0;

  const addProduct = () => {
    let productoAux = new Producto(data);
    productoAux.amount = productoAux.amount * 100;
    addProducto(shop, productoAux);
  };

  const removeProduct = () => {
    eliminarProducto(shop, data);
  };

  const clickHandler = () => {
    if (isComplex(data)) {
      navigation.navigate("ProductDetail" as never, { product: data } as never);
    } else {
      addProduct();
    }
  };

  function addExtraProduct(tienda: any, producto: any) {
    addProducto(tienda, producto);
  }

  function removeExtraProduct(tienda: any, producto: any) {
    eliminarProducto(tienda, producto, false);
  }

  const showActions = isComplex(data) ? false : true;
  return (
    <>
      <Plate
        isMenu={false}
        data={data}
        onPress={clickHandler}
        onRemove={removeProduct}
        quantity={quantity}
        showActions={showActions}
      />
      {isComplex(data) &&
        tiendas.map(function (tienda: any) {
          return tienda.productos.map((prod) => {
            if (prod.gid === data.gid) {
              return (
                <AddedProduct
                  data={prod}
                  quantity={prod.cantidad}
                  onRemove={() => removeExtraProduct(tienda, prod)}
                  onAdd={() => addExtraProduct(tienda, prod)}
                />
              );
            }
          });
        })}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
  };
};

const mapDispatchToProps = {
  addProducto,
  eliminarProducto,
};

export default connect(mapStateToProps, mapDispatchToProps)(SmallProductItem);
