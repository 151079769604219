import { SET_TIENDAS,GET_TIENDA,ADD_TIENDA,DELETE_TIENDA,EDIT_TIENDA } from "./tiendasTypes";

const initialState = {
  tiendas:[]
};
const tiendasReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case GET_TIENDA: {
     
      return { 
        ...state
      }
    }
    case ADD_TIENDA: {
      
      return { ...state }
      
    }
    case DELETE_TIENDA: {
     
      return { 
        ...state
      }
    }
    case EDIT_TIENDA: {
     
      return { ...state }
      
    }
    case SET_TIENDAS: {
     
      let tiendasAux = action.payload.tiendas.slice();
      state.tiendas = tiendasAux
      return { ...state }
    }
    
   
    default:
      return state;
  }
};

export default tiendasReducer;