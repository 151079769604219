const getNumOrders = (shops = []) => {
  let result = 0;
  for (let shop of shops) {
    for (let product of shop.productos) {
      result += product?.cantidad;
    }
  }
  return result;
};

export default getNumOrders;
