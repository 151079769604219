import React, { useState, useEffect } from 'react';
import { Alert,Platform,StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';
//import { BarCodeScanner } from 'expo-barcode-scanner';
import { connect } from "react-redux";
import {FontAwesome,Ionicons,AntDesign} from '@expo/vector-icons';
import VistaDetallesPedido from './vistaDetallesPedido';
import Pedido from '../objetos/Pedido';
import { BarCodeScanner } from 'expo-barcode-scanner';
const vistaEscaner = (props:any) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [pedidoSel, setPedidoSel] = useState(null);
  const [textoPantalla, setTextoPantalla] = useState('Escanea un pedido para ver sus detalles');
  const [textoEstado, setTextoEstado] = useState('');
  //const [pedidoEscaneado, setPedidoEscaneado] = useState(false);
  
  const [barCodeType, setBarCodeType] = useState([BarCodeScanner.Constants.BarCodeType.qr]);

  const [horizontal, setHorizontal] = useState(true);
  const [flash, setFlash] = useState(Camera.Constants.FlashMode.off);
  function eliminarPedido(){
    console.log("elimino")
    setBarCodeType([BarCodeScanner.Constants.BarCodeType.qr])

    //setPedidoEscaneado(false)
    setPedidoSel(null)
  
    props.navigation.replace('vistaEscaner');
    
  }
  function onPressValidar(){
    if(Platform.OS === 'web'){
      validarPedido()
    }else{
      Alert.alert(
        '',
        '¿Desea realizar el pedido?',
        [
          {
            text: 'Aceptar',
            onPress: () => {
              validarPedido()
            },
          },
          {
            text: 'Cancelar',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel'
          },
        ],
        { cancelable: true }
      );
    }
  }
  function goBack(){
    props.navigation.goBack()
  }
  function validarPedido(){
    if(pedidoSel!=null){
      pedidoSel.cambiarEstado("Completado","completed")
      eliminarPedido()
      alert("Pedido validado correctamente")
    }
  }
  useEffect(() => {
     
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
      console.log("se cambia el barCodeType",barCodeType)
     props.navigation.setOptions({
            headerTitle:'Escanea el pedido',
            
            headerRight: () => (
              <TouchableOpacity style={{padding:20}} onPress={eliminarPedido}>
                  <AntDesign name="delete" size={24} color="black" />
              </TouchableOpacity>
             
            ),  
            
        });
  }, [pedidoSel,

barCodeType]);
  useEffect(() => {
    console.log(props.width,props.height)
   if(props.width>props.height){
    setHorizontal(true)
     console.log(true)
   }else{
    setHorizontal(false)
    console.log(false)
   }
   
  }, [props.width]);
  useEffect(() => {
    
    props.navigation.setOptions({
            headerLeft: () => (
              <TouchableOpacity style={{padding:20}} onPress={goBack}>
                <AntDesign name="left" size={24} color="black" />
              </TouchableOpacity>
             
            ),
        });
  }, []);

  function onBarCodeRead (data:any) {
      
    /*let existePedido=-1
    props.tiendas.map(function(tienda:any,index:number) {
      tienda.barras.map(function(barra:any,index:number) {
        barra.pedidos.map(function(pedido:any,index:number) {
          if(pedido.id==data){
            existePedido=pedido 
          }
        })
      })
    })*/
    //console.log('pedidoEscaneado',pedidoEscaneado)
    //if(!pedidoEscaneado){

      setBarCodeType([''])
      //setPedidoEscaneado(true)

      let datastr = data.trim()
      let pedidoAux = new Pedido({gid:datastr})
      console.log("hago la llamada",datastr)
      pedidoAux.cargarPedidoById().then(function(pedidoRes:any){
        if(pedidoRes.gid!=''){
          if(pedidoRes.state=="cancelled" || pedidoRes.state=="payment_cancelled"){
            

            setTextoEstado("Ups, el pedido escaneado está cancelado")
            setPedidoSel(pedidoRes)
          }
          else if(pedidoRes.state=="completed"){


            setTextoEstado("Este pedido ya se ha validado anteriormente")
            setPedidoSel(pedidoRes)
          }else{
            setTextoEstado("")
            setPedidoSel(pedidoRes)
          }
          
        }
      })
    //}
        
  }
  function toggleFlash() {
    if(flash==Camera.Constants.FlashMode.off){
      setFlash(Camera.Constants.FlashMode.torch)
    }else{
      setFlash(Camera.Constants.FlashMode.off)
    }
    
  }
  return (
    <View style={[{flex: 1,backgroundColor:'black'}]}>
      <Camera 
        style={[styles.camera,horizontal?{width:props.width,height:props.width}:{width:props.height,height:props.height}]}
        type={type} 
        onBarCodeScanned={({ type, data }:any ) =>{
          if(data!=undefined && data!=null && data!=''){
            onBarCodeRead(data)
          }
        }}
        barCodeScannerSettings={{
          barCodeTypes: barCodeType,
        }}
         flashMode={flash}
        autoFocus={Camera.Constants.AutoFocus.on}
        focusDepth={1}
        >
        <View style={{position:'absolute', top:0,zIndex:2,width:props.width,height:100,justifyContent:'center', padding:20,alignItems:'flex-end'}}>
          <TouchableOpacity
            onPress={toggleFlash}>
            {flash==Camera.Constants.FlashMode.off?(
              <Ionicons name="flash" size={28} color="white" />
              ):(
              <Ionicons name="flash-off" size={28} color="white" />
            )}
            
          </TouchableOpacity>
        </View>
      </Camera>
            {
              pedidoSel ?
                (
                  <View style={[{backgroundColor:'rgba(0, 0, 0, 0.8)',position:'absolute',width:'100%',height:'100%',justifyContent:'center',alignItems:'center'},global.isDatafono?{justifyContent:'flex-start',paddingTop:20}:{}]}>
                    <View style={{width:'90%',justifyContent:'center',alignItems:'center'}}>
                    {
                      textoEstado=='' ?
                        (
                         <VistaDetallesPedido validarPedido={validarPedido} pedido={pedidoSel}></VistaDetallesPedido>
                        )
                      :
                        (
                          <View style={{justifyContent:'center',alignItems:'center',backgroundColor:'#E1E1E1',padding:50}}>
                            <Text style={{fontSize:14}}>{textoEstado}</Text>
                          </View>
                        )
                    }
                    </View>
                  </View>
                )
              :
                (
                  null
                )
            }
           
      
          
      
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    //flex: 1,
  },
  
});
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.tiendas.tiendas,
    width:state.dimensions.width,
    height:state.dimensions.height
  };
};
export default connect(mapStateToProps)(vistaEscaner);
