import React,{Component,useReducer} from 'react';
import { AntDesign  } from '@expo/vector-icons';
import {Dimensions,Image,TouchableOpacity,Text,View} from "react-native";
const Tienda = (props:any) => {
    const {tienda,abrirTienda} = props
    return(

          <View style={{borderRadius:8,marginBottom:20,shadowColor: "#000",shadowOffset: {width: 0,height: 0},shadowOpacity: 0.25,shadowRadius: 3.84,elevation: 5}}>
            <TouchableOpacity style={{overflow:'hidden',backgroundColor:'white',width:280,flexDirection:'row',borderRadius:8}} onPress={abrirTienda.bind(this,tienda)}>
              <View style={[{width:100}]}>
                  <Image
                      style={[{height: 100,width:100,resizeMode:'cover'}]}
                      source={
                              {uri:tienda.image}
                            }
                  />
              </View>
              <View style={{flexDirection:'row',flex:1,justifyContent:'space-between',paddingRight:10,alignItems:'center'}}>
                  <Text style={{fontSize: 16,padding:8,fontWeight:'bold'}}>{tienda.name}</Text>
                  <AntDesign name='right' size={24} color='black'/>
              </View>
              
          </TouchableOpacity>
        </View>


    );
    
}
export default Tienda;
