import { SET_OPCION_VISTA,SET_CARGANDO_FILTROS,SET_NAVIGATION,SET_FOOTER_VISIBLE } from "./configTypes";



export const setOpcionVista = (opcionVista:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_OPCION_VISTA,
      payload: {
        opcionVista: opcionVista
      }
    });
  };
};
export const setCargandoFiltros = (opcion:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_CARGANDO_FILTROS,
      payload: {
        opcion: opcion
      }
    });
  };
};
export const setNavigation = (navigationRedux:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_NAVIGATION,
      payload: {
        navigationRedux: navigationRedux
      }
    });
  };
};
export const setFooterVisible = (footerVisible:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_FOOTER_VISIBLE,
      payload: {
        footerVisible: footerVisible
      }
    });
  };
};