import React, { useContext } from "react";
import { StyleSheet, Text, View, ImageBackground } from "react-native";

// Context
import { FoodStallContext } from "../../../context/FoodStallContext";

const Header = ({ data }) => {
  const { image, name } = data;
  const { setSection } = useContext(FoodStallContext);

  return (
    <ImageBackground source={{ uri: image }} style={styles.image}>
      <View style={styles.opacity}>
        <Text style={styles.text}>{name}</Text>
      </View>
    </ImageBackground>
  );
};

export default Header;

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: 250,
  },
  opacity: {
    resizeMode: "cover",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.25)",
  },
  text: {
    fontSize: 48,
    textAlign: "center",
    color: "white",
  },
});
