import { SET_PEDIDO_IMPRIMIR,SET_PEDIDOS_PENDIENTES,SET_IMPRESORA_DISPONIBLE,SET_REFERENCIA_TICKET,SET_FACTURA_PRODUCTOS,SET_FACTURA_COMISION } from "./pedidoImprimirTypes";


export const setPedidoImprimir = (pedido:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDO_IMPRIMIR,
      payload: {
        pedido: pedido
      }
    });
  };
};
export const setFacturaProductos= (facturaProductos:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_FACTURA_PRODUCTOS,
      payload: {
        facturaProductos: facturaProductos
      }
    });
  };
};
export const setFacturaComision= (facturaComision:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_FACTURA_COMISION,
      payload: {
        facturaComision: facturaComision
      }
    });
  };
};
export const setPedidosPendientes = (pedidosPendientes:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_PEDIDOS_PENDIENTES,
      payload: {
        pedidosPendientes: pedidosPendientes
      }
    });
  };
};
export const setImpresoraDisponible = (impresoraDisponible:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_IMPRESORA_DISPONIBLE,
      payload: {
        impresoraDisponible: impresoraDisponible
      }
    });
  };
};
export const setReferenciaTicket = (referenciaTicket:any) => {
  return (dispatch:any) => {
    dispatch({
      type: SET_REFERENCIA_TICKET,
      payload: {
        referenciaTicket: referenciaTicket
      }
    });
  };
};