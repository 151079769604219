import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../../../components/MainButton";
import ModalBase from "../../../components/ModalBase";
import Divider from "../../../components/Divider";

const ErrorModal = ({ visible, setModal, error }) => {
  const { t } = useTranslation();
  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <Image
          style={styles.image}
          source={require("../../../../../assets/images/roomImage.png")}
        />
        <Divider height={20} />
        <Text style={styles.text}>{error}</Text>
        <Divider height={20} />
        <View style={styles.buttonWrapper}>
          <MainButton title={t("accept")} onPress={() => setModal()} />
        </View>
      </View>
    </ModalBase>
  );
};

export default ErrorModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    maxWidth: 200,
    width: "100%",
  },
  image: {
    width: 150,
    resizeMode: "contain",
    height: 150,
  },
  text: {
    fontSize: 20,
    color: "black",
    textAlign: "center",
  },
});
