import React, { useState, useEffect } from "react";
import { Text, FlatList, TouchableOpacity, View } from "react-native";

import { Ionicons } from "@expo/vector-icons";

import { addProducto } from "../../application/reducers";
import { connect } from "react-redux";
import ProductoFoto from "../components/ProductoFoto";
import Producto from "../../application/objetos/Producto";

const productoMenu = (props: any) => {
  var producto = props.route.params.producto;
  var tienda = props.route.params.tienda;

  let productoAux = new Producto(JSON.parse(JSON.stringify(producto)));
  productoAux.amount = productoAux.amount * 100;
  const [indicePaso, setIndicePaso] = useState(0);
  const [pasoSel, setPasoSel] = useState(productoAux.platos_elegir[indicePaso]);

  const [platosSel, setPlatosSel] = useState([]);
  function goBack() {
    props.navigation.goBack();
  }

  useEffect(() => {
    props.navigation.setOptions({
      headerTitle: pasoSel.nombre,
    });
  }, [pasoSel]);

  useEffect(() => {
    setPasoSel(productoAux.platos_elegir[indicePaso]);

    props.navigation.setOptions({
      headerLeft: () => (
        <TouchableOpacity
          style={{ paddingLeft: 20 }}
          onPress={() => {
            if (indicePaso > 0) {
              setIndicePaso(indicePaso - 1);
              let platosSelAux = platosSel.slice();
              platosSelAux.splice(platosSelAux.length - 1, 1);
              setPlatosSel(platosSelAux);
            } else {
              goBack();
            }
          }}
        >
          <Ionicons name="arrow-back" size={30} color="white" />
        </TouchableOpacity>
      ),
    });
  }, [indicePaso]);

  function addProductoCarrito(producto: any) {
    props.addProducto(tienda, producto);
    goBack();
  }
  function abrirVariantes(producto: any) {
    let aux = {
      producto: producto,
      tienda: tienda,
      siguientePaso: siguientePaso,
    };
    props.navigation.navigate("listadoVariantes", aux);
  }

  function onClickProductoMenu(producto: any) {
    console.log("onClickProductoMenu", producto);
    console.log("onClickProductoMenu", producto.variants.length);
    if (producto.variants.length > 0 || producto.ingredients.length > 0) {
      abrirVariantes(producto);
    } else {
      siguientePaso(producto);
    }
  }
  function siguientePaso(producto: any) {
    let platosSelAux = platosSel.slice();
    let platos_elegirAux = JSON.parse(JSON.stringify(pasoSel));
    platos_elegirAux.productos = [producto];
    platosSelAux.push(platos_elegirAux);
    setPlatosSel(platosSelAux);
    console.log("platosSelAux", platosSelAux);
    if (indicePaso < productoAux.platos_elegir.length - 1) {
      setIndicePaso(indicePaso + 1);
      console.log("indicePaso", indicePaso);
    } else {
      productoAux.platosSel = platosSelAux;
      addProductoCarrito(productoAux);
    }
  }
  return (
    <TouchableOpacity activeOpacity={1} onPress={goBack}>
      <View
        style={[
          {
            backgroundColor: "rgba(255,255,255,0.3)",
            alignItems: "center",
            justifyContent: "center",
            height: props.height - 120,
            marginTop: 60,
          },
          pantallaDividida
            ? { width: "60%", marginLeft: "40%" }
            : { width: "100%" },
        ]}
      >
        <TouchableOpacity
          activeOpacity={1}
          style={{
            width: "80%",
            height: "90%",
            borderRadius: 8,
            backgroundColor: "#E1E1E1",
            overflow: "hidden",
          }}
        >
          <View style={{ height: 50 }}>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <View
                style={{
                  zIndex: 2,
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundColor: "#3AA597",
                }}
              >
                <TouchableOpacity
                  style={{ paddingLeft: 10 }}
                  onPress={() => {
                    if (indicePaso > 0) {
                      setIndicePaso(indicePaso - 1);
                      let platosSelAux = platosSel.slice();
                      platosSelAux.splice(platosSelAux.length - 1, 1);
                      setPlatosSel(platosSelAux);
                    } else {
                      goBack();
                    }
                  }}
                >
                  <Ionicons name="arrow-back" size={20} color="white" />
                </TouchableOpacity>
                <Text
                  style={{
                    flex: 1,
                    fontSize: 18,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {pasoSel.name}
                </Text>
                <View style={{ paddingRight: 10 }}>
                  <Text style={{ color: "white", fontSize: 18 }}>
                    {indicePaso + 1}/{productoAux.platos_elegir.length}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <FlatList
              style={{}}
              data={pasoSel.productos}
              numColumns={2}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{ paddingBottom: 50 }}
              renderItem={({ item }: any) => {
                return (
                  <View style={[{ width: "50%" }]}>
                    <ProductoFoto
                      onClickProductoMenu={onClickProductoMenu.bind(this, item)}
                      tienda={tienda}
                      navigation={props.navigation}
                      producto={item}
                    ></ProductoFoto>
                  </View>
                );
              }}
            />
          </View>
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
};
const mapDispatchToProps = {
  addProducto,
};
const mapStateToProps = (state: any) => {
  return {
    height: state.dimensions.height,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(productoMenu);
