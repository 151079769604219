import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import Divider from "../../../../components/Divider";

// Methods
import { getIngredientsText } from "../../methods/getIngredientsText";
import { getVariantsText } from "../../methods/getVariantsText";

const MenuLines = ({ plates }) => {
  const { t } = useTranslation();
  if (plates.length === 0) {
    return <View />;
  }
  return (
    <View>
      {plates.map((product: any) => {
        const variantText = getVariantsText(product.variantesSel) || null;
        const ingredientsText =
          getIngredientsText(product.ingredientesSinSel) || null;
        return (
          <View>
            <Text style={styles.title}>{product.cantidad}x {t(product.name)}</Text>
            <View style={styles.column}>
              {ingredientsText && (
                <Text style={styles.text}>
                  {t("without") + ": " + ingredientsText}
                </Text>
              )}
              {variantText && <Text style={styles.text}>{variantText}</Text>}
            </View>
            <Divider height={5}/>
          </View>
        );
      })}
    </View>
  );
};

export default MenuLines;

const styles = StyleSheet.create({
  column: {
    paddingLeft: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: "200",
  },
  text: {
    fontSize: 18,
    fontWeight: "200",
  },
});
