import React,{useEffect,useState} from 'react';

import {Dimensions,Image,StyleSheet,TouchableOpacity,Text,View} from "react-native";
import {Entypo} from '@expo/vector-icons'; 

import Swipeable from 'react-native-gesture-handler/Swipeable';
const ProductoResumenCamarero = (props:any) => {
  const {lineaPedido} = props
  let producto = lineaPedido.producto
  const [strVariantes, setStrVariantes] = useState('');
  const [strIngredientes, setStrIngredientes] = useState('');
  let arrayInit:Array<any>=[]
  const [menu_lines, setMenu_lines] = useState(arrayInit);
  
  
    function pintarVariantes(variantes:any,quantityProduct:any){
      console.log("variantes",variantes)
     if(variantes!=undefined && variantes!=null){
       return(
         
          variantes.map(function(grupoVariante:any,indice:number) {
            if(grupoVariante!=undefined && grupoVariante!=null){
              return(
                grupoVariante.variants.map(function(opcion:any,index:number) {

                    const strCantidad = `+ ${opcion.quantity > 1 ?  `${opcion.quantity}x ` : ''}`;
                    return(
                      <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
                        <View style={{flex:1,flexDirection:'row'}}>
                          <Text style={{fontWeight:'bold'}}>{strCantidad}</Text>
                          <Text style={{}}>{opcion.name}</Text>
                        </View>
                        {props.sinPrecio?
                          (null)
                          :
                          (<Text style={{color:'black',fontSize:12}}>{(quantityProduct*opcion.quantity*(opcion.amount/100||0)).toFixed(2)}€</Text>)
                        }
                        
                      </View>
                    )
                  
                })
              )
             }
          })
        )
      }

    }
    function pintarIngredientes(ingredientes:any){
      /*let strIngredientes = ''
      let primerIng = true
      producto.ingredients_removed.map(function(ingrediente:any,indice:number) {
        var strComa=','
        if(primerIng){
          strComa=''
          primerIng=false
        }
        strIngredientes+=strComa+' '+ingrediente.name    
      })
      if(strIngredientes!=''){
        return(
          <View style={{marginLeft:20}}>
            <Text>SIN: {strIngredientes}</Text>          
          </View>
        )
      }*/
      console.log("ingredientes",ingredientes)
      if(ingredientes!=undefined){
      return(
        ingredientes.map(function(ingrediente:any,indice:number) {
         
          return(
            <View style={{marginLeft:20,flexDirection:'row',justifyContent:'space-between'}}>
              <View style={{flex:1,flexDirection:'row'}}>
                <Text style={{fontWeight:'bold'}}>SIN </Text>
                 <Text>{ingrediente}</Text> 
              </View>
             
            </View>
          )
              
        })
      )
    }
    }
    function getStrVariantes(variantes:any){
    
    let srtVariantesAux = ''
    let primero = true
    console.log("variantes",variantes)
    if(variantes!=undefined && variantes!=null){
      variantes.map(function(grupoVariante:any,indice:number) {
        if(grupoVariante!=null){
          let strOpciones = ''
          if( grupoVariante.variants!=undefined){
            grupoVariante.variants.map(function(opcion:any,index:number) {
                //if(opcion.valor){
                  var strComa=','
                  if(primero){
                    strComa=''
                    primero=false
                  }
                  /*let strCantidad = ''
                  if(opcion.cantidad>1){
                    strCantidad=opcion.cantidad+'x '
                  }*/
                  strOpciones+=strComa+' '+opcion.name           
                //}
            })
            srtVariantesAux+=strOpciones
          }
        }
      })
    }
    return srtVariantesAux
  }
  
  function getStrIngredientes(ingredientes:any){
    let srtIngredientesAux = ''
    let primerIng = true
    if(ingredientes!=undefined){
      ingredientes.map(function(ingrediente:any,indice:number) {
        var strComa=','
        if(primerIng){
          strComa=''
          primerIng=false
        }
        srtIngredientesAux+=strComa+' '+ingrediente    
      })
   }
    return srtIngredientesAux
  }
  /*{producto.unit_price!=undefined && producto.unit_price!=0?
                (<Text style={{}}>{producto.quantity*producto.unit_price/100}€</Text>)
                :
                (<Text style={{}}>{producto.quantity*producto.amount/100}€</Text>)
              }*/
  useEffect(() => {
     console.log("useEffect",producto)
    setStrVariantes(getStrVariantes(producto.groups))
    setStrIngredientes(getStrIngredientes(producto.ingredientesSinSel))
    let menu = producto.menu_lines || []
    let menuAux = []
    menu.map(function(item:any,index:any){
      if(item!=null){
        menuAux.push(item)
      }
    })
    setMenu_lines(menuAux)
     console.log("menuAux",menuAux)
  }, [producto]);
  /*
  { strIngredientes!='' ? (
              <View style={{paddingLeft:20}}>
                <Text style={{paddingRight:4,fontSize: 14,color:'black'}}>SIN: {strIngredientes}</Text>
              </View>
            ) : null}
            { strVariantes!='' ? (
            <View style={{paddingLeft:20}}>
              <Text style={{paddingRight:4,fontSize: 14}}>{strVariantes}</Text>
            </View>
            ) : null}
  */
    return(
      
        <View 
        
          >
          <View style={{marginBottom:1,backgroundColor:'white',paddingTop:5,paddingBottom:5}}>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
              {menu_lines.length>0 ? (
                <View style={{flexDirection:'row'}}>
                  <Text style={[{lineHeight:21,fontSize: 18,fontWeight:'bold',color:'black'}]}>{lineaPedido.cantidad || 1}x </Text>
                  <Text style={[{fontSize: 15},props.datafono?{fontWeight:'bold',fontSize: 17,color:'black'}:{fontSize: 15}]}>{producto.name}</Text>
                </View>
              ) : (
                <View style={{flexDirection:'row'}}>
                  <Text style={[{lineHeight:21,fontSize: 18,fontWeight:'bold',color:'#3AA597'},props.datafono?{color:'black'}:{color:'#3AA597'}]}>{lineaPedido.cantidad || 1}x </Text>
                  <Text style={[{fontSize: 15},props.datafono?{fontWeight:'bold',fontSize: 17,color:'black'}:{fontSize: 15}]}>{producto.name}</Text>
                </View>
              )}
              {props.sinPrecio?
                (null)
                :
                (<Text style={props.datafono?{fontWeight:'bold',fontSize: 17}:{fontSize: 15}}>{(producto.calcularPrecioProductoTicket()*(lineaPedido.cantidad || 1)).toFixed(2)}€</Text>)
              }
              
              
            </View>
            {pintarVariantes(producto.groups,lineaPedido.cantidad)}
            {pintarIngredientes(producto.ingredientesSinSel)}
            {menu_lines.length>0 ? (  
                menu_lines.map(function(productoPlato:any,index:number) {
                  if(productoPlato!=null){
                    console.log("productoPlato",productoPlato)
                     console.log("productoPlato",productoPlato.oculto)
                    if(!productoPlato.oculto){
                      let strVariantesAux = getStrVariantes(productoPlato.variants)
                      let strIngredientesAux = getStrIngredientes(productoPlato.ingredients_removed)
                      return(
                        <View style={{marginLeft:20}}>
                        <View style={{flexDirection:'row'}}>
                          {productoPlato.quantity>1 && (<Text style={[{lineHeight:21,fontSize: 18,fontWeight:'bold',color:'#3AA597'}]}>{productoPlato.quantity}x </Text>)}

                          <Text style={[{fontSize: 16,color:'black'}]}>{productoPlato.name}</Text>
                        </View>
                        <View style={{}}>
                          {pintarVariantes(productoPlato.variants,1)}
                          {pintarIngredientes(productoPlato.ingredients_removed)}
                        </View>
                      </View>
                      )
                    }
                  }                  
                  
                })

              ) : null}
          </View>
        </View>
    );
    
}
/*
{ strIngredientesAux!='' ? (
                            <View style={{}}>
                              <Text style={{paddingRight:4,fontSize: 15,color:'black'}}>SIN: {strIngredientesAux}</Text>
                            </View>
                          ) : null}
                          { strVariantesAux!='' ? (
                          <View style={{}}>
                            <Text style={{paddingRight:4,fontSize: 15}}>{strVariantesAux}</Text>
                          </View>
                          ) : null}*/
export default (ProductoResumenCamarero);