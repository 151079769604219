import React, { useState, useEffect, useRef } from 'react';
import { TextInput,ActivityIndicator,TouchableOpacity, Dimensions, View, StyleSheet, Image, ImageBackground,Text } from 'react-native';
import { styles } from '../../assets/styles/styles';
import { StatusBar } from 'expo-status-bar';
import {Octicons,MaterialIcons,FontAwesome,AntDesign  } from '@expo/vector-icons';
import {FlatList} from 'react-native-gesture-handler'
import MasonryList from '@react-native-seoul/masonry-list';
import {setFooterVisible,setFacturaProductos,setFacturaComision} from "../../application/reducers";

import ProductoTicket from '../components/ProductoTicket';
import Footer from '../components/Footer';
import { connect } from "react-redux";
import { LinearGradient } from 'expo-linear-gradient';
import myCrypto from '../funcionalidades/myCrypto';
import ProductoFoto from '../components/ProductoFoto';
const VistaFamiliaProductos = (props:any) => {
  let tienda
  let familia
  const [precioTotal, setPrecioTotal] = useState(0);
  const [totalProductos, setTotalProductos] = useState(0);
  if(props.route!=undefined){
    tienda=props.route.params.tienda
    familia= props.route.params.familia || ''
  }else{
    tienda=props.tienda
    familia= props.familia || ''
  }
  function reImprimirFacturas(){
    global.usuario.obtenerFacturas(props.ultimoPedido.gid).then(async function(res:any){
      console.log("resultado obtener facutras", res)
      if(res?.data?.data?.length>0){
        if(res?.data?.data?.length>0){
          for (var i=res?.data?.data?.length-1; i>=0; i--) {

              let item=res.data.data[i]
              let factura=item.data
              factura.ticket_bai_url=item.ticket_bai_url || ''
              if(i==0){
                props.setFacturaProductos(factura)
                setTimeout(async function(){
                  
                  if(global.isDatafono){
                    await myCrypto.imprimirAdyen(global.ticketRefFacturaProductos)
                  }else{
                    global.imprimirTicket(true,global.ticketRefFacturaProductos)
                  }
                },1000)
                
                
              }
              if(i==1){
                props.setFacturaComision(factura)
                setTimeout(async function(){
                  if(global.isDatafono){
                    await myCrypto.imprimirAdyen(global.ticketRefFacturaComision)
                  }else{
                    global.imprimirTicket(true,global.ticketRefFacturaComision)
                  }
                },1000)
              }


          }
        }
      }
      console.log("resobtenerFacturas",res)
    })
  }
  useEffect(() => {
    props.navigation.setOptions({
      headerRight: () => {
        return (
          <View>
           
            <View style={[{flex:1,alignItems:'center',justifyContent:'space-around',flexDirection:'row',paddingRight:20,minWidth:80}]}>
              {props.ultimoPedido!=''?(
                    <TouchableOpacity style={{marginRight:10,paddingVertical:5,width:50,borderRadius:6,backgroundColor:'#3AA597',alignItems:'center',justifyContent:'center',flex:1}} onPress={reImprimirFacturas.bind(this)}>
                      <AntDesign name="printer" size={25} color="white" />
                    </TouchableOpacity>
                    ):(null)
                }
              <View style={{}}>
                
                {props.conexion?(
                  <View style={{height:20,width:20, borderRadius:20,backgroundColor:'#73E13F'}}>
                  </View>
                  ):(
                  <View style={{height:20,width:20, borderRadius:20,backgroundColor:'red'}}>
                  </View>
                )}
              </View>
              

            </View>
          </View> 
        )
      }
    });
  }, [props.ultimoPedido,props.conexion]);
  
  console.log("tienda",tienda)
    useEffect(() => {
        props.navigation.setOptions({
            headerTitle:tienda.name
        });
      props.navigation.addListener('beforeRemove', () => {
        props.setFooterVisible(true)
      });
      if(familia!=''){

        setFamiliaSel(familia)
      }
    }, []);

  useEffect(() => {
    let precioTotalAux = 0
    let totalProductosAux = 0
    props.tiendas.map(function(tienda:any) {
      let precioTienda = 0
      tienda.productos.map(function(item:any) {
        precioTienda = precioTienda + item.cantidad*item.precioTotalProducto
        totalProductosAux=totalProductosAux+item.cantidad
      })
      
      precioTotalAux=precioTotalAux+precioTienda
    })
    setTotalProductos(totalProductosAux)
    if(totalProductosAux>0){
      props.setFooterVisible(false)
    }else{
      props.setFooterVisible(true)
    }
    setPrecioTotal(precioTotalAux)
  }, [props.tiendas]);
  const [familiaSel, setFamiliaSel] = useState(tienda.products[0]);
 
  function abrirVistaTicket(familia:any){
    let aux={
      tienda:tienda
    }
    props.navigation.navigate('VistaResumenPedido',aux)
  }

  function onClickFamilia(familia:any){
    setFamiliaSel(familia)
  }
  function abrirEscanerCashLess(){
    props.navigation.navigate('vistaEscanerCashLess')

  }
  /*
  */
  return (

        <View style={{backgroundColor:'white',width:'100%',height:'100%'}}>
          <View style={{marginTop:8,marginBottom:4}}>
            <FlatList
                data={tienda.products}
                keyExtractor={(item:object, index:number): string => index.toString()}
                showsHorizontalScrollIndicator={false}
                horizontal
                renderItem={function(item:any){
                  let familia = item.item
                  if(familia.products.length==0){
                    return(null)
                  }else{
                    return(
                      <TouchableOpacity 
                        style={[
                          {justifyContent:'center',marginRight:4,marginLeft:4,borderRadius:40,height:40},
                          familia.gid==familiaSel.gid ? {backgroundColor:'#3AA597'} : {borderWidth:1,borderColor:'#3AA597'}
                        ]}
                        onPress={onClickFamilia.bind(this,familia)}
                      >
                        <Text style={[{paddingLeft:20,paddingRight:20,paddingTop:5,paddingBottom:5,fontSize:16,color:'white',textAlign:'center'},familia.gid==familiaSel.gid ? {} : {color:'#3AA597'}]}>{familia.name}</Text>
                      </TouchableOpacity>
                    )
                  }
                }}
            />
          </View>
         
          <View style={{height:(props.height-180), marginTop:30}}>
            <MasonryList
              data={familiaSel.products}
              numColumns={3}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{paddingBottom:120,paddingLeft:4}}
              renderItem={({item}:any) => {
                return(<ProductoFoto tienda={tienda} navigation={props.navigation} producto={item}></ProductoFoto>)
                //return(<ProductoFamilia tienda={tienda} navigation={props.navigation} producto={item}></ProductoFamilia>)
              }}
            />
          </View>
          {
            global.pantallaDividida ?
              (null)
              :
              (
                <View style={{position:'absolute',bottom:0,width:'100%',zIndex:2}}>
                  {totalProductos>0?(
                    <View style={{backgroundColor:'white',height:50,width:'100%'}}>
                      <View style={{height:'100%',flexDirection:'row',justifyContent:'space-between'}}>
                        <View style={{height:'100%',justifyContent:'center',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                          {totalProductos==1?(
                            <Text style={{fontSize:16}}>{totalProductos}x Producto</Text>
                          ):(
                            <Text style={{fontSize:16}}>{totalProductos}x Productos</Text>
                          )}
                        </View>
                        <View style={{height:'100%',justifyContent:'center',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                          <Text style={{color:'#BABABA',fontSize:16}}>Total</Text>
                          <Text style={{fontSize:16}}>{precioTotal.toFixed(2)}</Text>
                        </View>
                        
                      </View>
                    </View>
                  ):(null)}
                  <View style={{flexDirection:'row',justifyContent:'space-between',flex:1}}>
                    <TouchableOpacity  onPress={abrirVistaTicket} style={{backgroundColor:'#3AA597',flex:1,alignItems:'center',justifyContent:'center'}}>
                        <Text style={{padding:13,fontSize:18,fontWeight:'bold',color:'white'}}>Aceptar</Text>
                    </TouchableOpacity>
                  </View>
               </View> 
              )
          }
          
        </View> 
        
  );
};
/*<Footer navigation={props.navigation}></Footer>*/
const mapDispatchToProps = {
  setFooterVisible,
  setFacturaProductos,
  setFacturaComision
};
const mapStateToProps = (state:any) => {
  return {
    tiendas: state.carrito.tiendas,
    height: state.dimensions.height,
    ultimoPedido:state.pedidos.ultimoPedido,
    conexion:state.dimensions.conexion
  };
};
export default connect(mapStateToProps,mapDispatchToProps) (VistaFamiliaProductos);
