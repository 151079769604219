import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { useTranslation } from "react-i18next";

// Components
import MainButton from "../../../components/MainButton";
import ModalBase from "../../../components/ModalBase";
import Divider from "../../../components/Divider";

const ConfirmModal = ({ visible, setModal, onFinish, roomNumber }) => {
  const { t } = useTranslation();

  const backHandler = () => {
    setModal();
  };

  return (
    <ModalBase modalVisible={visible} handleModal={() => setModal()}>
      <View style={styles.inputWrapper}>
        <Text style={styles.text}>{t("roomNumber.confirm")}:</Text>
        <Text style={styles.roomNumber}>{roomNumber}</Text>
        <Divider height={20} />
        <View style={styles.buttonWrapper}>
          <MainButton
            title={t("back")}
            color="white"
            textColor="black"
            borderColor="black"
            borderWidth={1}
            onPress={backHandler}
          />
          <Divider width={10} />
          <MainButton title={t("accept")} onPress={onFinish} />
        </View>
      </View>
    </ModalBase>
  );
};

export default ConfirmModal;

const styles = StyleSheet.create({
  inputWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    height: 50,
    flexDirection: "row",
    width: "100%",
  },
  image: {
    width: "100%",
    resizeMode: "contain",
    height: 70,
  },
  roomNumber: {
    fontSize: 22,
  },
  text: {
    fontSize: 18,
    color: "black",
    textAlign: "center",
  },
});
