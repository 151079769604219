import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import ActionButton from "./ActionButton";

const Actions = ({
  quantity,
  type,
  onAdd,
  onRemove,
  selected,
  moreAvailable,
  allowQuantity,
}) => {
  return (
    <View style={styles.container}>
      {allowQuantity && quantity > 0 ? (
        <>
          <TouchableOpacity style={styles.button} onPress={onRemove}>
            <MaterialCommunityIcons
              name={quantity === 1 ? "trash-can-outline" : "minus"}
              size={28}
              color="#C61414"
            />
          </TouchableOpacity>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{quantity}</Text>
          </View>
        </>
      ) : (
        <View />
      )}
      <ActionButton
        type={type}
        selected={selected}
        allowQuantity={allowQuantity}
        moreAvailable={moreAvailable}
        onAdd={onAdd}
      />
    </View>
  );
};

export default Actions;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "flex-end",
    height: 60,
  },
  button: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    backgroundColor: "#FFC4C4",
  },
  textContainer: {
    width: 50,
    alignItems: "center",
  },
  text: {
    fontSize: 24,
    fontWeight: "300",
  },
});
