import React,{Component,useReducer} from 'react';
import { createStyles, maxWidth} from 'react-native-media-queries';
import {AntDesign } from '@expo/vector-icons';
import {Dimensions,TouchableOpacity,Text,View} from "react-native";
const FamiliaListado = (props:any) => {
    const {familia,onPress} = props
    return(
        <View key={familia.id} style={styles.tienda} >
            <View style={[{width:'100%',borderRadius: 8,overflow:'hidden'}]}>
                <TouchableOpacity onPress={onPress.bind(this,familia)}>
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                        <Text style={{fontSize: 16,padding:8,fontWeight:'bold'}}>{familia.name}</Text>
                        <TouchableOpacity style={{paddingLeft:10}}>
                          <AntDesign name="right" size={24} color="black" />
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            </View>
        </View>

    );
    
}
export default FamiliaListado;
const base = {
  tienda: {
    width:'100%',
    padding:10,
    minWidth:300
  }
};
const styles = createStyles(
  base,
  // override styles only if screen height is less than 500
  maxWidth(400, {
    tienda: {
       width:'100%',
       minWidth:0
    }
  })
);