import Aes from 'react-native-aes-crypto'
//import RNFetchBlob from 'react-native-fetch-blob';
import Pbkdf2 from "react-native-fast-pbkdf2";
import { Buffer } from 'buffer';
import { captureRef } from 'react-native-view-shot';

const encryptData = (text, key,ivIn) => {
      return Aes.randomKey(16).then(iv => {

          //iv='b21897e17e08ee4be7a0c75e2f366e61'
          let ivMov= Buffer.from(iv, "hex")
          let ivOut= Buffer.alloc(16);
          for (var i = 0; i < 16; i++) {
            let result =ivIn[i]^ivMov[i];
           ivOut[i]=result
          }
          let ivAux = ivOut.toString('hex');
          return Aes.encrypt(text, key, ivAux,'aes-256-cbc').then(cipher => ({
              cipher,
              ivMov,
          }))
      })
  }
function getBlob(mensaje:any){
    return new Promise(async function(resolve, reject) {
      try {
          let pass = Buffer.from('comanderopassphrase');
          pass = pass.toString('base64');
          let salt = Buffer.from('AdyenNexoV1Salt');
          salt = salt.toString('base64');
          let res = await Pbkdf2.derive(pass, salt, 4000, 80, 'sha-1');
          //generateKey('comanderopassphrase', "AdyenNexoV1Salt", 4000,256).then(key => {
              console.log('Key:', res)
              
              //let keyadyen='d13b1af196ba150b0479ce87d4c09df09a5ce406dd3c455cb1f334745bdbd72c1b615a6b689aae3d3040a7d7c9d18268557126129471f350fb2706da62dcc13c249039c80eeeaa16a63be38632ccb41f'
              let keyBuff= Buffer.from(res, "base64")
              let hmac_keybuff= keyBuff.slice(0, 32)
              let hmac_key = hmac_keybuff.toString('hex');
              let cypher_keybuff= keyBuff.slice(32, 64)
              let cypher_key = cypher_keybuff.toString('hex');
              let iv_keybuff= keyBuff.slice(64, 80)
              let iv_key = iv_keybuff.toString('hex');

              console.log("hmac_key",hmac_key)
              console.log('cypher_key:', cypher_key)
              console.log('iv_key:', iv_key)
              let dkmHex={
                hmac_key:hmac_key,
                cypher_key:cypher_key,
                iv_key:iv_key
              }

              encryptData(mensaje, cypher_key,iv_keybuff)
                  .then(({ cipher, ivMov }) => {
                      console.log('Encrypted:', cipher)
                      console.log('iv Encrypted',ivMov)
                       /*decryptData({ cipher, iv }, key)
                          .then(text => {
                              console.log('Decrypted:', text)
                          })
                          .catch(error => {
                              console.log(error)
                          })*/

                         //cipher="U91N6k9F4r/CgdflirM8Xrb5ZcDuaXeAzdD5oHej4Nl0Haic8k9kF8sJQZ9VDHXI6zGz4JvdbaN1xz/Nx69DLwSSsHZjmhuFCYuXB6FP/sJZP9aZGbtfzUmCf2OylDKv0qH38lUCM4OtUT9r/B/ojmHre3tLh2h3kTfMtHJuJwVrletG8cslKxDmlJi6IN7XoEluhuxPy3Finsi3xN/V/Ccv3gcOKyPrRhtPaVZte/EYaSd2wyLhbM18423Yp4dRfCsLUCRJniAUeVutz6szgg+UqZ/wtipOLW2R6K1gyT5B0HPBfUvt1QE164lLnYIxr21UQlUHf49I9ngrAxu6iYjm87kdR4CwiM+ZkouXIXAuuIuvr3z6yLQC1Ug9FA5cFRla2zefFx5AQ0x2fNQtSxt9xHMpnxrNAwFDCQoBDKKEWhaPBhQmSVgmuemKpRqrxoS+qW0hzvSTnxx9yDn2lZbU20djqisJnaeDKBJ5gJzdFPagPImsvlae3VicCiFmvio+/4ltKHx6Ru/GQs6NJusz2wIuhlrDLwKQMqU+ouMGweNFYEATYy/ZFVGr320D"
                      Aes.hmac256(mensaje, hmac_key).then(hash => {
                          console.log('HMAC', hash)

                          let ivaux =  ivMov.toString('hex');
                          ivaux= Buffer.from(ivaux, 'hex').toString('base64');
                          let hashaux =  Buffer.from(hash, 'hex').toString('base64');

                       
                          resolve({mensaje:cipher,hmac:hashaux,iv:ivaux})
                      })
                  })
                  .catch(error => {
                      console.log("error encrypt",error)
                      reject()
                  })
          //})
      } catch (e) {
          console.error(e)
          reject()
      }
    })
  }

async function desencriptar(respuesta:any){
    try {

      let pass = Buffer.from('comanderopassphrase');
      pass = pass.toString('base64');
      let salt = Buffer.from('AdyenNexoV1Salt');
      salt = salt.toString('base64');
      let res = await Pbkdf2.derive(pass, salt, 4000, 80, 'sha-1');
      //generateKey('comanderopassphrase', "AdyenNexoV1Salt", 4000,256).then(key => {
          console.log('Key:', res)
          
          
          let keyBuff= Buffer.from(res, "base64")
          let hmac_keybuff= keyBuff.slice(0, 32)
          let hmac_key = hmac_keybuff.toString('hex');
          let cypher_keybuff= keyBuff.slice(32, 64)
          let cypher_key = cypher_keybuff.toString('hex');
          let iv_keybuff= keyBuff.slice(64, 80)
          let iv_key = iv_keybuff.toString('hex');

          console.log("hmac_key",hmac_key)
          console.log('cypher_key:', cypher_key)
          console.log('iv_key:', iv_key)

      var requestBody = respuesta.SaleToPOIResponse

      var payload = requestBody["NexoBlob"];
      //var bytes = base64ToBuffer(payload);

      var trailer = requestBody["SecurityTrailer"];
      
      var ivModB64 = trailer["Nonce"]
      var hmacB64 = trailer["Hmac"];

      //nexo_crypt(bytes, dkm.cipher_key, dkm.iv, ivMod, false);
                  //(bytes, key, ivIn, ivMod, encrypt)

      let ivMov= Buffer.from(ivModB64, "base64")
      let ivOut= Buffer.alloc(16);
      for (var i = 0; i < 16; i++) {
        let result =iv_keybuff[i]^ivMov[i];
       ivOut[i]=result
      }
      let ivAux = ivOut.toString('hex')

      let text =  await Aes.decrypt(payload, cypher_key, ivAux,'aes-256-cbc')
      return text
      

    }
    catch (error) {
      return 'Failed'
      console.log("error pago  cancelado",error)
    }
  }

async function imprimirAdyen(ref:any){
      let refAux =global.ticketRefDatafono
      if(ref){
        refAux=ref
      }
      console.log('refAux',refAux)
      setTimeout(async function(){
      let serviceID=new Date().getTime().toString()

      const result = await captureRef(refAux, {
        result:'base64',
        quality: 1,
        format: 'png',
      });
      console.log("result",result)
      let xmlStr='<?xml version="1.0" encoding="UTF-8"?><img src="data:image/png;base64, '+result+'"/>'
      var b = Buffer.from(xmlStr);
      var base64 = b.toString('base64');
      let printRequest={
      "SaleToPOIRequest":{
        "MessageHeader":{
           "ProtocolVersion":"3.0",
           "MessageClass":"Device",
           "MessageCategory":"Print",
           "MessageType":"Request",
           "ServiceID":serviceID.substr(serviceID.length - 8),
           "SaleID":"POSSystemID12345",
           "POIID":global.deviceName
        },
        "PrintRequest": {
           "PrintOutput": {
              "DocumentQualifier": "Document",
              "ResponseMode": "PrintEnd",
              "OutputContent": {
                 "OutputFormat": "XHTML",
                 "OutputXHTML": base64
              }
           }
        }
     }  
    }

     console.log("printRequest",JSON.stringify(printRequest))
      getBlob(JSON.stringify(printRequest)).then(async function(res:any){

        let result = {
          "SaleToPOIRequest":{

               "MessageHeader": {
                  "ProtocolVersion": "3.0",
                  "MessageClass": "Device",
                  "MessageCategory":"Print",
                  "MessageType": "Request",
                  "ServiceID":serviceID.substr(serviceID.length - 8),
                  "SaleID": "POSSystemID12345",
                  "POIID": global.deviceName
                },
                "NexoBlob":res.mensaje,
                "SecurityTrailer":{
                    "KeyVersion":1,
                    "KeyIdentifier":"comandero",
                    "Hmac":res.hmac,
                    "Nonce":res.iv,
                    "AdyenCryptoVersion":1
                }
            
          }
        }
        console.log("result",JSON.stringify(result))
        RNFetchBlob
        .config({
            trusty : true
        })
        .fetch('POST', 'https://localhost:8443/nexo',
          {
            'Accept': "application/json", 
            'Content-Type': 'application/json'
          },
          JSON.stringify(result)
        )
        .then(async (res) => {
          console.log("res",res)
        })
        .catch((err) => {
          console.log('err',err)
        })  
      },function(err){
        console.log("err",err)
      });
    },1000)
    
    
  }

export default {getBlob,imprimirAdyen,desencriptar};

