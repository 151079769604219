const getQuantityByGroup = (group) => {
  let quantity = 0;
  if (group?.opciones.length > 0) {
    for (const option of group?.opciones) {
      quantity += option.cantidad;
    }
  }
  return quantity;
};

export default getQuantityByGroup;
