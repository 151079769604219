import React from "react";
import { StyleSheet, Text, View } from "react-native";

const CategoryTitle = ({ title }) => {
  return (
    <View style={styles.headerContainter}>
      <View style={styles.headerContent}>
        <Text style={[styles.header, { color: "#3AA597" }]}>{title}</Text>
      </View>
    </View>
  );
};

export default CategoryTitle;

const styles = StyleSheet.create({
  headerContainter: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  headerContent: {
    width: "100%",
    maxWidth: 850,
    height: 40,
    justifyContent: "center",
    paddingLeft: 18,
    alignItems: "flex-start",
  },
  header: {
    fontSize: 22,
  },
});
