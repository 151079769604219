import React, { useState, useEffect, useRef } from 'react';
import {Dimensions,StyleSheet,TouchableOpacity,Text,View,TextInput,ScrollView} from "react-native";
import { Switch } from 'react-native-switch';

import { StatusBar } from 'expo-status-bar'
import { SearchBar } from 'react-native-elements';
import { connect } from "react-redux";
import {ordenarFiltrados, setPedidos,addPedido,eliminarPedido,editarPedido,setSocketConectado,setPedidosPerdidos } from "../../application/reducers";
import {Octicons,Entypo,MaterialIcons,AntDesign,EvilIcons,Ionicons ,FontAwesome,FontAwesome5,Feather } from '@expo/vector-icons';
import { createStyles, maxWidth} from 'react-native-media-queries';
import MasonryList from '@react-native-seoul/masonry-list';
import PedidoCocina from '../components/PedidoCocina';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
const TopBar = createMaterialTopTabNavigator();
const MenuFiltrosDatafono = (props:any) => {
   
   const [btnSel, setBtnSel] = useState('Para preparar');
   const [btnSelPreparar, setBtnSelPreparar] = useState('all');
   const [pedidosPreparar, setPedidosPreparar] = useState([]);

    function createTabParaEntregar(opcion){
      let pedidosAux=[]
      switch(opcion){
        case 'entregar':
          pedidosAux= props.pedidosParaEntregar
        break;
        case 'enviados':
          pedidosAux= props.pedidosEnviados
        break;
        case 'completados':
          pedidosAux= props.pedidosCompletados
        break;
      }

     return(
        <ScrollView style={{width:'100%',height:(props.height-210)}} contentContainerStyle={{paddingBottom:100}}>
          <MasonryList
            data={pedidosAux}
            numColumns={1}
            style={{width:'100%'}}
            renderItem={({item,i}:any) => {
              return(<PedidoCocina  key={i.toString()} navigation={props.navigation} pedido={item}></PedidoCocina>)

            }}
           
          />
        </ScrollView>
      )
    }
   function onPressBtn(btn:any){
      setBtnSel(btn)
    }
    function onPressPreparar(opcion){
      if(opcion==btnSelPreparar){
        setBtnSelPreparar('all')
      }else{
        setBtnSelPreparar(opcion)
      }
      
    }
    useEffect(() => {
      filtrarParaPreparar(props.pedidosPreparar,btnSelPreparar)
    }, [props.pedidosPreparar,btnSelPreparar]);

    function filtrarParaPreparar(pedidos,opcion){
      console.log("filtro")
      let pedidosCopia = pedidos.slice();
      if(opcion=='espera' || opcion =='proceso'){
        let pedidosAux=[]
          pedidosCopia.map(function(pedido:any,index:any){
            if(opcion=='espera' && pedido.state=='waiting'){
              pedidosAux.push(pedido)
            }else if(opcion=='proceso' && pedido.state=='processing'){
              pedidosAux.push(pedido)
            }
          })
           setPedidosPreparar(pedidosAux)
      }else{
        setPedidosPreparar(pedidosCopia)
      }
      
    }
    function cambiarEstado(pedido:any){
    let listadoPedidosAux = props.pedidos.slice()
    props.setPedidos(listadoPedidosAux)
  } 
    return(
      <View style={{flex:1,backgroundColor:'white'}}>
        <View style={{height:40,flexDirection:'row',borderRadius:40,backgroundColor:'white',marginBottom:8,marginTop:8,marginHorizontal:20,borderWidth:1,borderColor:'#757575'}}>
          <TouchableOpacity onPress={onPressBtn.bind(this,'Para preparar')} style={[styles.btn,btnSel=='Para preparar'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
            <Text style={[styles.textBtn,btnSel=='Para preparar'?{color:'white'}:{color:'black'}]}>Para preparar</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={onPressBtn.bind(this,'Para entregar')} style={[styles.btn,btnSel=='Para entregar'?{backgroundColor:'#3AA597',borderColor:'#3AA597'}:{}]}>
            <Text style={[styles.textBtn,btnSel=='Para entregar'?{color:'white'}:{color:'black'}]}>Para entregar</Text>
          </TouchableOpacity>
        </View>
        {btnSel=='Para preparar'?(
           <View>
              <View style={{height:50,flexDirection:'row'}}>
                
                <TouchableOpacity onPress={onPressPreparar.bind(this,'espera')} style={[styles.btnPreparar,btnSelPreparar=='espera'?{backgroundColor:'#3AA597'}:{}]}>
                  <Text style={[styles.textBtn,btnSelPreparar=='espera'?{color:'white'}:{color:'black'}]}>En espera</Text>
                  <View>
                      <View style={styles.numContainer}>
                        <Text  style={styles.numText}>{props.numeroEspera}</Text>
                      </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={onPressPreparar.bind(this,'proceso')} style={[styles.btnPreparar,btnSelPreparar=='proceso'?{backgroundColor:'#3AA597'}:{}]}>
                  <Text style={[styles.textBtn,btnSelPreparar=='proceso'?{color:'white'}:{color:'black'}]}>En proceso</Text>
                  <View>
                      <View style={styles.numContainer}>
                        <Text  style={styles.numText}>{props.numeroProceso}</Text>
                      </View>
                  </View>
                </TouchableOpacity>
               
              </View>
              <ScrollView style={{width:'100%',height:(props.height-150)}} contentContainerStyle={{paddingBottom:50}}>
                <MasonryList
                  data={pedidosPreparar}
                  numColumns={1}
                  style={{width:'100%'}}
                  renderItem={({item,i}:any) => {
                    return(<PedidoCocina  key={i.toString()} navigation={props.navigation} pedido={item} cambiarEstado={cambiarEstado.bind(this,item)}></PedidoCocina>)

                  }}
                 
                />
              </ScrollView>
            </View>
          ):(
          <TopBar.Navigator
          sceneContainerStyle={{backgroundColor: 'transparent'}}
          style={{backgroundColor: 'transparent'}}
          screenOptions={{
            tabBarActiveTintColor: '#3AA597',
            tabBarInactiveTintColor: 'black',
            tabBarLabelStyle: { fontSize: 14,fontWeight:'bold',height:50 },
            tabBarItemStyle: [{ width: (props.width)/3,height:50}],
            tabBarStyle:  [{ backgroundColor: 'white',height:50}],
            tabBarIndicatorStyle:{backgroundColor:'#3AA597',height:3},
            tabBarPressColor:'white'
          }}  >
          <TopBar.Screen name="paraEntregar" component={createTabParaEntregar.bind(this,'entregar')} options={{tabBarLabel:() => {
              return(
                <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Text style={styles.textoTab}>Entregar</Text>
                  <View>
                      <View style={styles.numContainer}>
                        <Text  style={styles.numText}>{props.numeroRecoger}</Text>
                      </View>
                  </View>
                </View>
              )
            }
          }}/>
          <TopBar.Screen name="enviados" component={createTabParaEntregar.bind(this,'enviados')} options={{tabBarLabel:() => {
              return(
                <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Text style={styles.textoTab}>Enviados</Text>
                  <View>
                      <View style={styles.numContainer}>
                        <Text  style={styles.numText}>{props.numeroEnviar}</Text>
                      </View>
                  </View>
                </View>
              )
            }
          }}/>
          <TopBar.Screen name="completados" component={createTabParaEntregar.bind(this,'completados')} options={{tabBarLabel:() => {
              return(
                <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Text style={styles.textoTab}>Comple.</Text>
                  <View>
                      <View style={styles.numContainer}>
                        <Text  style={styles.numText}>{props.numeroCompletado}</Text>
                      </View>
                  </View>
                </View>
              )
            }
          }}/>
        </TopBar.Navigator>
          )}
      </View>
    );
    
}
const mapDispatchToProps = {
  ordenarFiltrados,
  setPedidos,
  addPedido,
  eliminarPedido,
  editarPedido,
  setSocketConectado,
  setPedidosPerdidos
};
const mapStateToProps = (state:any) => {
  return {
   socketConectado: state.pedidos.socketConectado,
   pedidos: state.pedidos.pedidos,
   pedidosParaEntregar:state.pedidos.pedidosParaEntregar,
   pedidosEnviados:state.pedidos.pedidosEnviados,
   pedidosCompletados:state.pedidos.pedidosCompletados,
   pedidosPreparar:state.pedidos.pedidosPreparar,
   width: state.dimensions.width,
   height: state.dimensions.height,
    opcionVista:state.config.opcionVista
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(MenuFiltrosDatafono);
const styles = createStyles({
  btn:{
    flex:1,
    borderRadius:40,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center'
  },
  btnPreparar:{
    flex:1,
    backgroundColor:'white',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'row'
  },
  textBtn:{
    fontWeight:'bold'
  },
  btnFiltro:{
    height:40,
    justifyContent:'center',
    paddingHorizontal:10,
    borderBottomWidth:1,
    borderBottomColor:'black',
    marginHorizontal:10,
    flexDirection:'row',
    alignItems:'center'
  },
  textBtnFiltro:{
    fontWeight:'bold'
  },
  numContainer:{
    borderRadius:4,
    backgroundColor:'#3AA597',
    justifyContent:'center',
    alignItems:'center',
    width:30,
    height:30,
    marginLeft:10
  },
  numText:{
    fontWeight:'bold',
    color:'white'
  },
  btnAgrupados:{
    borderWidth:1,
    borderColor:'black',
    borderRadius:45,
    height:45,
    flexDirection:'row',
    alignItems:'center',
    padding:10,
    paddingLeft:20,
    width:220,
    justifyContent:'flex-start'
  },
  textoTab:{
    fontWeight:'bold',
    fontSize:14
  },
});