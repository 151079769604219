import {
  BluetoothManager,
  BluetoothEscposPrinter,
  BluetoothTscPrinter,
} from "react-native-bluetooth-escpos-printer";
import {DeviceEventEmitter } from 'react-native';

export default class ImpresoraController{
   connection=false
   colaImpresion=[]
   #running:boolean;
   #colaPedidos:Array<any>;
   constructor() {
     this.connection=false
     this.#running=false
     this.#colaPedidos=[]
     this.cargaEventoDesconectado()
   }
   public addColaPedidos(pedido:number,setPedidoImprimir:any,ref:boolean):void{
      console.log("#colaPedidos",this.#colaPedidos)
      this.#colaPedidos.push({"pedido":pedido,"ref":ref})
      if(!this.#running) this.printCola(setPedidoImprimir)
      return 
   }
   public async printCola(setPedidoImprimir:any){
      this.#running=true
      let long = this.#colaPedidos.length
      while(this.#colaPedidos.length>0){
         let elementoCola=this.#colaPedidos.shift()
         let pedido= elementoCola.pedido
         console.log("pedido",pedido)
         setPedidoImprimir(pedido)
         await global.imprimirTicket(elementoCola.ref)
         //renderizar el componente del pedido a imprimir
         //await this.imprimirTicket()
      }
      this.#running=false
      
   }
   
   async conectarImpresora(address:any){
      var t = this;
      console.log("conectarImpresora")
      console.log("BluetoothManager",BluetoothManager)
      //return new Promise(async function(resolve, reject) {
         if(t.conectarBluetooth()){
              console.log("conectado")
            if(t.connection){
               return true
            }else{
               console.log("connect",address)
               try {
                  await BluetoothManager.connect(address)
                  console.log("then connect")
                  t.connection=true
                  return true
               } catch (e) {
                  console.log("error connect",e)
                  return false
                  t.#running=false
               }
               
               
            }
              
         }
      //})
   }
   async imprimirTicket(img:any){
      console.log("img ",img)
      try
      {
         await BluetoothEscposPrinter.openDrawer(0, 250, 250);
         await BluetoothEscposPrinter.setWidth(576)
         await BluetoothEscposPrinter.printPic(img, {
           width: 576,
           left: 0,
         });
         
      }catch(err){
         console.log("err",err)
      }
      
   }
   async imprimirCola(){
      let t = this
      console.log("t.colaImpresion",t.colaImpresion)
      let long = t.colaImpresion.length
      for(let i=0;i<long;i++){
         console.log(i)
         let img=t.colaImpresion.shift()
         await t.imprimirTicket(img)
      }
   }
   async conectarBluetooth(){
      await BluetoothManager.isBluetoothEnabled().then(async (enabled)=> {
        if(enabled){
          return true
        }else{
         console.log("Bluetooth desactivado")
         await BluetoothManager.enableBluetooth().then(
            (r) => {
               console.log("activado")
               return true
            },
            (err) => {
               console.log("error")
               console.log('No se ha podido activar el bluetooth');
               return false
             
            }
          );
        }
    }, (err)=> {
       console.log('Comprueba que el bluetooth está activado')
        return false
        
    });
  }
  cargaEventoDesconectado(){
    let t=this
    if(BluetoothManager!=undefined){
      DeviceEventEmitter.addListener(
        BluetoothManager.EVENT_CONNECTION_LOST,
        (rsp) => {
          console.log("desconectado")
          t.connection=false
        }
      );
  }
  }
   
} 
