import React, { useState } from "react";
import { TouchableOpacity, Text, View, Image, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Reducers
import {
  obtenerProductos,
  addProducto,
  eliminarProducto,
} from "../../application/reducers";
import VariantsModal from "../screens/FoodStallScreenLegacy/components/modals/VariantsModal";

const ProductoFoto = (props: any) => {
  const { producto, navigation, tienda, onClickProductoMenu } = props;
  const [modal, setModal] = useState<String>();

  let name = producto.name;

  const hasImage =
    producto.image != "" &&
    producto.image != undefined &&
    producto.image != null;

  const { t } = useTranslation();

  if (name.length > 26) {
    name = name.substring(0, 26);
    name = name + "...";
  }
  function addProducto(productoAux: any) {
    props.addProducto(tienda, productoAux);
  }
  function eliminarProducto(productoAux: any) {
    props.eliminarProducto(tienda, productoAux);
  }

  const openVariants = () => {
    let aux = {
      producto: producto,
      tienda: tienda,
    };
    navigation.navigate("listadoVariantes", aux);
  };

  function abrirDescripcionLarga() {
    let aux = {
      producto: producto,
      tienda: tienda,
    };
    navigation.navigate("descripcionLarga", aux);
  }
  function onLongClickProducto() {
    if (
      producto.long_description != "" &&
      producto.long_description != undefined
    ) {
      abrirDescripcionLarga();
    }
  }
  function onClickProducto() {
    switch (producto.product_type) {
      case "variantes":
        // setModal("Variants");
        openVariants();
        break;
      case "simple":
      case "Producto Individual":
      case "":
      case "individual":
      case "bono":
      case "credit":
        if (producto.variants.length > 0 || producto.ingredients.length > 0) {
          // setModal("Variants");
          openVariants();
        } else {
          addProducto(producto);
        }
        break;
      case "menu":
      case "Menú":
        if (producto.variants.length > 0 || producto.ingredients.length > 0) {
          // setModal("Variants");
          openVariants();
        } else {
          abrirProductoMenu();
        }

        break;
    }
  }
  function onPressProducto() {
    if (producto.stock === 0) {
    } else {
      if (onClickProductoMenu == undefined) {
        onClickProducto();
      } else {
        onClickProductoMenu();
      }
    }
  }

  return (
    <TouchableOpacity
      onPress={onPressProducto}
      onLongPress={onLongClickProducto}
      style={{ marginRight: 10 }}
    >
      <View style={styles.content}>
        <Image
          style={styles.image}
          source={
            hasImage
              ? { uri: producto.image }
              : require("../../assets/icons/productoSinImagen.jpg")
          }
        />
        {producto.stock === 0 ? (
          <Image
            style={styles.soldOut}
            source={require("../../assets/icons/sold_out.png")}
          />
        ) : null}
      </View>
      <View style={styles.nameContainer}>
        <Text style={styles.name}>{name}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  content: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
    overflow: "hidden",
  },
  image: {
    width: 250,
    borderRadius: 8,
    height: 170,
    resizeMode: "cover",
  },
  soldOut: {
    position: "absolute",
    top: 0,
    width: 250,
    borderRadius: 8,
    height: 170,
    resizeMode: "cover",
  },
  nameContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: 16,
    padding: 8,
    textAlign: "center",
  },
});

const mapDispatchToProps = {
  obtenerProductos,
  addProducto,
  eliminarProducto,
};

const mapStateToProps = (state: any) => {
  return {
    tiendas: state.carrito.tiendas,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductoFoto);
