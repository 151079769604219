import React, { useState, useRef, useEffect, useCallback } from "react";
import { StyleSheet, View, SectionList } from "react-native";

// Components
import CategoriesCarousel from "./CategoriesCarousel";
import CategoryTitle from "./CategoryTitle";

// Methods
import SmallProductItem from "../SmallProductItem";
import { getProductSections } from "../../methods/getProductSections";
import { getCategoriesArray } from "../../methods/getCategoriesArray";

const ExtendedList = ({ products, shop, setShop }) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [viewableItem, setViewableItem] = useState();

  const productList = getProductSections(products);
  const categoriesList = getCategoriesArray(products);

  const productListRef = useRef();
  const carouselRef = useRef();
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 75 });

  const categoriesListHandler = (index) => {
    setSelectedCategory(index);
    if (carouselRef.current) {
      carouselRef?.current?.scrollToIndex({
        index: index,
        animated: true,
        viewPosition: 0,
      });
    }
  };

  const categoriesHandler = (index) => {
    categoriesListHandler(index);
    if (productListRef.current) {
      productListRef.current.scrollToLocation({
        animated: false,
        sectionIndex: index,
        itemIndex: 0,
      });
    }
  };

  // Updates current viewable item in product list by scroll
  useEffect(() => {
    if (viewableItem?.title) {
      let auxIndex = -1;
      categoriesList.map((cat, index) => {
        if (cat.gid === viewableItem.gid) {
          auxIndex = index;
        }
      });
      if (auxIndex !== -1) {
        categoriesListHandler(auxIndex);
      }
    }
  }, [viewableItem]);

  const _onViewableItemsChanged = useCallback(({ viewableItems }) => {
    setViewableItem(viewableItems[0]?.item);
  }, []);

  const _viewabilityConfig = {
    itemVisiblePercentThreshold: 100,
  };

  return (
    <View style={styles.productsSelector}>
      <View style={styles.categoriesWrapper}>
        <CategoriesCarousel
          categoriesList={categoriesList}
          selectedCategory={selectedCategory}
          categoriesHandler={categoriesHandler}
          listRef={carouselRef}
        />
      </View>
      <SectionList
        style={styles.content}
        viewabilityConfig={viewConfigRef.current}
        contentContainerStyle={styles.scrollContent}
        showsVerticalScrollIndicator={false}
        ref={productListRef}
        onViewableItemsChanged={_onViewableItemsChanged}
        viewabilityConfig={_viewabilityConfig}
        sections={productList}
        keyExtractor={(item, index) => item + index}
        renderItem={({ item }) => (
          <SmallProductItem key={item.gid} data={item} />
        )}
        ListFooterComponent={() => <View style={{ height: 200 }}></View>}
        stickySectionHeadersEnabled={false}
        renderSectionHeader={({ section: { title } }) => (
          <CategoryTitle title={title} />
        )}
      />
    </View>
  );
};

export default ExtendedList;

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    maxWidth: 850,
  },
  scrollContent: {
    paddingHorizontal: 12,
    width: "100%",
    paddingTop: 60,
  },
  productsSelector: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
  },
  categoriesWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    backgroundColor: "white",
    zIndex: 10,
  },
  productListWrapper: {
    width: "100%",
  },
});
