import { StyleSheet, Dimensions } from 'react-native'

export const styles = StyleSheet.create({
  imagenAnimada: {
    opacity: 0
  },
  image:{
    flex: 1,
    resizeMode: "cover",
    justifyContent: 'center',
    alignItems: 'center', 
  },
  mainBody: {
        flex: 1,
        justifyContent: 'center',
  },
  containerHead:{
    alignItems: 'center',
    //width: '100%',
    //marginBottom: 24,
    //position: 'absolute' ,
    //paddingTop:93,
    //height: '100%',
  },
  inputFormBad: {
      borderColor: 'red',
      borderWidth: 1
  },
  inputForm: {
    //padding: 20,
    marginBottom: 12,
    textAlign:'center',
    paddingTop:4,
    /*paddingBottom: 20,*/
    //zIndex: -1,
    color:'#3AA597',
    paddingBottom: 0,
    fontSize: 16,
    width: '60%',
    height: 54,
    borderRadius: 50,
    backgroundColor: "white",

  },
  inputFormLogin:{
    marginBottom: 12,
    textAlign:'center',
    paddingTop:4,
    color:'#3AA597',
    paddingBottom: 0,
    fontSize: 16,
    width: '80%',
    height: 54,
    borderRadius: 50,
    backgroundColor: "white",
  },
  inputFormDelivery:{
    marginBottom: 12,
    textAlign:'center',
    paddingTop:4,
    /*paddingBottom: 20,*/
    //zIndex: -1,
    color:'black',
    paddingBottom: 0,
    fontSize: 16,
    width: '70%',
    height: 34,
    borderRadius: 50,
    backgroundColor: "white",
  },
  inputBuscador: {
    textAlign:'center',
    color:'#3AA597',
    fontSize: 16,
    width: '60%',
    height: 25,
    borderRadius: 50,
    backgroundColor: "white"
  },
  labelStyle:{
      top:20,
      marginTop: -10,
      position:'absolute',
      width:'100%',
      textAlign: 'center',
      paddingTop: 4,
      lineHeight: 12 ,
      paddingRight: 0,
      height:  20,
      color: "#3AA597",
      fontSize: 16,
      marginBottom: 0
  },
  textoBtn:{
      height: 19,
      lineHeight: 19,
      fontSize: 18,
      fontWeight: "bold",
      fontStyle: "normal",
      color:'white',
      letterSpacing: 1.13,
      textAlign: "center"
  },
  btnLogin:{
      marginBottom:24,
      paddingBottom:10,
      paddingTop:10,
      paddingRight: 25,
      paddingLeft: 25,
      borderRadius: 50,
      backgroundColor: '#3AA597',
      width: '40%',
      height: 54,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 8,

    
  },
   barraAccesoriosFlechas: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  barraAccesoriosBoton: {
    flex: 1,
    right: 10,
    alignItems: 'flex-end'
  },
  barraAccesorios: {
    flex: 1,
    flexDirection: 'row',
  },
  arrowLeft: {
    marginLeft: 20
  },
  arrowRight: {
    marginLeft: 20
  },
  btnMenu:{
    backgroundColor:'#DCDCDC',
    width:200,
    height:200,
    alignItems:'center',
    justifyContent:"center",
    borderRadius:16
  },
  btnMenuPedido:{
    backgroundColor:'#DCDCDC',
    width:200,
    height:100,
    alignItems:'center',
    justifyContent:"center",
    borderRadius:16,
    padding:10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  btnCalculadora:{
    backgroundColor:'#5C3D2E',
    height:70,
    width:70,
    borderRadius:70,
    justifyContent:'center',
    marginBottom:20
  },
  txtCalculadora:{
    textAlign:'center',
    color:'white',
    fontSize:20
  },
  btnMoneda:{
    backgroundColor:'#5C3D2E',
    justifyContent:'center',
    flex:1,
    margin:0.5
  },
  txtMoneda:{
    textAlign:'center',
    color:'white',
    fontSize:16
  }
}); 
