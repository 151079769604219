import React, { useEffect } from "react";
import { StyleSheet, Text, View } from "react-native";

// Components
import Divider from "../../../components/Divider";
import Ingredient from "./Ingredient";

// Methods
import hasIngredients from "../methods/hasIngredients";

const Ingredients = ({ data, selectedIngredients, setSelectedIngredients }) => {
  const { ingredients, ingredients_group_nombre } = data;

  const handleIngredient = (el) => {
    if (selectedIngredients.includes(el)) {
      setSelectedIngredients(selectedIngredients.filter((ing) => ing !== el));
    } else {
      setSelectedIngredients([...selectedIngredients, el]);
    }
  };

  if (hasIngredients(data)) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>{ingredients_group_nombre}</Text>
        <Divider height={20} />
        <View style={styles.ingredientsWrapper}>
          {ingredients.map((ingredient) => (
            <Ingredient
              name={ingredient}
              selected={!selectedIngredients.includes(ingredient)}
              onPress={handleIngredient}
            />
          ))}
        </View>
      </View>
    );
  } else {
    return <View></View>;
  }
};

export default Ingredients;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingTop: 0,
    padding: 28,
  },
  title: {
    fontSize: 28,
    textAlign: "left",
    color: "black",
    fontWeight: "bold",
  },
  ingredientsWrapper: {
    width: "100%",
  },
});
